<template>
  <div class="section-chat">
    <HeaderChat :data="ticketSelected" ref="headerChat" :loading="loading" />
    <SectionBoard
      :data="ticketSelected"
      :data-client="client"
      type="inbox"
      id="sectionBoard"
      :loading="loading"
    />
    <ChatInput
      v-show="
        ticketSelected && ticketSelected.status === 'active' && !profileRemote
      "
      ref="inputChat"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import HeaderChat from '@/app/tickets/components/organisms/headers/HeaderChat.vue'
import SectionBoard from '@/app/tickets/components/organisms/sections/SectionBoard.vue'
import ChatInput from '@/app/tickets/components/organisms/inputs/ChatInput.vue'

export default {
  name: 'SectionChat',
  props: {
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    HeaderChat,
    SectionBoard,
    ChatInput,
  },
  data: () => ({
    stripeSection: null,
  }),
  computed: {
    ...mapGetters(['ticketSelected', 'client', 'profileRemote']),
  },
}
</script>

<style lang="sass" scoped>
.section-chat
  background-color: $white_000
  width: 100%
  height: 100%
  border-radius: 8px
  border: 1px solid #EBEBEB
  position: relative
  display: flex
  flex-direction: column
  .section-board
    height: 100%
  .chat-input
    bottom: 0
    width: 100%
</style>
