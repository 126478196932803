import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'
import { message } from 'ant-design-vue'
import { USERS } from '@/app/accounts/utils/accountsUtil'

const actions = {
  /**
   * Lista las cuentas de usuario de una empresa
   * @param {*} context
   * @param {Object} args
   * @param {String} args.accountStatus Estado de la cuenta del usuario a listar
   * @param {Number} args.pagination Número de paginación
   */
  async listAccounts(
    context,
    { accountStatus = 'active', pagination = 1, typeUser, search = '' }
  ) {
    if (
      !(await compare.isAllowedFor(context, [
        'admin',
        'supervisor',
        'master_agent',
      ]))
    )
      return
    let getQuery = ''
    if (typeUser) getQuery = `type=${typeUser}&`
    if (search) getQuery += `search=${search}`

    const url = `${vari.UHR}/admin/account/list/${accountStatus}/${pagination}?${getQuery}`
    await axios
      .get(url)
      .then((r) => {
        context.commit('SET_LIST_ACCOUNT', r.data.accounts)
        context.commit('SET_TOTAL_ACCOUNTS', r.data.total)
      })
      .catch((e) => {
        console.error(e)
      })
  },
  /** Alex Christian */
  /**
   * Obtener cuentas de usuarios
   * @param context
   * @param self: componente.vue
   * @param self.sectionLine: objeto en data
   * @param self.sectionLine.list: objeto para filtrar, buscar, loading, etc
   * @returns {Promise<void>}
   */
  async getAccounts(context, { self }) {
    if (
      !(await compare.isAllowedFor(context, [
        'admin',
        'supervisor',
        'master_agent',
      ]))
    )
      return

    self.sectionAccounts.list.data = []
    self.sectionAccounts.list.loading.table = true

    const url = `${vari.UHR}/admin/account/list/${self.sectionAccounts.list.account_status}/${self.sectionAccounts.list.pagination}`
    const params = {
      type: self.sectionAccounts.list.type_user,
      search: self.sectionAccounts.list.search,
      paymentStatus: self.sectionAccounts.list.account_payment_status,
    }
    await axios
      .get(url, { params: params })
      .then((r) => {
        if (r.status === 200) {
          self.sectionAccounts.list.loading.table = false
          self.sectionAccounts.list.data = r.data.accounts

          context.commit('SET_LIST_ACCOUNT', r.data.accounts)
          context.commit('SET_TOTAL_ACCOUNTS', r.data.total)
        }
      })
      .catch((e) => {
        self.sectionAccounts.list.loading.table = false
        console.error('[accountModule][getAccounts]', e)
      })
  },
  /**
   * Crear nuevo usuario
   * @param {vuex} context
   * @param {Object}    args
   * @param {Component} args.self componente.vue
   * @param {Object}    args.self.sectionAccounts objeto en data
   * @param {Object}    args.self.sectionAccounts.createoredit objeto para crear o actualizar
   * @param {String}    args.type_role Tipo de usuario o rol
   * @param {String}    [args.integrationId]
   * @returns {Promise<void>}
   */
  async createAccount(context, { self, type_role, companyIntegrationId }) {
    if (!type_role) throw `${type_role} invalid`

    let url = `${vari.UHR}/${type_role}/create`

    return await axios
      .post(url, self.sectionAccounts.createoredit)
      .then((r) => {
        if (!r.data.success) {
          if (r.data.result === 'duplicated') {
            if (r.data.fields.email !== undefined)
              return self.$message.error(
                'Lo sentimos, el Correo del usuario ya existe'
              )
            if (r.data.fields.username !== undefined)
              return self.$message.error(
                'Lo sentimos, el ID usuario del usuario ya existe'
              )
          } else if (r.data.result === 'exists') {
            if (r.data.fields.email !== undefined)
              return self.$message.error(
                'Lo sentimos, el Correo del usuario ya existe'
              )
            if (r.data.fields.username !== undefined)
              return self.$message.error(
                'Lo sentimos, el ID usuario del usuario ya existe'
              )
          } else if (r.data.result === 'external-duplicated') {
            const crmIntegration =
              self.sectionAccounts.createoredit.externals.find(
                (ext) =>
                  ext.active === true &&
                  ext.companyIntegrationId === companyIntegrationId
              )
            return self.$message.error(
              `Lo sentimos, el ${crmIntegration.field.name} (${crmIntegration.platform}) ya existe`
            )
          }
        } else {
          const user = USERS[self.sectionAccounts.createoredit.type]
          self.sectionAccounts.createoredit[user.id_property_in_create] =
            r.data[user.id_property]

          self.sectionAccounts.createoredit.created_at = new Date()
          self.sectionAccounts.createoredit.active = true // Usuario activo por defecto

          //Emitir evento socket.io
          this._vm.$socket.emit(
            'server:company:new:user',
            self.sectionAccounts.createoredit
          )

          //pushear
          context.commit(
            'SET_CREATE_ACCOUNT',
            self.sectionAccounts.createoredit
          )
          context.dispatch('setAnalytics', {
            eventName: 'create_user',
            extraData: { created_user_type: type_role },
          })

          const arrLines = self.sectionAccounts.createoredit.company.linesId
          if (arrLines.length > 0) {
            for (const line of arrLines) {
              // Asignar cola al agente
              context.commit('SET_LINE_IN_PROFILE', {
                lineId: line,
                asigned: true,
              })
            }
          }

          //Obtener cuentas de usuario
          context.dispatch('getAccounts', { self: self })

          //Modal
          // para saber cuando resetear
          self.successCreate = true
          // para cerrar modal
          self.modalCreateAccountVisible = false
          self.$message.success('Usuario registrado correctamente')
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          message.warning(error.response.data.details)
          context.commit('SET_MODAL_UPGRADE', { visible: true })
          return
        }
        return { result: 'overflow' }
      })
  },
  /**
   * Actualizar cuenta de usuario
   * @async
   * @param {vuex}      context
   * @param {Object}    args
   * @param {Component} args.payload.self componente.vue
   * @param {Object}    args.payload.self.sectionAccounts objeto en data
   * @param {Object}    args.payload.self.sectionAccounts.createoredit objeto para crear o actualizar
   * @param {String}    [args.payload.companyIntegrationId]
   * @returns {Promise<void>}
   */
  async updatedAccount(context, { self, companyIntegrationId }) {
    let type = self.sectionAccounts.createoredit.type
    let masterAgentMode = self.masterAgentMode
    let url = null

    switch (type) {
      case 'master_agent':
        if (masterAgentMode) {
          url = `${vari.UHR}/agent/update`
        } else {
          url = `${vari.UHR}/admin/update`
        }
        break
      case 'admin':
        url = `${vari.UHR}/admin/update`
        break
      case 'admin_agent':
      case 'agent':
        url = `${vari.UHR}/agent/update`
        break
      case 'supervisor_agent':
      case 'supervisor':
        url = `${vari.UHR}/supervisor/update`
        break
    }

    await axios
      .put(url, self.sectionAccounts.createoredit)
      .then((r) => {
        if (!r.data.success) {
          if (r.data.result === 'duplicated') {
            if (r.data.fields.email !== undefined)
              return self.$message.error(
                'Lo sentimos, el Correo del usuario ya existe'
              )
            if (r.data.fields.username !== undefined)
              return self.$message.error(
                'Lo sentimos, el ID usuario del usuario ya existe'
              )
          } else if (r.data.result === 'exists') {
            if (r.data.fields.email !== undefined)
              return self.$message.error(
                'Lo sentimos, el Correo del usuario ya existe'
              )
            if (r.data.fields.username !== undefined)
              return self.$message.error(
                'Lo sentimos, el ID usuario del usuario ya existe'
              )
          } else if (r.data.result === 'external-duplicated') {
            const crmIntegration =
              self.sectionAccounts.createoredit.externals.find(
                (ext) => ext.companyIntegrationId === companyIntegrationId
              )
            return self.$message.error(
              `Lo sentimos, el ${crmIntegration.field.name} (${crmIntegration.platform}) ya existe`
            )
          }
        } else {
          //pushear
          context.commit('SET_EDIT_ACCOUNT', self.sectionAccounts.createoredit)
          // Si es el mismo usuario el q actualiza sus datos
          const me =
            context.getters.profile._id ===
            self.sectionAccounts.createoredit._id
          if (me) {
            context.commit(
              'RESET_LINES_IN_PROFILE',
              self.sectionAccounts.createoredit.company.linesId
            )
          }
          //Obtener cuentas de usuario
          context.dispatch('getAccounts', { self: self })

          //Modal
          self.visibleModalUpdate = false
          self.$message.success('Usuario actualizado correctamente')
        }
      })
      .catch((error) => {
        console.error(error.response)
        // return error.response
      })
  },

  /**
   *
   * @param {*} context
   * @param {Object} profile
   * @returns
   */
  async updateUserProfile(context, profile) {
    let url = `${vari.UHR}/user/update/profile`

    const formData = new FormData()
    formData.append('profileId', profile.profileId)
    formData.append('userId', profile.userId)
    if (profile.avatar) formData.append('avatar', profile.avatar)
    formData.append('email', profile.email)
    formData.append('names', profile.names)
    if (profile.currentPassword)
      formData.append('currentPassword', profile.currentPassword)
    if (profile.password) formData.append('password', profile.password)
    formData.append('code', profile.code)
    formData.append('phone', profile.phone)
    formData.append('surnames', profile.surnames)

    try {
      const response = await axios.post(url, formData)
      return response.data
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  },

  async inactiveUser(context, userId) {
    try {
      const response = await axios.patch(`${vari.UHR}/users/${userId}`)
      context.dispatch('getCompany')

      return response.data
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  },

  async activeUser(context, userId) {
    try {
      const response = await axios.patch(`${vari.UHR}/users/${userId}`, {
        active: true,
      })
      context.dispatch('getCompany')

      return response.data
    } catch (error) {
      console.log(error.response)
      if (error.response.data.details == 'Insufficient') {
        context.commit('SET_MODAL_UPGRADE', { visible: true })
      }
      return { success: false }
    }
  },

  async updateCompanyProfile(context, profile) {
    let url = `${vari.UHR}/admin/company/profile`

    const formData = new FormData()
    formData.append('companyId', profile.companyId)
    if (profile.banner) formData.append('banner', profile.banner)
    formData.append('name', profile.name)
    formData.append('country', profile.country)
    formData.append('dialCode', profile.dialCode)
    formData.append('operation', profile.operation)
    formData.append('workers', profile.workers)
    formData.append('industry', profile.industry)
    formData.append('address', profile.address)
    formData.append('webpage', profile.webpage)

    try {
      const response = await axios.post(url, formData)
      if (response.data.success) {
        const companyUpdated = response.data.result
        context.commit('SET_COMPANY', {
          company: {
            banner: companyUpdated?.banner,
            name: companyUpdated.name,
            country: companyUpdated.country,
            operation: companyUpdated.operation,
            workers: companyUpdated.workers,
            industry: companyUpdated.industry,
            address: companyUpdated.address,
            webpage: companyUpdated.webpage,
          },
          setAll: false,
        })
        context.dispatch('listTicketsUnreads')
      }

      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Elimina la cuenta de un usuario
   * @param {String} typeUser
   * @param {String} userId
   * @returns {Object}
   */
  async deleteAccount(context, { typeUser, userId }) {
    try {
      if (!typeUser || !userId)
        throw new Error('typeUser and userId are required')
      const response = await axios.delete(`${vari.UHR}/${typeUser}/${userId}`)
      context.dispatch('getCompany')
      return response.data
    } catch (err) {
      console.error(err)
      return err.response.data
    }
  },
}

export default actions
