/**
 * Filtra los mensajes para que no se repitan
 * @param {Object[]} messages - mensajes de un ticket
 * @param {String} message[]._id
 * @param {String} message[].apiMessageId
 * @param {String} message[].scheduleId
 * @return {Object[]} messages
 */
const filterMessagesNoRepeated = ({ messages }) => {
  let hash = {} // objeto donde se guardará identifier como key

  const formattedMessages = messages.map((message) => {
    // si el mensaje no tiene apiMessageId, tomará el valor de scheduleId, sino tomará el valor de _id
    // identifier: nueva propiedad que almacenará a apiMessageId
    message.identifier =
      message.apiMessageId || message.scheduleId || message._id
    return message
  })

  // verifica que el indentifier no se repita
  const filteredMessages = formattedMessages.filter((message) =>
    hash[message.identifier] && message.identifier
      ? false
      : (hash[message.identifier] = true)
  )
  return filteredMessages
}

export default {
  filterMessagesNoRepeated,
}
