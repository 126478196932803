<template>
  <a-modal
    title="Resolver ticket"
    v-model="visible"
    width="480px"
    height="327px"
    centered
    @cancel="cancelResolveTicket"
    class="resolver-ticket-modal"
  >
    <p class="resolver-ticket-modal__description">
      Escribe un breve comentario sobre la gestión de este ticket para poder
      resolverlo.
    </p>
    <label class="resolver-ticket-modal__label">Comentario</label>
    <a-form-model-item>
      <a-textarea
        class="textarea"
        style="max-heigth: 54px"
        placeholder="Escribe aquí, tienes caracteres limitados..."
        v-model="resolveComment"
        auto-size
        :data-test="DATA_TEST.TICKET.RESOLVE.COMMENT_TEXT_AREA"
        :maxLength="maxComment"
      />
      <span class="counter"
        >{{ resolveComment ? resolveComment.length : 0 }}/{{ maxComment }}</span
      >
    </a-form-model-item>
    <template slot="footer">
      <a-button :size="buttons_size" @click="cancelResolveTicket"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        :size="buttons_size"
        @click="resolveTicket"
        :data-test="DATA_TEST.TICKET.RESOLVE.BUTTON"
        >Resolver</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import formatMixin from '@/app/shared/mixins/format'
import { mapGetters, mapActions } from 'vuex'
import { DATA_TEST } from '@/app/shared/utils/dataTest'

export default {
  name: 'ResolveTicket',
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: false,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  components: {},
  data: () => ({
    DATA_TEST,
    maxComment: 150,
    resolveComment: '',
  }),

  mixins: [formatMixin],
  computed: {
    ...mapGetters(['buttons_size']),
  },
  methods: {
    ...mapActions(['setAdminTicketMailbox']),

    async resolveTicket() {
      await this.onOk({ comment: this.resolveComment })
      this.resolveComment = ''
      this.$emit('onClose')
    },
    cancelResolveTicket() {
      this.resolveComment = ''
      this.$emit('onClose')
    },
  },
}
</script>
<style lang="sass" scoped>

.ant-form-item
  margin-bottom: 0px
.textarea
  height: 150px
  min-height: 150px
  max-height: 150px
  position: relative
.counter
  position: absolute !important
  bottom: 0px
  height: 25px
  right: 12px
  color: $gray_light_300
  font-size: 10px
.resolver-ticket-modal
  color: #262A31
  font-weight: 600
  font-size: 16px
  line-height: 24px
.resolver-ticket-modal__description
  font-size: 12px
  line-height: 18px
  font-weight: normal
  color: #262A31
.resolver-ticket-modal__label
  font-size: 15px
  color: #262A31
  font-weight: normal
  line-height: 24px
  margin-bottom: 1px
</style>
