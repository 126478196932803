<template>
  <section class="section">
    <div class="section__83w">
      <h5
        class="heading-h5 align-left gray-dark-900"
        style="margin-bottom: 8px"
      >
        MIS CANALES
      </h5>
      <p class="align-left gray-dark-900 body-3 section__description">
        Aquí aparecen listados todos tus canales conectados, en esta sección
        puedes filtrarlos por su estado o saber más información
      </p>
    </div>
    <table-filters
      :showButtons="false"
      :showMoreFilters="false"
      class="section__83w"
    >
      <!-- Filtros para mis canales -->
      <template slot="filters">
        <a-select
          class="filters__item"
          @change="handleFilter"
          v-model="filters.channel"
          style="width: 190px"
        >
          <template v-if="company">
            <a-select-option
              v-for="channel in activeChannelsForFilters"
              :key="`${channel.channel}`"
              :title="channel.channel"
            >
              {{ channel.name }}
            </a-select-option>
          </template>
        </a-select>

        <a-select
          class="filters__item"
          @change="handleFilter"
          v-model="filters.status"
          style="width: 190px"
        >
          <a-select-option
            v-for="channelStatus in channelStatuses"
            :key="channelStatus.key"
            :title="channelStatus.value"
          >
            {{ channelStatus.value }}
          </a-select-option>
        </a-select>
      </template>
    </table-filters>
    <!--Listado de mis canales-->
    <list-my-channels
      :data-source="paginate_company_channels"
      class="section__83w"
      :total="pagination_company_channels.total"
      :page="pagination_company_channels.page"
      :loading="loading"
      :status="statusNameOfFilter"
      @onPaginate="handleSetPaginate"
      @onLoading="handleReloadList"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import TableFilters from '@/app/shared/components/molecules/TableFilters'
import ListMyChannels from '@/app/channels/components/molecules/ListMyChannels'

export default {
  name: 'SectionMyChannels',
  components: {
    TableFilters,
    ListMyChannels,
  },
  data: () => ({
    filters: {
      channel: 'all',
      status: 'approved',
      page: 1,
    },
    channelStatuses: [
      { value: 'Todos los estados', key: 'all' },
      { value: 'Conectado', key: 'approved' },
      { value: 'Pendiente', key: 'pending' },
      { value: 'Rechazado', key: 'rejected' },
      { value: 'Expirado', key: 'troubled' },
      { value: 'Pendiente de baja', key: 'pre-unsuscribing' },
      { value: 'Desconectado', key: 'unsuscribing' },
    ],
    loading: false,
  }),
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'No company'
      this.loadInitialData()
    })
  },
  mixins: [attemptMixin, supportMixin],
  computed: {
    ...mapGetters([
      'company',
      'paginate_company_channels',
      'pagination_company_channels',
      'channels',
    ]),

    /**
     * @returns {String} - nombre del status filtrado
     */
    statusNameOfFilter() {
      const statusSelected = this.channelStatuses
        .find((statusFilter) => statusFilter.key == this.filters.status)
        .value.toLowerCase()

      return this.filters.status != 'all' ? statusSelected + 's' : ''
    },
  },
  methods: {
    ...mapActions(['getCompanyChannels']),
    ...mapMutations([
      'UPDATE_ACTIVE_COMPANY_CHANNEL',
      'DELETE_COMPANY_CHANNEL',
    ]),

    /**
     * Filtra todos los canales
     */
    handleFilter() {
      this.getCompanyChannels({
        page: 1,
        status: this.filters.status,
        channel: this.filters.channel,
      })
    },
    /**
     * Carga la data inicial
     */
    async loadInitialData() {
      this.loading = true
      await this.getCompanyChannels({
        page: 1,
        status: this.filters.status,
      })
      this.loading = false
    },
    /**
     * Setea la pagina
     */
    async handleSetPaginate(page) {
      this.loading = true
      await this.getCompanyChannels({
        page,
        status: this.filters.status,
        channel: this.filters.channel,
      })
      this.loading = false
    },
    /**
     * Vuelve a cargar la lista de canales
     * @param {Object} reason
     */
    handleReloadList(reason) {
      this.loading = true
      if (reason.reason === 'unlink_channel') {
        if (this.filters.status === 'all')
          this.UPDATE_ACTIVE_COMPANY_CHANNEL({
            channelCompanyId: reason.channelCompanyId,
            active: false,
          })
        else this.DELETE_COMPANY_CHANNEL(reason.channelCompanyId)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
$max_width: 83.33333333%

.section
  padding: 20px 12px 12px;
  &__83w
    margin: 0 auto
    max-width: $max_width
.filters
  margin-bottom: 12px
  &__item
    margin-right: 8px
.section
  &__description
    margin-bottom: 16px
</style>
