<template>
  <div
    class="bubble-chat"
    @mouseenter="showAddComment = true"
    @mouseleave="showAddComment = false"
  >
    <!--mensaje recibido-->
    <div
      v-if="message.method === 'received'"
      class="buble-chat-container skeleton-container"
      :style="`margin-top:${message.styles.marginTop}px`"
    >
      <template v-if="loading">
        <a-skeleton
          :loading="loading"
          active
          :avatar="{ size: 32 }"
          :paragraph="{ rows: 1 }"
          class="skeleton received"
        >
        </a-skeleton>
      </template>
      <template v-else-if="!loading && message && client">
        <a-avatar
          v-if="client.avatar"
          :src="client.avatar"
          class="mrg-right-8"
        />
        <a-avatar v-else class="avatar mrg-right-8" icon="user" />
        <div class="bubble-received__container">
          <add-comment-linked
            position="right"
            :show-button="showButtonAddComment"
            :action="message.commentId ? 'update' : 'add'"
            :messageId="message._id || message.apiMessageId"
            :commentId="message.commentId"
            :comment="message.comment"
            :blocked-actions="blockedActions"
          >
            <!-- Respuestas específicas -->
            <ReplyChat
              v-if="replyMessage"
              :method="message.method"
              :contextMessage="replyMessage"
              :loading="loading"
              :formats="formats"
            />
            <instagram-messages
              v-if="showInstagramMessage"
              :show="instagramTypeAllow"
              :message="message.message"
              :loading="loading"
              :date="message.message.text ? null : date"
            />
            <div
              v-if="!instagramTypeAllow || message.message.text"
              class="buble-chat-received"
              :class="{ 'bubble-chat--no-bg': message.message.stickerUrl }"
            >
              <!-- Solo texto -->
              <p
                v-if="
                  message.message.text &&
                  !message.message.location &&
                  !thumbnail &&
                  !(message.locked || message.message.deleted)
                "
                class="message body-2"
                :class="{ info_i1: message.autoSend }"
                v-html="
                  !formats.active
                    ? textFormatter(message.message.text, [], ['link'])
                    : textFormatter(message.message.text, nameFormats)
                "
              ></p>
              <p
                v-else-if="
                  message.message.text &&
                  !thumbnail &&
                  (message.locked || message.message.deleted)
                "
                class="message message-locked body-2"
              >
                {{ message.message.text }}
              </p>
              <!-- Si NO tiene atributos en el "message" -->
              <p
                v-if="Object.keys(message.message).length === 0"
                class="info_i1"
              >
                Mensaje no compatible
              </p>
              <template v-if="!instagramTypeAllow">
                <!-- Imagen -->
                <img
                  v-if="message.message.imageUrl"
                  :src="message.message.imageUrl"
                  class="image cursor-pointer"
                  @click="showModal"
                  alt=" Este contenido ya no está disponible"
                />
                <!-- Video -->
                <video
                  v-if="message.message.videoUrl"
                  controls
                  class="border-radius-8 bubble__video cursor-pointer"
                >
                  <source
                    :src="message.message.videoUrl"
                    :type="message.message.videoType"
                  />
                </video>
                <!-- Sticker -->
                <img
                  v-if="message.message.stickerUrl"
                  :src="message.message.stickerUrl"
                  class="bubble__sticker"
                  alt=" Este contenido ya no está disponible"
                />
                <!-- Miniatura de archivo -->
                <div v-if="thumbnail" class="file">
                  <!-- Si el icono es vacio que agregue el icono "file" por defecto -->
                  <a-icon
                    v-if="thumbnailIcon.icon === ''"
                    type="file"
                    class="mrg-right-12 gray_dark_900 font-size-24"
                  />
                  <a-icon
                    v-else
                    :type="`file-${thumbnailIcon.icon}`"
                    class="mrg-right-12 gray_dark_900 font-size-24"
                  />
                  <p class="body-2">
                    {{ nameFile }}
                  </p>
                  <a-icon
                    @click="downloadDoc(message.message[thumbnailIcon.attr])"
                    type="download"
                    style="font-size: 24px; color: #262a31; margin-left: 12px"
                  />
                </div>
                <!-- Audio -->
                <audio-message
                  v-if="message.message.audioUrl"
                  :src="message.message.audioUrl"
                />
                <!-- Ubicación -->
                <location-message
                  v-if="message.message.location"
                  :name="message.message.text"
                  :location="{
                    latitude: message.message.location.latitude,
                    longitude: message.message.location.longitude,
                  }"
                />
                <!-- Contacto -->
                <CardChatContact
                  v-if="message.message.contacts"
                  :contacts="message.message.contacts"
                />
              </template>
              <!-- Fecha -->
              <p class="hour body-1">
                {{ date }}
              </p>
            </div>
            <!--Texto extra para los mensajes de tipo anuncio-->
            <!--Se comenta esta parte porque la funcionalidad de contestar anuncios sin plantillas ya esta funcionando-->
            <!-- <p
            v-if="replyMessage && replyMessage.publication"
            class="
              bubble__extra-text
              text--left
              regular-12
              mrg-top-4 mrg-bottom-0
            "
          >
            Responde este mensaje con
            <a
              @click="handleShowModalContinue"
              :disabled="disabledExtraTextToReply"
              >plantillas</a
            >
          </p> -->
          </add-comment-linked>
        </div>
      </template>
    </div>
    <!--mensaje enviado-->
    <div
      v-else-if="message.method === 'sent'"
      class="buble-chat-sent skeleton-container"
      :style="`margin-top:${message.styles.marginTop}px`"
    >
      <template v-if="loading">
        <a-skeleton
          :loading="loading"
          active
          :avatar="{ size: 32 }"
          :paragraph="{ rows: 1 }"
          class="skeleton sent"
        >
        </a-skeleton>
      </template>
      <template v-else-if="!loading && message">
        <div class="bubble-sent__container">
          <add-comment-linked
            class=""
            position="left"
            :show-button="showButtonAddComment"
            :action="message.commentId ? 'update' : 'add'"
            :messageId="message._id || message.apiMessageId"
            :commentId="message.commentId"
            :comment="message.comment"
          >
            <div
              class="bubble-sent__content"
              :class="{ 'bubble-error': message.status === 'failed' }"
            >
              <p
                v-if="message.tagged"
                class="body-1 bubble-sent__text-reconection"
              >
                Mensaje de reconexión
              </p>
              <!--Imagen-->
              <img
                v-if="message.message.imageUrl"
                :src="message.message.imageUrl"
                class="image cursor-pointer"
                @click="showModal"
                alt=" Este contenido ya no está disponible"
              />
              <!--Documento-->
              <div v-if="thumbnail" class="file">
                <a-icon
                  @click="downloadDoc(message.message[thumbnailIcon.attr])"
                  :type="`file-${thumbnailIcon.icon}`"
                  style="font-size: 24px; color: #262a31; margin-right: 12px"
                />
                <p class="body-2">{{ nameFile }}</p>
                <a-icon
                  @click="downloadDoc(message.message[thumbnailIcon.attr])"
                  type="download"
                  style="font-size: 24px; color: #262a31; margin-left: auto"
                />
              </div>
              <!-- Video -->
              <video
                v-if="message.message.videoUrl"
                controls
                class="border-radius-8 bubble__video cursor-pointer"
              >
                <source
                  :src="message.message.videoUrl"
                  :type="message.message.videoType"
                />
              </video>
              <!--Título para listas-->
              <span
                v-if="message.message.lists"
                class="semibold-14 bubble__send__title-list"
                >{{ message.message.title }}</span
              >
              <!--Texto-->
              <p
                v-if="message.message.text"
                class="message-send body-2"
                :class="{ info_i1: message.autoSend }"
                v-html="
                  !formats.active
                    ? textFormatter(message.message.text, [], ['link'])
                    : textFormatter(message.message.text, nameFormats)
                "
              ></p>
              <!-- Audio -->
              <audio-message
                v-if="message.message.audioUrl"
                :src="message.message.audioUrl"
              />
              <carousel-cards
                v-if="message.message.cards"
                :data-source="message.message.cards"
                class="mrg-bottom-8"
              />

              <!--Fecha de envio-->
              <p class="hour-send body-1">
                {{ date }}

                <!--Estado del mensaje-->
                <template v-if="message.status !== 'failed'">
                  <img
                    :src="
                      require(`@/app/tickets/assets/icons/tick-${
                        message.status === 'pending' ? 'queued' : message.status
                      }.svg`)
                    "
                  />
                </template>

                <a-tooltip placement="bottomLeft">
                  <template :slot="tooltipError.slot">
                    {{ tooltipError.text }}
                  </template>
                  <span
                    v-if="message.status === 'failed'"
                    style="color: #f5222d; margin-left: 4px"
                    class="body-1"
                  >
                    <a-icon
                      type="warning"
                      style="color: #f5222d; font-size: 16px"
                    />
                    Error al enviar
                  </span>
                </a-tooltip>
              </p>
            </div>
          </add-comment-linked>
          <!--Botones-->
          <buttons-message
            v-if="message.message.buttons"
            :channel="message.channel.type"
            :buttons="message.message.buttons"
          />
          <!--Listas-->
          <list-message v-if="message.message.lists" :list="message.message" />
        </div>

        <a-avatar
          v-if="message.message.auto === true"
          :src="require('@/app/tickets/assets/icons/bot.png')"
          class="avatar-chat mrg-left-8"
        />
        <a-tooltip v-else placement="topRight">
          <!-- Tooltip del avatar -->
          <template
            slot="title"
            v-if="message.user && message.user.names === 'ERROR_NOT_FOUND'"
          >
            No se pudo encontrar el agente
          </template>
          <template
            slot="title"
            v-else-if="message.user && message.user.names !== 'ERROR_NOT_FOUND'"
            >{{ `${message.user.names} ${message.user.surnames}` }}</template
          >
          <!-- Avatar de usuario no encontrado-->
          <a-avatar
            v-if="message.user && message.user.names === 'ERROR_NOT_FOUND'"
            icon="user"
            class="mrg-left-8"
            alt="Avatar"
          />
          <!-- Avatar si es de tipo estrategia-->
          <a-avatar
            v-else-if="message.user && message.user.type === 'strategy'"
            class="mrg-left-8 bubble__avatar--strategy"
            alt="Avatar"
          >
            <i class="anticon" slot="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'18px'" v-bind:width="'18px'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.952 9.465l-7.953-7.953a2.108 2.108 0 00-3.24 2.671l-.01.055a14.714 14.714 0 01-4.037 7.543L2.064 15.43a2.108 2.108 0 000 2.982L4.052 20.4a2.108 2.108 0 002.983 0l.497-.497 3.479 3.48a2.108 2.108 0 002.982 0 2.111 2.111 0 000-2.982l-1.49-1.491.496-.498a2.108 2.108 0 000-2.982l-.338-.338a14.717 14.717 0 015.565-2.376l.056-.011a2.111 2.111 0 002.67-.257 2.111 2.111 0 000-2.982zm-14.911 9.94a.703.703 0 01-.995 0l-1.988-1.988a.703.703 0 010-.994l3.48-3.48 2.982 2.983-3.48 3.48zm6.958 1.989a.704.704 0 01-.994.994l-3.48-3.48.995-.994 3.48 3.48zm-2.485-4.474c.211-.211.528-.535 1.027-.961l.464.464a.703.703 0 010 .994l-.497.497-.994-.994zm.03-1.958L7.502 11.92A16.097 16.097 0 0010.924 5.4l6.138 6.139a16.097 16.097 0 00-6.518 3.422zm9.414-3.51a.703.703 0 01-.994 0L11.01 3.5a.703.703 0 11.994-.994l7.953 7.953a.704.704 0 010 .994z" fill="currentColor"/><path d="M7.035 15.43a.703.703 0 00-.994 0l-.995.993a.703.703 0 10.995.994l.994-.994a.703.703 0 000-.994zM16.228 0a.703.703 0 00-.703.703v1.406a.703.703 0 101.405 0V.703A.703.703 0 0016.228 0zM21.851 5.623h-1.406a.703.703 0 000 1.406h1.406a.703.703 0 000-1.406zM20.942 1.612a.703.703 0 00-.994 0l-1.406 1.406a.703.703 0 00.994.994l1.406-1.406a.703.703 0 000-.994z" fill="currentColor"/></svg>
            </i>
          </a-avatar>
          <!-- Avatar de usuario encontrado con foto-->
          <a-avatar
            v-else-if="message.user && message.user.avatar"
            :src="message.user.avatar"
            class="mrg-left-8"
          />
          <!-- Avatar de usuario pero sin foto-->
          <DefaultIconLetter
            v-else
            :names="message.user ? message.user.names : ''"
            class="mrg-left-8"
          />
        </a-tooltip>
      </template>
    </div>

    <!-- nota -->
    <div v-else-if="!loading && message.method === 'note'" class="bubble-note">
      <div class="bubble-note-container">
        <p class="body-1">
          {{
            message.message.text +
            ' El ' +
            moment(message.created_at).format('LLLL') +
            '.'
          }}
        </p>
      </div>
    </div>
    <!-- Modal de Vista Maximizada -->
    <modal-image-preview
      v-if="!loading && message.message.imageUrl"
      :visible="visible"
      :imageUrl="message.message.imageUrl"
      :user="user"
      :date="moment(message.apiReceivedAt).calendar()"
      @onClose="visible = false"
    />
    <!--Modal de plantillas continuar el chat-->
    <modal-continue-message
      :visible="modalContinue.visible"
      @onClose="modalContinue.visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter'
import CardChatContact from '@/app/shared/components/cards/CardChatContact'
import InstagramMessages from '@/app/tickets/components/molecules/InstagramMessages'
import ReplyChat from '@/app/tickets/components/molecules/ReplyChat.vue'
import UrlUtils from '@/app/shared/utils/url'
import AudioMessage from '@/app/tickets/components/molecules/AudioMessage'
import LocationMessage from '@/app/tickets/components/molecules/LocationMessage'
import ModalImagePreview from '@/app/tickets/components/molecules/ModalImagePreview'
import textFormatter from '@/app/shared/mixins/format'
import ModalContinueMessage from '@/app/shared/components/modals/ModalContinueMessage'
import AddCommentLinked from '@/app/tickets/components/molecules/AddCommentLinked'
import ButtonsMessage from '@/app/tickets/components/molecules/buttons/ButtonsMessage'
import CarouselCards from '@/app/tickets/components/molecules/cards/CarouselCards'
import ListMessage from '@/app/tickets/components/molecules/lists/ListMessage'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'BubleChat',
  props: {
    message: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
      require: false,
    },
    formats: {
      type: Object,
      required: false,
      default: () => ({
        active: false,
      }),
    },
    blockedActions: {
      type: Array,
      required: false,
      default: () => [],
      enum: [
        'reply-with-templates',
        'zoom-image',
        'add-comment',
        'update-comment',
        'delete-comment',
      ],
    },
  },
  components: {
    DefaultIconLetter,
    CardChatContact,
    InstagramMessages,
    ReplyChat,
    AudioMessage,
    LocationMessage,
    ModalImagePreview,
    ModalContinueMessage,
    AddCommentLinked,
    ButtonsMessage,
    CarouselCards,
    ListMessage,
  },
  data: () => ({
    moment: moment,
    visible: false,
    bodyStyle: {
      padding: '40px',
      height: '500px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    instagramMessageTypes: ['share', 'story_mention', 'reply'],
    modalContinue: { visible: false },
    showAddComment: false,
    dataListTest: {
      message: {
        list: {
          menu: 'Menú',
          sections: [
            {
              title: 'Escuela de Marketing',
              items: [
                { text: 'Community Manager' },
                { text: 'Especialista en SEO' },
                { text: 'Content Creator' },
              ],
            },
            {
              title: 'Escuela de Finanzas',
              items: [
                { text: 'Financial Analyst' },
                { text: 'Financial Analyst 2' },
                { text: 'Financial Analyst 3' },
                { text: 'Financial Analyst 4' },
                { text: 'Financial Analyst 5' },
                { text: 'Financial Analyst 6' },
                { text: 'Financial Analyst 7' },
                { text: 'Financial Analyst 8' },
              ],
            },
          ],
        },
      },
    },
  }),
  mixins: [textFormatter],
  computed: {
    ...mapGetters(['profile']),

    /**
     * @returns {Boolean}
     */
    instagramTypeAllow() {
      return this.instagramMessageTypes.includes(this.message.message.type)
    },
    /**
     * Formato de fecha
     * @returns {Moment}
     */
    date() {
      const iscurrentDate = moment(new Date()).isSame(
        this.message.apiReceivedAt,
        'days'
      )
      const isCurrentWeek = moment(new Date()).isSame(
        this.message.apiReceivedAt,
        'week'
      )
      if (iscurrentDate) {
        return moment(this.message.apiReceivedAt).format('LT')
      } else if (isCurrentWeek) {
        return moment(this.message.apiReceivedAt).calendar()
      } else return moment(this.message.apiReceivedAt).format('LLL')
    },
    /**
     * @returns {String}
     */
    nameFile() {
      return this.message.message.caption
        ? this.message.message.caption
        : UrlUtils.getFilenameFromUrl(
            this.message.message[this.thumbnailIcon.attr]
          )
    },
    /**
     * @returns {Boolean}
     */
    preview() {
      return (
        !!this.message.message.imageUrl ||
        !!this.message.message.videoUrl ||
        !!this.message.message.audioUrl
      )
    },
    /**
     * Se usa para saber si el mensaje contiene un archivo y no es solo texto
     * @returns {Boolean}
     */
    thumbnail() {
      return (
        !!this.message.message.pdfUrl ||
        !!this.message.message.wordUrl ||
        !!this.message.message.excelUrl ||
        !!this.message.message.pptUrl ||
        !!this.message.message.fileUrl
      )
    },
    /**
     * Se usa para saber que icono mostrar de acuerdo al tipo de archivo
     * @returns {Object} { icon, attr }
     */
    thumbnailIcon() {
      if (this.message.message.pdfUrl) return { icon: 'pdf', attr: 'pdfUrl' }
      else if (this.message.message.wordUrl)
        return { icon: 'word', attr: 'wordUrl' }
      else if (this.message.message.excelUrl)
        return { icon: 'excel', attr: 'excelUrl' }
      else if (this.message.message.pptUrl)
        return { icon: 'ppt', attr: 'pptUrl' }
      else if (this.message.message.fileUrl)
        return { icon: '', attr: 'fileUrl' }
      return { icon: '', attr: '' }
    },
    tooltipError() {
      return this.message.failureDetails
        ? { slot: 'title', text: this.message.failureDetails.message }
        : { slot: 'none', text: '' }
    },
    /**
     * @return {String}
     */
    statusIcon() {
      if (this.message.method !== 'sent' || this.message.status === 'failed') {
        return null
      }
      const iconName = `tick-${
        this.message.status === 'pending' ? 'queued' : this.message.status
      }`
      return `@/app/tickets/assets/icons/${iconName}.svg`
    },
    /**
     * @return {Boolean}
     */
    showInstagramMessage() {
      if (!this.message.channel) return false
      return this.message.channel.type === 'instagram'
    },
    /**
     * Nombre de todos los formatos
     * @return {String[]}
     */
    nameFormats() {
      if (!this.formats.active) return false
      const nameFormats = this.formats.formats.map((item) => item.format)
      return nameFormats
    },
    /**
     * @return {Object | null} replyMessage - Mensaje de tipo respuesta
     * @return {Object} replyMessage.origin_owner
     * @return {Object} replyMessage.publication
     * @return {String} replyMessage.publication.headline
     * @return {String} replyMessage.publication.body
     * @return {String} replyMessage.publication.source_url
     * @return {String} replyMessage.audioUrl
     * @return {String} replyMessage.videoUrl
     * @return {String} replyMessage.pdfUrl
     * @return {String} replyMessage.imageUrl
     */
    replyMessage() {
      if (!this.message && this.message.message) return
      let replyMessage = {}
      if (this.message.message.referral) {
        replyMessage.publication = {}
        replyMessage.publication = this.message.message.referral
        replyMessage.origin_owner = { method: 'sent' }
      } else if (this.message.message.reply)
        replyMessage = this.message.message.reply
      else replyMessage = null
      return replyMessage
    },
    /**
     * @return {Boolean} deshabilita la accion de mostrar plantillas
     */
    disabledExtraTextToReply() {
      return (
        this.replyMessage &&
        this.replyMessage.publication &&
        this.blockedActions.includes('reply-with-templates')
      )
    },
    /**
     * Devuelve el usuario de la burbuja
     * @return {Object| null} user
     * @return {String} user.fullName
     * @return {String} user.avatar
     */
    user() {
      let user = null
      // si es un mensaje recibido
      if (this.message.method === 'received') {
        user = {}
        if (!this.client) user.isEmpty = true
        else {
          if (this.client.names || this.client.surnames)
            user.fullName = `${this.client.names ? this.client.names : ''} ${
              this.client.surnames ? this.client.surnames : ''
            }`
          else if (this.client.phone) user.fullName = this.client.phone
          else user.isEmpty = true
          user.avatar = this.client.avatar
        }
      }
      // si es un mensaje enviado
      if (this.message.method === 'sent') {
        user = {}
        // si no existe usuario o so el nombre no se ha encontrado
        if (!this.message.user || this.message.user.names === 'ERROR_NOT_FOUND')
          user.isEmpty = true
        else {
          user.fullName = `${this.message.user.names} ${this.message.user.surnames}`
          user.avatar = this.message.user.avatar
          user.isCurrent =
            this.profile &&
            this.message.user.userId &&
            this.profile.userId === this.message.user.userId
        }
      }

      return user
    },
    /**
     * @return {Boolean} - muestra el boton de añadir comentario
     */
    showButtonAddComment() {
      return (
        !!this.message.commentId ||
        (!this.blockedActions.includes('add-comment') && this.showAddComment)
      )
    },
  },
  methods: {
    /**
     * Mostrar modal de vista previa
     */
    showModal() {
      this.visible = true
    },
    /**
     * @param {String} url URL a descargar
     */
    downloadDoc(url) {
      window.open(url)
    },
    goLocation() {
      window.open(
        `https://maps.google.com/?q=${this.message.message.location.latitude},${this.message.message.location.longitude}`
      )
    },
    /**
     * Muestra el modal para enviar plantilla
     */
    handleShowModalContinue() {
      if (this.disabledExtraTextToReply) return
      this.modalContinue.visible = true
    },
  },
}
</script>

<style lang="sass">
$max_width_buble: 80%
$bg_sent_color_hover: rgba(236, 244, 250, 0.4)


.border-radius-8
  border-radius: 8px
.file
  background-color: $white_000
  border-radius: 8px
  padding: 12px
  margin-bottom: 8px
  width: 100%
  height: 43px
  display: flex
  flex-direction: row
  align-items: center
  p
    margin-bottom: 0em
    max-width: 100%
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    word-break: break-all
.bubble-error
  background-color: #FFDBDB!important
.bubble-chat
  p
    white-space: pre-line
  .buble-chat-container
    //margin: 0px 0px 24px
    display: flex
    flex-direction: row
    align-items: flex-end
  .buble-chat-received
    background-color: $gray_light_000
    width: fit-content
    height: max-content
    border-radius: 16px 16px 16px 0px
    max-width: 100%
    padding: 12px
    .message
      text-align: left
      overflow-wrap: break-word
    .file
      p.body-2
        text-align: left
        cursor: default
  .buble-chat-sent
    // margin: 0px 0px 24px
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: flex-end
  .bubble-sent__content
    background-color: $blue_light_000
    width: fit-content
    height: max-content
    border-radius: 16px 16px 0px 16px
    max-width: 100%
    padding: 12px
    .message-send
      text-align: left
      margin-bottom: 0px !important
      color: $gray_dark_900
      padding-bottom: 8px
      word-wrap: break-word
    .hour-send
      text-align: right
      // padding-top: 8px
      margin-bottom: 0px !important
      color: $gray_dark_600
    .file
      p.body-2
        text-align: left
        cursor: default
    &:hover
      // background-color: $bg_sent_color_hover
  .image, .bubble__sticker
    border-radius: 8px
    min-height: 20%
    max-height: 275px
    min-width: 100px
    max-width: 100%
    margin-bottom: 8px
    background: $gray_5
  .message
    color: $gray_dark_900
    text-align: left
    margin-bottom: 0px !important
    color: $gray_dark_900
    padding-bottom: 8px
  .message-locked
    font-style: italic
    color: $gray_dark_500
  .hour
    text-align: left
    margin-bottom: 0px !important
    color: $gray_dark_600
.bubble-note
  display: flex
  justify-content: center
  margin: 20px 0
.bubble-note-container
  background-color: $yellow_200
  width: fit-content
  height: max-content
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  border-radius: 8px
  padding: 8px 12px
  max-width: 60%
  p
    margin-bottom: 0em
    color: $gray_light_600
    text-align: center

.avatar-chat
  background-color: $celestial_100 !important
.preview
  picture
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    img, svg
      background: $gray_5
      max-height: 100%
      max-width: 100%
        // min-width:

.skeleton-container
  .skeleton
    background-color: $white_000
    display: flex
    align-items: flex-end
    .ant-skeleton-header
      // vertical-align: bottom
    .ant-skeleton-content
      background-color: $gray_2
      padding: 12px
      width: 80%
      .ant-skeleton-title
        margin-top: 0px !important
      .ant-skeleton-paragraph
        margin-top: 12px !important
        margin-bottom: 0px !important
  .received
    .ant-skeleton-header
      padding-right: 8px
    .ant-skeleton-content
      border-radius: 16px 16px 16px 0px
  .sent
    flex-direction: row-reverse
    .ant-skeleton-header
      padding-left: 8px
      padding-right: 0px
    .ant-skeleton-content
      border-radius: 16px 16px 0px 16px

.bubble-received__container, .bubble-sent__container
  width: $max_width_buble
  position: relative
.bubble__extra-text
  color: $gray_7
.bubble__sticker
  background: transparent!important
.bubble-sent__container
  @include flex(column, flex-end, flex-end)
</style>

<style lang="sass" scoped>
$bg-avatar-strategy: #eff8e6
.mrg-bottom-26
  margin-bottom: 26px

.font-size-24
  font-size: 24px
.gray_dark_900
  color: $gray_dark_900
.bubble__video
  width: auto
  height: 260px
  max-width: 100%
.hour
  &-send
    svg
      vertical-align: middle
      border-style: none
.bubble__avatar--strategy
  background-color: $bg-avatar-strategy
  color: $polar-green_6
.bubble-chat--no-bg
  background-color: $white_000
  border: 1px solid $gray_light_100
.bubble--focus
  .bubble-sent__content, .buble-chat-received
    -webkit-animation: blink 1.33s ease-out infinite
    -moz-animation: blink 1.33s ease-out infinite
    animation: blink 1.33s ease-out infinite
.bubble-sent__text-reconection
  margin-bottom: 4px
  color: $gray_6
  text-align: left

.bubble__send__title-list
  color: $gray_dark_900
@keyframes blink
  0%
    opacity: 1
  20%
    opacity: .3
  100%
    opacity: 1
</style>
