<template>
  <div class="section-manage-tickets">
    <header-module
      title="GESTOR DE TICKETS"
      positionTooltip="bottom"
      tipography="heading-h5"
      textBeforeLink="Administra todos los tickets de tu empresa, o, si eres agente, los asignados a ti."
      :link="`${vari.WEB_DOC}caracteristicas/gestor-tickets/`"
      textAfterLink="si deseas conocer más."
    />
    <table-filters
      :columns="columns"
      :onMoreFilters="() => (showDrawerFilters = !showDrawerFilters)"
      :moreFiltersText="showDrawerFilters ? 'Ocultar' : 'Más filtros'"
      :showMoreTooltip="true"
      moreTooltipText="Filtra tickets combinando varias propiedades"
      :onOk="() => (modalDownloadCrm.visible = true)"
      okText="Descargar tickets"
      okIcon="download"
      :showOkTooltip="true"
      okTooltipText="Tus tickets se descargarán de acuerdo a los filtros seleccionados."
      @onChangeCol="handleChangeColumn"
    >
      <template slot="filters">
        <!-- Busqueda -->
        <a-tooltip placement="left">
          <template slot="title">
            Buscar por # de ticket, nombre de cliente o número de teléfono
          </template>
          <a-input-search
            placeholder="Buscar"
            class="filters__item"
            v-model="seeker"
            @search="handleChangeStatus"
          />
        </a-tooltip>
        <!-- Rango de fecha de creación -->
        <a-range-picker
          class="filters__item mrg-left-8"
          :disabled-date="disabledDate"
          :show-time="{
            format: localePicker.lang.dateTimeFormat,
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('23:59:59', 'HH:mm:ss'),
            ],
          }"
          :placeholder="['Fecha de inicio', 'Fecha de fin']"
          :format="localePicker.lang.dateTimeFormat"
          :locale="localePicker"
          v-model="ticketFilters.dateRange"
          @change="handleChangeRange"
          @ok="handleChangeRange"
          style="width: 19em"
        />
        <!-- Colas -->
        <a-select
          class="filters__item mrg-left-8"
          @change="handleChangeStatus"
          v-model="ticketFilters.line"
        >
          <a-select-option
            v-for="line in linesAsigned"
            :key="`${line._id}`"
            :value="line._id"
            :title="line.default ? line.name : `Cola ${line.name}`"
          >
            {{ line.default ? line.name : `Cola ${line.name}` }}
          </a-select-option>
        </a-select>
        <!-- Canales -->
        <a-select
          class="filters__item filter-channel mrg-left-8"
          @change="handleChangeChannel"
          v-model="ticketFilters.channel"
        >
          <a-select-option
            v-for="channel in channelsForFilters"
            :key="channel.channel"
            :title="channel.default ? channel.name : `${channel.name}`"
          >
            {{ channel.default ? channel.name : `${channel.name}` }}
          </a-select-option>
        </a-select>
      </template>
      <!--## Mejorar el componente ListColumns para que acepte los parametros que son usados aqui-->
      <a-popover
        slot="list-columns"
        placement="bottomRight"
        trigger="click"
        :overlayStyle="popStyles"
      >
        <a-tooltip title="Elige que datos de los tickets quieres listar">
          <span slot="title"> Escoge columnas a mostrar </span>
        </a-tooltip>
        <template slot="content">
          <a-row type="flex" justify="space-between"
            ><p>Columnas seleccionadas:</p>
            <span>{{ columnsChecked.length }}/{{ columns.length }}</span></a-row
          >

          <div class="columns-filter scrollbar_basic">
            <p
              v-for="column in columns.filter(
                (col) => !col.isCustom && !col.isFieldClient
              )"
              :key="column.key"
            >
              <a-checkbox v-model="column.checked" :disabled="column.disabled">
                {{ column.title }}
              </a-checkbox>
            </p>
            <h6 v-if="showFieldsClientTitle" class="semibold-14">
              Campos del cliente
            </h6>
            <p
              v-for="column in columns.filter((col) => col.isFieldClient)"
              :key="column.key"
            >
              <a-checkbox v-model="column.checked" :disabled="column.disabled">
                {{ column.title }}
              </a-checkbox>
            </p>
            <h6 v-if="showCustomTitle" class="semibold-14">
              Campos del ticket
            </h6>
            <p
              v-for="column in columns.filter((col) => col.isCustom)"
              :key="column.key"
            >
              <a-checkbox v-model="column.checked" :disabled="column.disabled">
                {{ column.title }}
              </a-checkbox>
            </p>
          </div>
        </template>
        <a-button> Escoger columnas</a-button>
      </a-popover>
    </table-filters>
    <!-- Opciones masivas -->
    <a-row
      v-if="!showMassiveOptions"
      type="flex"
      justify="space-between"
      align="middle"
      class="tickets-title"
    >
      <p class="body-2 mrg-bottom-0">
        Selecciona uno o más tickets para realizar acciones masivas
      </p>
      <a-button :disabled="loading" @click="fetchTickets">
        <a-icon type="sync" :spin="loading" />
        Actualizar tickets</a-button
      >
    </a-row>

    <a-row
      type="flex"
      justify="space-between"
      align="middle"
      class="body-2 tickets-title"
      style="margin-left: 12px"
      v-else
    >
      <span>
        <span class="heading-h6">{{ ticketsToTransfer.length }}</span
        >{{ ' ' }}
        {{
          ticketsToTransfer.length > 1
            ? 'tickets seleccionados'
            : 'ticket seleccionado'
        }}
      </span>
      <!-- Drawer -->
      <a-row type="flex">
        <a-dropdown
          :disabled="hasFinishTicked"
          :trigger="['click']"
          placement="bottomRight"
        >
          <a-tooltip>
            <a-button
              :disabled="hasFinishTicked"
              class="filters__button filters__button--center"
            >
              <a-row type="flex" justify="center" align="middle">
                <a-icon type="swap" />
                <span class="text">Transferir</span>
              </a-row>
            </a-button>
          </a-tooltip>
          <!-- menú -->
          <a-menu slot="overlay">
            <!-- Transferencia única a agente -->
            <a-menu-item v-if="ticketsToTransfer.length == 1" key="0">
              <a @click="handleTransferAgent(null)">Transferir a un agente</a>
            </a-menu-item>
            <!-- Transferencia única a Cola -->
            <a-sub-menu
              style="
                max-height: 200px;
                overflow-y: auto;
                background-clip: initial;
              "
              popupClassName="scrollbar_basic"
              key="1"
            >
              <span slot="title"><span>Transferir a cola</span></span>
              <a-menu-item
                v-for="(line, index) in filterCompanyLines"
                :key="`item${index}`"
                @click="confirmTransferLine(line)"
              >
                {{ line.name }}
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
        </a-dropdown>
        <!--Etiquetas masivas-->
        <popover-tags-checkboxes
          v-if="tags.length > 0"
          :data-source="dataSourceTags"
          @onCheck="handleSetTicket"
        >
          <a-button
            :disabled="hasFinishTicked"
            class="filters__button filters__button--center"
          >
            <a-row type="flex" justify="center" align="middle">
              <a-icon type="tag" />
              <span class="text">Etiquetar</span>
            </a-row>
          </a-button>
        </popover-tags-checkboxes>
        <!--Botón de resolver tickets-->
        <a-button
          :disabled="disabledResolveTicket"
          class="filters__button filters__button--center"
          @click="handleResolveTicket(null, { massive: true })"
        >
          <a-row type="flex" justify="center" align="middle">
            <a-icon type="check" />
            <span class="text">Resolver</span>
          </a-row>
        </a-button>
      </a-row>
    </a-row>
    <client-details
      :visible="showClientDetails"
      :data-client="clientToSee"
      @close="showClientDetails = false"
    />
    <filter-tickets-drawer
      :visible="showDrawerFilters"
      :ticketFilters="ticketFilters"
      @close="showDrawerFilters = false"
      :onOk="handleFilterDrawer"
      @onResetFilters="setInitialFilters"
    />
    <tickets-table
      :loading="loading"
      :tickets="ticketsToManage"
      :page="page"
      :columnsToShow="columnsChecked"
      :showGhost="ticketFilters.isGhost"
      @paginate="handlePaginate"
      @transferLine="handleTransferLine"
      @transferAgent="handleTransferAgent"
      @resolveTicket="handleResolveTicket"
      @seeTicket="handleSeeTicket"
      @selectedRows="handleSelectedRows"
      @seeClient="onSeeClient"
      @editTicket="onEditTicket"
      @onShowProducts="onShowAssignedProducts"
    />
    <modal-transfer-agent
      :ticket="ticketToTransfer"
      :tickets="ticketsToTransfer"
      :visible="modalTransferAgentVisible"
      @onClose="onCloseActions('modalTransferAgentVisible')"
      @onSuccess="updateAgentInTicket"
      @onError="handleChangeStatus"
      role="admin"
    />
    <resolve-ticket
      v-if="typeModalResolve === 'default'"
      :visible="modalResolveTicket.visible"
      :onOk="resolveTicket"
      @onClose="handleCloseModalResolve"
    />
    <modal-confirmation-resolve-ticket
      v-else-if="typeModalResolve === 'confirmation'"
      :visible="modalResolveTicket.visible"
      :onOk="resolveTicket"
      :quantity-tickets="quantityTicketsResolve"
      @onClose="handleCloseModalResolve"
    />
    <modal-ticket-details
      :visible="detailsTicketVisible"
      :ticket="ticketToTransfer"
      @close="onCloseActions('detailsTicketVisible')"
    />
    <update-ticket-drawer
      :ticket="ticketToUpdate"
      :visible="updateTicketDrawerVisible"
      @close="onCloseActions('updateTicketDrawerVisible')"
      @updated="onUpdateTicket"
      @onLoading="onLoadingUpdated"
    />
    <assigned-products-drawer
      :visible="drawerProducts.visible"
      :data-source="drawerProducts.data"
      @onClose="handleCloseProducts"
    />
    <modal-download-crm
      :visible="modalDownloadCrm.visible"
      title="Descarga tus tickets"
      :description="modalDownloadCrm.description"
      :onOk="handleExportTickets"
      @onClose="modalDownloadCrm.visible = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import vari from '@/app/shared/utils/variables'
import FilterTicketsDrawer from '@/app/manageTickets/components/organisms/FilterTicketsDrawer'
import UpdateTicketDrawer from '@/app/manageTickets/components/organisms/UpdateTicketDrawer'
import TicketsTable from '@/app/manageTickets/components/organisms/TicketsTable'
import ModalTicketDetails from '@/app/shared/components/modals/ModalTicketDetails'
import ResolveTicket from '@/app/manageTickets/components/organisms/ResolveTicket'
import ClientDetails from '@/app/manageTickets/components/organisms/ClientDetails'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import formatMixin from '@/app/shared/mixins/format'
import ModalTransferAgent from '@/app/shared/components/modals/ModalTransferAgent'
import moment from 'moment'
import AssignedProductsDrawer from '@/app/shared/components/drawers/AssignedProductsDrawer'
import TableFilters from '@/app/shared/components/molecules/TableFilters'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import ModalDownloadCrm from '@/app/shared/components/modals/ModalDownloadCrm'
import PopoverTagsCheckboxes from '@/app/shared/components/molecules/PopoverTagsCheckboxes'
import ModalConfirmationResolveTicket from '@/app/shared/components/modals/ModalConfirmationResolveTicket'
import {
  STATUSES_TEMPLATE,
  COLUMNS_FILTERS_TICKETS,
} from '@/app/manageTickets/utils/ticketsUtil'

const QUEUED = 'queued'

export default {
  name: 'SectionManageTickets',
  components: {
    FilterTicketsDrawer,
    TicketsTable,
    ModalTransferAgent,
    ResolveTicket,
    ModalTicketDetails,
    ClientDetails,
    UpdateTicketDrawer,
    AssignedProductsDrawer,
    TableFilters,
    HeaderModule,
    ModalDownloadCrm,
    PopoverTagsCheckboxes,
    ModalConfirmationResolveTicket,
  },
  data: () => ({
    vari,
    popover: false,
    moment,
    loading: true,
    showDrawerFilters: false,
    seeker: '',
    page: 1,
    columns: [],
    ticketStatusesSelected: [], // Arreglo de Strings
    ticketFilters: {
      dateRange: [],
      mailbox: 'all',
      status: 'all',
      order: 'new',
      line: 'all',
      ticketStatuses: [],
      channel: 'all',
      channelCompanyId: 'all',
      tags: [],
      customFields: [],
      favorite: 'all',
      clientsId: null,
      agent: 'all',
      products: [],
      lastStatusFirstTemplate: [],
      isGhost: false,
    },
    ticketToTransfer: null,
    ticketsToTransfer: [],
    modalTransferAgentVisible: false,
    detailsTicketVisible: false,
    showClientDetails: false,
    updateTicketDrawerVisible: false,
    clientToSee: {},
    ticketToUpdate: { fields: {}, ticket: '', settings: {} },
    popStyles: {
      width: '240px',
    },
    drawerProducts: {
      visible: false,
      load: false,
      data: [],
    },
    ticketStatuses: [
      // Arreglo de objetos
      {
        _id: 'queued',
        name: 'Cola de espera',
      },
      {
        _id: 'issued',
        name: 'Entregado',
      },
      {
        _id: 'opened',
        name: 'Respondido',
      },
      {
        _id: 'ended',
        name: 'Finalizado',
      },
    ],
    modalDownloadCrm: {
      visible: false,
      description:
        'Selecciona un rango de fechas de creación de tickets, recuerda que el rango puede ser de hasta 2 meses como máximo y además se están considerando los filtros aplicados.',
    },
    modalResolveTicket: {
      visible: false,
      isMassive: false,
    },
  }),
  created() {
    this.ticketFilters.clientsId = this.clientsIdFilterTicket
    // si existe un rango para filtrar los tickets
    if (this.rangeFilterTickets.length > 0)
      this.ticketFilters.dateRange = this.rangeFilterTickets
    this.intervalPromiseAttempt(() => {
      if (!this.company) throw 'No company'
      this.loadInitialData()
    })
  },
  destroyed() {
    this.SET_CLIENTSID_FILTER_TICKET([])
  },
  mixins: [formatMixin, attemptMixin, supportMixin],
  watch: {
    clientsIdFilterTicket(clientsId) {
      this.ticketFilters.clientsId = clientsId
    },
    rangeFilterTickets(range) {
      if (!range.length) return
      this.ticketFilters.dateRange = range
    },
  },
  computed: {
    ...mapGetters([
      'company',
      'ticketsToManage',
      'mailboxes',
      'profile',
      'lines',
      'companyLines',
      'tags',
      'company_custom_fields_tickets',
      'profile',
      'fetchedFromOtherModule',
      'clientsIdFilterTicket',
      'rangeFilterTickets',
      'crm_max_months_to_get',
      'channels',
      'status_to_prevent_resolve_tickets',
      'enabled_resolve_queued_tickets',
      'company_custom_fields_clients',
    ]),
    /**
     * Colas asignadas para el filtro
     * @returns {Object[]}
     */
    linesAsigned() {
      let linesAsigned = this.lines.slice()
      linesAsigned.unshift({
        _id: 'all',
        name: 'Todas las colas',
        default: true,
      })
      return linesAsigned
    },
    /**
     * @returns {Object[]}
     */
    columnsChecked() {
      return this.columns.filter((column) => column.checked && column.show)
    },
    /**
     * @returns {Object[]}
     */
    columnsToExport() {
      const columns = COLUMNS_FILTERS_TICKETS.filter((columnFilter) => {
        const checked = this.columns.some(
          (column) => column.key === columnFilter.key && column.checked
        )
        // validacion para mostrar la columna de tickets fantasmas
        const columnGhost = this.ticketFilters.isGhost && !columnFilter.show
        return checked || columnGhost
      })

      // validacion para mostar la columna de campos customizados del ticket
      const columnsFieldChecked = this.columns.filter(
        (column) =>
          (column.checked && column.isCustom) ||
          (column.checked && column.isFieldClient)
      )
      return [...columns, ...columnsFieldChecked]
    },
    /**
     * @returns {Object[]}
     */
    hasFinishTicked() {
      return this.ticketsToTransfer.some((ticket) =>
        this.status_to_prevent_resolve_tickets.includes(ticket.status)
      )
    },
    /**
     * Deshabilita el boton si es que hay un ticket
     * que no puede ser resuelto
     * @returns {Boolean}
     */
    disabledResolveTicket() {
      return this.ticketsToTransfer.some(
        (ticket) =>
          this.status_to_prevent_resolve_tickets.includes(ticket.status) ||
          this.status_to_prevent_resolve_tickets.includes(ticket.subStatus)
      )
    },
    /**
     * Filtra las colas con los canales comunes de los tickets seleccionados
     * @return {Object[]}
     */
    filterCompanyLines() {
      const tickets = [...this.ticketsToTransfer]
      const channels = []
      const lines = []
      // encontrar los canales del ticket
      for (let ticket of tickets) {
        if (!channels.includes(ticket.channelCompanyId))
          channels.push(ticket.channelCompanyId)
      }
      // encontrar las colas que tienen los mismos canales del ticket seleccionado
      for (let line of this.companyLines) {
        // si los tickets seleccionados solo tienen un canal en comun
        if (channels.length === 1) {
          const include = line.channels_companies?.some(
            (channels_company) => channels_company._id === channels[0]
          )
          if (include) lines.push(line)
        } else {
          const compare = channels.every((channel) => {
            return line.channels_companies?.some(
              (channel_company) => channel_company._id === channel
            )
          })
          if (compare) lines.push(line)
        }
      }
      return lines
    },
    /**
     * Mostrar el titulo para campos del cliente
     * @return {Boolean}
     */
    showFieldsClientTitle() {
      return !!this.columns.filter((column) => column.isFieldClient).length
    },
    /**
     * Mostrar el titulo para campos personalizados
     * @return {Boolean}
     */
    showCustomTitle() {
      return !!this.company_custom_fields_tickets.length
    },
    /**
     * @return {Object[]} tags
     * @return {Boolean} tags[]checked
     * @return {String} tags[]key
     */
    dataSourceTags() {
      const tickets = [...this.ticketsToTransfer]

      return this.tags.map((tag) => {
        // encontrar si ya esta seleccionado o no
        const isChecked = tickets.every((ticket) =>
          ticket.settings.tags.some(
            (ticketTag) => ticketTag.tagId === tag.idTag
          )
        )
        const newObj = { ...tag }
        newObj.checked = !!isChecked
        newObj.key = tag.idTag
        return newObj
      })
    },
    /**
     * Muestra las opciones masivas
     * @return {Boolean}
     */
    showMassiveOptions() {
      return this.ticketsToTransfer && this.ticketsToTransfer.length > 0
    },
    /*
     * Id de etiquetas habilitadas para ser seleccionadas
     * @return {String[]}
     */
    idTagsToSelect() {
      if (!this.company) return []
      const ids = this.tags.map((tag) => tag.idTag)
      return [...ids, 'none']
    },
    /**
     * @return {String} - tipo de modal para resolver ticket
     */
    typeModalResolve() {
      if (this.enabled_resolve_queued_tickets) {
        if (this.ticketToTransfer) {
          if (this.ticketToTransfer.subStatus === QUEUED) return 'confirmation'
          else return 'default'
        } else if (
          this.ticketsToTransfer &&
          this.ticketsToTransfer.some((ticket) => ticket.subStatus === QUEUED)
        )
          return 'confirmation'
        else return 'default'
      }
      return 'default'
    },
    /**
     * @return {Number} - cantidad de tickets para ser resueltos
     */
    quantityTicketsResolve() {
      if (this.enabled_resolve_queued_tickets) {
        if (this.ticketToTransfer) {
          if (this.ticketToTransfer.subStatus === QUEUED) return 1
          else return 0
        } else if (this.ticketsToTransfer) {
          const quantity = this.ticketsToTransfer.filter(
            (ticket) => ticket.subStatus === QUEUED
          )
          return quantity
        } else return 1
      }
      return 1
    },
  },
  methods: {
    ...mapActions([
      'getAllTicketsToManage',
      'getListCustomFields',
      'listAgents',
      'transferAdminTicket',
      'updateAgentInTicketsToManage',
      'updateStatusInTicketsToManage',
      'updateTagMasive',
      'setAnalytics',
      'exportTickets',
      'getAssignedProductsNoPagedOfTicket',
      'resolveTicketFromAdmin',
    ]),
    ...mapMutations(['SET_CLIENTSID_FILTER_TICKET']),

    /**
     * Carga la data inicial para el módulo
     */
    async loadInitialData() {
      this.ticketFilters.ticketStatuses = [...this.ticketStatuses]
      this.ticketFilters.tags = []
      this.ticketFilters.lastStatusFirstTemplate = [
        ...STATUSES_TEMPLATE.map((status) => status._id),
      ]
      this.handleChangeStatus()
      await this.getListCustomFields()
      this.loadFields()
    },
    /**
     * Setea los campos personalizados a columnas
     */
    async loadFields() {
      this.columns = [
        ...COLUMNS_FILTERS_TICKETS.filter((column) => column.show),
      ]

      this.company_custom_fields_clients.forEach((customField) => {
        this.columns.push({
          title: customField.name,
          key: customField._id,
          checked: false,
          isFieldClient: true,
          show: true,
        })
      })

      this.company_custom_fields_tickets.forEach((customField) => {
        this.columns.push({
          title: customField.name,
          key: customField._id,
          checked: false,
          isCustom: true,
          show: true,
        })
      })
    },

    async fetchTickets() {
      this.loading = true
      if (!this.fetchedFromOtherModule) {
        await this.getAllTicketsToManage({
          search: this.seeker,
          page: this.page,
          dateRange: this.ticketFilters.dateRange,
          idMailbox: this.ticketFilters.mailbox,
          status: 'all',
          read: this.ticketFilters.status,
          line: this.ticketFilters.line,
          ticketStatuses: this.ticketStatusesSelected,
          channel: this.ticketFilters.channel,
          channelCompanyId: this.ticketFilters.channelCompanyId,
          tags: this.ticketFilters.tags,
          customFields: this.ticketFilters.customFields,
          favorite: this.ticketFilters.favorite,
          order: 'new',
          clientsId: this.ticketFilters.clientsId,
          agent: this.ticketFilters.agent,
          products: this.ticketFilters.products,
          strategies: this.ticketFilters.strategies,
          isGhost: this.ticketFilters.isGhost,
          lastStatusFirstTemplate: this.ticketFilters.lastStatusFirstTemplate,
        })
      }
      this.loading = false
    },
    /**
     * Escucha el cambio del valor del rango
     */
    handleChangeRange() {
      this.handleChangeStatus()
    },
    async handleChangeStatus() {
      if (!this.fetchedFromOtherModule) {
        this.SET_CLIENTSID_FILTER_TICKET([])
      }
      this.page = 1
      this.ticketStatusesSelected = [
        ...this.ticketFilters.ticketStatuses.map((el) => el._id),
      ]
      this.fetchTickets()
    },
    /**
     * Filtra los tickets de acuerdo a los filtros pasados
     * @param {Object} filters
     * @param {String[]} filters.ticketStatuses
     * @param {String[]} filters.products
     * @param {String[]} filters.favorite
     * @param {String} filters.agent
     * @param {String[]} filters.status
     * @param {String[]} filters.line
     * @param {String} filters.mailbox
     */
    async handleFilterDrawer(filters) {
      const allTicketStatuses = [...this.ticketStatuses]
      this.ticketStatusesSelected = [...filters.ticketStatuses]
      this.ticketFilters = JSON.parse(JSON.stringify(filters)) // por alguna razón, stop propagation no funciona
      this.ticketFilters.ticketStatuses = allTicketStatuses.filter((st) =>
        this.ticketStatusesSelected.includes(st._id)
      )
      this.page = 1
      await this.fetchTickets()
      this.showDrawerFilters = false
    },
    async handlePaginate(page) {
      this.page = page
      this.fetchTickets()
    },
    /**
     * Esta confirmacion se muestra para las acciones masivas del CRM
     * @param {Object} line
     * @param {String} line.name
     */
    async confirmTransferLine(line) {
      this.$confirm({
        title: `¿Está seguro(a) de transferir ${
          this.ticket ? 'el ticket' : 'los tickets'
        } a la cola "${line.name}"?`,
        content:
          'Recuerda que los tickets serán derivados y repartidos en la cola seleccionada',
        okText: 'Transferir',
        cancelText: 'Cancelar',
        onOk: () => {
          this.handleTransferLine(null, line, this.ticketsToTransfer, true)
        },
      })
    },
    async handleTransferLine(ticket, line, tickets, masive = false) {
      this.$message.loading({
        content: `Transfiriendo ticket${ticket ? '' : 's'}....`,
        key: 'transfer',
        duration: 16,
      })
      const response = await this.transferAdminTicket({
        typeTransfer: 'line',
        ticket,
        line,
        massive: masive,
        tickets: tickets,
      })
      if (response.success) {
        this.$message.success({
          content: ticket
            ? 'El ticket se transfirió con éxito'
            : 'Los tickets se transfirieron con éxito',
          key: 'transfer',
          duration: 3,
        })
        return
      } else {
        this.$message.error({
          content:
            'Al parecer ya no se puede transferir ' +
            (ticket ? 'el ticket' : 'los tickets'),
          key: 'transfer',
          duration: 3,
        })
      }
      this.handleChangeStatus()
    },
    /**
     * Setea etiquetas al ticket
     * @param {Boolean} cheked
     * @param {String} key
     */
    async handleSetTicket(checked, key) {
      const tag = this.tags.find((tag) => tag.idTag === key)
      this.$message.loading({
        content: `Actualizando etiqueta...`,
        key: 'tag',
        duration: 16,
      })
      const response = await this.updateTagMasive({
        tag,
        tickets: this.ticketsToTransfer,
        checked,
      })

      if (response.success) {
        this.$message.success({
          content: 'Se actualizo la etiqueta con éxito',
          key: 'tag',
          duration: 3,
        })
        return
      }
      this.$message.error({
        content: 'Hubo un problema al actualizar la etiqueta ',
        key: 'tag',
        duration: 3,
      })
    },
    handleTransferAgent(ticket) {
      let ticketToCompare = ticket
      if (!ticketToCompare) ticketToCompare = this.ticketsToTransfer[0]
      if (ticketToCompare.status == 'ended') return
      this.modalTransferAgentVisible = true
      this.ticketToTransfer = ticketToCompare
    },
    /**
     * Escucha la resolución de tickets
     * @param {Object} ticket
     * @param {Object} args
     * @param {Boolean} args.massive
     */
    handleResolveTicket(ticket, { massive = false }) {
      let ticketToCompare = ticket
      if (!ticketToCompare) {
        if (this.hasFinishTicked) return
      } else if (
        this.status_to_prevent_resolve_tickets.includes(
          ticketToCompare.status
        ) ||
        this.status_to_prevent_resolve_tickets.includes(
          ticketToCompare.subStatus
        )
      )
        return
      if (!massive) this.ticketToTransfer = ticketToCompare
      this.modalResolveTicket.visible = true
      this.setAnalytics({ eventName: 'ticket_resolve' })
    },
    handleSeeTicket(ticket) {
      this.detailsTicketVisible = true
      ticket.messages = []
      ticket.pagination_messages = { page: 1 }
      this.ticketToTransfer = ticket
      this.setAnalytics({ eventName: 'ticket_watch' })
    },
    handleSelectedRows(selectedRows) {
      this.ticketsToTransfer = selectedRows
    },
    updateAgentInTicket(agent, ticket, tickets) {
      this.updateAgentInTicketsToManage({ agent, ticket, tickets })
    },
    updatedTicketStatus(status, ticket, tickets) {
      this.updateStatusInTicketsToManage({ status, ticket, tickets })
    },
    onCloseActions(toShow) {
      this[toShow] = false
      this.ticketToTransfer = null
    },
    onSeeClient(client) {
      this.clientToSee = client
      this.showClientDetails = true
    },
    onEditTicket(ticket) {
      this.ticketToUpdate = ticket
      this.updateTicketDrawerVisible = true
      this.setAnalytics({ eventName: 'ticket_edit' })
    },
    /**
     * @param {Object} {ticket}
     */
    async onUpdateTicket() {
      this.updateTicketDrawerVisible = false
      this.showDrawerFilters = false
    },
    /**
     * Deshabilitar días siguientes a hoy
     * @param {Date}
     */
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    /**
     * Exporta los tickets en un archivo
     * @param {Date} dateRange
     */
    handleExportTickets(dateRange) {
      this.exportTickets({
        filters: {
          search: this.seeker,
          page: this.page,
          dateRange: dateRange,
          idMailbox: this.ticketFilters.mailbox,
          status: 'all',
          read: this.ticketFilters.status,
          line: this.ticketFilters.line,
          ticketStatuses: [...this.ticketStatusesSelected, 'unanswered'],
          channel: this.ticketFilters.channel,
          channelCompanyId: this.ticketFilters.channelCompanyId,
          tags: this.ticketFilters.tags,
          customFields: this.ticketFilters.customFields,
          favorite: this.ticketFilters.favorite,
          order: 'new',
          clientsId: this.ticketFilters.clientsId,
          agent: this.ticketFilters.agent,
          strategies: this.ticketFilters.strategies,
          isGhost: this.ticketFilters.isGhost,
          lastStatusFirstTemplate: this.ticketFilters.lastStatusFirstTemplate,
        },
        headers: this.columnsToExport,
      })
    },
    async onShowAssignedProducts(idTicket) {
      this.drawerProducts.visible = true
      this.drawerProducts.load = true
      const response = await this.getAssignedProductsNoPagedOfTicket(idTicket)
      if (response.success) this.drawerProducts.data = response.result
      else
        this.$message.error(
          response.details ? response.details : 'Ocurrió un error 😥'
        )
      this.drawerProducts.load = false
    },
    handleCloseProducts() {
      this.drawerProducts.visible = false
    },
    onLoadingUpdated(isLoading) {
      this.loading = isLoading
    },
    /**
     * Resolver ticket
     * @param {String} comment
     */
    async resolveTicket({ comment }) {
      this.$message.loading({
        content: `Resolviendo ticket${this.ticketToTransfer ? '' : 's'}....`,
        key: 'resolve',
        duration: 16,
      })
      const response = await this.resolveTicketFromAdmin({
        ticket: this.ticketToTransfer,
        tickets: this.ticketsToTransfer,
        comment,
      })

      if (response.success) {
        this.$message.success({
          content: this.ticketToTransfer
            ? 'El ticket se resolvió con éxito'
            : 'Los tickets se resolvieron con éxito',
          key: 'resolve',
          duration: 3,
        })
        this.updatedTicketStatus(
          'ended',
          this.ticketToTransfer,
          this.ticketsToTransfer
        )
        this.modalResolveTicket.visible = false
      } else {
        this.$message.error({
          content:
            'Al parecer ya no se puede resolver ' +
            (this.ticketToTransfer ? 'el ticket' : 'los tickets'),
          key: 'resolve',
          duration: 3,
        })
        this.handleChangeStatus()
      }
    },
    /**
     * Ejecuta el cambio en Columnas
     * @param {Boolean} checked
     * @param {String} key - id de la columna
     */
    handleChangeColumn(checked, key) {
      const column = this.columns.find((col) => col.key === key)
      if (column === undefined) return
      column.checked = checked
    },
    /**
     * Escucha los cambios de un canal
     */
    handleChangeChannel() {
      this.ticketFilters.channelCompanyId = 'all'
      this.handleChangeStatus()
    },
    handleCloseModalResolve() {
      this.modalResolveTicket.visible = false
      this.modalResolveTicket.isMassive = false
      this.ticketToTransfer = null
    },
    setInitialFilters() {
      this.ticketFilters = {
        dateRange: [],
        mailbox: 'all',
        status: 'all',
        order: 'new',
        line: 'all',
        ticketStatuses: [],
        channel: 'all',
        channelCompanyId: 'all',
        tags: [],
        customFields: [],
        favorite: 'all',
        clientsId: null,
        agent: 'all',
        products: [],
        lastStatusFirstTemplate: [],
        isGhost: false,
      }
      this.ticketFilters.clientsId = this.clientsIdFilterTicket
      // si existe un rango para filtrar los tickets
      if (this.rangeFilterTickets.length > 0)
        this.ticketFilters.dateRange = this.rangeFilterTickets
      this.ticketFilters.ticketStatuses = [...this.ticketStatuses]
      this.ticketFilters.tags = this.idTagsToSelect
      this.ticketFilters.lastStatusFirstTemplate = [
        ...STATUSES_TEMPLATE.map((status) => status._id),
      ]
      this.handleChangeStatus()
    },
  },
}
</script>

<style lang="sass" scoped>
.section-manage-tickets
  padding-top: 8px
  h5
    text-align: left
    color: $gray_dark_900
.filters__item
  width: 16em
.filters__button
  width: 110px
  margin-right: 12px
  padding: 0
  .text
    width: 65px
    margin-right: 5px
.filters__button--center
  .text
    margin-right: 0px
.filter-channel
  display: none
  @include xxl
    display: block
.tickets-title
  text-align: left
  margin-top: 12px
  margin-bottom: 8px
.columns-filter
  overflow: scroll
  max-height: 300px
  width: 100%
.section
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
