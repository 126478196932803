<template>
  <div class="section-menu-workspace">
    <header-module
      title="Workspace"
      tipography="heading-h5"
      class="section__header"
      :link="`${vari.WEB_DOC}caracteristicas/configuracion-workspace/`"
      text-before-link="Administra opciones que potenciarán las conversaciones con tus
          clientes en Workspace."
      text-after-link="si deseas conocer más."
      textLink="Click aquí"
      positionTooltip="right"
    />
    <div id="menu-workspace-master-container" class="master-container">
      <div
        id="menu-workspace-container"
        class="menu-workspace-container scrollbar_basic"
        @scroll="
          setShadows(
            'menu-workspace-master-container',
            'menu-workspace-container',
            'menu-workspace-content'
          )
        "
      >
        <div id="menu-workspace-content" class="menu-workspace-content">
          <SectionTag
            v-if="company"
            :tags="company.settings.tags"
            class="mrg-bottom-20"
          />
          <section-fast-answers class="mrg-bottom-20" />
          <SectionCustomFields
            :listCustomFields="company_custom_fields_tickets"
            class="mrg-bottom-20"
            description="Esta opción te permitirá crear campos para tus tickets. Todos los campos creados se listarán en el módulo de tickets de tus agentes."
            :pagination="{}"
            title="Campos personalizados del ticket"
            icon="form"
            module="workspace"
          />
          <SectionCustomFields
            :listCustomFields="company_custom_fields_clients"
            class="mrg-bottom-20"
            description="Esta opción te permite crear campos con información adicional de tus contactos. Estos se verán en el perfil del contacto."
            :pagination="{}"
            title="Campos personalizados del contacto"
            icon="user"
            module="clients"
          />
          <SectionMailbox
            v-if="company"
            class="mrg-bottom-20"
            :mailboxes="company.settings.mailboxes"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import SectionCustomFields from '@/app/settings/components/organisms/workspaceMenu/SectionCustomFields'
import SectionMailbox from '@/app/settings/components/organisms/workspaceMenu/SectionMailbox'
import SectionTag from '@/app/settings/components/organisms/workspaceMenu/SectionTag'
import SectionFastAnswers from '@/app/settings/components/organisms/workspaceMenu/SectionFastAnswers'
import attemptMixin from '@/app/shared/mixins/attempt'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'

export default {
  name: 'SectionMenuWorkspace',
  mixins: [attemptMixin],
  components: {
    SectionCustomFields,
    SectionMailbox,
    SectionTag,
    SectionFastAnswers,
    HeaderModule,
  },
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'No company'
      this.getListCustomFields()
      this.getFastAnswers({
        // trae todas las respuestas rapidas
        page: 1,
        search: '',
        module: 'general_settings',
      })
    })
  },
  data: () => ({ vari }),
  computed: {
    ...mapGetters([
      'company',
      'company_custom_fields_tickets',
      'company_custom_fields_clients',
    ]),
  },
  methods: {
    ...mapActions(['getListCustomFields', 'getFastAnswers']),
    setShadows(idMaster, idContainer, idContent) {
      const masterContainer = document.getElementById(idMaster)
      const container = document.getElementById(idContainer)
      const content = document.getElementById(idContent)
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$size-title: 24px
$margin-bottom-title: 12px
$padding-top-header: 20px

.section-menu-workspace
  height: 100%
  .section
    &__header
      padding: $padding-top-header 20px 0
      display: flex
      align-items: center
      margin-bottom: $margin-bottom-title
      .title
        text-align: left
    &__icon
      margin-left: 8px
      font-size: 18px
      cursor: pointer
  .master-container
    position: relative
    padding-top: 4px
    padding-bottom: 4px
    height: calc(100% - #{$size-title + $margin-bottom-title + $padding-top-header + 12px})
    .menu-workspace-container
      overflow-y: auto
      height: 100%
      .menu-workspace-content
        margin: 0 20px
.shadow-top
  &::before
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    top: 0
.shadow-bottom
  &::after
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    bottom: 0
</style>
