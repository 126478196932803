const PREFIX_VARS = '#'
const SUPPORT_WBA = 'whatsapp'
const SUPPORT_MSG = 'messenger'
const SUPPORT_IG = 'instagram'
const SUPPORT_WBT = 'webchat'
const SUPPORT_WBA_IG_MSG_WBT = 'all_channels'

const NODES = {
  message: {
    title: 'Mensaje',
    add_text: null,
    icon: 'message',
    layout: 'basic-node',
    tooltip_text:
      'Recuerda que no se podrán agregar más mensajes en este y se resolverá el ticket.',
    type: 'message',
    description: 'Agregar nuevo mensaje',
    add_function: 'addDefaultNodeMessage',
  },
  catcher: {
    title: 'Capturar variable',
    add_text: null,
    icon: 'number',
    layout: 'basic-node',
    tooltip_text:
      'Recuerda que no se podrán agregar más mensajes en este y se resolverá el ticket.',
    type: 'catcher',
    description: 'Agregar capturar variable',
    add_function: 'addDefaultNodeCatcher',
  },
  options: {
    title: 'Opciones múltiples',
    add_text: 'Agregar opción',
    icon: 'profile',
    layout: 'parent-node',
    is_father: true, // si el nodo tiene hijos
    description_property: 'content',
    child: {
      group: 'options',
      title: 'Opción',
      title_plural: 'Opciones',
      id_property: 'idOption',
      type: 'option',
      support_channels: {
        [SUPPORT_WBA]: {
          max: 20,
        },
        [SUPPORT_MSG]: {
          max: 20,
        },
        [SUPPORT_IG]: {
          max: 20,
        },
        [SUPPORT_WBT]: {
          max: 20,
        },
        [SUPPORT_WBA_IG_MSG_WBT]: {
          max: 20,
        },
      },
    },
    type: 'options',
    description: 'Agregar opciones múltiples',
    add_function: 'addDefaultNodeOptions',
  },
  buttons: {
    title: 'Botones',
    add_text: 'Agregar botón',
    icon: 'button',
    layout: 'parent-node',
    is_father: true, // si el nodo tiene hijos
    description_property: 'content',
    child: {
      group: 'buttons',
      title: 'Botón',
      title_plural: 'Botones',
      id_property: 'idButton',
      type: 'button',
      support_channels: {
        [SUPPORT_WBA]: {
          max: 3,
        },
        [SUPPORT_MSG]: {
          max: 13,
        },
        [SUPPORT_IG]: {
          max: 0,
        },
        [SUPPORT_WBT]: {
          max: 13,
        },
        [SUPPORT_WBA_IG_MSG_WBT]: {
          max: 0,
        },
      },
    },
    type: 'buttons',
    description: 'Agregar botones',
    support_channels: [SUPPORT_WBA, SUPPORT_MSG, SUPPORT_WBT],
    add_function: 'addDefaultNodeButtons',
  },
  cards: {
    title: 'Tarjetas con botones',
    add_text: 'Agregar tarjeta',
    icon: 'browser',
    layout: 'parent-node',
    is_father: true, // si el nodo tiene hijos
    description_property: 'content',
    child: {
      group: 'cards',
      title: 'Tarjeta',
      title_plural: 'Tarjetas',
      simulate_parent: true,
      add_text: 'Agregar botón',
      type: 'card',
      id_property: 'idCard',
      support_channels: {
        [SUPPORT_MSG]: {
          max: 10,
        },
        [SUPPORT_WBT]: {
          max: 10,
        },
      },
      child: {
        group: 'buttons',
        title: 'Botón',
        title_plural: 'Botones',
        type: 'card_button', // usado para diferenciarlos de otro componente con igual nombre
        is_subchild: true,
        id_property: 'idButton',
        title_property: 'text', // usaso para el cambio de orden
      },
    },
    type: 'cards',
    description: 'Agregar tarjetas con botones',
    support_channels: [SUPPORT_MSG, SUPPORT_WBT],
    add_function: 'addDefaultNodeCards',
  },
  lists: {
    title: 'Listas',
    add_text: 'Agregar sección',
    icon: 'bars',
    layout: 'parent-node',
    is_father: true, // si el nodo tiene hijos
    description_property: 'title',
    child: {
      group: 'lists',
      title: 'Sección',
      title_plural: 'Secciones',
      simulate_parent: true,
      add_text: 'Agregar item',
      type: 'list_section',
      id_property: 'idItem',
      support_channels: {
        [SUPPORT_WBA]: {
          max: 10,
        },
      },
      child: {
        group: 'options',
        title: 'Item',
        title_plural: 'Items',
        type: 'list_item', // usado para diferenciarlos de otro componente con igual nombre
        is_subchild: true,
        id_property: 'idOption',
        title_property: 'title', // usado para el cambio de orden yleerlo en el arbol de nodos
        extra_params_for_change_order: ['description'], // usado para el cambio de orden
      },
    },
    type: 'lists',
    description: 'Agrega listas de opciones',
    support_channels: [SUPPORT_WBA],
    add_function: 'addDefaultNodeLists', // nombre de la función que agrega una lista
  },
}

const ACTIONS_TO_NODES = [
  {
    value: 'continue_catcher', // se usará para enviar el valor hacia la endpoint
    title: 'Agregar capturar variable',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [
      SUPPORT_WBA,
      SUPPORT_MSG,
      SUPPORT_IG,
      SUPPORT_WBT,
      SUPPORT_WBA_IG_MSG_WBT,
    ],
    action: 'continue_catcher', // se harán todas las validaciones en los componentes
    description:
      'Añadiremos un capturador de variables para continuar con el flujo de tu chatbot. Recuerda guardar tus cambios.',
  },
  {
    value: 'continue_message',
    title: 'Agregar un nuevo mensaje',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [
      SUPPORT_WBA,
      SUPPORT_MSG,
      SUPPORT_IG,
      SUPPORT_WBT,
      SUPPORT_WBA_IG_MSG_WBT,
    ],
    action: 'continue_message',
    description: 'Añadiremos un nuevo mensaje para continuar el flujo.',
  },
  {
    value: 'continue_options',
    title: 'Continuar a opciones múltiples',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [
      SUPPORT_WBA,
      SUPPORT_MSG,
      SUPPORT_IG,
      SUPPORT_WBT,
      SUPPORT_WBA_IG_MSG_WBT,
    ],
    action: 'continue_options',
    description:
      'Añadiremos nuevas opciones múltiples para continuar con el flujo de tu chatbot. Recuerda guardar tus cambios.',
  },
  {
    value: 'continue_buttons',
    title: 'Continuar con botones',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [SUPPORT_WBA, SUPPORT_MSG, SUPPORT_WBT],
    action: 'continue_buttons',
    description:
      'Añadiremos nuevos botones para continuar con el flujo de tu chatbot. Recuerda guardar tus cambios.',
  },
  {
    value: 'continue_cards',
    title: 'Continuar con tarjetas',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [SUPPORT_MSG, SUPPORT_WBT],
    action: 'continue_cards',
    description:
      'Añadiremos una nueva tarjeta para continuar con el flujo de tu chatbot. Recuerda guardar tus cambios.',
  },
  {
    value: 'continue_lists',
    title: 'Continuar con lista de opciones',
    allowed: true,
    allowed_to_nodes: ['message', 'catcher', 'option', 'list_item'],
    allowed_to_support_channels: [SUPPORT_WBA],
    action: 'continue_lists',
    description:
      'Añadiremos una nueva lista para continuar con el flujo de tu chatbot. Recuerda guardar tus cambios.',
  },
  {
    value: 'transfer',
    title: 'Transferir a cola',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [
      SUPPORT_WBA,
      SUPPORT_MSG,
      SUPPORT_IG,
      SUPPORT_WBT,
      SUPPORT_WBA_IG_MSG_WBT,
    ],
    action: 'transfer',
    description: 'El ticket será transferido a la cola seleccionada.',
  },
  {
    value: 'none',
    title: 'Finalizar chatbot',
    allowed: true,
    allowed_to_nodes: [
      'message',
      'catcher',
      'option',
      'button',
      'card_button',
      'list_item',
    ],
    allowed_to_support_channels: [
      SUPPORT_WBA,
      SUPPORT_MSG,
      SUPPORT_IG,
      SUPPORT_WBT,
      SUPPORT_WBA_IG_MSG_WBT,
    ],
    action: 'none',
    description: 'El ticket será finalizado y cerrado.',
  },
  {
    value: 'rollback',
    title: 'Regresar al menú anterior',
    allowed: true,
    allowed_to_nodes: ['option'],
    allowed_to_support_channels: [
      SUPPORT_WBA,
      SUPPORT_MSG,
      SUPPORT_IG,
      SUPPORT_WBT,
      SUPPORT_WBA_IG_MSG_WBT,
    ],
    action: 'rollback',
  },
]

export { ACTIONS_TO_NODES, PREFIX_VARS, NODES }
