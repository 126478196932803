<template>
  <div class="account-list">
    <a-table
      class="account-table"
      :columns="columns"
      :data-source="dataNFormatted"
      :pagination="pagination"
      :loading="sectionAccounts.list.loading.table"
      rowKey="_id"
      :locale="locale"
      @change="handleTableChange"
      size="middle"
      :scroll="{ x: '90vw', y: 'calc(100vh - 230px)' }"
    >
      <!--tipos de usuarios en la tabla-->
      <span slot="type" slot-scope="type">
        {{ type.option }}
      </span>
      <!--Código de telefono + número telefono-->
      <span slot="phone" slot-scope="phone, record">
        {{ record.code }} {{ phone }}
      </span>
      <!--Estado de usuario-->
      <span slot="active" slot-scope="active">
        <a-tag v-if="active" color="blue"> Activo </a-tag>
        <a-tag v-else> Inactivo </a-tag>
      </span>
      <!--Estados de pago-->
      <span slot="status_payment" slot-scope="status_payment">
        <a-tag :color="transformStatus(status_payment).color">
          {{ transformStatus(status_payment).name }}
        </a-tag>
      </span>

      <!--acciones en la tabla-->
      <span slot="action" slot-scope="text, record">
        <a @click="() => edit(record)" :disabled="!enabledUpdate(record)"
          >Editar</a
        >
        <a-divider
          v-if="record.status_payment === 'rejected' || !record.active"
          type="vertical"
        />
        <a
          v-if="record.status_payment === 'rejected'"
          @click="handleRetryPay(record)"
          >Reintentar pagar</a
        >
        <a
          v-else-if="!record.active"
          :disabled="!enabledUpdate(record)"
          @click="
            enabledUpdate(record) &&
              $emit(
                'onActiveAccount',
                false,
                record.names + ' ' + record.surnames,
                record._id
              )
          "
          >Reactivar usuario</a
        >
      </span>
    </a-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import accountsMixin from '@/app/accounts/mixins/accountsMixin'

export default {
  name: 'AccountList',
  props: {
    sectionAccounts: {
      type: Object,
    },
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    totalData: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    currentPage: 1,
    locale: {
      emptyText: 'No se ha encontrado registro',
    },
  }),
  watch: {
    reloadTableAccounts() {
      this.handleTableChange({ current: this.currentPage })
    },
  },
  computed: {
    ...mapGetters(['reloadTableAccounts', 'profile', 'roles']),
    pagination() {
      let pg = {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.totalData || 0,
      }
      return pg
    },
    dataNFormatted() {
      return this.data.map((el) => {
        el.number =
          el.number + (this.currentPage - 1) * this.pagination.defaultPageSize // muestra el número de orden según la paginación
        return el
      })
    },
  },
  mixins: [attemptMixin, compareMixin, accountsMixin],
  async created() {
    await this.load()
  },
  mounted() {
    this.intervalAttempt(async () => {
      if (!this.$socket) throw 'socket'
      this.listenAccountCreated({ self: this })
    })
  },
  methods: {
    ...mapActions(['listAccounts', 'getAccounts', 'listenAccountCreated']),
    async load() {
      await this.getAccounts({ self: this })
    },
    /**
     * Llenar la tabla de Cuentas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleTableChange(pagination) {
      this.currentPage = pagination.current
      this.sectionAccounts.list.pagination = pagination.current
      await this.getAccounts({ self: this })
    },
    edit(record) {
      // record es el objeto que contiene las propiedades de la fila
      this.$emit('onOpenModal', record)
    },
    /**
     * Transformar el Estado
     * @param {String} status
     */
    transformStatus(status) {
      switch (status) {
        case 'approved':
          return {
            name: 'Pagado',
            color: 'green',
          }
        case 'pending':
          return {
            name: 'Pendiente',
            color: 'volcano',
          }
        case 'rejected':
          return {
            name: 'Rechazado',
            color: 'red',
          }
        default:
          return {
            name: 'Rechazado',
            color: 'red',
          }
      }
    },
    handleRetryPay(account) {
      this.$emit('onOpenBuyAccount', account)
    },
    /**
     * Habilitar para actualizar o no
     * @param {Object} record
     * @param {String} record.userId
     * @param {Boolean} record.owner
     * @param {Object} record.type
     * @param {String} record.type.value
     * @return {Boolean}
     */
    enabledUpdate(record) {
      const isMine = this.profile && this.profile.userId === record.userId
      const isOwner = record.owner
      const enabled =
        this.permissionsModule &&
        this.isAllowedFor(
          record.type.value,
          [...this.permissionsModule.users_to_manage],
          { exclude: ['master_agent'] }
        )

      return (isMine && isOwner) || (!isOwner && enabled)
    },
  },
}
</script>
<style lang="sass" scoped>
.account-list
   .left
    display: flex
    justify-content: flex-end
    margin: 8px 0
</style>
<style lang="sass">
.ant-table-body
  background-color: $white_000
// .ant-table-tbody > tr > td
//   padding: 12px 8px!important
// .ant-table-thead > tr > th
//   padding: 12px 8px!important
.account-table
  .ant-table-body, .ant-table-body-inner
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
