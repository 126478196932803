var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-continue-message"},[_c('custom-section-scroll',{attrs:{"master-styles":{ height: _vm.heightContainer }}},[_c('a-row',{staticClass:"form-content flex-no-wrap",class:{
        'form-content--empty':
          !_vm.assigned_templates || !_vm.assigned_templates.length,
      },attrs:{"type":"flex"}},[(_vm.assigned_templates && _vm.assigned_templates.length)?[_c('div',[_c('p',{staticClass:"body-2 mrg-bottom-8"},[_vm._v("Completa todos los campos")]),(_vm.error.visible)?_c('a-alert',{staticClass:"mrg-bottom-8",attrs:{"message":_vm.error.message,"type":"error","show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"form__container",attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Selecciona una plantilla"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['template']),expression:"['template']"}],attrs:{"size":_vm.inputs_size,"placeholder":"Escoge una plantilla"},on:{"change":_vm.handleTemplate}},_vm._l((_vm.assigned_templates),function(assigned_template){return _c('a-select-option',{key:assigned_template._id},[_vm._v(" "+_vm._s(assigned_template.friendlyName)+" ")])}),1)],1),(_vm.templateSelected)?_c('h6',{staticClass:"heading-h7",staticStyle:{"margin-bottom":"4px"}},[_vm._v(" Parámetros ")]):_vm._e(),(_vm.templateSelected)?_c('custom-section-scroll',{attrs:{"master-styles":{ maxHeight: _vm.heightBodyForm }}},_vm._l((_vm.templateSelected.parameters),function(param){return _c('a-form-item',{key:`param${param.idParameter}`,attrs:{"label":param.title}},[_c('a-input',{attrs:{"size":_vm.inputs_size,"placeholder":"Escribe aquí..."},on:{"input":_vm.replaceParamsBody},model:{value:(param.value),callback:function ($$v) {_vm.$set(param, "value", $$v)},expression:"param.value"}})],1)}),1):_vm._e(),(_vm.templateSelected.type === 'MEDIA')?_c('a-form-item',{attrs:{"label":"Archivo adjunto"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'fileMessage',
                  {
                    valuePropName: 'fileList',
                    getValueFromEvent: _vm.normFile,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor sube el archivo',
                      },
                    ],
                  },
                ]),expression:"[\n                  'fileMessage',\n                  {\n                    valuePropName: 'fileList',\n                    getValueFromEvent: normFile,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Por favor sube el archivo',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"name":"file","multiple":false,"file-list":_vm.fileList,"customRequest":_vm.uploadFile,"before-upload":_vm.beforeUpload,"remove":_vm.handleRemoveFile,"accept":_vm.templateSelected.formats.accept}},[_c('p',[_c('a-icon',{staticClass:"form__icon-inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_c('span',[_vm._v(" Solo se acepta "+_vm._s(_vm.templateSelected.formats.titles.join(' ,'))+". ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.templateSelected.max_size.title)+" tamaño máximo. ")]),(
                      _vm.templateSelected &&
                      _vm.templateSelected.typeAttachment &&
                      _vm.templateSelected.typeAttachment === 'IMAGE'
                    )?[_c('br'),_c('span',[_vm._v("Las dimensiones ideales son 500 x 500")])]:_vm._e()],2)])],1):_vm._e()],1)],1),(!_vm.active_breakpoint.is_mobile)?_c('mobile-wsp',{staticClass:"form__mobile",attrs:{"message":_vm.message,"media-type":_vm.mediaTypeForPreview,"media-url":_vm.mediaUrl,"file-name":_vm.fileName,"type":"small"}}):_c('preview-message',{attrs:{"text":_vm.message,"media-type":_vm.mediaTypeForPreview,"media-url":_vm.mediaUrl,"file-name":_vm.fileName}})]:[_c('p',{staticClass:"body-2 text--center full-width"},[_vm._v(" No tienes plantillas asignadas. ")])]],2)],1),_c('div',{staticClass:"footer"},[_c('a-button',{staticClass:"mrg-right-8",attrs:{"size":_vm.buttons_size},on:{"click":_vm.handleCancel}},[_vm._v("Cancelar")]),_c('a-button',{attrs:{"type":"primary","size":_vm.buttons_size,"loading":_vm.loadBtnSend,"disabled":_vm.disabledBtnSend || _vm.disabledForAttachment},on:{"click":_vm.prepareTemplate}},[_vm._v("Enviar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }