<template>
  <div class="filter-contact">
    <div class="filter-contact-content">
      <!-- Busqueda -->
      <a-tooltip placement="left">
        <template slot="title"> Buscar por nombre, email, teléfono </template>
        <a-input-search
          v-model="drawerFilters.filters.search"
          placeholder="Buscar"
          class="filters__item"
          @search="onChange"
        />
      </a-tooltip>
      <!-- Canales -->
      <a-select
        v-model="drawerFilters.filters.channel"
        default-value="all"
        @change="handleChangeChannel"
        class="filters__item"
      >
        <a-select-option
          v-for="channel in channelsForFilters"
          :key="channel.channel"
          :title="channel.default ? channel.name : `${channel.name}`"
        >
          {{ channel.default ? channel.name : `${channel.name}` }}
        </a-select-option>
      </a-select>
      <!-- Ids de canales -->
      <a-select
        v-model="drawerFilters.filters.channelCompanyId"
        @change="onChange"
        class="filters__item"
      >
        <a-select-option
          v-for="simpleChannel in simpleChannelsFiltered"
          :key="simpleChannel._id"
          :title="simpleChannel.title"
        >
          <a-row type="flex" align="middle">
            <social-icon
              v-if="simpleChannel.channel"
              :type="simpleChannel.channel"
              :size="14"
              :disabled="true"
              class="mrg-right-4"
            />
            <span :class="{ 'mrg-right-4': simpleChannel.alias }"
              >{{ simpleChannel.title }}
            </span>
            <span>{{
              simpleChannel.alias ? `(${simpleChannel.alias})` : ''
            }}</span>
          </a-row>
        </a-select-option>
      </a-select>
      <!-- Rango de fecha de creación -->
      <a-range-picker
        class="filters__item"
        :disabled-date="disabledDate"
        :show-time="{
          format: localePicker.lang.dateTimeFormat,
          defaultValue: [
            moment('00:00:00', 'HH:mm:ss'),
            moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        :placeholder="['Fecha de inicio', 'Fecha de fin']"
        :format="localePicker.lang.dateTimeFormat"
        :locale="localePicker"
        @change="onChange"
        @ok="onChange"
        :popupStyle="stylesPopup"
        v-model="drawerFilters.filters.dateRange"
      />
      <!--Otros filtros, abre el filtro de drawer-->
      <a-button @click="handleShowFilters">
        <span> {{ buttonShowFilter }}</span>
        <i class="anticon anticon-right">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'16'" v-bind:width="'16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 11a4.002 4.002 0 01-3.874-3H2V6h7.126A4.002 4.002 0 0117 7a4 4 0 01-4 4zm6-5h3v2h-3V6zM8 21a4.002 4.002 0 01-3.874-3H2v-2h2.126A4.002 4.002 0 0112 17a4 4 0 01-4 4zm6-3h8v-2h-8v2zm-4-1a2 2 0 11-4 0 2 2 0 014 0zm5-10a2 2 0 11-4 0 2 2 0 014 0z" fill="curretColor"/></svg>
        </i>
      </a-button>
      <drawer-filters-contact
        :visible="drawerFilters.visible"
        :filters="{ ...drawerFilters.filters }"
        @onClose="drawerFilters.visible = false"
        @onSetFilters="handleSetFilters"
        @onLoading="handleLoadtable"
      />
    </div>
    <a-tooltip placement="bottomRight" :overlayStyle="{ width: '240px' }">
      <span slot="title">
        Tus contactos se descargarán de acuerdo a los filtros seleccionados.
      </span>
      <a-button
        type="primary"
        icon="download"
        @click="modalDownloadCrm.visible = true"
        >Descargar contactos</a-button
      >
    </a-tooltip>
    <modal-download-crm
      :visible="modalDownloadCrm.visible"
      title="Descarga tus contactos"
      :description="modalDownloadCrm.description"
      :onOk="handleExportContacts"
      @onClose="modalDownloadCrm.visible = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DrawerFiltersContact from '@/app/contacts/components/organisms/modals/DrawerFiltersContact'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import moment from 'moment'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import ModalDownloadCrm from '@/app/shared/components/modals/ModalDownloadCrm'

export default {
  name: 'FilterContactModule',
  components: {
    DrawerFiltersContact,
    SocialIcon,
    ModalDownloadCrm,
  },
  mixins: [formatMixin, supportMixin, attemptMixin, compareMixin],
  data: () => ({
    moment,
    stylesPopup: {
      zIndex: 1,
    },
    drawerFilters: {
      visible: false,
      filters: {
        page: 0,
        search: '',
        channel: 'all',
        channelCompanyId: 'all',
        dateRange: [],
        agent: 'all',
        products: [],
      },
    },
    simpleChannelsFiltered: [],
    modalDownloadCrm: {
      visible: false,
      description:
        'Hola 👋, vemos que deseas descargar tus contactos, para poder realizarlo es necesario que selecciones un rango de fecha por creación de contactos. Además recuerda que el rango puede ser de hasta 2 meses como máximo.',
    },
  }),
  created() {
    this.handleChangeChannel(this.drawerFilters.filters.channel, true, false)
  },
  mounted() {
    this.intervalAttempt(async () => {
      this.handleLoadtable(true)
      if (!this.company) throw 'No company'
      this.onChange()
      this.handleLoadtable(false)
    })
  },
  watch: {
    contacts_page() {
      this.filter()
    },
    simple_company_channels() {
      this.handleChangeChannel(this.drawerFilters.filters.channel, true, false)
    },
  },
  computed: {
    ...mapGetters([
      'contacts_page',
      'fetchedFromOtherModule',
      'profile',
      'crm_max_months_to_get',
      'company',
    ]),
    /**
     * Nombre del boton de filtros
     * @returns {Boolean}
     */
    buttonShowFilter() {
      return this.drawerFilters.visible ? 'Ocultar filtros' : 'Más filtros'
    },
  },
  methods: {
    ...mapActions(['fetchContacs', 'exportContacts']),
    ...mapMutations(['SET_CONTACTS_PAGE']),
    onSearch() {
      this.endWaitTyping(() => {
        this.onChange()
      })
    },
    /**
     * Escucha los eventos de cambio de valor de los filtros
     */
    onChange() {
      this.SET_CONTACTS_PAGE(1)
      this.filter()
    },
    /**
     * Obtiene los contactos segun los filtros
     */
    filter() {
      if (!this.fetchedFromOtherModule) {
        this.drawerFilters.filters.page = this.contacts_page
        this.fetchContacs(this.drawerFilters.filters)
      }
      if (this.drawerFilters.filters.dateRange)
        this.$emit('onChangeRange', this.drawerFilters.filters.dateRange)
    },
    /**
     * Deshabilita los dias a partir de mañana
     */
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    /**
     * Exporta los contactos segun los filtros
     */
    handleExportContacts(dateRange) {
      this.exportContacts({ ...this.drawerFilters.filters, dateRange })
    },
    /**
     * Mostrar el drawer de filtros
     */
    handleShowFilters() {
      this.drawerFilters.visible = !this.drawerFilters.visible
    },
    /**
     * Setear valores a los filtros
     * @param {Object} filters
     */
    handleSetFilters(filters) {
      for (let property in this.drawerFilters.filters) {
        this.drawerFilters.filters[property] = filters[property]
      }
      this.handleChangeChannel(filters.channel, false)
    },
    /**
     * Cargar la tabla de productos
     * @param {Boolean} loading
     */
    handleLoadtable(loading) {
      this.$emit('onLoading', loading)
    },
    /**
     * Escucha el cambio de canales
     * @param {String} channel
     */
    handleChangeChannel(channel, setDefault = true, getData = true) {
      const simpleChannels = this.filterSimpleByChannel({
        channel,
        defaultChannel: {
          _id: 'all',
          title: 'Todos los IDs',
        },
      })
      if (setDefault)
        this.drawerFilters.filters.channelCompanyId = simpleChannels[0]._id
      this.simpleChannelsFiltered = simpleChannels
      if (getData) this.onChange()
    },
  },
}
</script>

<style lang="sass" scoped>
.filter-contact
  z-index: 1
  display: flex
  justify-content: space-between
  &-content
    flex-grow: 1
.filters__item
  max-width: 20em
  min-width: 14em
  margin-right: 12px
</style>
