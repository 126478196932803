import { promisify } from '../utils/promises'

export default {
  data: () => ({
    waitTimeout: null, // setTimeout
  }),
  methods: {
    /**
     * Intenta ejecutar una función en un intervalo de tiempo cada vez que una función cae en throw o hasta que lo intente en un periodo de tiempo
     * @param {Function} callback Función a ejecutarse
     * @param {Number} ms Tiempo límite (en milisegundos) para realizar los intentos
     */
    intervalAttempt(callback, ms = 12000) {
      const delay_interval = 500

      const attempts = setInterval(async () => {
        try {
          await callback()
          clearInterval(attempts)
        } catch (error) {
          // console.log("attempting for...", error)
        }
      }, delay_interval)
      setTimeout(() => {
        clearInterval(attempts)
      }, ms)
    },
    /**
     * Intenta ejecutar una función convertida en promesa en un intervalo de tiempo
     * cada vez que cae en throw o en un determinado tiempo
     * @param {Function} callback Función a ejecutarse
     * @param {Number} ms Tiempo límite (en milisegundos) para realizar los intentos
     */
    intervalPromiseAttempt(callback, ms = 12000, print = false) {
      const delay_interval = 500
      const promises = [] // array de promesas
      const attempts = setInterval(async () => {
        try {
          const promise = promisify(callback)
          // adjunta la promesa a una cola
          if (print) console.log('promises', promises)
          promises.push(promise)
          // si alguna de las promesas es resuelta, se limpiará el interval
          await Promise.any(promises)
          clearInterval(attempts)
        } catch (error) {
          if (print) console.error('attempting for...', error)
        }
      }, delay_interval)
      setTimeout(() => {
        clearInterval(attempts)
      }, ms)
    },
    /**
     * Es un Interval  que funciona como un "do-while" porque primero ejecuta la función y luego repite
     * @param {Function} callback Función a ejecutar
     * @param {Number} ms Tiempo del interval en ms
     */
    setDoInterval(callback, ms = 10) {
      callback()
      return setInterval(callback, ms)
    },
    /**
     * Ejecuta una funcion en cuanto se haya terminado de escribir
     * @param {Function}  callback Función a ejecutar tras terminar de escribir
     * @param {Number}    delay tiempo de retraso - opcional
     */
    endWaitTyping(callback, delay = 1000) {
      if (this.waitTimeout) clearTimeout(this.waitTimeout)

      this.waitTimeout = setTimeout(async () => {
        await callback()
      }, delay)
    },
  },
}
