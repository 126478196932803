<template>
  <box-template>
    <div
      v-if="isLoading"
      class="
        container-loader
        display-flex
        justify-content--center
        align--center
      "
    >
      <lottie-animation
        path="img/lottie/preloader.json"
        :width="120"
        :height="140"
      />
    </div>
    <section-webchat v-if="nameChannel === 'webchat'" v-show="!isLoading" />
    <section-whatsapp
      v-if="!isLoading && nameChannel === 'whatsapp' && channel"
      :dataChannel="current_channel"
    />
  </box-template>
</template>

<script>
import SectionWhatsapp from '@/app/channels/components/whatsapp/SectionWhatsApp'
import SectionWebchat from '@/app/channels/components/webchat/SectionWebchat'
import BoxTemplate from '@/app/shared/components/templates/BoxTemplate'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs
import attemptMixin from '@/app/shared/mixins/attempt'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ChannelTemplate',
  components: {
    SectionWhatsapp,
    SectionWebchat,
    BoxTemplate,
    LottieAnimation,
  },
  data: () => ({
    nameChannel: null,
    channel: null,
    isLoading: true,
  }),
  created() {
    this.intervalAttempt(async () => {
      this.isLoading = true
      if (!this.company) throw 'no company'
      this.nameChannel = this.$route.params.channel
      if (this.nameChannel !== 'whatsapp' && this.nameChannel !== 'webchat')
        return this.$router.push({ name: 'error' })
      this.loadInitialData()
    })
  },
  mixins: [attemptMixin],
  watch: {
    $route() {
      // observa si la ruta ha cambiado
      this.loadInitialData()
    },
  },
  computed: {
    ...mapGetters(['company', 'current_channel']),
  },
  methods: {
    ...mapActions(['getCompanyChannel']),

    /**
     * Carga data inicial para los canales que tiene channel Id
     */
    async loadInitialData() {
      const nameChannel = this.$route.params.channel
      this.isLoading = true
      // si en el parametro esta incluido create
      if (this.$route.query.create) {
        this.isLoading = false
        switch (nameChannel) {
          case 'webchat':
            return (this.channel = {})
          default:
            return this.$router.push({ name: 'error' })
        }
      } else if (this.$route.query.channelId && nameChannel) {
        // si tiene el parametro channelId y name channel
        const response = await this.getCompanyChannel(
          this.$route.query.channelId
        )
        if (response.success) {
          this.channel = response.data
          setTimeout(() => {
            this.isLoading = false
          }, 3000)
        } else return this.$router.push({ name: 'error' })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$border-width: 1px

.channel-template
  height: 100%
.container-loader
  height: calc( (100vh - #{$padding-heigth-inner * 2}) - #{$border-width*2})
</style>
