<template>
  <div class="filter-menu-billing-container">
    <a-row class="filter-menu-billing-content">
      <!--Filtros-->
      <a-col
        :md="12"
        :lg="16"
        :xl="showButtons ? 14 : 22"
        :xxl="showButtons ? 14 : 22"
      >
        <a-row class="filter__inputs" :gutter="[12, 12]">
          <!-- ID del canal -->
          <a-col v-if="type == 'spending'" :span="showButtons ? 10 : 8">
            <a-popover
              placement="bottomLeft"
              trigger="click"
              :overlayStyle="popStyles"
            >
              <template slot="content">
                <h6 class="semibold-14">Escoge los IDs a mostrar</h6>
                <a-divider class="mrg-0" />
                <a-row
                  type="flex"
                  justify="space-between"
                  class="mrg-top-4 mrg-bottom-8"
                >
                  <p class="mrg-bottom-0">IDs seleccionados:</p>
                  <span
                    >{{ channelsChecked.length }}/{{
                      simpleChannelsFiltered.length
                    }}
                  </span>
                </a-row>
                <div class="columns-filter scrollbar_basic">
                  <p
                    v-for="channel in simpleChannelsFiltered"
                    :key="channel._id"
                    class="mrg-bottom-0"
                  >
                    <a-checkbox v-model="channel.checked" @change="onChange">
                      {{ channel.alias || channel.title }}
                    </a-checkbox>
                  </p>
                </div>
              </template>
              <a-button class="full-width">
                {{ textButtonChannels }}
                <a-icon type="down" />
              </a-button>
            </a-popover>
          </a-col>
          <a-col v-else :span="showButtons ? 8 : 6">
            <a-input-search
              v-model="search"
              placeholder="Buscar por numero de pago"
              @search="onChange"
              class="full-width"
            />
          </a-col>
          <!-- Rango de fechas -->
          <a-col
            :md="12"
            :lg="showButtons ? 10 : 8"
            :xl="showButtons ? 10 : 8"
            :xxl="8"
          >
            <a-range-picker
              :disabled-date="disabledDate"
              :show-time="{
                format: localePicker.lang.dateTimeFormat,
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss'),
                ],
              }"
              :placeholder="['Fecha de inicio', 'Fecha de fin']"
              :format="localePicker.lang.dateTimeFormat"
              :locale="localePicker"
              @change="onChange"
              @ok="onOk"
              style="width: 100%"
              :popupStyle="stylesPopup"
              v-model="seletedDate"
              :allowClear="false"
            />
          </a-col>
          <!-- Estado de Recarga -->
          <a-col v-if="type != 'spending'" :span="8">
            <a-select
              v-model="statusSelected"
              default-value="allChannels"
              @change="onChange"
              class="full-width"
            >
              <a-select-option value="all"> Todos los estados </a-select-option>
              <a-select-option
                v-for="status in payments_statuses"
                :key="status.key"
              >
                {{ status.value }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>

      <!-- Boton de Actualizar datos -->
      <a-col
        v-if="showButtons"
        class="filters__buttons"
        :md="12"
        :lg="8"
        :xl="10"
        :xxl="10"
      >
        <a-row
          type="flex"
          align="bottom"
          :gutter="[12, 12]"
          class="filters__buttons__container"
        >
          <a-col v-if="type == 'spending'">
            <a-button
              class="btn-dashed"
              type="dashed"
              @click="onChange"
              :disabled="loading"
            >
              <a-icon type="sync" :spin="loading" />Traer datos recientes
            </a-button>
          </a-col>
          <!-- Botón de descargas -->
          <a-col v-if="showDownload">
            <a-button
              type="primary"
              class="blue-5"
              icon="download"
              @click="handleDownload"
            >
              Descargar gastos
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'

export default {
  name: 'FilterMenuSpendings',
  mixins: [formatMixin, supportMixin, attemptMixin],
  props: {
    type: {
      type: String,
      default: 'spending',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    moment,
    search: '',
    statusSelected: 'all',
    seletedDate: [
      moment(`${moment().subtract(7, 'd').format('L')}`, 'D/M/YYYY HH:mm'),
      moment(`${moment().format('L')} 23:59:59`, 'D/M/YYYY HH:mm'),
    ],
    stylesPopup: {
      zIndex: 1,
    },
    subType: 'all',
    simpleChannelSelected: 'all',
    simpleChannelsFiltered: [],
    popStyles: {
      width: '240px',
    },
    channelsFilter: [],
  }),
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'no company'
      this.onChange()
      this.getFilterApiBussineesId()
    })
    window.moment = this.moment
  },
  computed: {
    ...mapGetters(['payments_pagination', 'payments_statuses', 'company']),

    /**
     * @return {Boolean} muestra los botones
     */
    showButtons() {
      return this.type === 'spending' || this.showDownload
    },
    /**
     * @return {Number} total de canales contactados
     */
    channelsChecked() {
      const items = this.simpleChannelsFiltered.filter(
        (channel) => channel.checked
      )
      return items
    },
    /**
     * @return {String} texto del tooltip
     */
    textButtonChannels() {
      const isEqual =
        this.channelsChecked.length === this.simpleChannelsFiltered.length
      if (isEqual) return 'Todos los IDs de WhatsApp'
      else return `${this.channelsChecked.length} IDs seleccionados`
    },
  },
  methods: {
    onSearch() {
      this.endWaitTyping(() => {
        this.onChange()
      })
    },
    onChange() {
      this.filterPayments()
    },
    onOk() {},
    handleDownload() {
      this.$message.success('Se descargó exitosamente')
    },
    /**
     * @param {Date} current
     * @return {Boolean}
     */
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    filterPayments() {
      // crear una fecha cualquiera en utc 0
      // setearle a esa fecha la fecha que eligio el usuario
      const startDate = moment.utc().set({
        year: moment(this.seletedDate[0]).get('year'),
        month: moment(this.seletedDate[0]).get('month'),
        date: moment(this.seletedDate[0]).get('date'),
      })
      const endDate = moment.utc().set({
        year: moment(this.seletedDate[1]).get('year'),
        month: moment(this.seletedDate[1]).get('month'),
        date: moment(this.seletedDate[1]).get('date'),
      })
      const channelsCompaniesChecked = this.simpleChannelsFiltered.filter(
        (item) => item.checked
      )
      const channelsCompaniesIds = channelsCompaniesChecked.map(
        (item) => item._id
      )
      this.$emit('search', {
        page: 1,
        subType: this.subType,
        search: this.search,
        startDate: moment(startDate).set({
          hour: '00',
          minute: '00',
          second: '00',
        }),
        endDate: moment(endDate).set({ hour: 23, minute: 59, second: 59 }),
        status: this.statusSelected,
        channelsCompaniesIds: channelsCompaniesIds.join(','),
      })
    },
    getFilterApiBussineesId() {
      //supportMixin
      const simpleChannels = this.filterSimpleByChannel({
        channel: 'whatsapp',
        request: true,
      })
      this.simpleChannelsFiltered = simpleChannels
      this.setCheckedForChannels()
    },
    /**
     * Setea los canales checkeados
     */
    setCheckedForChannels() {
      this.$nextTick(() => {
        this.simpleChannelsFiltered.forEach((item) => {
          this.$set(item, 'checked', true)
        })
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.filter-menu-billing
  &-container
    text-align: left
    margin-top: 21px
  &-content
    // display: flex
    // justify-content: space-between
    .blue-5
      background-color: $blue_5
.filters
  &__buttons__container
    margin-right: 0px!important
    justify-content: flex-end
</style>
