<template>
  <div
    class="card-chatbot"
    :class="{
      'card-chatbot-active': active,
      'card-chatbot-inactive': !active,
    }"
  >
    <a-skeleton
      v-if="loading"
      :loading="loading"
      active
      :paragraph="{ rows: 2 }"
    >
    </a-skeleton>
    <template v-else>
      <a-row
        type="flex"
        justify="space-between"
        style="margin-bottom: 4px; display: flex; align-items: center"
      >
        <!--Canales-->
        <div class="card__channels display-flex">
          <span
            v-for="channel in channelsAssignedToChatbot"
            :key="channel.channel"
            class="mrg-right-12"
          >
            <popover-channel
              :channel="channel.channel"
              :list-titles="channel.titles"
            />
          </span>
        </div>
        <!--Switch de estado-->
        <a-tooltip placement="bottom">
          <template slot="title">
            {{ active ? 'Inactivar' : 'Activar' }}
          </template>
          <a-switch
            :checked="active"
            :loading="loadingSwitch"
            @change="handleChangeStatus"
            size="small"
          />
        </a-tooltip>
      </a-row>
      <!-- Nombre de chatbot -->
      <h6 class="heading-h7 name ellipsis">
        {{ name }}
      </h6>
      <!-- Descripción -->
      <p class="body-1 description ellipsis">
        {{ description }}
      </p>
      <a-row class="row" :gutter="[0, 4]">
        <a-col :span="24" class="row__col_flex">
          <ul>
            <li class="body-1 mrg-bottom-0 ellipsis">
              <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="{ 'icon-inactive-path': !active }" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 .583a6.417 6.417 0 110 12.834A6.417 6.417 0 017 .583zm5.204 7.115c-.618.322-1.433.59-2.358.776-.131 1.343-.442 2.528-.894 3.402a5.256 5.256 0 003.252-4.178zM8.742 7.47c.005-.154.008-.31.008-.469 0-2.926-.93-5.25-1.75-5.25S5.25 4.074 5.25 7c0 .168.003.333.009.496.547.057 1.132.087 1.741.087.6 0 1.188-.042 1.742-.114zm1.172-.204c.002-.088.003-.176.003-.265 0-1.941-.354-3.694-.965-4.875a5.256 5.256 0 013.256 4.21c-.274.308-1.14.674-2.294.93zM5.354 8.68c.524.047 1.075.071 1.646.071.562 0 1.116-.032 1.648-.09-.263 2.098-.99 3.59-1.648 3.59-.655 0-1.38-1.483-1.646-3.571zM4.087 7.32a8.998 8.998 0 01-.932-.235c-.724-.227-1.197-.506-1.367-.723a5.255 5.255 0 013.26-4.238C4.438 3.306 4.083 5.059 4.083 7c0 .108.001.215.004.321zm.072 1.202c.134 1.323.443 2.49.89 3.353a5.257 5.257 0 01-3.239-4.08c.609.318 1.412.564 2.349.727z" fill="#595959"/></svg>
              <span> Zona horaria: ({{ utc }}) {{ timezoneSelected }} </span>
            </li>
            <li class="body-1 mrg-bottom-0 ellipsis">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="{ 'icon-inactive-path': !active }" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 13.417A6.417 6.417 0 117 .584a6.417 6.417 0 010 12.833zm0-1.167a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5zm2.917-5.833H7.583V3.5H6.417v4.083h3.5V6.418z" fill="#51555A"/></svg>
              <span>
                Hora actual de la zona horaria: {{ currentSelectedTime }}
              </span>
            </li>
          </ul>
        </a-col>
      </a-row>
      <a-row>
        <a @click="openDetails">Ver detalles de horario</a>
      </a-row>
      <a-row type="flex" justify="space-between" class="row-footer">
        <div>
          <!--Estado de chatbot-->
          <a-tag :color="status.color">
            {{ status.status }}
          </a-tag>
          <a-tooltip v-if="tooltipWarning" placement="right">
            <template slot="title">
              Actualmente, este chatbot no está atendiendo por el canal de
              WhatsApp porque no cuentas con saldo disponible.
            </template>
            <a-tag color="red"> Sin saldo </a-tag>
          </a-tooltip>
          <!--Si el chatbot es una copia-->
          <a-tag v-if="isCopy" color="orange"> Duplicado </a-tag>
        </div>
        <!--Acciones-->
        <div class="actions">
          <!-- Eliminar -->
          <a-tooltip>
            <template slot="title">
              <span v-if="active"
                >Solo puedes eliminar un chatbot si está desactivado.</span
              >
              <span v-else>Elimina tu chatbot</span>
            </template>
            <a
              :disabled="active"
              @click="!active && confirmDeleteChatbot()"
              class="mrg-right-12"
            >
              <a-icon
                :class="{ 'card__actions-icon--delete': !active }"
                type="delete"
                class="card__actions-icon"
              />
            </a>
          </a-tooltip>
          <!-- Editar -->
          <a-tooltip>
            <template slot="title">
              <span v-if="active"
                >Solo puedes editar un chatbot si está desactivado.</span
              >
              <span v-else>Edita tu chatbot</span>
            </template>
            <a
              :disabled="active"
              @click="!active && handleEdit(id)"
              class="mrg-right-12"
            >
              <a-icon type="edit" class="card__actions-icon" />
            </a>
          </a-tooltip>
          <!-- Previsualizar -->
          <a-tooltip>
            <template slot="title">
              <span>Previsualiza el flujo de tu chatbot</span>
            </template>
            <a @click="$emit('onPreview', id, name)" class="mrg-right-12">
              <a-icon type="eye" class="card__actions-icon" />
            </a>
          </a-tooltip>
          <!-- Duplicar -->
          <a-tooltip>
            <template slot="title">
              <span>Duplica tu chatbot</span>
            </template>
            <a @click="handleShowConfirmDuplicate(id, name)">
              <a-icon type="copy" class="card__actions-icon" />
            </a>
          </a-tooltip>
        </div>
      </a-row>
    </template>
    <!--Modal de inactivar-->
    <modal-actions
      :visible="modalInactive.visible"
      :title="modalTitle"
      :item-source="{ _id: id, channels_companies: assignedChannels, active }"
      :data-source-selects="lines"
      :subtitle="modalSubtitle"
      :keyWord="modalInactive.keyWord"
      :onOk="onOkModalActions"
      :show-action-finish="true"
      name-button="Inactivar chatbot"
      :showKeyWord="false"
      @onClose="handleCloseInactive"
    >
      <div slot="description" class="mrg-bottom-0">
        <p>Actualmente este chatbot tiene:</p>
        <p></p>
        <ul>
          <!--si hay tickets mayores de 24hras -->
          <li v-if="!!totalTicketsOfChatbots.ticketsMoreThan24Hrs">
            <strong>
              {{ totalTicketsOfChatbots.ticketsMoreThan24Hrs }}
              {{
                totalTicketsOfChatbots.ticketsMoreThan24Hrs > 1
                  ? 'tickets'
                  : 'ticket'
              }}
            </strong>
            sin respuesta en las últimas 24 horas que
            {{
              totalTicketsOfChatbots.ticketsMoreThan24Hrs > 1
                ? 'serán finalizados'
                : 'será finalizado'
            }}.
          </li>
          <li v-if="!!totalTicketsOfChatbots.ticketsLessThan24Hrs">
            <strong>
              {{ totalTicketsOfChatbots.ticketsLessThan24Hrs }}
              {{
                totalTicketsOfChatbots.ticketsLessThan24Hrs > 1
                  ? 'tickets activos'
                  : 'ticket activo'
              }}
            </strong>
            que aún se
            {{
              totalTicketsOfChatbots.ticketsLessThan24Hrs > 1
                ? 'pueden'
                : 'puede'
            }}
            responder.
          </li>
        </ul>
        Para inactivar el chatbot es necesario que decidas que hacer con
        {{
          totalTicketsOfChatbots.ticketsLessThan24Hrs > 1
            ? 'los tickets activos'
            : 'con el ticket activo'
        }}.
      </div>
    </modal-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import transformMixin from '@/app/shared/mixins/transform'
import moment from 'moment'
import localTimeMixin from '@/app/shared/mixins/localTime'
import supportMixin from '@/app/shared/mixins/support'
import { DATA_TEST } from '@/app/shared/utils/dataTest'
import ModalActions from '@/app/shared/components/modals/ModalActions'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'

export default {
  name: 'CardChatbot',
  props: {
    id: {
      type: [String, Number],
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
    assignedChannels: {
      type: Array,
      required: false,
    },
    timezone: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    isCopy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ModalActions,
    PopoverChannel,
  },
  data: () => ({
    DATA_TEST,
    moment,
    disabled: true,
    loadingSwitch: false,
    currentSelectedTime: null,
    timezoneSelected: null,
    utc: null,
    modalInactive: {
      visible: false,
      keyWord: 'Inactivar',
    },
    totalTicketsOfChatbots: {
      ticketsLessThan24Hrs: 0,
      ticketsMoreThan24Hrs: 0,
    },
  }),
  async created() {
    let { currentSelectedTime, timezone, utc } = await this.recalcRelativeTime(
      this.timezone,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    this.utc = utc
    this.currentSelectedTime = currentSelectedTime?.format('HH:mm a')
    this.timezoneSelected = timezone
  },
  mixins: [transformMixin, localTimeMixin, supportMixin],
  computed: {
    ...mapGetters(['profile', 'company', 'lines', 'simple_company_channels']),
    /**
     * Estado del chatbot, retorna un objeto con el color del tag y el estado
     * @returns {Object} {color,status}
     */
    status() {
      if (this.active) return { color: 'green', status: 'Activo' }
      else return { color: '', status: 'Inactivo' }
    },
    /**
     * Muestra el tooltip de advertencia cuando el saldo del wallet queda vacío
     * @return {Boolean}
     */
    tooltipWarning() {
      const hasWsp = this.assignedChannels.some((item) => item === 'whatsapp')
      if (hasWsp && !this.company.channels?.whatsapp?.request) return false
      return hasWsp && this.company.wallet.total <= 0
    },
    /**
     * @return {String} la palabra "segura" según el género
     */
    wordSure() {
      if (!this.profile) return 'segur@'
      return this.transformWordGender('seguro', this.profile.gender)
    },
    /**
     * @return {String} titulo del modal
     */
    modalTitle() {
      return `¿Estás ${this.wordSure} de inactivar el chatbot "${this.name}"?`
    },
    /**
     * @return {String} subtitulo del modal
     */
    modalSubtitle() {
      if (
        this.totalTicketsOfChatbots.ticketsLessThan24Hrs &&
        !this.totalTicketsOfChatbots.ticketsMoreThan24Hrs
      )
        return 'Acciones para los tickets de este chatbot'
      else return 'Acciones para los tickets que aun se pueden responder'
    },
    /**
     * Canales asignado a los chatbots
     * @return {Object}
     */
    channelsAssignedToChatbot() {
      const channels = [...this.assignedChannels].reduce((acc, current) => {
        const isIncludeInAcc = acc[current.channel]
        const alias = this.channelCompanyAlias(current._id)
        if (!isIncludeInAcc)
          acc[current.channel] = {
            _id: current.channel, // id del canal global
            channel: current.channel,
            titles: [alias ? `${current.title} ( ${alias} )` : current.title], // ids de los canales conectados que tienen este canal
            name: this.transformWord(current.channel, 'capitalize'),
          }
        else
          acc[current.channel].titles.push(
            alias ? `${current.title} ( ${alias} )` : current.title
          )
        return acc
      }, {})
      return channels
    },
  },
  methods: {
    ...mapActions([
      'saveSelectChatbot',
      'activeChatbot',
      'deleteChatbot',
      'getTotalTicketsOfChatbot',
      'duplicateChatbot',
    ]),
    /**
     * Escucha el cambio de estado de la card
     * @param {Boolean} checked
     */
    async handleChangeStatus(checked) {
      this.loadingSwitch = true
      let showConfirmation = checked
      let action = 'transfer' // transferencia a la cola general
      let ticketsMoreThan24Hrs = null
      let ticketsLessThan24Hrs = null
      if (!showConfirmation) {
        // si se inactiva obtendra los tickets activos
        const responseTotalTickets = await this.getTotalTicketsOfChatbot(
          this.id
        )
        if (!responseTotalTickets.success)
          return this.$message.error('Ocurrio un error')
        this.totalTicketsOfChatbots = responseTotalTickets.data
        ticketsMoreThan24Hrs = this.totalTicketsOfChatbots.ticketsMoreThan24Hrs
        ticketsLessThan24Hrs = this.totalTicketsOfChatbots.ticketsLessThan24Hrs
        showConfirmation =
          (!ticketsMoreThan24Hrs && !ticketsLessThan24Hrs) ||
          (ticketsMoreThan24Hrs && !ticketsLessThan24Hrs)
        // si el chatbot solo tiene mensajes mayores a 24 hras
        if (ticketsMoreThan24Hrs) action = 'finish'
      }
      // si tiene tickets se mostrara el modal de accion
      if (!showConfirmation) return (this.modalInactive.visible = true)
      const message = checked
        ? 'Recuerda que los tickets nuevos, primero serán atendidos por el chatbot y luego derivados a tus agentes.'
        : action === 'finish'
        ? `Actualmente este chatbot tiene ${ticketsMoreThan24Hrs} ${
            ticketsMoreThan24Hrs > 1 ? 'tickets' : 'ticket'
          } sin respuesta en las ultimas 24 horas que ${
            ticketsMoreThan24Hrs > 1 ? 'serán finalizados' : 'será finalizado'
          }.`
        : 'Recuerda que todos los tickets activos de este chatbot se transferirán a la cola general.'
      this.showConfirm(
        `¿Estás ${this.transformWordGender('seguro', this.profile.gender)} de ${
          !checked ? 'inactivar' : 'activar'
        } "${this.name}"?`,
        message,
        `${!checked ? 'Inactivar' : 'Activar'}`,
        () => this.emitLoadData({ active: checked, id: this.id }),
        () => this.handleCancelChange()
      )
    },
    /**Emite una funcion para cargar la data de chatbots
     * @param {Object} args
     * @param {Boolean} args.active
     * @param {String} args.id
     * @param {Object} args.action
     * @param {String} args.action.type default, specific, finish
     * @param {Object} args.action.channels
     */
    async emitLoadData({ active, id, action, comment }) {
      this.loadingSwitch = true
      let actionToActive = null
      if (action && action.type)
        actionToActive =
          action.type === 'default' || action.type === 'specific'
            ? 'transfer'
            : action.type
      const response = await this.activeChatbot({
        active,
        chatbotId: id,
        action: actionToActive,
        actionsTransferLine: {
          action: actionToActive === 'finish' ? null : action?.type,
          channels: actionToActive === 'finish' ? null : action?.channels,
        },
        commentFinishTicket: comment,
      })

      this.loadingSwitch = false
      if (response.success) {
        const message = active
          ? `El chatbot  ${this.name} fue activada exitosamente`
          : `El chatbot  ${this.name} fue inactivada exitosamente`

        this.$message.success(message)
        this.$emit('loadData', !active)
      } else {
        switch (response.result) {
          case 'welcome active':
            this.$message.error(
              'El mensaje de bienvenida debe estar inactivo para usar un chatbot'
            )
            break
          case 'not channels':
            this.$message.error('El chatbot no tiene canales asignados')
            break
          case 'not nodes':
            this.$message.error('El chatbot no tiene acciones')
            break

          default:
            this.$message.error(
              response.details ? response.details : 'Ocurrió un error 😓'
            )
            break
        }
      }
    },
    /**Cancela el cambio de estado */
    handleCancelChange() {
      this.loadingSwitch = false
    },
    /**
     * Muestra la confirmacion para eliminar el chatbot
     */
    confirmDeleteChatbot() {
      this.showConfirm(
        `¿Estás ${this.wordSure} de eliminar el chatbot "${this.name}"`,
        'Recuerda que los tickets activos en este momento con este chatbot se transferirán a la cola general.',
        'Eliminar',
        async () => {
          const result = await this.deleteChatbot(this.id)
          if (result.success) {
            this.$message.success('Se eliminó exitosamente')
            this.$emit('loadData')
          } else this.$message.error('Ocurrió un error al eliminar el chatbot')
        },
        () => this.destroyAll
      )
    },
    /**
     * Modal de confirmacion
     * @param {String} title
     * @param {String} content
     * @param {String} okText
     * @param {String} cancelText
     * @param {Function} onOkey
     * @param {Function} onCancel
     */
    showConfirm(title, content, okText, fnOk, fnCancel) {
      this.$confirm({
        title: title,
        content: content,
        okText: okText,
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          await fnOk()
        },
        onCancel() {
          fnCancel()
        },
      })
    },
    handleEdit(id) {
      this.$router.push({ name: 'id', params: { id: id } })
    },
    /**
     * Funcion para cuando el modal de eliminar se cierra
     */
    destroyAll() {
      this.$destroyAll()
    },
    /**
     * CLick en ver más detalles
     */
    openDetails() {
      this.$emit('onSeeSchedule')
    },
    /**
     * Escucha cuando el modal de inactivo se cierra
     */
    handleCloseInactive() {
      this.modalInactive.visible = false
      this.loadingSwitch = false
    },
    /**
     * Funcion de ok en el modal de acciones
     * @param {Object} args
     * @param {Boolean} args.active
     * @param {String} args.id
     * @param {Object} args.action
     * @param {String} args.action.type transfer, finish
     * @param {Object} args.action.channels
     */
    onOkModalActions({ active, id, action }) {
      this.emitLoadData({ active, id, action, comment: action.comment })
    },
    /**
     * Muestra la confirmacion para duplicar el chatbot
     * @param {String} chatbotId
     * @param {String} chatbotName
     */
    handleShowConfirmDuplicate(chatbotId, chatbotName) {
      const dataForConfirm = {
        title: `¿Estás segur@ de duplicar el chatbot "${chatbotName}"?`,
        content:
          'Recuerda que se duplicará toda la configuración y el flujo de este chatbot, se recomienda revisar antes de activarlo',
        okText: 'Duplicar',
      }
      this.showConfirm(
        dataForConfirm.title,
        dataForConfirm.content,
        dataForConfirm.okText,
        async () => {
          const response = await this.duplicateChatbot(chatbotId)
          if (response.success) this.$message.success('Se duplicó exitosamente')
          else
            this.$message.error(
              response.details ? response.details : 'Ocurrió un error 😥'
            )
        },
        () => this.destroyAll
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.card-chatbot
  background-color: $white_000
  padding: 12px 12px 12px
  border-radius: 8px
  text-align: left
  width: 100%
  border: 1px solid $gray_dark_100
  height: 210px
  position: relative
  &:hover
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
    border-radius: 8px
  .name
    margin: 8px 0 0 0
  .description
    margin-bottom: 4px
    line-height: 1rem
    -webkit-line-clamp: 2 !important
  .row
    &__col_flex
      display: flex
      align-items: center
      p
        color: $gray_dark_700
        margin-left: 8px
    ul, li
      margin: 0
      padding: 0
      list-style: none
    ul
      margin: 4px 0
      li
        display: flex
        align-items: center
        span
          margin: 0 0 0 4px
  .row-footer
    border-top: 1px solid $gray_6
    padding: 8px 0 0 0
    position: absolute
    width: -webkit-fill-available
    bottom: 0
    left: 0
    margin: 12px
.card-chatbot-active
  .name
    color: $gray_dark_900
  .description
    color: $gray_dark_800
.card-chatbot-inactive
  .name
    color: $gray_dark_400
  .description, ul
    color: $gray_dark_300
  .row
    &__col_flex
      p
        color: $gray_dark_300
.card__actions-icon
  &--delete
    color: $red_6
.card__channel
  margin-right: 8px
</style>

<style lang="sass">
svg.icon-inactive-path
  path
    fill: $gray_dark_400
</style>
