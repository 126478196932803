<template>
  <div class="dashboard-template scrollbar_basic template">
    <div class="header-dashboard width-no-collapsed">
      <header-module
        title="DASHBOARDS"
        tipography="heading-h5"
        class="template__header"
        :link="`${vari.WEB_DOC}caracteristicas/dashboards/`"
        :text-before-link="`Visualiza y descarga datos de tu gestión en ${app}`"
        text-after-link="si deseas conocer más."
        textLink="Click aquí"
        positionTooltip="right"
      />
      <div class="first-section" ref="firstSection">
        <a-select
          default-value="allLines"
          style="width: 312px; margin-right: 12px"
          :dropdownStyle="stylesDropdown"
          @change="handleLine"
        >
          <a-select-option v-for="line in allLines" :key="line._id">
            {{ line.name }}
          </a-select-option>
        </a-select>
        <a-range-picker
          :value="valuePicker"
          :disabled-date="disabledDate"
          :show-time="{
            format: localePicker.lang.dateTimeFormat,
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('23:59:59', 'HH:mm:ss'),
            ],
          }"
          :placeholder="['Fecha de inicio', 'Fecha de fin']"
          :format="localePicker.lang.dateTimeFormat"
          :locale="localePicker"
          @change="onChange"
          @ok="onOk"
          style="min-width: 312px"
          :popupStyle="stylesPopup"
          :allowClear="false"
        />
        <a-button
          class="btn-dashed"
          type="dashed"
          @click="handleGetData"
          :disabled="disabledLoad"
        >
          <a-icon type="sync" :spin="spin" />Traer datos recientes
        </a-button>
      </div>
    </div>
    <a-row :gutter="[12, 12]" class="contain-dashboards" style="margin: 0">
      <a-col
        v-for="component in components"
        :key="component.name"
        :span="component.span"
        :id="component.id"
      >
        <component
          :is="component.name"
          @handleAgentSchedule="handleAgentSchedule"
          @onChangeAgent="handleFilterTimeAverageStates"
          @onChangeState="handleFilterTimeAverageStates"
          :lineId="lineId"
          :date="valuePicker"
          :isLoading="disabledLoad"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import vari from '@/app/shared/utils/variables'
import SectionNewTickets from '@/app/dashboard/components/organisms/SectionNewTickets.vue'
import SectionStateTickets from '@/app/dashboard/components/organisms/SectionStateTickets.vue'
import SectionSMS from '@/app/dashboard/components/organisms/SectionSMS.vue'
import SectionTemplate from '@/app/dashboard/components/organisms/SectionTemplate.vue'
import SectionSummaryCalendar from '@/app/dashboard/components/organisms/SectionSummaryCalendar.vue'
import SectionTimeAnswer from '@/app/dashboard/components/organisms/SectionTimeAnswer.vue'
import SectionTimeState from '@/app/dashboard/components/organisms/SectionTimeState.vue'
import SectionTotalTags from '@/app/dashboard/components/organisms/SectionTotalTags.vue'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import formatMixin from '@/app/shared/mixins/format'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'

export default {
  name: 'DashboardTemplate',
  mixins: [formatMixin, attemptMixin],
  components: {
    SectionNewTickets,
    SectionStateTickets,
    SectionSMS,
    SectionTemplate,
    SectionSummaryCalendar,
    SectionTimeAnswer,
    SectionTimeState,
    SectionTotalTags,
    HeaderModule,
  },
  data: () => ({
    moment,
    vari,
    app: process.env.VUE_APP_NAME,
    seletedDate: [],
    valuePicker: [],
    timer: null,
    spin: false,
    stylesDropdown: {
      zIndex: 1052,
    },
    stylesPopup: {
      zIndex: 1052,
    },
    lineId: 'allLines',
    nameRange: 'hours',
    isNow: true, // Trae datos actuales?
    disabledLoad: true,
    agentIdSchedule: 'allAgents',
    agentIdAverage: 'allAgents',
    timeAverageStates: 'allStatus',
    keyMessage: 'message-loading',
  }),
  computed: {
    ...mapGetters([
      'lines',
      'totalTags',
      'profile',
      'modalRemoteLogout',
      'modalSessions',
      'timeAnswer',
      'menuCollapse',
      'company',
      'news',
      'dashboard_max_months_to_get',
    ]),
    allLines() {
      let arrLines = []
      if (this.lines) {
        arrLines = this.lines.slice()
        const allLines = {
          _id: 'allLines',
          name: 'Todas las colas',
          default: true,
        }
        arrLines.splice(0, 0, allLines)
        return arrLines
      } else return arrLines
    },
    spanTimeAnswer() {
      return this.timeAnswer.lines.length > 10 ? 24 : 12
    },
    spanTags() {
      return this.totalTags[0] &&
        (this.totalTags.length > 20 || this.totalTags[0].lines.length > 10)
        ? 24
        : 12
    },
    components() {
      const arr = [
        { id: 'dashboard-new-tickets', name: 'SectionNewTickets', span: 24 },
        {
          id: 'dashboard-state-tickets',
          name: 'SectionStateTickets',
          span: 24,
        },
        { id: 'dashboard-templates', name: 'SectionTemplate', span: 12 },
        { id: 'dashboard-sms', name: 'SectionSMS', span: 12 },

        {
          id: 'dashboard-tags',
          name:
            this.spanTags > this.spanTimeAnswer ||
            this.spanTags == this.spanTimeAnswer
              ? 'SectionTotalTags'
              : 'SectionTimeAnswer',
          span: this.spanTags === this.spanTimeAnswer ? this.spanTags : 24,
        },
        {
          id: 'dashboard-time-answers',
          name:
            this.spanTags > this.spanTimeAnswer ||
            this.spanTags == this.spanTimeAnswer
              ? 'SectionTimeAnswer'
              : 'SectionTotalTags',
          span: this.spanTags === this.spanTimeAnswer ? this.spanTags : 12,
        },
        { id: 'dashboard-calendar', name: 'SectionSummaryCalendar', span: 12 },
        { id: 'dashboard-time-agents', name: 'SectionTimeState', span: 12 },
      ]
      return arr
    },
  },
  created() {
    this.intervalAttempt(() => {
      this.$message.loading({
        key: this.keyMessage,
        content: 'Cargando dashboards. Por favor espere',
      })
      if (!this.company) throw 'No company'
      this.getAssignedLines()
      this.getAccountsUsers()
      this.handleGetData()
    })
  },
  methods: {
    ...mapActions([
      'graphTags',
      'graphSchedules',
      'graphNewTickets',
      'graphAverageStatuses',
      'graphAverageAnswer',
      'graphStatusesTickets',
      'graphSMS',
      'graphTemplate',
      'exportDashboard',
      'getAssignedLines',
      'getAccountsUsers',
    ]),
    ...mapMutations(['SET_INTRODUCTORY_MODAL']),

    onChange(value) {
      const maxMonths = this.dashboard_max_months_to_get // Limitación de máxima cantidad de meses para el filtro
      this.isNow = false
      const start = moment(value[0])
      const end = moment(value[1])
      const duration = moment.duration(end.diff(start))
      if (duration.asMonths() > 1.015 * maxMonths) {
        // Esto representa 31 días
        this.$message.error(
          `No puede seleccionar un rango mayor a ${maxMonths} meses`
        ) // mensaje de exitoso
        return
      }
      this.valuePicker = value
    },
    onOk(value) {
      /**calcula la diferencia de tiempos */
      const a = moment(value[1])
      const b = moment(value[0])
      const diffDays = a.diff(b, 'days') // diferencia de dias
      // const diffWeeks = a.diff(b, 'weeks') // diferencia de semanas
      const diffMonths = a.diff(b, 'months') // diferencia de meses
      const diffYears = a.diff(b, 'years') // diferencia de años
      if (diffDays < 1) this.nameRange = 'hours'
      // si solo hay 1 dia trae por horas
      else if (diffDays >= 1 && diffDays <= 62) this.nameRange = 'days'
      // solo si max es 31 dias
      // else if(diffWeeks>4 && diffWeeks<=20) this.nameRange = 'weeks' // solo si es mayor a 1 mes y es menor o igual a 5 meses
      else if (diffMonths >= 2 && diffYears <= 2) this.nameRange = 'months'
      // solo si es mayor a 5 meses y menor o igual a 2 años
      else this.nameRange = 'years' // para años
      this.loadGraph(value, this.isNow, this.lineId, this.nameRange)
    },
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    async handleGetData() {
      this.isNow = true
      this.nameRange = 'hours'
      this.valuePicker = [
        moment(`${moment().format('L')} 00:00`, 'D/M/YYYY HH:mm'),
        moment().endOf('day'),
      ]
      await this.loadGraph(
        this.valuePicker,
        this.isNow,
        this.lineId,
        this.nameRange
      )
    },
    /**
     * @param {Array[Date]} valuePicker Rango de fechas
     * @param {Boolean}     isNow los datos a traer son los más actualizados?
     * @param {String}      lineId id de la cola
     * @param {String}      nameRange nombre de rango [hours,days,months,years]
     */
    async loadGraph(valuePicker, isNow, lineId, nameRange) {
      if (this.spin) return
      try {
        this.spin = true // Inicia animacion carga
        this.disabledLoad = true
        this.$message.loading({
          key: this.keyMessage,
          content: 'Cargando dashboards. Por favor espere',
        })
        // Carga de nuevos tickets
        await this.graphNewTickets({
          rangeDate: valuePicker,
          isNow,
          lineId,
          nameRange,
        })
        // Carga de estados de tickets
        await this.graphStatusesTickets({
          rangeDate: valuePicker,
          isNow,
          lineId,
          nameRange,
        })
        // carga de sms
        await this.graphSMS({
          rangeDate: valuePicker,
          isNow,
          lineId,
          nameRange,
        })
        // carga el tiempo promedio de respuesta
        await this.graphAverageAnswer({
          rangeDate: valuePicker,
          isNow,
          lineId,
        })
        // carga las etiquetas
        await this.graphTags({ rangeDate: valuePicker, isNow, lineId })
        // carga las agendas
        await this.graphSchedules({
          rangeDate: valuePicker,
          isNow,
          lineId,
          agentId: this.agentIdSchedule,
        })
        // carga el promedio de estados
        await this.graphAverageStatuses({
          rangeDate: valuePicker,
          isNow,
          lineId,
          agentId: this.agentIdAverage,
          status: this.timeAverageStates,
        })
        // carga de plantillas
        await this.graphTemplate({
          rangeDate: valuePicker,
          isNow,
          lineId,
          nameRange,
        })
        setTimeout(() => {
          // Delay para esperar a que terminen de cargar los dashboards
          this.spin = false // Termina animacion carga
          this.disabledLoad = false
        }, 1000)
        this.$message.success({
          key: this.keyMessage,
          content: 'Dashboard actualizado',
        }) // mensaje de exitoso
      } catch (error) {
        this.spin = false // Termina animacion carga
        this.disabledLoad = false
        this.$message.error(error) // mensaje de error
        console.error('[loadGraph] error', error)
      }
    },
    handleLine(value) {
      this.lineId = value
      this.agentIdSchedule = 'allAgents'
      this.agentIdAverage = 'allAgents'
      this.loadGraph(this.valuePicker, this.isNow, value, this.nameRange)
    },
    /**
     * obtiene el id que viene desde el select de summary calendar
     * @param {String} userId Is de usuario del agente
     */
    handleAgentSchedule(userId) {
      this.agentIdSchedule = userId
      this.loadGraphSchedules(
        this.valuePicker,
        true,
        this.lineId,
        this.nameRange,
        userId
      )
    },
    // carga el grafico de resumen de calendario
    async loadGraphSchedules(valuePicker, isNow, lineId, nameRange, agentId) {
      await this.graphSchedules({
        rangeDate: valuePicker,
        isNow,
        lineId,
        nameRange,
        agentId,
      })
    },
    /**
     * carga el grafico de promedio de estatus
     * @param {Object[]} valuePicker
     * @param {Boolean} isNow
     * @param {String} lineId
     * @param {String} nameRange
     * @param {String} agentId
     * @param {String[]} statuses
     */
    async loadGraphAverageStatuses({
      valuePicker,
      isNow,
      lineId,
      nameRange,
      agentId,
      statuses,
    }) {
      await this.graphAverageStatuses({
        rangeDate: valuePicker,
        isNow,
        lineId,
        nameRange,
        agentId,
        statuses,
      })
    },
    handleOpenIntroducction() {
      const modal = {
        visible: true,
        description:
          'In voluptate elit excepteur sint adipisicing non aliqua nostrud.',
      }
      this.SET_INTRODUCTORY_MODAL(modal)
    },
    /**
     * Filtra el tiempo promedio de los estados de los usuarios
     * @param {String} agentId
     * @param {String[]} statuses array de keys de estados
     */
    handleFilterTimeAverageStates({ agentId, statuses }) {
      if (agentId) this.agentIdAverage = agentId
      this.loadGraphAverageStatuses({
        valuePicker: this.valuePicker,
        now: true,
        lineId: this.lineId,
        nameRange: this.nameRange,
        agentId,
        statuses: statuses,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.margin-top-12
  margin-top: 12px
.padding-right-6
  padding-right: 6px
.padding-left-6
  padding-left: 6px
.z-index-1051
  z-index: 1051
.z-index-1000
  z-index: 1000
.background-red
  background-color: red
.width-no-collapsed
  width: calc(100% - 58px)
  // transition: width 0.5s
.width-collapsed
  width: calc(100% - 224px)
  // transition: width 0.3s
.dashboard-template
  // height: 100vh
  // padding: 12px 4px 12px 12px
  .header-dashboard
    background-color: $background_000
    position: fixed
    padding: 20px 12px 12px
    box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.1)
    z-index: 1000
    h5
      text-align: left
      color: $gray_dark_900
    .first-section
      display: flex
      flex-direction: row
      width: 100%
      position: relative
      .btn-dashed
        // margin-right: 8px
        position: absolute
        right: 0
  .contain-dashboards
    padding: 92px 6px 6px 6px
    overflow: hidden
// .ant-modal-mask, .ant-modal-wrap
//   z-index: 1053!important
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
<style lang="sass">
.ant-message
  z-index: 1052!important
</style>
