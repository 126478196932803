<template>
  <div class="menu-footer">
    <a-menu
      class="menu-footer__content"
      mode="vertical"
      :class="{
        'menu-footer__content--collapse': collapse,
        'menu-footer__content--no-collapse': !collapse,
      }"
      @openChange="handleOpenChangePopup"
    >
      <a-sub-menu
        key="submenu-footer-first"
        class="menu-footer__submenu"
        placement="rightBottom"
        @titleClick="handleGoToProfile"
        :popupClassName="popupClassNameFooter"
        :disabled="disabled"
      >
        <menu-item-footer
          slot="title"
          :collapse="collapse"
          :title="names"
          :subtitle="email"
          :avatar="avatar"
        />
        <template v-if="showPopup">
          <a-menu-item
            key="1"
            :style="`height: ${
              profileStatus.action_when_select.includes('show-status-in-avatar')
                ? '348px'
                : '290px'
            } ; padding: 0; margin-top: 0`"
            class="menu-footer__contain-summary"
            disabled
          >
            <summary-account
              :names="names"
              :email="email"
              :username="username"
              :phone-number="phoneNumber"
              :avatar="avatar"
              :banner="banner"
              :type-user="typeUser"
              :company-name="companyName"
              :plan-name="planName"
              :timer="timerFormatted"
              @onReconnect="handleReconnect"
            />
          </a-menu-item>
          <a-menu-divider></a-menu-divider>
          <a-menu-item
            key="2"
            class="menu-footer__item"
            @click="handleGoToProfile"
          >
            Mi perfil
          </a-menu-item>
          <a-menu-divider></a-menu-divider>
          <a-sub-menu
            title="Cambiar mi estado a:"
            id="states"
            class="menu-footer__submenu--states"
            :popupClassName="popupClassNameStates"
            key="3"
          >
            <a-menu-item
              v-for="status in profile_states_for_list"
              :key="status._id"
              :id="status.state"
              class="fix-center"
              style="display: flex; flex-direction: row; align-items: center"
              @click="handleSetStatus(status.state, status.action_when_select)"
            >
              <a-badge
                :color="status.color"
                :text="status.title_for_footer_menu"
              />
            </a-menu-item>
          </a-sub-menu>
          <a-menu-divider></a-menu-divider>
          <template
            v-if="isAllowedFor(profile.type, ['admin', 'master_agent'])"
          >
            <a-menu-item
              key="extra-4"
              class="menu-footer__item"
              @click="handleGoToSpending"
            >
              Ver mi saldo 💰
            </a-menu-item>
            <a-menu-divider></a-menu-divider>
          </template>
          <template
            v-if="isAllowedFor(profile.type, ['admin', 'master_agent'])"
          >
            <a-menu-item
              key="5"
              class="menu-footer__item"
              style="height: max-content"
            >
              <div class="menu-footer__content-item" @click="handleGoToGuide">
                Proceso de mi guía de usuario
                <a-progress
                  :percent="percentage"
                  size="small"
                  class="menu-footer__progress"
                />
              </div>
            </a-menu-item>
            <a-menu-divider></a-menu-divider>
          </template>
          <!-- Se requiere ocultar este valor sobretodo para los RI -->
          <a-menu-item
            v-if="submenu_chat_atc"
            class="menu-footer__item"
            @click="handleGoToSupport"
          >
            Chatear con atención al cliente 👩🏻‍💻
          </a-menu-item>
          <a-menu-divider></a-menu-divider>
          <a-menu-item key="6" class="menu-footer__item" @click="logout">
            Cerrar mi sesión 👋
          </a-menu-item>
        </template>
      </a-sub-menu>
      <!--menu item para q no aparezca el menu inline-->
      <a-menu-item key="" style="height: 0px; margin: 0">
        <a-icon type="" />
        <span></span>
      </a-menu-item>
    </a-menu>
    <modal-state
      :type="modalState.type"
      :visible="modalState.visible"
      :timer="timerFormatted"
      @onReconnect="handleReconnect"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MenuItemFooter from '@/app/commons/components/molecules/MenuItemFooter'
import SummaryAccount from '@/app/commons/components/molecules/SummaryAccount'
import ModalState from '@/app/commons/components/organisms/ModalState'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import compareMixin from '@/app/shared/mixins/compare'
import moment from 'moment'

export default {
  name: 'MenuFooter',
  components: {
    MenuItemFooter,
    SummaryAccount,
    ModalState,
  },
  props: {
    names: { type: String, required: false, default: null },
    email: { type: String, required: false, default: null },
    companyName: { type: String, required: false, default: null },
    username: { type: String, required: false, default: null },
    phoneNumber: { type: String, required: false, default: null },
    avatar: { type: String, required: false },
    banner: { type: String, required: false },
    collapse: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, default: false },
    showPopup: { type: Boolean, default: false },
    typeUser: { type: String, required: false, default: null },
    planName: { type: String, required: false, default: null },
  },
  data: () => ({
    idStatus: null,
    timerSeconds: 0,
    timer: null, // Funcion del timer
    modalState: {
      type: null,
      visible: false,
      timer: null,
    },
  }),
  mixins: [formatMixin, compareMixin, supportMixin],
  mounted() {
    this.counter()
    this.handleSetModalState(this.profile)
  },
  watch: {
    profile: {
      deep: true,
      handler(nv) {
        // nv: nuevo valor
        this.handleSetModalState(nv)
      },
    },
    loggedIn() {
      if (!this.loggedIn) this.cleanData()
    },
  },
  computed: {
    ...mapGetters([
      'profile',
      'loggedIn',
      'submenu_chat_atc',
      'profile_states',
      'profile_states_for_list',
    ]),

    /**
     * Devuelve el tiempo que ha pasado en un formato de texto
     * @return {String} tiempo formateado
     */
    timerFormatted() {
      if (this.timerSeconds < 60) return `${this.timerSeconds} segundos`
      else if (this.timerSeconds < 3600)
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toMinutes'
        )} minutos`
      else
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toHours'
        )} horas`
    },
    /**
     * Devuelve el porcentaje de avance de guia de uusario
     * @return {Number}
     */
    percentage() {
      if (
        this.profile &&
        this.isAllowedFor(this.profile.type, ['admin', 'master'])
      ) {
        let percentage = 0
        const routes = this.profile.routes
        if (!routes) return 0
        if (routes?.createTicket.completed) percentage += 30
        if (routes?.activateChatbot.completed) percentage += 35
        if (routes?.integrateWsp.completed) percentage += 35
        else percentage += 0
        return percentage
      } else return 0
    },
    /**
     * Clase del popup footer
     */
    popupClassNameFooter() {
      const classNameForStatus = this.profileStatus.action_when_select.includes(
        'show-status-in-avatar'
      )
        ? 'menu-footer__popup--show-status'
        : ''

      return `menu-footer__popup menu-footer__popup--${this.profile.type} ${classNameForStatus}`
    },
    /**
     * Clase del popup de estados
     */
    popupClassNameStates() {
      const className = this.isAllowedFor(this.profile.type, ['agent'], {
        literal: true,
      })
        ? 'menu-footer__popup-states--agent'
        : 'menu-footer__popup-states--admin'

      return `menu-footer__popup-states ${className}`
    },
  },
  methods: {
    ...mapActions(['saveStatusUser', 'logout', 'selectMenu', 'setAnalytics']),
    ...mapMutations(['SET_IS_OPEN_SOME_POPUP_FOOTER']),

    /** Evalúa el estado y si activar el modal de otro estado tras cargar el sidebar */
    async counter() {
      let statusObj = JSON.parse(localStorage.getItem('status'))
      const statusProfile = this.profile_states[statusObj.status]
      if (
        statusProfile &&
        statusProfile.action_when_select.includes('start-timer')
      ) {
        /** Setea contador de la supervision del estado y menu de la fila seleccionada */
        let diff_ms = moment(new Date()).diff(statusObj.startedIn)
        this.startTimer(Math.round(diff_ms / 1000))
      }
    },
    /**
     * @param {String} status Modal que se mostrará según su estado
     */
    async handleSetStatus(status, actions) {
      this.stopTimer()
      const startedIn = new Date()
      if (actions.includes('start-timer')) this.startTimer()
      if (actions.includes('save')) {
        const response = await this.saveStatusUser({
          status,
          startedIn,
          cleanTicketSelected: true,
        })
        if (response.success && actions.includes('show-notification-on-save')) {
          this.$notification.success({
            message: this.profile_states[status].notification_title,
            description: this.profile_states[status].notification_description,
            duration: 5,
          })
        }
      }
      this.idStatus = status
    },
    /**
     * Inicia la cuenta del contador
     */
    startTimer(initialTime = 0) {
      this.timerSeconds = initialTime
      this.timer = setInterval(() => {
        this.timerSeconds++
      }, 1000)
    },
    /**
     * Finaliza la cuenta del contador
     */
    stopTimer() {
      clearInterval(this.timer)
      this.timerSeconds = 0
    },
    handleReconnect() {
      this.saveStatusUser({ status: 'connected' })
      this.stopTimer()

      const divElementStatus = document.getElementById(this.idStatus)
      if (divElementStatus) {
        divElementStatus.classList.remove('ant-menu-item-selected')
      }

      const divElement = document.getElementById('statuses')
      if (divElement) {
        divElement.classList.remove('ant-menu-submenu-selected')
      }

      const divElementLogout = document.getElementById('logout')
      if (divElementLogout) {
        divElementLogout.classList.remove('ant-menu-item-selected')
      }
    },
    /**
     * Ir hacia la vista de profile
     */
    handleGoToProfile() {
      const statesElement = document.getElementById('states')
      if (statesElement)
        statesElement.classList.remove('ant-menu-submenu-selected')
      const routeName = 'profile'
      this.$router.push({ name: 'menu-user' })
      const menuItem = { route: routeName }
      this.selectMenu(menuItem)
      this.setAnalytics({ eventName: 'link_profile' })
    },
    /**
     * Limpia la data si no esta logueado
     */
    cleanData() {
      this.timer = null
      this.timerSeconds = 0
    },
    /**
     * Ir hacia la seccion de guia de usuarios
     */
    handleGoToGuide() {
      this.$router.push({ name: 'menu-guide' })
      const menuItem = { route: 'profile' }
      this.selectMenu(menuItem)
    },
    /**
     * Cambia según si el popup se encuentra abierto o no
     * @param {Array} val - es el submenu
     */
    handleOpenChangePopup(val) {
      const menuStates = document.getElementById('states')
      if (menuStates) menuStates.classList.remove('ant-menu-submenu-selected')

      const menuSelecteds = document.querySelectorAll(
        '.menu-footer__popup .ant-menu-item-selected'
      )
      if (menuSelecteds.length > 0) {
        menuSelecteds.forEach((menuSelected) => {
          menuSelected.classList.remove('ant-menu-item-selected')
        })
      }
      if (val.includes('submenu-footer-first')) {
        this.SET_IS_OPEN_SOME_POPUP_FOOTER(true)
      } else {
        this.SET_IS_OPEN_SOME_POPUP_FOOTER(false)
      }
    },
    /**
     * Setea el estado del perfil al modal
     * @param {Object} profile
     * @param {String} profile.type
     * @param {String} profile.status
     */
    handleSetModalState(profile) {
      if (!profile) return
      const currentStatus = this.profile_states[profile.status]
      if (!currentStatus) return

      if (currentStatus.action_when_select.includes('show-modal')) {
        this.modalState.visible = true
        this.modalState.type = profile.status
      } else {
        this.modalState.visible = false
      }
    },
    handleGoToSupport() {
      window.open(`${window.location.origin}/support/`, '_blank', 'noopener')
    },
    handleGoToSpending() {
      this.$router.push({ name: 'menu-recharge-spending' })
    },
  },
}
</script>

<style lang="sass" scoped>

.menu-footer
  border-top: 1px solid $white_000
  height: 72px
  &__content
    background-color: $gray_dark_900
    border-right: 0px
    height: 72px
    display: flex
    align-items: center
    width: inherit
    &--no-collapse
      width: inherit
  &__item
    text-align: center
    margin-bottom: 0px!important
  &__submenu
    width: 100%
    &--states
      text-align: center
  &__contain-summary
    height: 280px
    width: 332px
    padding: 0
    margin-top: 0
    display: flex
    flex-direction: column
    align-items: center
    white-space: normal
    cursor: default
  &__content-item
    display: flex
    flex-direction: column
    align-items: center
  &__progress
    width: 70%
</style>
<style lang="sass">
.menu-footer__popup
  position: fixed!important
  &--agent
    >ul
      margin-top: 34px
  &--admin_agent
    >ul
      margin-top: -548px
  &--admin_agent.menu-footer__popup--show-status
    >ul
      margin-top: -609px
  &--master_agent
    >ul
      margin-top: -39em
  &--master_agent.menu-footer__popup--show-status
    >ul
      margin-top: -489px

.menu-footer__popup-states
  >ul
    margin: 0
  &--admin
    top: -246px!important
  &--agent
    top: 293px!important
.menu-item-footer--no-collapse
  & + .ant-menu-submenu-arrow
    display: none
</style>
