<template>
  <section class="full-height">
    <a-row class="full-height section__row">
      <a-col
        v-if="!!grid_responsive.section_chat"
        class="full-height section__col"
        :span="grid_responsive.section_chat"
      >
        <SectionChat :loading="loading" />
      </a-col>
      <a-col
        v-if="!!grid_responsive.section_drawer"
        :span="grid_responsive.section_drawer"
        class="full-height"
        :class="{
          section__col: !grid_responsive.menu_ticket,
        }"
      >
        <router-view
          :loading="loading"
          class="full-height"
          :class="{
            'padding-right-20': !!grid_responsive.menu_ticket,
          }"
        ></router-view>
      </a-col>
      <a-col
        v-if="!!grid_responsive.mini_sidebar"
        :span="grid_responsive.mini_sidebar"
        class="full-height section__col"
      >
        <sub-sidebar />
      </a-col>
      <a-col
        v-if="!!grid_responsive.menu_ticket"
        :span="grid_responsive.menu_ticket"
        class="full-height section__col-menu"
      >
        <menu-ticket class="full-height" />
      </a-col>
    </a-row>
  </section>
</template>

<script>
import SectionChat from '@/app/tickets/components/organisms/sections/SectionChat.vue'
import SubSidebar from '@/app/tickets/components/organisms/SubSidebar'
import MenuTicket from '@/app/tickets/components/organisms/MenuTicket'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'TicketTemplate',
  components: {
    SectionChat,
    SubSidebar,
    MenuTicket,
  },
  data: () => ({
    loading: false,
  }),
  created() {
    this.intervalPromiseAttempt(() => {
      this.loading = true
      const allTickets =
        this.$route.query.status === 'ended' ? this.ticketsEnded : this.tickets

      if (!allTickets.length) throw 'tickets'
      this.loadTicket()
    })
  },
  watch: {
    $route() {
      if (!this.$route.query.status) return
      const idTicket = this.$route.params.ticketId
      if (this.ticketSelected !== idTicket) this.loadTicket()
    },
  },
  mixins: [attemptMixin, supportMixin],
  computed: {
    ...mapGetters([
      'company',
      'profile',
      'profileRemote',
      'tickets',
      'isLoadingSelectedTicket',
      'currentIdMailbox',
      'ticketsEnded',
      'mailboxes',
      'ticketSelected',
      'simple_company_channels',
      'active_breakpoint',
      'main_layout',
    ]),

    /**
     * @returns {Array} tickets dependiendo del estado
     */
    allTickets() {
      return this.$route.query.status === 'ended'
        ? this.ticketsEnded
        : this.tickets
    },
    /**
     * Grillas responsive
     */
    grid_responsive() {
      const params = this.$route.params
      const query = this.$route.query
      const typeMenu = this.viewBreakpoint.type_menu

      // si es una sola vista y si la ruta viene de mobile
      // solo se mostrará una vista
      if (this.main_layout.type === 'one-view') {
        return {
          section_chat: query.main === 'chat' ? 24 : 0,
          section_drawer:
            query.main === 'chat,drawer' && typeMenu === 'menu'
              ? 16
              : query.main !== 'chat' && params.drawerId
              ? 24
              : 0,
          mini_sidebar: 0,
          menu_ticket:
            typeMenu === 'menu' && query.main === 'chat,drawer' ? 8 : 0,
        }
      } else
        return {
          section_chat: 15,
          section_drawer: 8,
          mini_sidebar: 1,
          menu_ticket: 0,
        }
    },
  },
  methods: {
    ...mapActions(['getFieldsToTicket', 'selectTicket', 'searchTickets']),
    ...mapMutations(['SET_MODAL_UPGRADE', 'SET_IS_LOADING_SELECTED_TICKET']),

    /**
     * Setea el ticket ingresado como un ticket seleccionado, se ejecuta cad vez q se selecciona un ticket
     * @param {String}  ticket id del ticket seleccionado por la URL
     */
    async selection(ticket) {
      if (ticket.locked) return this.SET_MODAL_UPGRADE({ visible: true })
      // si existe ticketSelected
      if (this.ticketSelected) {
        if (this.ticketSelected._id === ticket._id) {
          return
        } else {
          const response = await this.getFieldsToTicket({
            idTicket: ticket._id,
            userId: (() => {
              if (this.company.settings.userMode.adminAgent) {
                return this.profile?.userId
              } else {
                return this.profileRemote?.userId
              }
            })(),
          })

          const ticketSelected = ticket
          // si existen campos customizados en la respuesta
          if (Object.prototype.hasOwnProperty.call(response, 'fields'))
            ticketSelected['fields'] = response.fields
          // si existe inputEnabled en la respuesta
          if (Object.prototype.hasOwnProperty.call(response, 'inputEnabled'))
            ticketSelected.inputEnabled = response.inputEnabled
          this.SET_IS_LOADING_SELECTED_TICKET(true)
          await this.selectTicket(ticketSelected)
          this.SET_IS_LOADING_SELECTED_TICKET(false)
        }
      }
      // si no existe ticketSelected, se le setea un ticket
      else {
        const response = await this.getFieldsToTicket({
          idTicket: ticket._id,
          userId: (() => {
            if (this.company.settings.userMode.adminAgent) {
              return this.profile?.userId
            } else {
              return this.profileRemote?.userId
            }
          })(),
        })
        const ticketSelected = ticket
        // si existen campos customizados en la respuesta
        if (Object.prototype.hasOwnProperty.call(response, 'fields'))
          ticketSelected['fields'] = response.fields
        // si existe inputEnabled en la respuesta
        if (Object.prototype.hasOwnProperty.call(response, 'inputEnabled'))
          ticketSelected.inputEnabled = response.inputEnabled
        this.SET_IS_LOADING_SELECTED_TICKET(true)
        await this.selectTicket(ticketSelected)
        this.SET_IS_LOADING_SELECTED_TICKET(false)
      }
    },
    /**
     * Cargar los datos del ticket
     */
    async loadTicket() {
      // FUNCION PARA CARGAR UN TICKET
      this.loading = true
      const idTicket = this.$route.params.ticketId

      const currentIdMailbox = this.$route.query.mailbox
      // busqueda del mailbox
      const mailbox = currentIdMailbox
        ? this.mailboxes.find(
            (el) => el.idMailbox.toString() === currentIdMailbox
          )
        : this.mailboxes.find((el) => el.default)
      const status = this.$route.query.status
      let currentTicket = null
      currentTicket = this.allTickets.find(
        (ticket) => ticket.ticket === idTicket
      )
      // si el id del ticket no se encuentra en los tickets cargados, buscara de acuerdo con el orden
      if (!currentTicket) {
        const page =
          typeof this.$route.query.page === 'string'
            ? parseInt(this.$route.query.page)
            : this.$route.query.page
        // busqueda de acuerdo al skip
        await this.searchTickets({
          idMailbox: mailbox.idMailbox,
          seek: '',
          status,
          page,
          order: 'new',
          line: 'all',
          channel: 'all',
          tag: 'all',
          read: 'all',
        })
        currentTicket = this.allTickets.find(
          (ticket) => ticket.ticket === parseInt(idTicket)
        )
      }
      // vuelve a verificar que el ticket exista, sino existe manda al error
      if (!currentTicket) {
        const currentNameRoute = this.$route.meta.is_workspace
          ? 'workspace'
          : 'workspace-remote'

        return this.$router.push({
          name: currentNameRoute,
          query: { mailbox: mailbox.idMailbox },
        })
      }

      //Funcion y validaciones para obtener el alias de un canal en caso lo tuviera y agregarlo a la información del ticket
      const simpleCompanys = this.simple_company_channels
      let aliasChannel = null
      if (simpleCompanys.length > 0) {
        const simpleCompany = simpleCompanys.find(
          (simpleCompany) => simpleCompany._id == currentTicket.channelCompanyId
        )

        aliasChannel =
          simpleCompany && simpleCompany.alias ? simpleCompany.alias : null
      }

      if (aliasChannel) {
        currentTicket.channel.alias = aliasChannel
      }

      await this.selection(currentTicket)
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.padding-right-20
  padding-right: 20px
.section__row
  height: 100%!important
.section__col
  padding-right: 12px
  &:last-child
    padding-right: 0px
  &-menu
    border-left: 1px solid $gray_4
</style>
