var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-time-state"},[_c('div',{staticClass:"header"},[_c('p',{staticClass:"title heading-h6"},[_vm._v("Tiempo promedio de estados de agentes")]),_c('div',[_c('a-tooltip',{attrs:{"placement":"bottomRight","arrowPointAtCenter":true}},[_c('template',{slot:"title"},[_vm._v(" Visualiza el tiempo promedio que transcurren los agentes en los diferentes estados dentro de "+_vm._s(_vm.app)+". También puedes filtrar por agente en específico. Se mostrarán según el rango de fecha y hora que decidas en el filtro. Este dashboard no es afectado por el filtro de colas. ")]),_c('a-icon',{staticStyle:{"font-size":"20px","color":"#595959","margin-right":"8px"},attrs:{"type":"info-circle"}})],2),(_vm.visibleConfirm)?_c('a-popconfirm',{attrs:{"title":"¿Estás segur@ de descargar los datos de este dashboard?","ok-text":"Descargar","cancel-text":"Cancelar","placement":"bottomRight","arrowPointAtCenter":true,"overlayStyle":_vm.popStyles,"disabled":_vm.isLoading},on:{"confirm":_vm.confirm}},[_c('a-tooltip',{attrs:{"placement":"bottomRight","arrowPointAtCenter":true,"overlayClassName":"tooltip-dash1","trigger":"hover"}},[(!_vm.isLoading)?_c('template',{slot:"title"},[_vm._v(" Descargar en excel ")]):_vm._e(),_c('a-icon',{staticClass:"section__icon--download",class:{
              'btn-dowload': !_vm.isLoading,
              'icon--disabled': _vm.isLoading,
            },attrs:{"type":"download"}})],2)],1):_vm._e(),(_vm.visiblePopOver)?_c('a-popover',{attrs:{"placement":"bottomRight","arrowPointAtCenter":true,"visible":_vm.visiblePopOver,"overlayStyle":_vm.popStyles}},[_c('template',{slot:"content"},[_c('p',{staticClass:"gray-dark-900",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.textProgress)+" ")]),_c('a-progress',{attrs:{"percent":_vm.percent,"status":_vm.status}}),(this.status === 'exception')?_c('div',{staticClass:"contain-btns"},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":_vm.handleCancel}},[_vm._v("Cancelar")]),_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":_vm.handleOk}},[_vm._v("Volver a intentar")])],1):_vm._e()],1),_c('a-icon',{staticClass:"btn-dowload section__icon--download",class:{
            'btn-focus': _vm.visiblePopOver && !_vm.isLoading,
            'icon--disabled': _vm.isLoading,
          },attrs:{"type":"download"}})],2):_vm._e()],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"contain-select"},[_c('a-select',{staticStyle:{"min-width":"140px"},attrs:{"value":_vm.agentId,"size":"small","show-search":"","option-filter-prop":"children"},on:{"change":_vm.handleAgent}},_vm._l((_vm.arrAgents),function(agent){return _c('a-select-option',{key:agent.userId,attrs:{"title":`${agent.names} ${agent.surnames ? agent.surnames : ''}`}},[_vm._v(_vm._s(agent.names)+" "+_vm._s(agent.surnames))])}),1),_c('a-select',{staticClass:"mrg-left-8 dashboard__select",attrs:{"mode":"multiple","size":"small","placeholder":"Selecciona","show-search":"","option-filter-prop":"children"},on:{"change":_vm.handleChangeState},model:{value:(_vm.statusesSelected),callback:function ($$v) {_vm.statusesSelected=$$v},expression:"statusesSelected"}},_vm._l((_vm.statusesLocal),function(state){return _c('a-select-option',{key:state.key,attrs:{"title":state.state}},[_vm._v(_vm._s(_vm.transformWord(state.state, 'capitalize')))])}),1)],1),_c('bar-chart',{attrs:{"styles":_vm.myStyles,"chartdata":_vm.chartdata,"options":_vm.options}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }