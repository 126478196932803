var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{class:{ background_default: _vm.showBackground },style:({ width: `${_vm.width}` }),attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('CustomSectionScroll',{attrs:{"idMaster":"form-base-account","contentStyles":{ padding: '16px 24px' },"masterStyles":{ height: '500px' }}},[_c('h6',{staticClass:"heading-h7 form_subtitle"},[_vm._v("Información personal")]),_c('a-divider',{staticClass:"form_divider"}),_c('a-row',{attrs:{"gutter":12}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Nombres"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'names',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]),expression:"[\n              'names',\n              {\n                rules: [\n                  { required: true, message: 'Por favor rellena el campo' },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Escribir nombres","maxLenght":30}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Apellidos"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'surnames',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]),expression:"[\n              'surnames',\n              {\n                rules: [\n                  { required: true, message: 'Por favor rellena el campo' },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Escribir apellidos","maxLenght":30}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Género"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'gender',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                ],
              },
            ]),expression:"[\n              'gender',\n              {\n                rules: [\n                  { required: true, message: 'Por favor rellena el campo' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Seleccione el género"}},[_c('a-select-option',{attrs:{"value":"female"}},[_vm._v("Femenino")]),_c('a-select-option',{attrs:{"value":"male"}},[_vm._v("Masculino")])],1)],1)],1)],1),_c('h6',{staticClass:"heading-h7 form_subtitle"},[_vm._v("Información de contacto")]),_c('a-divider',{staticClass:"form_divider"}),_c('a-row',{attrs:{"gutter":12}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Correo  "),_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_vm._v(" Este email será usado para iniciar sesión en "+_vm._s(_vm.app)+". ")]),_c('a-icon',{staticStyle:{"margin-top":"3px"},attrs:{"type":"info-circle"}})],2)],1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo.',
                  },
                  {
                    type: 'email',
                    message: 'Ingresa un correo válido.',
                  },
                  {
                    whitespace: true,
                    message: 'No se aceptan espacios en blanco.',
                  },
                  {
                    min: 6,
                    message: '6 caracteres como mínimo.',
                  },
                  {
                    max: 50,
                    message: '50 caracteres como máximo.',
                  },
                ],
              },
            ]),expression:"[\n              'email',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellena el campo.',\n                  },\n                  {\n                    type: 'email',\n                    message: 'Ingresa un correo válido.',\n                  },\n                  {\n                    whitespace: true,\n                    message: 'No se aceptan espacios en blanco.',\n                  },\n                  {\n                    min: 6,\n                    message: '6 caracteres como mínimo.',\n                  },\n                  {\n                    max: 50,\n                    message: '50 caracteres como máximo.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Escribir correo","maxLenght":60},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}},[_c('a-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.newEmail),expression:"newEmail",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopyEmail),expression:"onCopyEmail",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"icon-copy",attrs:{"slot":"suffix","type":"copy"},slot:"suffix"})],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Teléfono"}},[_c('a-row',{attrs:{"gutter":8,"type":"flex"}},[_c('a-col',{attrs:{"span":16,"order":2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor rellena el campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]),expression:"[\n                  'phone',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Por favor rellena el campo',\n                      },\n                      {\n                        whitespace: true,\n                        message: 'No se admiten espacios en blanco.',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"maxLenght":20,"disabled":!_vm.countrySelected ? true : false,"placeholder":"Escribe aquí..."}},[_c('a-tooltip',{attrs:{"slot":"suffix","title":`Ingresa un móvil real porque podrás usarlo por ${_vm.app} para alertas SMS`},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"info-circle"}})],1)],1)],1),_c('a-col',{attrs:{"span":8,"order":1}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor rellena el campo',
                      },
                    ],
                  },
                ]),expression:"[\n                  'code',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Por favor rellena el campo',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"show-search":"","option-filter-prop":"children","placeholder":"País"},on:{"change":function($event){return _vm.handleSelectCountry('_id', $event)}}},_vm._l((_vm.countries),function(country){return _c('a-select-option',{key:country._id,attrs:{"title":country.nameSpanish}},[_vm._v(" "+_vm._s(country.dialCode)+" "+_vm._s(country.nameSpanish)+" ")])}),1)],1)],1)],1)],1)],1),_c('h6',{staticClass:"heading-h7 form_subtitle"},[_vm._v("Información en "+_vm._s(_vm.app))]),_c('a-divider',{staticClass:"form_divider"}),_c('a-row',{attrs:{"gutter":12}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Tipo de usuario  "),_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_vm._v(" Conoce los perfiles de usuario de "+_vm._s(_vm.app)+" "),_c('anchor',{attrs:{"title":"aquí","href":`${_vm.vari.WEB_DOC}primeros-pasos/gestion-usuarios/`}})],1),_c('a-icon',{staticStyle:{"margin-top":"3px"},attrs:{"type":"info-circle"}})],2)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'typeUser',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                ],
              },
            ]),expression:"[\n              'typeUser',\n              {\n                rules: [\n                  { required: true, message: 'Por favor rellena el campo' },\n                ],\n              },\n            ]"}],staticClass:"form__selector__option",attrs:{"placeholder":"Seleccione tipo de usuario","disabled":_vm.disabledFieldUserType || _vm.profile.type === 'supervisor'},on:{"change":_vm.handleChangeTypeUser}},_vm._l((_vm.typeUsersAllowed),function(typeUser){return _c('a-select-option',{key:typeUser.value},[(typeUser.content)?_c('a-popover',{attrs:{"title":typeUser.content.title,"placement":"right"}},[_c('template',{slot:"content"},[_c('p',{staticClass:"mrg-bottom-0",domProps:{"innerHTML":_vm._s(typeUser.content.content)}})]),_c('div',{staticClass:"selector__option-container"},[_vm._v(" "+_vm._s(typeUser.title)+" ")])],2):_c('span',[_vm._v(" "+_vm._s(typeUser.title)+" ")])],1)}),1)],1)],1),(!_vm.account.socialLogin)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.passwordLabel}},[_c('a',{staticClass:"form-item-link__password",on:{"click":_vm.handleGeneratePassword}},[_vm._v("Generar contraseña")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  {
                    required: _vm.account.socialLogin ? false : _vm.requirePassword,
                    message: 'Por favor rellena este campo',
                  },
                ],
              },
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  {\n                    required: account.socialLogin ? false : requirePassword,\n                    message: 'Por favor rellena este campo',\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.disabledPassword,"placeholder":"Escribir contraseña"}},[_c('a-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.newPassword),expression:"newPassword",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopyPassword),expression:"onCopyPassword",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],staticClass:"icon-copy",attrs:{"slot":"suffix","type":"copy"},slot:"suffix"})],1)],1)],1):_vm._e(),_c('a-col',{attrs:{"span":12}},[(_vm.hasCRMIntegration)?_c('a-form-item',{attrs:{"label":`${_vm.externalCRMIntegration.field.name} (${_vm.externalCRMIntegration.platform})`}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'id_user_external_crm',
              {
                initialValue: '',
                rules: [
                  { type: '', message: 'No es un ID válido.' },
                  {
                    pattern: /^([0-9])*$/g,
                    message: 'Los caracteres deben ser números',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]),expression:"[\n              'id_user_external_crm',\n              {\n                initialValue: '',\n                rules: [\n                  { type: '', message: 'No es un ID válido.' },\n                  {\n                    pattern: /^([0-9])*$/g,\n                    message: 'Los caracteres deben ser números',\n                  },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"10000","maxLength":11}})],1):_vm._e()],1)],1),_c('div',{staticClass:"form__label"},[_c('p',{staticClass:"body-2 mrg-bottom-0"},[_vm._v("Asignar colas")]),_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_vm._v(" Conoce cómo te ayudan las colas "),_c('a',{attrs:{"href":`${_vm.vari.WEB_DOC}caracteristicas/colas/`,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" aquí ")])]),_c('a-icon',{staticStyle:{"margin-top":"3px","margin-left":"4px"},attrs:{"type":"info-circle"}})],2)],1),_c('TransferAssign',{ref:"transfer_assign",attrs:{"data-source":_vm.formattedLines(_vm.lines, _vm.profile),"selectedItems":_vm.validateAssignLines},on:{"onSelectedItems":_vm.handleSelectedItems}}),(!_vm.account.owner)?_c('div',{staticClass:"form__actions"},[(!_vm.requirePassword || _vm.payment.status == 'approved')?_c('a',{staticClass:"form__action",class:{
          link__primary: _vm.account.active === 'inactive',

          link__default: _vm.account.active === 'active',
        },on:{"click":function($event){return _vm.handleChangeActive(
            _vm.account.active,
            `${_vm.account.names} ${_vm.account.surnames}`
          )}}},[_vm._v(_vm._s(_vm.account.active === 'active' ? 'Inactivar' : 'Activar usuario'))]):_vm._e(),(_vm.showDelete)?_c('a',{staticClass:"link__danger form__action",on:{"click":function($event){return _vm.$emit('onDelete')}}},[_vm._v(" Eliminar cuenta")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form_footer"},[_c('a-button',{staticClass:"form_footer_button__default",on:{"click":() => _vm.$emit('onCancel')}},[_vm._v(_vm._s(_vm.cancelText))]),_c('a-button',{key:"submit",staticClass:"form_footer_button__primary",attrs:{"type":"primary","html-type":"submit","disabled":!_vm.allowedUpdate,"loading":_vm.loading}},[_vm._v(_vm._s(_vm.okText)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }