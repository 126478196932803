import fastAnswersModule from './fastAnswersModule'
import customFieldsModule from './customFieldsModule'
import blacklistModule from './blacklistModule'

const modules = {
  fastAnswersModule,
  customFieldsModule,
  blacklistModule,
}

export default {
  modules,
}
