import router from '@/router'
import vari from '@/app/shared/utils/variables'

const actions = {
  async listenNotifications(context) {
    try {
      this._vm.$socket.on('client:user:notification', (payload) => {
        const newNotification = {
          ...payload,
        }
        // newNotification.new = true
        /**Añadir notificacion a las notificaciones */
        context.commit('ADD_NOTIFICATION', newNotification)
        context.dispatch('playSound', 'notification') // Reproducir sonido
        /**Añadir el badge al sidebar */
        if (router.currentRoute.name !== 'notifications')
          context.commit('SET_PROFILE_NOTIFICATIONS', 1)
        else context.dispatch('cleanNotifications')

        // Verifica la ruta
        let isInChannelCompany = false
        if (
          [
            'approved-template',
            'rejected-template',
            'disabled-template',
          ].includes(newNotification.activity.type)
        )
          // si la vista ya esta en el canal de la plantilla
          isInChannelCompany =
            router.currentRoute.name === 'channel' &&
            router.currentRoute.params.channel === 'whatsapp' &&
            router.currentRoute.query.channelId ===
              newNotification.activity.channelCo

        switch (newNotification.activity.type) {
          case 'ticket-unlocked-notification':
            {
              context.commit('SET_RELOAD_NOTIFICATION', {
                visible: true,
                title: 'Se liberaron tus tickets',
                description:
                  'Tenías tickets retenidos, actualiza para que puedas verlos.',
              })
            }
            break
          case 'wallet-unlocked-notification':
            {
              context.commit('SET_RELOAD_NOTIFICATION', {
                visible: true,
                title: 'Se liberaron tus mensajes',
                description:
                  'Tenías mensajes retenidos, actualiza para que puedas verlos.',
              })
            }
            break
          case 'pause-strategy-for-limit':
            {
              context.commit('SET_NOTIFICATION_WITH_TYPE', {
                visible: true,
                type: 'warning',
                title: 'Tu campaña ha sido pausada',
                description: newNotification.message.es,
              })
            }
            break
          case 'approved-template':
            {
              context.commit('SET_NOTIFICATION_WITH_TYPE', {
                visible: true,
                type: 'success',
                title: '¡Se aprobó tu plantilla de WhatsApp! 🥳🥳',
                description: `WhatsApp aprobó tu plantilla ${newNotification.activity.value}. Recuerda activarla y asignarla a tus agentes o colas para poder usarla sin problemas.`,
                showButton: true,
                okText: 'Actualizar mi canal',
                route: {
                  name: 'channel',
                  params: { channel: 'whatsapp' },
                  query: {
                    channelId: newNotification.activity.channelCompanyId,
                  },
                },
                reload: isInChannelCompany,
              })
            }
            break
          case 'rejected-template':
          case 'disabled-template':
            {
              const type =
                newNotification.activity.type === 'approved-template'
                  ? 'success'
                  : 'error'
              const title =
                newNotification.activity.type === 'approved-template'
                  ? '¡Se aprobó tu plantilla de WhatsApp! 🥳🥳'
                  : 'Tu plantilla de WhatsApp fue rechazada 😥'

              const description =
                newNotification.activity.type === 'approved-template'
                  ? `${vari.APP_NAME} aprobó tu plantilla ${newNotification.activity.value}. Recuerda activarla y asignarla a tus agentes o colas para poder usarla sin problemas.`
                  : `${vari.APP_NAME} desaprobó tu plantilla ${newNotification.activity.value}, pero no te preocupes puedes volver a enviar tu plantilla.`

              // si la vista ya esta en el canal de la plantilla
              const isInChannelCompany =
                router.currentRoute.name === 'channel' &&
                router.currentRoute.params.channel === 'whatsapp' &&
                router.currentRoute.query.channelId ===
                  newNotification.activity.channelCompanyId

              context.commit('SET_NOTIFICATION_WITH_TYPE', {
                visible: true,
                type: type,
                title: title,
                description: description,
                showButton: true,
                okText: isInChannelCompany
                  ? 'Actualizar mi canal'
                  : 'Quiero ver mi plantilla',
                route: {
                  name: 'channel',
                  params: { channel: 'whatsapp' },
                  query: {
                    channelId: newNotification.activity.channelCompanyId,
                  },
                },
                reload: isInChannelCompany,
              })
            }
            break
        }
      })
    } catch (err) {
      console.error(err)
    }
  },
}

export default { actions }
