<template>
  <a-row
    type="flex"
    class="card-packet"
    :gutter="[60, 60]"
    justify="center"
    align="middle"
  >
    <a-col :span="12" :order="orders[0]">
      <img :src="require(`@/app/shop/assets/${picture}`)" width="100%" />
    </a-col>
    <a-col :span="12" :order="orders[1]">
      <h6 class="semibold-20 card-packet-title">{{ titlePacket }}</h6>
      <p class="regular-16 card-packet-description">
        {{ description }}
        <!--Si el pack es diferente de SMS-->
        <template v-if="type !== 'sms'">
          Para más información
          <a
            href="https://www.securitec.pe/whatsapp-business-api/precios/"
            target="_blank"
            rel="noopener noreferrer"
            >click aquí</a
          >
        </template>
      </p>
      <!--Si el pack es SMS, listara los precios de sms-->
      <template v-if="type === 'sms'">
        <label class="regular-14 label-input-number"
          >Selecciona el país de destino</label
        >
        <a-select
          :default-value="listPrices.AR.name"
          class="select-price"
          @change="handleSelectCountry"
          id="select-prices"
        >
          <a-select-option
            v-for="itemPrice in listPrices"
            :key="itemPrice.countryCode"
          >
            {{ itemPrice.name }}
          </a-select-option>
        </a-select>

        <p class="regular-14 label-input-number" style="margin-top: 12px">
          Selecciona la cantidad
        </p>
        <a-select
          class="select-price"
          v-model="valueQuantity"
          @change="handleSelect"
          id="select-prices"
        >
          <a-select-option
            v-for="itemPrice in visiblePresentationsCountry"
            :key="itemPrice.idPresentation"
          >
            <div class="item-price">
              <span>
                {{
                  itemPrice.amount === 'free'
                    ? 'Otra cantidad'
                    : `${itemPrice.amount} ${namePacket}`
                }}
              </span>
              <span>$ {{ itemPrice.price }} c/u</span>
            </div>
          </a-select-option>
        </a-select>
        <!--Solo si el la opcion seleccionada es Peru y el monto es libre-->
        <template
          v-if="
            listPrices[countryCodeSelected].countryCode === 'PE' &&
            itemSelected.amount === 'free'
          "
        >
          <p class="regular-14 label-input-number" style="margin-top: 12px">
            Ingresa la cantidad de SMS
          </p>
          <a-input-number
            v-model="amountFree"
            :min="itemSelected.min"
            :max="10000"
            style="width: 100%"
            @change="onChangeAmountFree"
            placeholder="5001"
          />
        </template>
      </template>
      <template v-else>
        <!--si la opcion elegida tiene un monto libre-->
        <template v-if="subType === 'free'">
          <p class="regular-14 label-input-number">
            Ingrese el monto a recargar
          </p>
          <a-input-number
            v-model="amountFree"
            :min="listPrices[0].min"
            :max="5000"
            style="width: 100%"
            @change="onChangeAmountFree"
            placeholder="$10"
            :formatter="
              (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
            :parser="handleParserNumber"
          />
        </template>
        <a-select
          v-else
          :default-value="listPrices[0].idPresentation"
          class="select-price"
          @change="handleSelect"
          id="select-prices"
        >
          <a-select-option
            v-for="itemPrice in listPrices"
            :key="itemPrice.idPresentation"
          >
            <div class="item-price">
              <span>
                {{
                  itemPrice.amount === 'free'
                    ? 'Otra cantidad'
                    : `${itemPrice.amount} ${namePacket}`
                }}
              </span>
              <span>$ {{ itemPrice.price }} c/u</span>
            </div>
          </a-select-option>
        </a-select>
        <!--si el pack tiene libre recarga pero no la opcion-->
        <template v-if="itemSelected.amount === 'free' && subType !== 'free'">
          <p class="regular-14 label-input-number">
            Ingrese la cantidad de SMS que necesita
          </p>
          <a-input-number
            v-model="amountFree"
            :min="listPrices[listPrices.length - 2].amount + 1"
            style="width: 100%"
            @change="onChangeAmountFree"
          />
        </template>
      </template>
      <div class="card__footer display-flex justify--between align--center">
        <p class="regular-20 total" id="total">
          Total: ${{ itemSelected.total }}
        </p>
        <a-tooltip>
          <span v-if="!this.isAllowBuyPlans" slot="title"
            >Actualmente no se pueden comprar planes y paquetes a la vez 😥
          </span>
          <a-button
            type="primary"
            :disabled="addToCartDisabled"
            :loading="btnActions.loading"
            @click="addPurchase(itemSelected)"
            >{{ btnActions.name }}</a-button
          >
        </a-tooltip>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import Round from '@/app/shared/utils/round'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CardPacket',
  props: {
    namePacket: {
      type: String,
      require: true,
      default: 'SMS',
    },
    titlePacket: {
      type: String,
      require: true,
      default: 'Compra',
    },
    description: {
      type: String,
      require: false,
      default:
        'Manten tus campañas activas, recuerda mientras más cantidad menor precio 😉',
    },
    type: {
      type: String,
      require: false,
    },
    subType: {
      type: String,
      require: false,
    },
    listPrices: {
      type: [Array, Object],
      require: false,
      default: () => [
        {
          idPresentation: '1',
          amount: 'free',
          price: '0',
          quantity: '100 SMS',
        },
        { idPresentation: '2', price: '1', quantity: '200 SMS' },
        { idPresentation: '3', price: '2', quantity: '300 SMS' },
      ],
    },
    idPack: {
      type: String,
      require: false,
    },
    picture: {
      type: String,
      require: true,
    },
    order: { type: String, require: false, default: null },
  },
  data: () => ({
    itemSelected: {},
    btnActions: {
      loading: false,
      disabled: false,
      name: 'Agregar al carro',
    },
    amountFree: 0, // cantidad libre
    countryCodeSelected: 'AR',
    valueQuantity: null,
  }),
  mounted() {
    const item = { ...this.listPrices[0] }
    this.itemSelected = { ...item, idPack: this.idPack }
    const free =
      typeof this.listPrices === Array
        ? this.listPrices.find((item) => item.amount === 'free')
        : null
    this.amountFree = free ? free.min : 10
    if (this.subType == 'free') this.onChangeAmountFree(this.amountFree)
    if (this.type === 'sms') {
      this.valueQuantity =
        this.listPrices[
          this.countryCodeSelected
        ].presentations[0].idPresentation
      this.itemSelected =
        this.listPrices[this.countryCodeSelected].presentations[0]
    }
  },
  computed: {
    ...mapGetters(['isAllowBuyPlans']),

    /**
     * @return {Boolean}
     */
    addToCartDisabled() {
      if (!this.isAllowBuyPlans) return true
      if (this.subType !== 'free' && this.itemSelected.amount !== 'free')
        return false
      if (this.amountFree < this.itemSelected.min) return true
      return false
    },
    /**
     * @return {Object[]}
     */
    visiblePresentationsCountry() {
      if (!this.countryCodeSelected) return []
      return this.listPrices[this.countryCodeSelected].presentations.filter(
        (p) => !p.hide
      )
    },
    orders() {
      if (this.order && this.order === 'reverse') return [2, 1]
      else return [1, 2]
    },
  },
  methods: {
    ...mapMutations(['SET_IS_ALLOW_BUY_PLANS']),

    addPurchase(item) {
      this.btnActions.loading = true
      this.btnActions.disabled = true
      this.btnActions.name = 'Agregando al carro'
      this.$emit(
        'addPurchase',
        Object.assign(
          { ...item },
          {
            country: this.type === 'sms' ? this.countryCodeSelected : null,
            countryName:
              this.type === 'sms'
                ? this.listPrices[this.countryCodeSelected].name
                : null,
            idPack: this.idPack,
            amount:
              item.amount === 'free' || this.subType == 'free'
                ? this.amountFree
                : item.amount,
          }
        )
      )
      this.btnActions.disabled = false
      setTimeout(() => {
        this.btnActions.loading = false
        this.btnActions.name = 'Agregar al carro'
      }, 600)
      this.SET_IS_ALLOW_BUY_PLANS(false)
    },
    handleSelect(idPrice) {
      let item = null
      if (this.type === 'sms') {
        item = this.listPrices[this.countryCodeSelected].presentations.find(
          (elem) => elem.idPresentation === idPrice
        )
        this.itemSelected.total = Round.round10(
          this.itemSelected.price * this.itemSelected.amount,
          -2
        )
      } else {
        item = this.listPrices.find((elem) => elem.idPresentation === idPrice)
      }
      this.itemSelected = { ...item }
      if (this.itemSelected.amount === 'free') {
        this.amountFree = this.itemSelected.min
        this.itemSelected.total = Round.round10(
          this.itemSelected.price * this.amountFree,
          -2
        )
      }
    },
    /**
     * Escucha el monto que se ponde en el input number
     * @param {String, Number} value - valor del campo
     */
    onChangeAmountFree(value) {
      // si el valor es tipo string o no existe
      if (typeof value === 'string' || !value) return
      const num = value.target?.value || value
      // Ajusta los decimales del precio seleccionado a dos decimales
      this.itemSelected.total = Round.round10(this.itemSelected.price * num, -2)
    },
    handleSelectCountry(country) {
      this.countryCodeSelected = country
      this.valueQuantity =
        this.listPrices[
          this.countryCodeSelected
        ].presentations[0].idPresentation
      this.itemSelected =
        this.listPrices[this.countryCodeSelected].presentations[0]
      this.itemSelected.total = Round.round10(
        this.itemSelected.price * this.itemSelected.amount,
        -2
      )
    },
    /**
     * Parsea los numeros segun el simbolo
     * @param {String} value
     */
    handleParserNumber(value) {
      const str = value.replace('$', '')
      return str.replace(/ /g, '')
    },
  },
}
</script>

<style lang="sass" scoped>
.card-packet
  background-color: $white_000
  padding: 16px
  text-align: left
  width: 100%
  &-title
    margin-bottom: 8px
    color: $gray_9
  &-description
    margin-bottom: 20px
    color: $gray_dark_800
  .select-price
    width: 100%
  .label-input-number
    text-align: left
    margin-bottom: 2px
    margin-top: 8px
    color: $gray_dark_900
  .total
    color: $gray_dark_900
    text-align: right
    margin: 8px 0px
.item-price
  display: flex
  flex-direction: row
  justify-content: space-between
.card__footer
  margin-top: 32px
</style>
<style lang="sass">
.select-price
  .ant-select-selection
    .ant-select-selection__rendered
      .ant-select-selection-selected-value
        width: 100%
</style>
