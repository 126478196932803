<template>
  <a-list-item>
    <!-- Botones bajos -->
    <a-row slot="actions" class="cursor-auto">
      <!--Estado de cada canal-->
      <a-col class="text--right mrg-bottom-12">
        <!--Estados extra-->
        <template v-if="channelStatus.extra">
          <a-tag
            v-for="(extra, index) in channelStatus.extra"
            :key="index"
            :color="extra.color"
          >
            {{ extra.value }}
          </a-tag>
        </template>
        <!--Estado de cada canal-->
        <anchor :href="channelStatus.api_status" :force-show="true">
          <a-tag
            v-if="channelStatus"
            :color="channelStatus.color"
            :style="channelStatus.api_status ? 'cursor:pointer' : ''"
          >
            {{ channelStatus.value }}
          </a-tag>
        </anchor>
      </a-col>
      <!-- Validaciones para WhatsApp -->
      <a-col v-if="channel && channel.channel === 'whatsapp'">
        <a-row type="flex" justify="end" align="bottom">
          <!-- Redireccióna si el canal se encuentra activo -->
          <router-link
            v-if="
              channel.status === 'approved' ||
              (channel.status === 'pre-unsuscribing' && channel.active)
            "
            :to="{
              name: 'channel',
              params: { channel: channel.channel },
              query: { channelId: channel._id },
            }"
            :key="channel._id"
          >
            Ver canal activo
          </router-link>
          <!--Si la solicitud de canal se encuentra pendiente o rechazado-->
          <p
            v-else-if="
              ['pending', 'rejected', 'troubled'].includes(channel.status)
            "
            class="mrg-bottom-0"
          >
            <a @click="$emit('onOpenDetails', channel)"
              >Ver detalles de solicitud</a
            >
          </p>
        </a-row>
      </a-col>

      <!-- Validaciones para canales Facebook -->
      <p
        v-if="
          channel.active &&
          (channel.channel === 'messenger' || channel.channel == 'instagram')
        "
        class="mrg-bottom-0"
        :class="{ 'gray-color-300': !channel.active }"
        @click="showModal(channel.channel)"
      >
        <a>Ver página conectada</a>
      </p>
      <!-- Validaciones para canales Webchat -->
      <p
        v-if="channel.channel === 'webchat' && channel.status === 'approved'"
        class="mrg-bottom-0"
      >
        <router-link
          :to="{
            name: 'channel',
            params: { channel: 'webchat' },
            query: { channelId: channel._id },
          }"
        >
          Ver canal activo
        </router-link>
      </p>
      <!-- Validaciones para canales SMS -->
      <p
        v-if="channel.active && channel.channel === 'sms'"
        class="mrg-bottom-0"
        :class="{ 'gray-color-300': !channel.active }"
        @click="handleShowModalSimpleChannel"
      >
        <a>Ver canal activo</a>
      </p>
    </a-row>
    <!-- Contenido -->
    <a-col class="text--left">
      <a-list-item-meta class="display-flex align--center">
        <!--Icono del canal-->
        <SocialIcon
          slot="avatar"
          :type="channel.channel"
          class="list-item__icon"
        />
        <template slot="description">
          <h6 class="semibold-14 mrg-bottom-12">
            {{ channel.title || 'Canal Solicitado' }}
          </h6>
          <a-row type="flex" class="list-item__row">
            <p class="regular-14 text-align-left mrg-bottom-0 list-item__date">
              {{ `Fecha de conexión: ${approvedSince}` }}
            </p>
            <p
              v-if="channel.alias"
              class="regular-14 text-align-left mrg-bottom-0"
            >
              {{ `Alias: ${channel.alias || 'Ninguno'}` }}
            </p>
          </a-row>
        </template>
      </a-list-item-meta>
    </a-col>

    <!--Modal de pagina conectada para Messenger e Instagram-->
    <modal-simple-channel
      v-if="
        channel.active === true &&
        (channel.channel === 'messenger' || channel.channel == 'instagram')
      "
      :visible="modalPageSelected.visible"
      :title="`Página conectada a tu canal - ${channel.channel}`"
      :picture="channel.broadcast.picture"
      :name="channel.broadcast.name"
      :ok-loading="modalPageSelected.loadingUnlink"
      :ok-disabled="modalPageSelected.disabledUnlink"
      :on-disconnect="onGuideFacebookUnlink"
      @onClose="handleCloseModalPageSelected"
    />
    <!--Modal de pagina conectada para SMS-->
    <modal-simple-channel
      v-if="channel.active === true && channel.channel === 'sms'"
      :visible="modalSimpleChannel.visible"
      :title="modalSimpleChannel.title"
      social-icon="sms"
      :name="modalSimpleChannel.title"
      :ok-loading="modalSimpleChannel.okLoading"
      :ok-disabled="modalSimpleChannel.okDisabled"
      :on-disconnect="handleShowModalConfirmation"
      @onClose="handleCloseModalSimpleChannel"
    />
    <!--Modal de confirmación para desconectar SMS-->
    <modal-confirmation
      :visible="modalConfirmation.visible"
      :colorIcon="red_6"
      typeIcon="delete"
      :title="`¿Estás segura de desvincular tu canal de SMS?`"
      :isList="true"
      :descriptions="modalConfirmation.descriptions"
      subTitle="Recuerda que se tomarán las siguientes acciones:"
      strongWord="Desvincular SMS"
      nameButton="Desvincular"
      typeButton="danger"
      :loadingButton="modalConfirmation.loading"
      @onChangeVisible="modalConfirmation.visible = !modalConfirmation.visible"
      @actionExecuted="handleDeleteChannelSms"
    />
    <!--Modal de checklist para los canales de facebook-->
    <ModalInfoList
      v-if="hasModalInfo && !!Object.keys(dataModalInfoList).length"
      :data="dataModalInfoList"
      :enumerated="enumetaredModalInfoList"
      @handleClose="closeModalInfo"
      @handleOk="okModalInfo"
    />
  </a-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import SocialIcon from '@/app/shared/components/icons/SocialIcon.vue'
import transformWordGender from '@/app/shared/mixins/transform'
import ModalInfoList from '@/app/channels/components/organisms/modals/ModalInfoList.vue'
import Anchor from '@/app/shared/components/atoms/Anchor'
import ModalSimpleChannel from '../organisms/modals/ModalSimpleChannel.vue'
import ModalConfirmation from '@/app/accounts/components/modals/ModalConfirmation'

export default {
  name: 'ListItemChannel',
  props: {
    channel: {
      // este canal viene de canales
      type: Object,
      required: false,
      _id: { type: String },
      active: { type: Boolean },
      apiId: { type: String },
      api_version: { type: String },
      channel: { type: String },
      channelId: { type: String },
      status: { type: String },
    },
  },
  components: {
    SocialIcon,
    ModalInfoList,
    Anchor,
    ModalSimpleChannel,
    ModalConfirmation,
  },
  mixins: [transformWordGender],
  data: () => ({
    app: process.env.VUE_APP_NAME,
    red_6: '#f5222d',
    modalPageSelected: {
      visible: false,
      loadingUnlink: false,
      disabledUnlink: false,
    },
    drawerDetails: {
      visible: false,
    },
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bodyStyleRequest: {
      padding: '12px 24px',
    },
    visibleRequest: false,
    page: {
      name: 'Pagina numero 1',
    },
    visibleModalSelect: false,
    listPages: [], // lista de paginas vinculadas
    pageSelected: {
      // objeto de pagina seleccionada
      channel: '',
      idFacebookPage: null,
      pageAccessToken: '',
      name: '',
      picture: '', // url de la imagen de la pagina
    },
    styleModalSelected: {
      padding: '0',
    },
    responseError: [], // errores de login
    loadBtnConected: false,
    tempResponse: null,
    disabledBtnConect: false,
    loginType: '',
    channelToView: '',
    dataModalInfoList: {}, // Modales informativos para mostrar
    otherFbIntegration: false, // Existe otra conexión de integración con otra aplicación de Facebook?
    stepModal: 1, // Numero del paso de los modales a mostrar
    sandboxNumber: '',
    modalSimpleChannel: {
      visible: false,
      title: '',
      okLoading: false,
      okDisabled: false,
    },
    modalConfirmation: {
      visible: false,
      loading: false,
      descriptions: [
        'Todos tus tickets activos de SMS se finalizarán.',
        'SMS se desasignará de las colas que esten asociadas.',
        'Tus campañas SMS que estén en progreso se detendrán.',
      ],
    },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'requestForm' })
  },
  computed: {
    ...mapGetters([
      'profile',
      'company',
      'channels',
      'simple_company_channels',
    ]),

    /**
     * @return {Object} {status_es, fontColor, bgColor, borColor}
     */
    channelStatus() {
      let channelStatus = null
      let simpleChannel = null
      // encuentra el canal general
      if (this.simple_company_channels && !!this.simple_company_channels.length)
        simpleChannel = this.simple_company_channels.find(
          (simple_company_channel) =>
            simple_company_channel._id === this.channel._id
        )

      if (simpleChannel && simpleChannel.api) {
        const api = simpleChannel.api
        // si la api esta caida
        if (api.down) {
          return {
            color: 'red',
            value: 'Error de proveedor',
            apiStatus: api.api_status,
          }
        }
      }
      if (this.channel.active) {
        if (this.channel.status === 'approved') {
          return {
            color: 'blue',
            value: 'Conectado',
          }
        }
        if (
          this.channel.status === 'pre-unsuscribing' &&
          this.channel.api_version === 'gupshup_v1'
        ) {
          return {
            color: 'orange',
            value: 'Pendiente de baja',
            extra: [
              {
                color: 'blue',
                value: 'Conectado',
              },
            ],
          }
        }
      }
      switch (this.channel.status) {
        case 'pending':
          {
            channelStatus = {
              color: 'orange',
              value: 'Pendiente',
            }
          }
          break
        case 'rejected':
          {
            channelStatus = {
              color: 'red',
              value: 'Rechazado',
            }
          }
          break
        case 'troubled': {
          channelStatus = {
            color: 'red',
            value: 'Expirado',
          }
          break
        }
        case 'unsuscribing': {
          channelStatus = {
            color: '',
            value: 'Desconectado',
          }
          break
        }
        default: {
          channelStatus = {
            color: '',
            value: 'Desconectado',
          }
        }
      }
      channelStatus.key = this.channel.status
      return channelStatus
    },
    /**
     * @return {Boolean}
     */
    hasModalInfo() {
      return ['instagram', 'messenger'].includes(this.channel.channel)
    },
    /**
     * Lista del modal enumerado? Lo enumera si la lista tiene más de un elemento
     * @return {Boolean}
     */
    enumetaredModalInfoList() {
      return (
        this.dataModalInfoList.list && this.dataModalInfoList.list.length > 1
      )
    },
    /**
     * Fecha de aprovación del canal
     * @return {String}
     */
    approvedSince() {
      const approvedStatus = 'approved'
      const channelCompanyStatusObj = this.channel?.tracking?.find(
        (el) => el.status === approvedStatus
      )
      if (channelCompanyStatusObj) {
        return moment(channelCompanyStatusObj.created_at).format('LL')
      }
      return 'Pendiente'
    },
  },
  methods: {
    ...mapActions([
      'fbUnlinkPage',
      'unsuscribeChannel',
      'finishTicketsInChannel',
      'getInstagramBusiness',
      'deleteChannelSms',
    ]),
    // muestra el modal
    showModal() {
      this.modalPageSelected.visible = true
    },
    // cierra el modal informativo
    closeModalInfo() {
      this.dataModalInfoList = {}
    },
    /**
     * Evento ok del ModalInfo
     * @param {String} channel
     * @param {String} key
     */
    okModalInfo(channel, key) {
      this.dataModalInfoList = {}
      switch (channel) {
        case 'messenger':
          {
            if (key === 'considerations_unlink') {
              this.unlinkFbPage()
            }
          }
          break
        case 'instagram':
          {
            if (key === 'considerations_unlink') {
              this.unlinkFbPage()
            }
          }
          break
      }
    },
    /**
     * Abre los modales de Facebook para la desvinculación
     * @param {Number} [step=1] El número del paso en el que se encuentra de la llamada de modales
     * @return {Boolean} último modal?
     */
    onGuideFacebookUnlink() {
      switch (this.channel.channel) {
        case 'messenger': {
          this.otherFbIntegration = false // isInstagramConnected
          if (this.otherFbIntegration) {
            // Modal para desconectar Messenger cuando Instagram está activo
            this.dataModalInfoList = {
              key: 'considerations_unlink',
              channel: 'messenger',
              companionIcon: '/img/channels/unplug-msgr.svg',
              title: 'Facebook Messenger',
              okButtonName: 'Desconectar mi canal de Facebook Messenger',
              okButtonType: 'primary',
              subtitle:
                '¡Hola! vemos que vas a desvincular tu canal de Facebook Messenger, ten en cuenta lo siguiente',
              list: [
                {
                  title:
                    'Si tu página de Facebook que tienes conectada en el canal de Instagram, es la misma que tienes conectada en el canal de Messenger',
                  description: `Ambos canales se desvincularán y deberás volver a conectar tu canal de Facebook Messenger en ${this.app}`,
                  info: `
                  <p style="font-weight: 500;">En nuestra plataforma sucederá lo siguiente:</p>
                  <p style="margin: 4px 0;">✅ Los tickets activos de los canales de Messenger e Instagram serán finalizados.</p>
                  <p style="margin: 4px 0;">✅ Los canales de Messenger e Instagram se desvincularán de tus chatbots.</p>
                  <p>✅ Los canales de Messenger e Instagram se desvincularán de tus colas.</p>
                  `,
                },
              ],
            }
          } else {
            // Modal para desconectar Messenger cuando Instagram NO está activo
            this.dataModalInfoList = {
              key: 'considerations_unlink',
              channel: 'messenger',
              title: 'Facebook Messenger',
              okButtonName: 'Desconectar mi canal de Facebook Messenger',
              okButtonType: 'primary',
              subtitle:
                '¡Hola! vemos que vas a desvincular tu canal de Facebook Messenger, ten en cuenta lo siguiente:',
              list: [
                {
                  title:
                    'El canal de Messenger se desvinculará y en la plataforma sucederá lo siguiente',
                  info: `
                  <p style="margin: 20px 0 4px 0;" >✅ Los tickets activos de Messenger serán finalizados.</p>
                  <p style="margin: 4px 0;">✅ El canales de Messenger se desvinculará de tus chatbots.</p>
                  <p>✅ El canal de Messenger se desvinculará de tus colas.</p>
                  `,
                },
              ],
            }
          }
          break
        }
        case 'instagram': {
          // Modal para desconectar Instagram
          // const isMessengerConnected = !!(
          //   this.company.messenger && this.company.messenge.active
          // )
          this.otherFbIntegration = false //isMessengerConnected
          if (this.otherFbIntegration) {
            this.dataModalInfoList = {
              key: 'considerations_unlink',
              channel: 'instagram',
              // companionIcon: 'instagram',
              title: 'Instagram para empresas',
              okButtonName: 'Desconectar mi canal de Instagram',
              okButtonType: 'primary',
              subtitle:
                '¡Hola! Vemos que vas a desvincular tu canal de Instagram, ten en cuenta lo siguiente',
              list: [
                {
                  title:
                    'Si tu página de Instagram es la misma que esta conectada en Facebook',
                  description:
                    'Ambos canales se desvincularán y deberás volver a conectar tu página para Facebook Messenger.',
                  info: `
                  <p style="font-weight: 500;" >En nuestra plataforma sucederá lo siguiente:</p>
                  <p style="margin: 0 0 4px 0;" >✅ Los tickets activos de los canales de Instagram y Messenger serán finalizados.</p>
                  <p style="margin: 0 0 4px 0;" >✅ Los canales de Instagram y Messenger se desvincularán de tus chatbots.</p>
                  <p style="margin: 0 0 4px 0;" >✅ Los canales de Instagram y Messenger se desvincularán de tus colas.</p>
                  `,
                },
              ],
            }
          } else {
            // Modal para desconectar Messenger cuando Instagram NO está activo
            this.dataModalInfoList = {
              key: 'considerations_unlink',
              channel: 'instagram',
              title: 'Instagram para empresas',
              okButtonName: 'Desconectar mi canal de Instagram',
              okButtonType: 'primary',
              subtitle:
                '¡Hola! Vemos que vas a desvincular tu canal de Instagram, ten en cuenta lo siguiente',
              list: [
                {
                  title:
                    'El canal de Instagram se desvinculará y en la plataforma sucederá lo siguiente:',
                  info: `
                  <p style="margin: 20px 0 4px 0;" >✅ Los tickets activos de Instagram serán finalizados.</p>
                  <p style="margin: 4px 0;">✅ El canales de Instagram se desvinculará de tus chatbots.</p>
                  <p>✅ El canal de Instagram se desvinculará de tus colas.</p>
                  `,
                },
              ],
            }
          }
          break
        }
        default:
          break
      }
    },
    /**
     * Desvincular de Facebook Messenger
     */
    async unlinkFbPage() {
      this.modalPageSelected.loadingUnlink = true
      this.modalPageSelected.disabledUnlink = true
      /**Actuaiza los datos en base */
      await this.unsuscribeChannel({
        channelCompanyId: this.channel._id,
        channel: this.channel.channel,
        idFacebookPage: this.channel.credentials.idFacebookPage,
      })
      this.$emit('onLoading', {
        reason: 'unlink_channel',
        channelCompanyId: this.channel._id,
      })
      this.modalPageSelected.visible = false
      this.modalPageSelected.loadingUnlink = false
      this.modalPageSelected.disabledUnlink = false
    },
    /**
     * Cierra el modal de página seleccionada para canales
     * de Facebook
     */
    handleCloseModalPageSelected() {
      this.modalPageSelected.visible = false
      this.modalPageSelected.loadingUnlink = false
      this.modalPageSelected.disabledUnlink = false
    },
    /**
     * Muestra el modal simple de sms
     */
    handleShowModalSimpleChannel() {
      this.modalSimpleChannel.title = this.channel.broadcast?.title || ''
      this.modalSimpleChannel.visible = true
    },
    /**
     * Cierra el modal simple del canal de sms
     */
    handleCloseModalSimpleChannel() {
      this.modalSimpleChannel.visible = false
      this.modalSimpleChannel.okLoading = false
      this.modalSimpleChannel.okDisabled = false
    },
    /**
     * Muestra el modal de confirmación para eliminar SMS
     */
    handleShowModalConfirmation() {
      this.handleCloseModalSimpleChannel()
      this.modalConfirmation.visible = true
    },
    /**
     * Ejecuta la acción de eliminar SMS
     */
    async handleDeleteChannelSms() {
      this.modalConfirmation.loading = true
      const response = await this.deleteChannelSms(this.channel._id)
      this.modalConfirmation.loading = false

      if (response.success) {
        this.modalConfirmation.visible = false
        this.$message.success('Se desvinculó correctamente')
        this.$emit('onLoading', {
          reason: 'unlink_channel',
          channelCompanyId: this.channel._id,
        })
      } else
        return this.$message.error(response.details || 'Ocurrió un error 😥')
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-color-700
  color: $gray_dark_700
.gray-color-300
  color: $gray_dark_300
.gray-dark-900
  color: $gray_dark_900

.connected-page
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  border: 1px solid $gray_dark_200
  border-radius: 8px
  width: 256px
  padding: 8px 12px
  img
    margin-right: 12px
  &-name
    margin-bottom: 0
    color: $gray_dark_900
  &-status
    display: flex
    flex-direction: row
    align-items: center
    p
      margin-bottom: 0
      margin-right: 4px
      color: $gray_dark_800
.list-item__icon
  margin-top: 12px
.list-item__date
  margin-right: 4em
.list-item__row
  color: $gray_8
</style>
