var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{staticClass:"text--left",attrs:{"label":"Archivo adjunto"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'fileMedia',
        {
          valuePropName: 'fileList',
          getValueFromEvent: _vm.normFile,
          rules: [
            {
              required: false,
              message: 'Por favor sube el archivo',
            },
          ],
        },
      ]),expression:"[\n        'fileMedia',\n        {\n          valuePropName: 'fileList',\n          getValueFromEvent: normFile,\n          rules: [\n            {\n              required: false,\n              message: 'Por favor sube el archivo',\n            },\n          ],\n        },\n      ]"}],attrs:{"name":"fileMedia","multiple":false,"customRequest":_vm.uploadFile,"before-upload":_vm.beforeUpload,"remove":_vm.handleRemoveFile,"accept":_vm.formatsToUpload,"disabled":_vm.onlyRead},on:{"preview":_vm.handlePreviewModal}},[_c('p',[_c('a-icon',{staticClass:"form__icon--inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_c('span',[_vm._v(" Solo se aceptan "+_vm._s(_vm.descriptionUpload)+". ")]),(_vm.extraDescription)?[_c('br'),_c('span',[_vm._v(_vm._s(_vm.extraDescription))])]:_vm._e()],2)])],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Título"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'title',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellene el campo',
            },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            },
          ],
        },
      ]),expression:"[\n        'title',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellene el campo',\n            },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribir aquí","maxLength":_vm.validations.maxLengthTitleCard,"disabled":_vm.onlyRead},on:{"input":_vm.onInput}})],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje"}},[_c('p',{staticClass:"help text--left mrg-bottom-8"},[_vm._v(" Recuerda que será un mensaje de tipo texto para tus usuarios. ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'subtitle',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellene el campo',
            },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            },
          ],
        },
      ]),expression:"[\n        'subtitle',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellene el campo',\n            },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribir aquí","maxLength":_vm.validations.maxLengthSubtitleCard,"disabled":_vm.onlyRead},on:{"input":_vm.onInput}})],1),_c('modal-preview-media',{attrs:{"visible":_vm.modalPreviewMedia.visible,"title":_vm.modalPreviewMedia.title,"type":_vm.modalPreviewMedia.type,"url":_vm.modalPreviewMedia.url},on:{"onClose":_vm.handleCloseModalPreview}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }