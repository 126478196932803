<template>
  <a-form :form="form" layout="vertical">
    <!--Adjuntar archivo-->
    <a-form-item label="Archivo adjunto" class="text--left">
      <a-upload-dragger
        v-decorator="[
          'fileMedia',
          {
            valuePropName: 'fileList',
            getValueFromEvent: normFile,
            rules: [
              {
                required: false,
                message: 'Por favor sube el archivo',
              },
            ],
          },
        ]"
        name="fileMedia"
        :multiple="false"
        :customRequest="uploadFile"
        :before-upload="beforeUpload"
        :remove="handleRemoveFile"
        :accept="formatsToUpload"
        @preview="handlePreviewModal"
        :disabled="onlyRead"
      >
        <p>
          <a-icon type="inbox" class="form__icon--inbox" />
        </p>
        <p class="ant-upload-text">Sube aquí tu archivo</p>
        <p class="ant-upload-hint">
          <span>
            Solo se aceptan
            {{ descriptionUpload }}.
          </span>
          <template v-if="extraDescription">
            <br />
            <span>{{ extraDescription }}</span>
          </template>
        </p>
      </a-upload-dragger>
    </a-form-item>
    <!--Titulo de la tarjeta-->
    <a-form-item label="Título" class="section-form-item">
      <a-input
        v-decorator="[
          'title',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        @input="onInput"
        placeholder="Escribir aquí"
        :maxLength="validations.maxLengthTitleCard"
        :disabled="onlyRead"
      />
    </a-form-item>
    <!-- Campo de texto para mostrar -->
    <a-form-item label="Mensaje" class="section-form-item">
      <p class="help text--left mrg-bottom-8">
        Recuerda que será un mensaje de tipo texto para tus usuarios.
      </p>
      <a-input
        v-decorator="[
          'subtitle',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        @input="onInput"
        placeholder="Escribir aquí"
        :maxLength="validations.maxLengthSubtitleCard"
        :disabled="onlyRead"
      />
    </a-form-item>

    <modal-preview-media
      :visible="modalPreviewMedia.visible"
      :title="modalPreviewMedia.title"
      :type="modalPreviewMedia.type"
      :url="modalPreviewMedia.url"
      @onClose="handleCloseModalPreview"
    />
  </a-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import filterSimpleVarsBySave from '@/app/chatbots/mixins/actionsToNodes'
import ModalPreviewMedia from '@/app/chatbots/components/organisms/modals/ModalPreviewMedia'
import multimediaMixin from '@/app/chatbots/mixins/multimediaMixin'

export default {
  name: 'CardForm',
  components: {
    ModalPreviewMedia,
  },
  mixins: [filterSimpleVarsBySave, multimediaMixin],
  props: {
    dataSourceLines: {
      type: Array,
      default: () => [],
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentNodeId: {
      type: String,
      required: true,
    },
    childId: {
      type: String,
      required: true,
    },
    chatbotId: {
      type: String,
      required: true,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
    isChangeAction: false,
    modalPreviewMedia: {
      visible: false,
      type: '',
      url: '',
      title: '',
    },
    fileUploaded: {},
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'card-form-chatbot' })
  },
  computed: {
    ...mapGetters(['nodes', 'selectedNodeId', 'validations_chatbot']),

    /**
     * @return {String} - descripción de los formatos para subir archivo
     */
    descriptionUpload() {
      if (this.multimediaForNode.length === 1) {
        const multimedias = this.multimediaForNode[0].formats.titles
        return `${multimedias.join(', ')}`
      } else {
        const multimedias = this.multimediaForNode.map(
          (multimedia) =>
            `${multimedia.title} (max. ${multimedia.max_size.title})`
        )
        return multimedias
      }
    },
    /**
     * @return {String} - descripción extra para el tamaño máximo
     */
    extraDescription() {
      return `${this.multimediaForNode[0].max_size.title} tamaño máximo.`
    },
    /**
     * @return {String} - formatos permitidos para subir archivo
     */
    formatsToUpload() {
      const formats = this.multimediaForNode.map(
        (multimedia) => multimedia.formats.accept
      )
      return formats.join(', ')
    },
    /**
     * Validaciones del nodo de acuerdo al soporte
     * @return {Object} validations
     * @return {Number} validations.maxLengthTitleCard
     * @return {Number} validations.maxLengthSubtitleCard
     */
    validations() {
      const node = 'cards'
      return this.validations_chatbot[node]
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),
    ...mapActions(['updateChild', 'uploadFileToNode', 'deleteFileToNode']),

    /**
     * Setea valores al formulario
     * @param {Object} args
     * @param {String} args.title
     * @param {String} args.subtitle
     * @param {Object} args.media
     * @param {Number} args.order
     */
    setValues({ subtitle, title, media, order }) {
      this.order = order

      this.$nextTick(() => {
        this.form.setFieldsValue({
          subtitle,
          title,
          fileMedia: Object.values(media).length
            ? [
                {
                  uid: '1',
                  name: media.originalName,
                  status: 'done',
                  url: media.urlStorage,
                  type: media.type,
                },
              ]
            : [],
        })
      })
      this.fileUploaded = media
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        this.handleUpdateCard(values)
        this.setIsSavedItem(true)
      })
    },
    /**
     * Subir cambios al nodo de mensaje
     * @param {Object} values - valores del formulario
     * @param {String} values.text - texto del mensaje
     * @param {Boolean} values.wait - si el mensaje esperará una respuesta o no
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     * @param {String} values.action - transfer, continue, none
     */
    async handleUpdateCard(values) {
      const settings = {
        order: this.order,
        parentNodeId: this.parentNodeId,
        childId: this.childId,
      }
      const newChild = {
        order: settings.order,
        title: values.title,
        subtitle: values.subtitle,
        media: this.fileUploaded,
      }

      const response = await this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: settings.parentNodeId,
        nodeType: 'cards',
        childId: settings.childId,
        newChild,
        childIdProperty: 'idCard',
      })
      if (response.success) {
        this.$emit('onChangeSettings', response)
        this.$message.success('Se actualizó correctamente')
      } else {
        this.$message.error(response.details || 'No se pudo ejecutar la acción')
      }
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     */
    handleSetEmoji(emoji) {
      let text = this.form.getFieldValue('text')
      text = text + emoji
      this.form.setFieldsValue({
        text,
      })
      this.setIsSavedItem(false)
    },
    /**
     * Escucha el cambio de texto y verifica si el archivo es requerido
     */
    handleChangeText() {
      this.setIsSavedItem(false)
    },
    /**
     * Obtiene el archivo que se subio para tomarlo en el formulario
     * @param {Object} e - evento
     * @return {Object[]} fileList
     * @return {String} fileList[].name
     * @return {String} fileList[].status
     * @return {String} fileList[].url
     */
    normFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList && e.fileList.filter((file) => file.status)
    },
    /**
     * Verifica si el archivos cumple con las reglas antes de subirlo
     * @param {Object} file
     */
    beforeUpload(file) {
      this.handleRemoveFile(false)

      const multimedia = this.typeMultimedia(file)

      if (!multimedia) {
        this.$message.error('Archivo no permitido')
        return false
      }

      // verificar el tamaño del archivo
      const isValidSize = file.size / 1024 / 1024 <= multimedia.max_size.size
      if (!isValidSize)
        this.$message.error(
          `El tamaño máximo para ${multimedia.title} es de ${multimedia.max_size.size}`
        )
      return isValidSize
    },
    /**
     * Subida local
     * @param {Object}   args
     * @param {String}   args.action
     * @param {File}     args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    async uploadFile({ file, onSuccess, onError }) {
      const multimedia = this.typeMultimedia(file)
      await this.uploadFileToNode({
        file,
        type: multimedia.type,
      })
        .then((res) => {
          if (!res.success) {
            this.$message.error(
              res.details || 'Ocurrió un error mientras se subía el archivo 😥.'
            )
            this.handleRemoveFile()
            return
          }
          onSuccess(res.urlFile)
          this.$message.success('Se adjunto el archivo exitosamente')
          this.fileUploaded = res.urlFile
          this.setIsSavedItem(false)
        })
        .catch((err) => {
          console.error('[uploadFile]', err)
          this.$message.error(
            err.details || 'Ocurrió un error mientras se subía el archivo 😥.'
          )
          onError(err)
        })
    },
    /**
     * Devuelve el tipo de multimedia de acuerdo al archivo subido
     * @param {File} file
     * @return {Object} multimedia
     * @return {String} multimedia.type
     * @return {String} multimedia.title
     * @return {Object} multimedia.formats
     * @return {String[]} multimedia.formats.titles
     * @return {String[]} multimedia.formats.values
     * @return {String} multimedia.formats.accept
     * @return {Object} multimedia.max_size
     */
    typeMultimedia(file) {
      const type = file.type
      const multimedia = this.multimediaForNode.find((multimedia) =>
        multimedia.formats.accept.includes(type)
      )
      return multimedia
    },
    /**
     * Remueve el archivo subido
     * @param {Boolean} isChange - si debería considerarse como cambio
     */
    async handleRemoveFile(isChange) {
      this.form.resetFields(['fileMedia'])
      this.fileUploaded = {}

      if (isChange) this.setIsSavedItem(false)
    },
    /**
     * Previsualiza el archivo en un modal
     * @param {File}
     */
    handlePreviewModal(file) {
      if (file.response) {
        file.type = file.response.type
        file.url = file.response.urlStorage
      }
      // si es de tipo documento
      if (file.type === 'document') return window.open(file.url)
      // si es de tipo video o imagen
      this.modalPreviewMedia.title = file.name || file.originalName
      this.modalPreviewMedia.type = file.type
      this.modalPreviewMedia.url = file.url
      this.modalPreviewMedia.visible = true
    },
    /**
     * Cierra el modal de previsualizacion
     */
    handleCloseModalPreview() {
      this.modalPreviewMedia = {
        visible: false,
        type: '',
        url: '',
        title: '',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.radio-message
  display: flex
  flex-direction: row
  align-items: center
  &__icon
    font-size: 14px
.form__selector__option
  width: 100%
.selector__option-container
  width: calc(100% + 12px)
  margin-left: -12px
  padding-left: 12px
.form__icon--inbox
  font-size: 24px
</style>
