import navModule from '@/app/commons/store/nav'
import socketModule from '@/app/commons/store/socket'
import globalModule from '@/app/commons/store/global'

const modules = {
  navModule,
  ...socketModule.modules,
  globalModule,
}

export default {
  modules,
}
