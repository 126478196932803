import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_CHANNEL_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  SET_CHANNELS(state, channels) {
    state.channels = channels
  },
  SET_ALL_TEMPLATES(state, allTemplates) {
    state.allTemplates = allTemplates
  },
  SET_ALL_NEXT_TEMPLATES(state, allNextTemplates) {
    state.allTemplates = state.allTemplates.concat(allNextTemplates)
  },
  /**
   * Setea datos básicos al state de simple_company_channels y al state de filtered_company_channels
   * @param {*} state
   * @param {Object[]} simple_channels
   * @param {Boolean} simple_channels[].active
   * @param {String} simple_channels[].channel
   * @param {String} simple_channels[].channelId
   * @param {String} simple_channels[].api_version
   * @param {String} simple_channels[].apiId
   * @param {String} simple_channels[].updated_at
   * @param {String} simple_channels[].created_at
   */
  SET_SIMPLE_COMPANY_CHANNELS(state, simple_channels) {
    state.simple_company_channels = simple_channels
  },
  /**
   * Elimina un canal de la lista de canales simples
   * @param {*} state
   * @param {String} channelCompanyId
   */
  DELETE_SIMPLE_COMPANY_CHANNEL(state, channelCompanyId) {
    const index = state.simple_company_channels.findIndex(
      (simpleCompany) => simpleCompany._id === channelCompanyId
    )
    if (index === -1) return
    state.simple_company_channels.splice(index, 1)
  },
  SET_PAGINATE_COMPANY_CHANNELS(state, paginate_company_channels) {
    state.paginate_company_channels = paginate_company_channels
  },
  SET_PAGINATION_COMPANY_CHANNELS(state, pagination_company_channels) {
    state.pagination_company_channels = pagination_company_channels
  },
  /**
   * Actualiza el valor de active de un canal paginado
   * @param {*} state
   * @param {Boolean} active
   * @param {String} channelCompanyId
   */
  UPDATE_ACTIVE_COMPANY_CHANNEL(state, { channelCompanyId, active }) {
    const channelCompany = state.paginate_company_channels.find(
      (el) => el._id === channelCompanyId
    )
    if (!channelCompany) return
    channelCompany.active = active
  },
  /**
   * Elimina un canal de la lista de canales paginados
   * @param {*} state
   * @param {String} channelCompanyId
   */
  DELETE_COMPANY_CHANNEL(state, channelCompanyId) {
    const index = state.paginate_company_channels.findIndex(
      (companyChannel) => companyChannel._id === channelCompanyId
    )
    if (index === -1) return
    state.paginate_company_channels.splice(index, 1)
  },
  /**
   * Setea datos al canal actual
   * @param {Object} current_channel
   */
  SET_CURRENT_CHANNEL(state, current_channel) {
    state.current_channel = current_channel
  },
}

export default mutations
