import auth from './auth'
import analytics from './analytics'
import tickets from './tickets'
import schedule from './schedule'
import manageTickets from './manageTickets'
import contacts from './contacts'
import accounts from './accounts'
import supervision from './supervision'
import lines from './lines'
import channels from './channels'
import chatbots from './chatbots'
import settings from './settings'
import profile from './profile'
import dashboard from './dashboard'
import shared from './shared'
import commons from './commons'
import notifications from './notifications'
import signup from './signup'
import shop from './shop'
import support from './support'
import products from './products'
import strategies from './strategies'
import companies from './companies'

/**
 * Routes for each app module
 */

const routes = [
  ...auth.routes,
  ...tickets.routes,
  ...schedule.routes,
  ...manageTickets.routes,
  ...contacts.routes,
  ...accounts.routes,
  ...supervision.routes,
  ...lines.routes,
  ...channels.routes,
  ...chatbots.routes,
  ...settings.routes,
  ...profile.routes,
  ...dashboard.routes,
  ...shared.routes,
  ...commons.routes,
  ...shop.routes,
  ...signup.routes,
  ...notifications.routes,
  ...support.routes,
  ...products.routes,
  ...strategies.routes,
  ...companies.routes,
]

/**
 * Vuex modules
 */

const store = {
  modules: {
    ...auth.store.modules,
    ...analytics.store.modules,
    ...lines.store.modules,
    ...tickets.store.modules,
    ...schedule.store.modules,
    ...manageTickets.store.modules,
    ...contacts.store.modules,
    ...accounts.store.modules,
    ...supervision.store.modules,
    ...channels.store.modules,
    ...chatbots.store.modules,
    ...dashboard.store.modules,
    ...commons.store.modules,
    ...signup.store.modules,
    ...shop.store.modules,
    ...notifications.store.modules,
    ...settings.store.modules,
    ...profile.store.modules,
    ...shared.store.modules,
    ...products.store.modules,
    ...strategies.store.modules,
    ...companies.store.modules,
  },
}

export default {
  routes,
  store,
}
