<template>
  <a-form :form="form" layout="vertical" class="text--left">
    <router-link :to="{ name: 'campaigns' }">
      <a-icon type="arrow-left" /> Regresar a campañas
    </router-link>
    <h4 class="semibold-20 mrg-bottom-8 mrg-top-12">
      {{ title }}
    </h4>
    <p
      class="regular-14"
      :class="{
        form__description: !error.visible,
        'mrg-bottom-0': error.visible,
      }"
    >
      Completa todos los campos e importa tu campaña. Recuerda enviar plantillas
      a contactos que han
      <strong> aceptado recibir información </strong> de tu negocio. Si tienes
      dudas de como hacerlo
      <anchor :href="`${vari.WEB_DOC}campanas/campana-masiva-whatsapp/`">
        revisa nuestros docs</anchor
      >.
    </p>
    <a-alert
      v-if="error.visible"
      class="form__alert"
      type="error"
      :message="error.message"
      show-icon
    />
    <!--Primer paso-->
    <template v-if="currentStep === 0">
      {{ validateNewValuesInDom() }}
      <!--Nombre de campaña-->
      <a-row>
        <a-form-item label="Nombre de campaña">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Escribe aquí..."
          />
        </a-form-item>
      </a-row>
      <!--Canal de whatsapp-->
      <a-row>
        <a-form-item label="Número">
          <a-select
            v-decorator="[
              'channelCompanyId',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Selecciona un número"
            @change="handleChannelCompany"
          >
            <a-select-option
              v-for="channel in filterSimpleChannelWhatsAppByType({
                type: 'gupshup',
              })"
              :key="channel._id"
            >
              {{ channel.title }}
              ( {{ channel.alias ? channel.alias : 'Sin alias' }} )
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
      <!--Selector de cola-->
      <a-row>
        <a-form-item>
          <span slot="label">
            Cola de destino&nbsp;
            <a-tooltip placement="right" :overlayStyle="tooltipStyles">
              <template slot="title">
                Los tickets respondidos serán asignados a esta cola.
              </template>
              <a-icon type="info-circle" class="form__icon-info" />
            </a-tooltip>
          </span>
          <a-select
            v-decorator="[
              'lineId',
              {
                initialValue: !linesFilteredByChannel.length
                  ? undefined
                  : linesFilteredByChannel[0]._id,
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Selecciona una cola"
          >
            <a-select-option
              v-for="line in linesFilteredByChannel"
              :key="line._id"
            >
              {{ line.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
      <!--Tiempo de respuesta-->
      <a-row>
        <a-form-item>
          <span slot="label">
            Selecciona el tiempo máximo de respuesta&nbsp;
            <a-tooltip placement="right" :overlayStyle="tooltipStyles">
              <template slot="title">
                {{ app }} usará este tiempo para verificar y notificar que las
                respuestas a los envíos son de esta campaña o no.
              </template>
              <a-icon type="info-circle" class="form__icon-info" />
            </a-tooltip>
          </span>
          <a-select
            v-decorator="[
              'hoursOfLife',
              {
                initialValue: timeOptions[0],
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                ],
              },
            ]"
            placeholder="Selecciona una cola"
          >
            <a-select-option v-for="time in timeOptions" :key="time">
              {{ time }} horas
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
    </template>
    <!--Segundo paso-->
    <template v-else-if="currentStep === 1">
      {{ validateNewValuesInDom() }}
      <!--tipo de plantilla-->
      <a-row>
        <a-form-item label="Selecciona el tipo de plantilla">
          <a-select
            v-decorator="[
              'mediaType',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor completa este campo',
                  },
                ],
              },
            ]"
            placeholder="Selecciona aquí"
            @change="handleChangeTypeTemplate"
          >
            <a-select-option
              v-for="typeTemplate in TYPE_TEMPLATES_FOR_LIST"
              :key="typeTemplate.value"
            >
              {{ typeTemplate.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
      <!--selector de plantilla-->
      <a-row>
        <a-form-item>
          <span slot="label">
            Selecciona una plantilla&nbsp;
            <a-tooltip placement="right" :overlayStyle="tooltipStyles">
              <template slot="title">
                Se listarán únicamente las plantillas de tipo "Texto".
              </template>
              <a-icon type="info-circle" class="form__icon-info" />
            </a-tooltip>
          </span>
          <a-select
            v-decorator="[
              'templateId',
              {
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Selecciona una plantilla"
            @change="handleSelectTemplate"
          >
            <a-select-option
              v-for="template in templatesFilteredByType"
              :key="template._id"
            >
              {{ template.friendlyName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
      <!--adjunto de archivo media-->
      <a-row>
        <a-form-item
          v-if="typeTemplateSelected.type === 'MEDIA'"
          label="Archivo adjunto"
        >
          <a-upload-dragger
            v-decorator="[
              'fileMedia',
              {
                valuePropName: 'fileMediaList',
                getValueFromEvent: normFileMedia,
                rules: [
                  {
                    required: true,
                    message: 'Por favor sube el archivo',
                  },
                ],
              },
            ]"
            name="fileMedia"
            :multiple="false"
            :file-list="fileMediaList"
            :customRequest="uploadFileMedia"
            :before-upload="beforeUploadMedia"
            :remove="handleRemoveFileMedia"
            :accept="typeTemplateSelected.formats.accept"
          >
            <p>
              <a-icon type="inbox" class="form__icon-inbox" />
            </p>
            <p class="ant-upload-text">Sube aquí tu archivo</p>
            <p class="ant-upload-hint">
              <span>
                Solo se acepta
                {{ typeTemplateSelected.formats.titles.join(' ,') }}.
              </span>
              <br />
              <span>
                {{ typeTemplateSelected.max_size.title }} tamaño máximo.
              </span>
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-row>
    </template>

    <!--Tercer paso-->
    <!--Generar formato-->
    <template v-else-if="currentStep === 2">
      {{ validateNewValuesInDom() }}
      <a-row type="flex" align="middle" class="mrg-bottom-8">
        <p class="semibold-14 mrg-bottom-0 mrg-right-8 form__label--black">
          Formato para campaña
        </p>
        <a-tooltip placement="right" :overlayStyle="tooltipStyles">
          <template slot="title">
            <anchor
              :href="`${vari.WEB_DOC}campanas/plantilla-predefinida-whatsapp/`"
            >
              Click aquí</anchor
            >. si deseas más info de cómo completar la plantilla predefinida.
          </template>
          <a-icon type="info-circle" class="form__icon-info" />
        </a-tooltip>
      </a-row>
      <div class="form__container-download">
        <a
          :disabled="
            hasNewValues({
              valuesForm: form.getFieldsValue(),
              except: 'fileMessage',
            })
          "
          @click="handleGenerateAndDownloadFileCsv(false)"
          >Click aquí para descargar archivo .CSV</a
        >
      </div>

      <!--Subir archivo-->
      <p class="semibold-14 mrg-bottom-8 form__label--black">
        Sube tu formato con los datos completos
      </p>
      <p class="mrg-bottom-8">
        Tienes un envío
        <strong>
          máximo a {{ tier.tier }} contactos en tu
          <anchor
            href="https://developers.facebook.com/docs/whatsapp/messaging-limits#quality"
            >{{ tier.type }}</anchor
          >
        </strong>
        y recuerda que el tiempo de envío es de 5 segundos por contacto.
      </p>
      <a-tooltip>
        <template
          v-if="
            hasNewValues({
              valuesForm: form.getFieldsValue(),
              except: 'fileMessage',
            })
          "
          slot="title"
          placement="right"
        >
          Completa todos los campos para poder subir tu archivo.</template
        >
        <a-form-item>
          <a-upload-dragger
            v-decorator="[
              'fileMessage',
              {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [
                  { required: true, message: 'Por favor sube el archivo' },
                ],
              },
            ]"
            name="file"
            :multiple="false"
            :customRequest="uploadFile"
            :file-list="fileList"
            :before-upload="beforeUpload"
          >
            <p>
              <a-icon type="inbox" class="form__icon-inbox" />
            </p>
            <p class="ant-upload-text">Sube aquí tu archivo</p>
            <p class="ant-upload-hint">Solo se aceptan archivos .csv</p>
          </a-upload-dragger>
        </a-form-item>
      </a-tooltip>
      <div>
        <p class="semibold-14 mrg-bottom-8">Recuerda</p>
        <p class="info_i2">
          Esta campaña solo podrá ser lanzada durante los próximos 7 días desde
          la fecha de su creación. Luego se detendrá forzosamente.
        </p>
      </div>
    </template>
  </a-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vari from '@/app/shared/utils/variables'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import Anchor from '@/app/shared/components/atoms/Anchor'
import {
  TYPE_TEMPLATES_FOR_LIST,
  TYPES_TEMPLATES,
} from '@/app/channels/utils/componentsDataTemplates'

export default {
  name: 'AddStrategyForm',
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    vari,
    TYPE_TEMPLATES_FOR_LIST,
    TYPES_TEMPLATES,
    app: process.env.VUE_APP_NAME,
    linesAssigned: [],
    disabledDownload: false,
    loadingDownload: false,
    templateId: null,
    fileUploaded: null,
    fileList: [],
    error: {
      visible: false,
      message: '',
    },
    timeOptions: [24, 48, 72],
    isWasChanged: false,
    errorInFile: false,
    tooltipStyles: {
      width: '200px',
    },
    wasSetValueInTemplate: false, // usado para validar si el id del template ya fue seteado
    linesFilteredByChannel: [],
    tier: {
      type: 'Tier 1', // valor por defecto
      tier: 1000,
    },
    typeTemplateSelected: { type: 'TEXT', value: 'TEXT' },
    fileMediaList: [],
    mediaAttachment: {
      mediaUrl: null,
      mediaType: null,
      mediaCaption: null,
    },
    newStrategy: {},
    channelCompanyId: '',
  }),
  components: {
    Anchor,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_strategy' })
  },
  created() {
    this.intervalAttempt(async () => {
      if (!this.profile) throw 'no profile'
      this.loadLinesAssigned()
    })
  },
  mixins: [attemptMixin, supportMixin],
  computed: {
    ...mapGetters([
      'profile',
      'companyLines',
      'simple_templates',
      'isPlanAdvanced',
      'isPlanFree',
    ]),
    /**
     * @return {String} nombre para el boton de descarga
     */
    nameDownload() {
      if (this.isWasChanged) return 'Generando arhivo'
      return this.loadingDownload ? 'Descargando archivo' : 'Descargar archivo'
    },
    /**
     * Plantillas filtradas por tipo
     * @return {Object[]} templates
     * @return {String} templates._id
     * @return {String} templates.friendlyName
     */
    templatesFilteredByType() {
      const typesAllowed = [this.typeTemplateSelected.value]
      return this.simple_templates.filter(
        (simpleTemplate) =>
          typesAllowed.includes(simpleTemplate.type) ||
          typesAllowed.includes(simpleTemplate.typeAttachment)
      )
    },
  },
  methods: {
    ...mapActions([
      'uploadFileStrategy',
      'addCompanyStrategy',
      'getLine',
      'generateFileCsv',
      'getSimpleTemplates',
      'getTierWhatsAppChannel',
      'uploadFileMediaStrategy',
    ]),
    ...mapMutations(['SET_MODAL_UPGRADE']),

    /**
     * Subida local
     * @param {Object} args
     * @param {String} args.action
     * @param {String} args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    async uploadFile({ file, onSuccess, onError }) {
      if (this.isPlanAdvanced || this.isPlanFree)
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })

      this.handleResetError()
      this.handleRemoveFile()

      await this.uploadFileStrategy({
        fileCsv: file,
        templateId: this.templateId,
        channelCompanyId: this.channelCompanyId,
      })
        .then((res) => {
          if (!res.success) {
            this.setAlertError(
              res.details ||
                'Ocurrió un error mientras se subía el archivo 😥.',
              true
            )

            this.errorInFile = true
            this.handleRemoveFile()
            return this.$message.error(
              'Ocurrió un error mientras se subía el archivo 😥.'
            )
          }
          onSuccess(res.urlFile)
          this.fileUploaded = res.urlFile
          this.fileList = [file]
          this.errorInFile = false
          this.hasNewValues({
            valuesForm: this.form.getFieldsValue(),
          })
        })
        .catch((err) => {
          console.error('[uploadFile]', err)
          this.setAlertError(
            'Ocurrió un error mientras se subía el archivo 😥.',
            true
          )
          onError(err)
        })
    },
    /**
     * Submit de formulario
     */
    handleSubmit() {
      if (this.isPlanAdvanced || this.isPlanFree)
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err && !this.errorInFile) {
          this.handleResetError()
          // guarda los valores para enviarlo en el ultimo paso

          this.handleSetNewStrategy(values)

          if (this.currentStep === 2) {
            values.filenameCsv = this.fileUploaded?.filename
            values.urlFileCsv = this.fileUploaded?.gcp
            values.delimiterCsv = this.fileUploaded?.delimiter

            this.newStrategy = { ...this.newStrategy, ...values }

            this.$emit('onLoading', true)
            const response = await this.addCompanyStrategy(this.newStrategy)
            this.$emit('onLoading', false)

            if (response.success) {
              this.handleGoToStrategies()
              // si la respuesta tiene title quiere decir que se debería mostrar una notificacion
              if (response.title)
                this.showWarningNotification(response.title, response.details)
              else this.showSuccessNotification()
            } else {
              this.error.visible = true
              this.error.message = response.details || 'Ocurrió un error 😥'
            }
          }
          this.isWasChanged = false
        }
      })
    },
    /**
     * Setear valores al formulario
     * @param {String} values.name
     * @param {String} values.lineId
     * @param {String} values.templateId
     * @param {String} values.channelCompanyId
     * @param {String} values.mediaType
     * @param {File} values.fileMessage
     * @param {File} values.fileMedia
     */
    handleSetValues(values) {
      this.form.setFieldsValue(values)
    },
    /**
     * Genera el archivo csv de acuerdo a la plantilla
     */
    async handleGenerateAndDownloadFileCsv(disabled) {
      if (disabled) return
      if (this.isPlanAdvanced || this.isPlanFree)
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
      this.loadingDownload = true
      const response = await this.generateFileCsv({
        templateId: this.templateId,
        channel: 'whatsapp',
      })
      this.loadingDownload = false
      if (!response.success)
        this.$message.error('Ocurrió un error en la descarga')
    },
    /**
     * Seleccion de un template
     * @param {String} templateId
     */
    handleSelectTemplate(templateId) {
      this.templateId = templateId
      this.$emit('onChange', 'templateId', templateId)
      this.isWasChanged = true
      this.handleRemoveFile()
      setTimeout(() => {
        this.isWasChanged = false
      }, 1000)
    },
    /**
     * Verifica si hay un nuevos Valores
     * @param {Object} args
     * @param {Object} args.valuesForm
     * @param {String} args.except
     */
    hasNewValues({ valuesForm, except = null, returnValue = true }) {
      const fields = { ...valuesForm }
      // elimina la palabra que no se validara
      delete fields[except]
      // verifica que ningun valor este vacio
      const values = Object.values(fields)

      // si es que hay un valor que se esta exceptuando, se emite que no esta completo
      const copyValuesForm = Object.values({ ...valuesForm })
      // si tiene todos los datos completados
      const disabled =
        !copyValuesForm.length ||
        copyValuesForm.some((val) => {
          if (Array.isArray(val)) return !val || !val.length
          else return !val
        })
      this.$emit('onHasNewValues', disabled)
      if (returnValue) return !values.length || values.some((val) => !val)
    },
    /**
     * Verifica si el archivos cumple con las reglas antes de subirlo
     * @param {Object} file
     */
    beforeUpload(file) {
      this.handleRemoveFile()
      let isAllowed = true

      const extension = file.name.split('.').pop()
      const isCsv = extension === 'csv'
      if (!isCsv) {
        this.$message.error('Solo puedes subir archivos csv')
        isAllowed = false
      }
      const isLt5M = file.size / 1024 / 1024 <= 5
      if (!isLt5M) {
        this.$message.error('El tamaño máximo permitido es de 5MB')
        isAllowed = false
      }
      if (this.isPlanAdvanced || this.isPlanFree) {
        this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
        isAllowed = false
      }
      if (!isAllowed)
        setTimeout(() => {
          this.handleRemoveFile()
        }, 1500)
      return isAllowed
    },
    /**
     * Va hacia campañas
     */
    handleGoToStrategies() {
      this.$router.push({ name: 'campaigns' })
    },
    /**
     * Muestra la notifiacion de exitoso
     */
    showSuccessNotification() {
      this.$notification.success({
        message: 'Tu campaña se creo con éxito',
        description:
          'Recuerda que los tickets generados a partir de esta campaña serán atendidos por tus agentes.',
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },
    /**
     * Muestra la notifiacion de warning
     * @param {String} title
     * @param {String} description
     */
    showWarningNotification(title, description) {
      this.$notification.warning({
        message: title,
        description: description,
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },
    /**
     * Resetea los errores
     */
    handleResetError() {
      this.error = {
        visible: false,
        message: '',
      }
      this.errorInFile = false
    },
    /**
     * Evento de la subida del archivo
     * @param {Event} e - evento
     */
    normFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    /**
     * Evento de la subida del archivo
     * @param {Event} e - evento
     */
    normFileMedia(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    /**
     * Remueve el archivo subido
     */
    handleRemoveFile() {
      this.fileList = []
      this.form.resetFields(['fileMessage'])
    },
    /**
     * Escucha el evento de cambio de canal
     * @param {String} channelCompanyId
     */
    handleChannelCompany(channelCompanyId) {
      this.channelCompanyId = channelCompanyId
      this.getSimpleTemplates(channelCompanyId)
      this.filterLinesByChannelCompany(channelCompanyId)
      this.handleSetTier(channelCompanyId)
      this.$nextTick(() => {
        this.hasNewValues({
          valuesForm: this.form.getFieldsValue(),
          returnValue: false,
        })
      })
    },
    /**
     * @param {String} channelCompanyId
     * @return {Object[]} colas con el canal de whatsapp
     */
    filterLinesByChannelCompany(channelCompanyId) {
      this.linesFilteredByChannel = this.companyLines.filter((line) => {
        if (!line.channels_companies) return false
        else
          return line.channels_companies.some(
            (channelCompany) => channelCompany._id === channelCompanyId
          )
      })
    },
    /**
     * Obtiene y setea el tier a la data local
     * @param {String} channelCompanyId
     */
    async handleSetTier(channelCompanyId) {
      const response = await this.getTierWhatsAppChannel(channelCompanyId)
      if (response.success) {
        const tier = response.data
        this.tier = {
          type: tier.tier_type,
          tier: tier.tier,
        }
      }
    },
    /**
     * Escucha el cambio de tipo de plantilla
     * @param {String} value - TEXT, DOCUMENT, VIDEO, IMAGE
     */
    handleChangeTypeTemplate(value) {
      const typeTemplateSelectedFromList = this.TYPE_TEMPLATES_FOR_LIST.find(
        (typeTemplateForList) => typeTemplateForList.value === value
      )

      this.typeTemplateSelected = {
        value,
        type: typeTemplateSelectedFromList.type,
        ...this.TYPES_TEMPLATES[typeTemplateSelectedFromList.template],
      }
      // resetea la plantilla
      this.form.resetFields(['templateId'])
      this.handleRemoveFileMedia()
      this.$emit('onResetMedia')

      // emite cambios
      this.$emit('onChange', 'mediaType', value.toLowerCase())
    },
    /**
     * Subida local
     * @param {Object}   args
     * @param {String}   args.action
     * @param {File}     args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    async uploadFileMedia({ file, onSuccess, onError, onProgress }) {
      const fileName = file.name || new Date().getTime()
      await this.uploadFileMediaStrategy({
        file,
        caption: fileName,
        type: this.typeTemplateSelected.value,
        channelCompanyId: this.channelCompanyId,
      })
        .then((res) => {
          if (!res.success) {
            this.setAlertError(
              res.details ||
                'Ocurrió un error mientras se subía el archivo 😥.',
              true
            )
            this.handleRemoveFileMedia()
            return
          }

          this.mediaAttachment = {
            mediaUrl: res.urlFile.temp,
            mediaType: this.typeTemplateSelected.typeAttachment,
            mediaCaption: fileName,
          }
          onProgress({ percent: 100 })
          this.$emit('onChange', 'mediaUrl', res.urlFile.temp)
          this.$emit('onChange', 'fileName', fileName)
          onSuccess(res.urlFile)
          this.fileMediaList = [file]
        })
        .catch((err) => {
          console.error('[uploadFileMedia]', err)
          this.setAlertError(
            'Ocurrió un error mientras se subía el archivo 😥.',
            true
          )
          onError(err)
        })
    },
    /**
     * Verifica si el archivos cumple con las reglas antes de subirlo
     * @param {Object} file
     * @return {Boolean} isAllowed
     */
    beforeUploadMedia(file) {
      this.handleRemoveFileMedia()
      this.setAlertError('', false)
      let isAllowed = true

      const extension = file.name.split('.').pop()
      const formats = this.typeTemplateSelected.formats.values.map((format) =>
        format.toLowerCase()
      )
      const isValidFormat = formats.includes(extension)
      if (!isValidFormat) {
        this.setAlertError(
          `Solo se pueden subir archivos ${this.typeTemplateSelected.formats.titles.join(
            ' ,'
          )}`,
          true
        )
        isAllowed = false
      }
      const isValidSize =
        file.size / 1024 / 1024 <= this.typeTemplateSelected.max_size.size

      if (!isValidSize) {
        this.setAlertError(
          `El tamaño máximo permitido es de ${this.typeTemplateSelected.max_size.title}`,
          true
        )
        isAllowed = false
      }
      if (!isAllowed)
        setTimeout(() => {
          this.handleRemoveFileMedia()
        }, 1500)
      return isAllowed
    },
    /**
     * Setea datos a a la alerta de error
     * @param {String} message
     * @param {Boolean} visible
     */
    setAlertError(message, visible) {
      this.error.message = message
      this.error.visible = visible
    },
    /**
     * Remueve el archivo subido
     */
    handleRemoveFileMedia() {
      console.log('handleRemoveFileMedia')
      this.fileMediaList = []
      this.form.resetFields(['fileMedia', 'fileMediaList'])
      this.mediaAttachment.mediaUrl = null
      this.$emit('onChange', 'mediaUrl', null)
      this.$emit('onChange', 'fileName', null)
    },
    /**
     * Valida los nuevos valores del formulario en el DOM
     */
    validateNewValuesInDom() {
      this.$nextTick(() => {
        this.hasNewValues({
          valuesForm: this.form.getFieldsValue(),
          returnValue: false,
        })
      })
    },
    /**
     * Esucha el retroceso de los pasos
     */
    handleBack() {
      const values = this.form.getFieldsValue()
      this.handleSetNewStrategy(values)
    },
    /**
     * Setea nuevos datos a la nueva estrategia
     */
    handleSetNewStrategy(values) {
      switch (this.currentStep) {
        case 0: {
          this.newStrategy = { ...this.newStrategy, ...values }
          return this.newStrategy
        }
        case 1: {
          values.mediaUrl = this.mediaAttachment.mediaUrl
          values.mediaCaption = this.mediaAttachment.mediaCaption
          this.newStrategy = { ...this.newStrategy, ...values }
          return this.newStrategy
        }
        case 2: {
          values.filenameCsv = this.fileUploaded?.filename
          values.urlFileCsv = this.fileUploaded?.gcp
          values.delimiterCsv = this.fileUploaded?.delimiter

          this.newStrategy = { ...this.newStrategy, ...values }
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form__icon
  font-size: 32px
  &-inbox
    font-size: 24px
    color: $gray_8
  &-spin
    font-size: 16px
    color: $gray_8
  &-info
    font-size: 14px
.form
  &__container-download
    margin-bottom: 24px
  &__label--black
    color: $gray_9
  &__description
    margin-bottom: 20px
  &__alert
    margin: 12px 0px
</style>
