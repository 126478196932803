<template>
  <div class="summary">
    <img class="summary__banner" :src="banner" alt="Banner de la compañia" />
    <avatar-status
      shape="semicircle"
      :status="profileStatus.state"
      :border="profileStatus.border"
      icon-size="large"
      class="summary__contain-avatar"
    >
      <a-avatar
        v-if="avatar"
        :src="avatar"
        :size="78"
        class="summary__avatar"
        alt="Avatar de perfil"
      />
      <DefaultIconLetter
        v-else
        :names="names"
        :size="78"
        class="summary__avatar"
      />
    </avatar-status>

    <div class="summary__body">
      <template v-if="profileStatus.action_when_select.includes('start-timer')">
        <p class="regular-14 mrg-bottom-4 text--center">
          <b>Estado:</b> {{ profileStatus.title_for_footer_menu }}
          <a-tooltip
            :title="`Bloquea la recepción de nuevos tickets, mientras continuas utilizando ${app}.`"
          >
            <a-icon type="info-circle"></a-icon>
          </a-tooltip>
        </p>
        <p class="regular-14 mrg-bottom-0 text--center">
          <i>Te encuentras en este estado hace {{ timer }}</i>
        </p>

        <a-button
          type="link"
          class="mrg-bottom-8"
          @click="$emit('onReconnect', profileStatus.state)"
          >Volver a conectarse</a-button
        >
      </template>

      <h6 v-if="planName" class="semibold-12 mrg-bottom-4">{{ planName }}</h6>
      <h6 v-if="names" class="heading-h7">{{ names }}</h6>
      <p v-if="companyName" class="body-2 mrg-bottom-12">
        {{ companyName }}
      </p>
      <p v-if="email" class="body-1" style="margin-bottom: 6px; color: #686a6f">
        <a-icon type="mail" /> {{ email }}
      </p>
      <p
        v-if="typeUser"
        class="body-1"
        style="margin-bottom: 6px; color: #686a6f"
      >
        <a-icon type="smile" /> {{ typeUser }}
      </p>
      <p
        v-if="username"
        class="body-1"
        style="margin-bottom: 6px; color: #686a6f"
      >
        <a-icon type="user" /> {{ username }}
      </p>
      <p
        v-if="phoneNumber"
        class="body-1"
        style="margin-bottom: 6px; color: #686a6f"
      >
        <a-icon type="mobile" /> {{ phoneNumber }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import AvatarStatus from '@/app/shared/components/avatars/AvatarStatus.vue'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'SummaryAccount',
  components: {
    DefaultIconLetter,
    AvatarStatus,
  },
  mixins: [supportMixin],
  props: {
    names: { type: String, required: false, default: null },
    email: { type: String, required: false, default: null },
    companyName: { type: String, required: false, default: null },
    username: { type: String, required: false, default: null },
    phoneNumber: { type: String, required: false, default: null },
    avatar: { type: String, required: false },
    banner: {
      type: String,
      required: false,
      default: require('@/app/shared/assets/bg-profile.png'),
    },
    typeUser: { type: String, required: false, default: null },
    planName: { type: String, required: false, default: null },
    timer: { type: [String, Number], required: false, default: '0' },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
  }),
  computed: {
    ...mapGetters(['profile', 'profile_states']),
  },
}
</script>

<style lang="sass" scoped>
.mrg-bottom-12
  margin-bottom: 12px
.mrg-bottom-4
  margin-bottom: 4px

.summary
  width: 100%
  position: relative
  display: flex
  justify-content: center
  &__banner
    width: 100%
    height: 87px
    background-size: cover
    border-radius: 4px 4px 0px 0px
    cursor: auto
    object-fit: cover
  &__contain-avatar
    position: absolute
    top: 43px
  &__avatar
    top: 0px
    border: 3px solid $white_000
  &__body
    position: absolute
    top: 135px
    display: flex
    flex-direction: column
    align-items: center
    color: $gray_dark_900
    padding: 0 20px
</style>
<style lang="sass">
.summary__avatar
  .ant-avatar-string
    line-height: 72px!important
</style>
