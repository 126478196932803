var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-list-item',[_c('a-row',{staticClass:"cursor-auto",attrs:{"slot":"actions"},slot:"actions"},[_c('a-col',{staticClass:"text--right mrg-bottom-12"},[(_vm.channelStatus.extra)?_vm._l((_vm.channelStatus.extra),function(extra,index){return _c('a-tag',{key:index,attrs:{"color":extra.color}},[_vm._v(" "+_vm._s(extra.value)+" ")])}):_vm._e(),_c('anchor',{attrs:{"href":_vm.channelStatus.api_status,"force-show":true}},[(_vm.channelStatus)?_c('a-tag',{style:(_vm.channelStatus.api_status ? 'cursor:pointer' : ''),attrs:{"color":_vm.channelStatus.color}},[_vm._v(" "+_vm._s(_vm.channelStatus.value)+" ")]):_vm._e()],1)],2),(_vm.channel && _vm.channel.channel === 'whatsapp')?_c('a-col',[_c('a-row',{attrs:{"type":"flex","justify":"end","align":"bottom"}},[(
            _vm.channel.status === 'approved' ||
            (_vm.channel.status === 'pre-unsuscribing' && _vm.channel.active)
          )?_c('router-link',{key:_vm.channel._id,attrs:{"to":{
            name: 'channel',
            params: { channel: _vm.channel.channel },
            query: { channelId: _vm.channel._id },
          }}},[_vm._v(" Ver canal activo ")]):(
            ['pending', 'rejected', 'troubled'].includes(_vm.channel.status)
          )?_c('p',{staticClass:"mrg-bottom-0"},[_c('a',{on:{"click":function($event){return _vm.$emit('onOpenDetails', _vm.channel)}}},[_vm._v("Ver detalles de solicitud")])]):_vm._e()],1)],1):_vm._e(),(
        _vm.channel.active &&
        (_vm.channel.channel === 'messenger' || _vm.channel.channel == 'instagram')
      )?_c('p',{staticClass:"mrg-bottom-0",class:{ 'gray-color-300': !_vm.channel.active },on:{"click":function($event){return _vm.showModal(_vm.channel.channel)}}},[_c('a',[_vm._v("Ver página conectada")])]):_vm._e(),(_vm.channel.channel === 'webchat' && _vm.channel.status === 'approved')?_c('p',{staticClass:"mrg-bottom-0"},[_c('router-link',{attrs:{"to":{
          name: 'channel',
          params: { channel: 'webchat' },
          query: { channelId: _vm.channel._id },
        }}},[_vm._v(" Ver canal activo ")])],1):_vm._e(),(_vm.channel.active && _vm.channel.channel === 'sms')?_c('p',{staticClass:"mrg-bottom-0",class:{ 'gray-color-300': !_vm.channel.active },on:{"click":_vm.handleShowModalSimpleChannel}},[_c('a',[_vm._v("Ver canal activo")])]):_vm._e()],1),_c('a-col',{staticClass:"text--left"},[_c('a-list-item-meta',{staticClass:"display-flex align--center"},[_c('SocialIcon',{staticClass:"list-item__icon",attrs:{"slot":"avatar","type":_vm.channel.channel},slot:"avatar"}),_c('template',{slot:"description"},[_c('h6',{staticClass:"semibold-14 mrg-bottom-12"},[_vm._v(" "+_vm._s(_vm.channel.title || 'Canal Solicitado')+" ")]),_c('a-row',{staticClass:"list-item__row",attrs:{"type":"flex"}},[_c('p',{staticClass:"regular-14 text-align-left mrg-bottom-0 list-item__date"},[_vm._v(" "+_vm._s(`Fecha de conexión: ${_vm.approvedSince}`)+" ")]),(_vm.channel.alias)?_c('p',{staticClass:"regular-14 text-align-left mrg-bottom-0"},[_vm._v(" "+_vm._s(`Alias: ${_vm.channel.alias || 'Ninguno'}`)+" ")]):_vm._e()])],1)],2)],1),(
      _vm.channel.active === true &&
      (_vm.channel.channel === 'messenger' || _vm.channel.channel == 'instagram')
    )?_c('modal-simple-channel',{attrs:{"visible":_vm.modalPageSelected.visible,"title":`Página conectada a tu canal - ${_vm.channel.channel}`,"picture":_vm.channel.broadcast.picture,"name":_vm.channel.broadcast.name,"ok-loading":_vm.modalPageSelected.loadingUnlink,"ok-disabled":_vm.modalPageSelected.disabledUnlink,"on-disconnect":_vm.onGuideFacebookUnlink},on:{"onClose":_vm.handleCloseModalPageSelected}}):_vm._e(),(_vm.channel.active === true && _vm.channel.channel === 'sms')?_c('modal-simple-channel',{attrs:{"visible":_vm.modalSimpleChannel.visible,"title":_vm.modalSimpleChannel.title,"social-icon":"sms","name":_vm.modalSimpleChannel.title,"ok-loading":_vm.modalSimpleChannel.okLoading,"ok-disabled":_vm.modalSimpleChannel.okDisabled,"on-disconnect":_vm.handleShowModalConfirmation},on:{"onClose":_vm.handleCloseModalSimpleChannel}}):_vm._e(),_c('modal-confirmation',{attrs:{"visible":_vm.modalConfirmation.visible,"colorIcon":_vm.red_6,"typeIcon":"delete","title":`¿Estás segura de desvincular tu canal de SMS?`,"isList":true,"descriptions":_vm.modalConfirmation.descriptions,"subTitle":"Recuerda que se tomarán las siguientes acciones:","strongWord":"Desvincular SMS","nameButton":"Desvincular","typeButton":"danger","loadingButton":_vm.modalConfirmation.loading},on:{"onChangeVisible":function($event){_vm.modalConfirmation.visible = !_vm.modalConfirmation.visible},"actionExecuted":_vm.handleDeleteChannelSms}}),(_vm.hasModalInfo && !!Object.keys(_vm.dataModalInfoList).length)?_c('ModalInfoList',{attrs:{"data":_vm.dataModalInfoList,"enumerated":_vm.enumetaredModalInfoList},on:{"handleClose":_vm.closeModalInfo,"handleOk":_vm.okModalInfo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }