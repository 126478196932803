<template>
  <div class="content-title">
    <p :class="`${tipography} mrg-bottom-0`">{{ title }}</p>
    <div class="underline"></div>
  </div>
</template>

<script>
export default {
  name: 'UnderlineTitle',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Ticker title',
    },
    tipography: {
      type: String,
      required: false,
      default: 'heading-h6',
    },
  },
}
</script>

<style lang="sass" scoped>
.content-title
  text-align: left
  color: $gray_dark_900
.underline
  width: 100%
  height: 1px
  background-color: $gray_7
  margin-top: 8px
</style>
