// import Vue from 'vue'
import initialState from './initialState'
import router from '@/router'
import sort from '@/app/shared/utils/sort'
import Vue from 'vue'
import filters from '@/app/shared/utils/filters'

const mutations = {
  /** RESET MODULE */
  RESET_TICKET_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },

  /**
   * Setear tickets
   * @param {*} state
   * @param {Object} args
   * @param {String} args.status // Estado de los tickets para insertar en la lista adecuada
   * @param {Array} args.tickets // Arreglo de tickets
   */
  SET_TICKETS(state, { status, tickets }) {
    if (status === 'active') state.tickets = tickets
    else if (status === 'ended') state.ticketsEnded = tickets
  },

  /**
   * Agrega un ticket a la lista de tickets actualizando el abstract y sumando como pendiente siempre y cuando no sea una nota
   * @param {*} state
   * @param {Object} ticket Objeto de un ticket
   */
  ADD_TICKET(state, ticket) {
    if (!ticket) return
    if (ticket.messages && ticket.messages.length > 0) {
      ticket.messages = sort.sortMessages({ messages: ticket.messages })
      ticket.abstract = [...ticket.messages].pop()
    }
    if (!ticket.external)
      ticket.pendings = !ticket.pendings ? 1 : ticket.pendings
    if (ticket.inputEnabled === undefined) ticket.inputEnabled = true

    ticket.pagination_messages = { page: 1 }
    state.tickets.push({ ...ticket })
  },
  /**
   * Agrega tickets
   * @param {vuex} state
   * @param {Object} args
   */
  ADD_TICKETS(state, { tickets, status }) {
    if (status === 'active') {
      const idTickets = state.tickets.map((ticket) => ticket._id)
      state.tickets = state.tickets.concat(
        tickets.filter((newTicket) => !idTickets.includes(newTicket._id))
      )
    } else if (status === 'ended') {
      const idTickets = state.ticketsEnded.map((ticket) => ticket._id)
      state.ticketsEnded = state.ticketsEnded.concat(
        tickets.filter((newTicket) => !idTickets.includes(newTicket._id))
      )
    } else if (status === 'old') {
      const idTickets = state.oldTickets.map((ticket) => ticket._id)
      state.oldTickets = state.oldTickets.concat(
        tickets.filter((newTicket) => !idTickets.includes(newTicket._id))
      )
    }
  },
  /**
   * Agrega un ticket a la lista de tickets tal cual como el objeto que recibe
   * @param {*} state
   * @param {Object} ticket Objeto de un ticket
   */
  INSERT_TICKET(state, ticket) {
    state.tickets.push(ticket)
  },
  /**
   * Quita un ticket de la lista de tickets activos mediante su id
   * @param {*} state
   * @param {String} idTicket Id de un ticket activo
   */
  QUIT_TICKET(state, idTicket) {
    for (let i = 0; i < state.tickets.length; i++) {
      if (state.tickets[i]._id === idTicket) {
        state.tickets.splice(i, 1)
      }
    }
  },
  /**
   * Habilita o deshabilita el chat input de un ticket
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.idTicket
   * @param {String}  args.ticketStatus [active,ended,old]
   * @param {Boolean} args.inputStatus
   */
  SET_STATUS_CHAT_INPUT(state, { idTicket, ticketStatus, inputStatus }) {
    let ticket
    if (ticketStatus === 'active')
      ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    else if (ticketStatus === 'ended')
      ticket = state.ticketsEnded.find((ticket) => ticket._id === idTicket)
    else if (ticketStatus === 'old')
      ticket = state.oldTickets.find((ticket) => ticket._id === idTicket)
    else throw `inputStatus ${inputStatus} not supported`
    ticket.inputEnabled = inputStatus
  },

  /**
   * Inserta el placeholder del input de un ticket
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.idTicket
   * @param {String}  args.ticketStatus [active,ended,old]
   * @param {Boolean} args.placeholder
   */
  SET_PLACEHOLDER_CHAT_INPUT(state, { idTicket, ticketStatus, placeholder }) {
    let ticket
    if (ticketStatus === 'active')
      ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    else if (ticketStatus === 'ended')
      ticket = state.ticketsEnded.find((ticket) => ticket._id === idTicket)
    else if (ticketStatus === 'old')
      ticket = state.oldTickets.find((ticket) => ticket._id === idTicket)
    else throw `ticketStatus ${ticketStatus} not supported`
    ticket.placeholderTextInput = placeholder
  },

  /**
   * Inserta el tooltipTextInput del input de un ticket
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.idTicket
   * @param {String}  args.ticketStatus [active,ended,old]
   * @param {Boolean} args.tooltipTextInput
   */
  SET_TOOLTIP_TEXT(state, { idTicket, ticketStatus, tooltipTextInput }) {
    let ticket
    if (ticketStatus === 'active')
      ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    else if (ticketStatus === 'ended')
      ticket = state.ticketsEnded.find((ticket) => ticket._id === idTicket)
    else if (ticketStatus === 'old')
      ticket = state.oldTickets.find((ticket) => ticket._id === idTicket)
    else throw `ticketStatus ${ticketStatus} not supported`
    ticket.tooltipTextInput = tooltipTextInput
  },

  /**
   * Inserta nuevos mensajes al arreglo de los tickets
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.idTicket
   * @param {String}  args.status [active,ended,old]
   * @param {Array}   args.newMessages
   */
  ADD_MESSAGES_IN_TICKET(
    state,
    { idTicket, status, newMessages, ticketToManage }
  ) {
    let ticket
    if (ticketToManage) {
      ticket = ticketToManage
    } else if (status === 'active')
      ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    else if (status === 'ended')
      ticket = state.ticketsEnded.find((ticket) => ticket._id === idTicket)
    else if (status === 'old') {
      ticket = state.oldTickets.find((ticket) => ticket._id === idTicket)
    } else return

    if (!ticket.messages) ticket.messages = []

    let messages = newMessages.concat(ticket.messages)

    // Agrega failureDetails a los mensajes enviados que no tienen
    messages = messages.map((message) => {
      if (message.method === 'sent' && !message.failureDetails)
        message.failureDetails = {}
      return message
    })
    // filtra los mensajes para que se repitan
    messages = filters.filterMessagesNoRepeated({ messages })

    ticket.messages = messages
  },
  /**
   * Setea una paginacion de mensajes a cada ticket
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.idTicket
   * @param {String}  args.status [active,ended,old]
   * @param {Object}   args.pagination
   */
  SET_PAGINATION_MESSAGES_IN_TICKET(
    state,
    { idTicket, status, pagination, ticketToManage }
  ) {
    let ticket
    if (ticketToManage) {
      ticket = ticketToManage
    } else if (status === 'active')
      ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    else if (status === 'ended')
      ticket = state.ticketsEnded.find((ticket) => ticket._id === idTicket)
    else if (status === 'old') {
      ticket = state.oldTickets.find((ticket) => ticket._id === idTicket)
    } else return

    if (!ticket.pagination_messages) ticket.pagination_messages = {}

    ticket.pagination_messages = pagination
  },
  /**
   * Quita un mensaje enviado del ticket
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.ticketId
   * @param {String}  args.apiMessageId
   * @param {String}  args.status [active,ended,old]
   */
  QUIT_MESSAGE_FROM_TICKET(
    state,
    { ticketId, apiMessageId, status = 'active' }
  ) {
    let ticket = null
    if (status === 'active') {
      ticket = state.tickets.find((tk) => tk._id === ticketId)
    } else if (status === 'old') {
      ticket = state.oldTickets.find((tk) => tk._id === ticketId)
    } else if (status === 'ended') {
      ticket = state.ticketsEnded.find((tk) => tk._id === ticketId)
    } else {
      throw `status ${status} not supported`
    }
    ticket.messages = ticket.messages.filter(
      (msg) => msg.apiMessageId !== apiMessageId
    )
  },
  /**
   * Setea el estado a un mensaje
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idTicket
   * @param {String} args.apiMessageId
   * @param {String} args.status [queued,sent,delivered,read]
   * @param {Object} args.failureDetails
   * @param {String} args.failureDetails.message
   * @param {Number} args.statusPriority
   */
  SET_MESSAGE_STATUS(
    state,
    { idTicket, apiMessageId, status, failureDetails, statusPriority }
  ) {
    const ticket = state.tickets.find((tk) => tk._id === idTicket)
    const message = ticket.messages.find(
      (msg) => msg.apiMessageId === apiMessageId
    )
    // verifica si el nuevo estado es de mayor prioridad que el anterior
    if (statusPriority < message.statusPriority) return

    message.status = status
    message.statusPriority = statusPriority
    if (!message.tracking) message.tracking = []
    message.tracking.push({ status, created_at: new Date() })
    if (status === 'deleted') {
      message.message = { text: 'Se eliminó este mensaje', deleted: true }
    }
    if (status === 'failed')
      message.failureDetails = {
        message: failureDetails?.message,
      }
  },

  /**
   * Setea una lista de ids de tickets seleccionados
   * @param {*} state
   * @param {Array} idTickets Arreglo de Id de tickets que se ha seleccionado
   */
  SET_TICKETSID_SELECTED(state, idTickets) {
    state.ticketsIdSelected = idTickets
  },
  /**
   * Agregar el Id de un ticket a la lista de Ids de tickets seleccionados
   * @param {*} state
   * @param {String} idTicket Id del ticket que se ha seleccionado
   */
  ADD_TICKETID_SELECTED(state, idTicket) {
    const exits = state.ticketsIdSelected.some(
      (ticketIdSelected) => ticketIdSelected === idTicket
    )
    if (exits) return
    state.ticketsIdSelected.push(idTicket)
  },
  /**
   * Quitar el Id de un ticket de la lista de Ids de tickets seleccionados
   * @param {*} state
   * @param {String} idTicket Id del ticket que se ha seleccionado
   */
  QUIT_TICKETID_SELECTED(state, idTicket) {
    state.ticketsIdSelected = state.ticketsIdSelected.filter(
      (ticketID) => ticketID !== idTicket
    )
  },
  /** Limpia todos los campos de los Ids de los tickets seleccionado */
  CLEAN_TICKETSID_SELECTED(state) {
    state.ticketsIdSelected = []
  },

  /** Setea un mensaje a un ticket */
  ADD_TEXT_MESSAGE_IN_TICKET(
    state,
    {
      idMessage,
      message,
      idTicket,
      apiMessageId,
      apiReceivedAt,
      method,
      channel,
      status,
      type,
      locked,
      user,
      failureDetails,
      tagged,
    }
  ) {
    let messageObject = {
      _id: idMessage,
      apiMessageId,
      apiReceivedAt,
      message,
      channel,
      method,
      status,
      type,
      locked,
      user,
      failureDetails,
      tagged,
    }
    /** Buscar el ticket con el ID correspondiente */
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return

    ticket.messages = ticket.messages || []
    /** Buscar si el mensaje con el ID ya existe, si es así, no lo pinta */
    const messageFound = ticket.messages.find(
      (message) => message.apiMessageId === messageObject.apiMessageId
    )
    if (messageFound) return
    /**	Inserta el mensaje en el arreglo */
    ticket.messages.push(messageObject)
    ticket.lastMessage_at = new Date(apiReceivedAt)
    if (!message.auto) ticket.abstract = messageObject // No agrega al resumen a los mensajes automaticos
    if (method === 'received') ticket.inputEnabled = true

    // si el mensaje pertenece al ticket seleccionado
    if (state.ticketSelected && state.ticketSelected._id === idTicket) {
      state.ticketSelected.lastMessage_at = new Date(apiReceivedAt)
      if (!message.auto) state.ticketSelected.abstract = messageObject // No agrega al resumen a los mensajes automaticos
      if (method === 'received') state.ticketSelected.inputEnabled = true
      if (!state.ticketSelected.messages) state.ticketSelected.messages = []
      state.ticketSelected.messages.push(messageObject)
    }
  },
  /**
   * Reemplaza el apiMessageId temporal y el apiReceivedAt
   * @param {*} state
   * @param {Object} args
   * @param {String} args.ticketId
   * @param {String} args.tempMessageId
   * @param {String} args.apiMessageId
   * @param {Date} args.apiReceivedAt
   */
  REPLACE_TEMP_MESSAGEID(
    state,
    { ticketId, tempMessageId, apiMessageId, apiReceivedAt }
  ) {
    let ticket = null
    // si ticketSelected existe y el id del ticket a reemplazar es el mismo
    if (state.ticketSelected && state.ticketSelected._id === ticketId)
      ticket = state.ticketSelected
    // si no hay ticketSelected o el id del ticket actual no es el mismo
    // buscará el ticket en todos los tickets
    else ticket = state.tickets.find((tck) => tck._id === ticketId)

    const message = ticket.messages.find(
      (msg) => msg.apiMessageId === tempMessageId
    )

    if (message) {
      message.apiMessageId = apiMessageId
      message.apiReceivedAt = apiReceivedAt
    }
  },
  /**
   * Reemplaza el apiReceivedAt
   * @param {*} state
   * @param {Object} args
   * @param {String} args.ticketId Ticket a buscar
   * @param {String} args.apiMessageId Mensaje del ticket a buscar
   * @param {Date} args.apiReceivedAt Fecha de recepción a modificar
   */
  REPLACE_TICKET_MESSAGEID(state, { ticketId, apiMessageId, apiReceivedAt }) {
    const ticket = state.tickets.find((tck) => tck._id === ticketId)
    if (!ticket) return
    const message = ticket.messages.find(
      (msg) => msg.apiMessageId === apiMessageId
    )
    if (message) {
      message.apiReceivedAt = apiReceivedAt
    }
  },
  /**
   * Setea los id de los tickets sin leer
   * @param {*} state
   * @param {String[]} ticketsIdUnread
   */
  SET_TICKETS_UNREAD(state, ticketsIdUnread) {
    state.ticketsIdUnread = ticketsIdUnread
  },
  /**
   * Setea el id de un ticket en la lista de tickets NO leidas
   * @param {*} state
   * @param {String} idTicket Id de un ticket activo
   */
  ADD_TICKET_UNREAD(state, idTicket) {
    if (state.ticketsIdUnread.includes(idTicket)) return // No se inserta si este ticket ya se encuentra en la lista
    state.ticketsIdUnread.push(idTicket)
  },
  /**
   * Setea 0 en mensajes pendientes de un ticket
   * @param {*} state
   * @param {String} ticketId Id de un ticket activo
   */
  CLEAR_PENDINGS(state, ticketId) {
    const ticket = state.tickets.find((ticket) => ticket._id === ticketId)
    if (!ticket) return
    ticket.pendings = 0
  },
  /**
   * Quitar id de una lista de tickets NO leidas
   * @param {*} state
   * @param {String} idTicket Id de un ticket activo
   */
  QUIT_TICKET_UNREAD(state, idTicket) {
    for (let i = 0; i < state.ticketsIdUnread.length; i++) {
      if (state.ticketsIdUnread[i] === idTicket) {
        state.ticketsIdUnread.splice(i, 1)
      }
    }
  },
  /** Setear objeto del ticket seleccionada */
  SET_SELECTED_TICKET(state, ticket) {
    state.ticketSelected = ticket
  },
  /**
   * Setea el nombre del cliente en los tickets que se encuentre
   * @param {*} state
   * @param {Object} args
   * @param {String} args.clientId Id del cliente
   * @param {String} args.clientNames Nombre actualizado del cliente
   */
  SET_CLIENT_NAME_IN_TICKETS(state, { clientId, clientNames }) {
    if (!clientNames) return
    state.tickets.forEach((ticket) => {
      if (ticket.client.clientId === clientId) ticket.client.names = clientNames
    })
    state.ticketsEnded.forEach((ticket) => {
      if (ticket.client.clientId === clientId) ticket.client.names = clientNames
    })
  },
  /**
   * Setea el ticket como favorito o no
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Boolean} args.starred ¿Es favorito?
   */
  SET_STARRED_IN_TICKET(state, { idTicket, starred }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    ticket.settings.starred = starred
  },
  /**
   * Setea cantidad de mensajes nuevos sin leer a un ticket
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Number} args.quantity Cantidad de mensajes sin leer
   */
  SET_UNREAD_IN_TICKET(state, { idTicket, quantity = 0 }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    ticket.pendings = quantity
  },
  /**
   * Suma mensajes pendientes sin leer en un ticket siempre y cuando la sala no ha sido seleccionado, a menos q sea admin
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {String} args.userType tipo de usuario
   * @param {String} args.method Método del mensaje
   */
  SUM_CHAT_PENDINGS_IN_TICKET(state, { idTicket, method }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    /** Comprobar si se ha seleccionado algun ticket  */
    if (!state.ticketSelected) {
      /** Agregar como un mensaje mas no leido del chat */
      if (method === 'received') ticket.pendings++
    } else if (state.ticketSelected._id !== idTicket) {
      /** Comprobar el mensaje entrante pertenece al ticket seleccionado */
      /** Agregar como un mensaje mas no leido del chat */
      if (method === 'received') ticket.pendings++
    } else if (router.currentRoute.meta.is_workspace) {
      /** Y si lo fue, mientras sea admin, se le puede sumar pendientes */
      if (method === 'received') ticket.pendings++
    }
  },
  /**
   * Setea el nuevo buzon a un ticket
   * @param {vuex}    state
   * @param {Object}  args
   * @param {String}  args.idTicket Id del ticket a mover
   * @param {Object}  args.mailbox  Objeto del Buzon destino
   * @param {Boolean} args.end  Marca al ticket como finalizado e ineditable
   */
  SET_MAILBOX_IN_TICKET(state, { mailbox, idTicket, end }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    if (end === true) {
      ticket.status = 'ended'
      ticket.mailboxId = mailbox.idMailbox
      ticket.mailboxName = mailbox.title
      state.ticketsEnded.push(ticket)
      state.tickets = state.tickets.filter((ticket) => ticket._id !== idTicket)
    } else {
      ticket.mailboxId = mailbox.idMailbox
      ticket.mailboxName = mailbox.title
    }
  },
  /**
   * Setea nombre del buzón correspondiente en cada ticket (mailboxName)
   * @param {*} state
   * @param {Array} mailboxes Buzones de la empresa
   */
  UPDATE_MAILBOXNAMES_IN_TICKETS(state, mailboxes) {
    state.tickets.forEach((ticket) => {
      const mailbox = mailboxes.find((mailbox) => {
        if (ticket.mailboxId === mailbox.idMailbox) return mailbox
      })
      if (!mailbox) return
      ticket.mailboxName = mailbox.title
    })
  },
  /**
   * Setea nombre del buzón correspondiente en cada ticket finalizado(mailboxName)
   * @param {*} state
   * @param {Array} mailboxes Buzones de la empresa
   */
  UPDATE_MAILBOXNAMES_IN_TICKETS_ENDED(state, mailboxes) {
    state.ticketsEnded.forEach((ticket) => {
      const mailbox = mailboxes.find((mailbox) => {
        if (ticket.mailboxId === mailbox.idMailbox) return mailbox
      })
      if (!mailbox) return
      ticket.mailboxName = mailbox.title
    })
  },
  /**
   * Setea que el ticket tiene una agenda pendiente o no
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Boolean} args.hasSchedule El ticket tiene alguna agenda activa?
   */
  SET_SCHEDULE_IN_TICKET(state, { idTicket, hasSchedule }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    ticket.settings.schedules = hasSchedule
    // state.ticketSelected = ticket
  },
  /**
   * Setear tickets finali9zados de un cliente
   * @param {*} state
   * @param {Array} tickets // Arreglo de tickets
   */
  SET_OLD_TICKETS(state, tickets) {
    state.oldTickets = tickets
  },
  /**
   * Setea el ticket antiguo seleccionado de un cliente
   * @param {*} state
   * @param {Object} ticket Objeto del ticket antiguo del cliente
   */
  SET_OLD_TICKET_SELECTED(state, ticket) {
    state.oldTicketSelected = ticket
  },
  /**
   * Actualiza las etiquetas asignadas a un ticket
   * @param {*} state
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket
   * @param {Array} args.tags Arreglo de etiquetas a setear en un ticket
   */
  SET_TAG_TICKETS(state, { idTicket, tags }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    ticket.settings.tags = sort.sortTags({ tags })
  },
  /**
   * Quita todos los tiques de una cola
   * @param {*}       state
   * @param {String}  idLine id de la Cola
   */
  QUIT_TICKETS_IN_LINE(state, idLine) {
    state.tickets = state.tickets.filter(
      (ticket) => ticket.company.lineId !== idLine
    )
    state.ticketsEnded = state.ticketsEnded.filter(
      (ticket) => ticket.company.lineId !== idLine
    )
  },
  /**
   * Quita todos los tickets con el canal eliminado de una cola
   * @param {*}       state
   * @param {String}  idLine id de la Cola
   * @param {String}  channel canal
   */
  QUIT_TICKETS_IN_LINE_CHANNEL(state, { lineId, channel }) {
    state.tickets = state.tickets.filter((ticket) => {
      return !(
        ticket.company.lineId == lineId && ticket.channel.type == channel
      )
    })
  },
  /** Setear objeto del ticket seleccionada */
  SET_FIELDS_TO_TICKET(state, fields) {
    state.ticketSelected.fields = fields
  },
  /**Setea la respuesta de los agentes asignados */
  SET_AGENTS_ASSIGNED_TO_TICKET(state, agentsAssigned) {
    state.agentsAssignedToTicket = agentsAssigned
  },
  /**
   * Habilitar o deshabilitar el chat input del ticket seleccionado
   * @param {*} state
   * @param {Object} args
   * @param {String} args.ticketId
   * @param {Boolean} args.enable
   */
  ENABLE_CURRENT_INPUT_CHAT(state, { ticketId, enable }) {
    const ticket = state.tickets.find((ticket) => ticket._id === ticketId)
    if (!ticket) return
    ticket.inputEnabled = enable
  },
  /**
   * Setea el mailbox seleccionado
   */
  SET_CURRENT_MAILBOX(state, { mailboxId, end }) {
    state.currentMailbox = { mailboxId, end }
  },
  /**
   * Agregar nota a los mensajes de un ticket
   * @param {Object} note
   * @param {String} note._id
   * @param {String} note.method
   * @param {Object} note.message
   * @param {String} note.message.text
   * @param {String} note.updated_at
   * @param {String} note.created_at
   * @param {String} idTicket - id del ticket al que se le añadira la nota
   * @param {String} mailboxId - id del buzon al que se esta moviendo
   */
  ADD_NOTE_MESSAGE(state, { note, idTicket, mailboxId }) {
    const ticket = state.tickets.find((ticket) => ticket._id === idTicket)
    if (!ticket) return
    if (mailboxId) ticket.mailboxId = mailboxId
    ticket.messages.push(note)
    ticket.abstract = { ...ticket.abstract, ...note }
    ticket.lastMessage = { ...ticket.abstract, ...note }
    ticket.lastMessage_at = note.created_at
    // si el id del ticket es igual al id del ticket seleccionado
    if (state.ticketSelected && state.ticketSelected._id === idTicket) {
      state.ticketSelected.messages.push(note)
      state.ticketSelected.lastMessage = { ...ticket.abstract, ...note }
      state.ticketSelected.lastMessage_at = note.created_at
    }
  },
  /**
   * Agregar un comentario linkeado a un mensaje
   * @param {Object} comment
   * @param {String} comment._id
   * @param {String} comment.messageId
   * @param {String} comment.text
   * @param {String} comment.updated_at
   * @param {String} comment.created_at
   * @param {String} comment.ticketId - id del ticket al que se le añadio el comentario
   * @param {Object} comment.user
   * @param {String} comment.user.avatar
   * @param {String} comment.user.names
   * @param {String} comment.user.surnames
   * @param {String} comment.user.userId
   */
  SET_COMMENT_LINKED(state, comment) {
    const ticket = state.tickets.find(
      (ticket) => ticket._id === comment.ticketId
    )
    if (!ticket) return
    const message = ticket.messages.find(
      (message) => message._id === comment.messageId
    )

    if (!message) return
    message.comment = comment
    message.commentId = comment._id

    // si el id del ticket es igual al id del ticket seleccionado
    // entonces se actualizará su mensaje también
    if (state.ticketSelected && state.ticketSelected._id === comment.ticketId) {
      const messageIndexTicketSelected =
        state.ticketSelected.messages.findIndex(
          (message) => message._id === comment.messageId
        )
      if (messageIndexTicketSelected === -1) return

      const newMessageTicketSelected = {
        ...state.ticketSelected.messages[messageIndexTicketSelected],
        comment,
        commentId: comment._id,
      }

      Vue.set(
        state.ticketSelected.messages,
        messageIndexTicketSelected,
        newMessageTicketSelected
      )
    }
  },
  DELETE_COMMENT_LINKED(state, { messageId, ticketId }) {
    const ticket = state.tickets.find((ticket) => ticket._id === ticketId)
    if (!ticket) return
    const message = ticket.messages.find((message) => message._id === messageId)

    if (!message) return
    message.comment = null
    message.commentId = null

    // si el id del ticket es igual al id del ticket seleccionado
    // entonces se actualizará su mensaje también
    if (state.ticketSelected && state.ticketSelected._id === ticketId) {
      const messageIndexTicketSelected =
        state.ticketSelected.messages.findIndex(
          (message) => message._id === messageId
        )
      if (messageIndexTicketSelected === -1) return

      const newMessageTicketSelected = {
        ...state.ticketSelected.messages[messageIndexTicketSelected],
        comment: null,
        commentId: null,
      }

      Vue.set(
        state.ticketSelected.messages,
        messageIndexTicketSelected,
        newMessageTicketSelected
      )
    }
  },
  /*
   * Setea la paginacion de los tickets
   * @param {*} state
   * @param {Object} pagination_tickets
   * @param {Number} pagination_tickets.total
   * @param {Number} pagination_tickets.page
   */
  SET_PAGINATION_TICKETS(state, pagination_tickets) {
    const currentIdMailbox = state.currentMailbox.mailboxId
    let ticketsFiltered = []

    if (currentIdMailbox) {
      if (!state.currentMailbox.end)
        ticketsFiltered = state.tickets.filter(
          (ticket) => ticket.mailboxId === currentIdMailbox
        )
      else
        ticketsFiltered = state.ticketsEnded.filter(
          (ticket) => ticket.mailboxId === currentIdMailbox
        )
    }

    const lengthTickets = ticketsFiltered.length

    const diffBefore = pagination_tickets.total - lengthTickets
    const diffWithTickets = {
      diffBefore,
      diffAfter: diffBefore,
    }
    state.pagination_tickets = { ...pagination_tickets, diffWithTickets }
  },
  /**
   * Setea el total de tickets asignados
   * @param {*} state
   * @param {Number} total_assigned_tickets
   */
  SET_TOTAL_ASSIGNED_TICKETS(state, total_assigned_tickets) {
    state.total_assigned_tickets = total_assigned_tickets
  },
  /**
   * Descuenta los intentos de envio de mensajes de reconexion
   * en el ticket del id pasado
   * @param {*} state
   * @param {Object} args
   * @param {Number} args.discount - cantidad a descontar
   * @param {String} args.ticketId - id del ticket a descontar
   */
  DISCOUNT_ATTEMPTS_TAGGED_MESSAGE(state, { discount = 1, ticketId }) {
    const ticket = state.tickets.find((ticket) => ticket._id === ticketId)
    if (!ticket) return

    const property = 'attemptsForTaggedMessage'
    if (ticket && ticket[property])
      ticket[property] = ticket[property] - discount
  },
}

export default mutations
