<template>
  <div class="widget-demo">
    <div
      class="widget-demo-content"
      :style="`background-color:${bgColorHeader}`"
    >
      <div class="widget-demo__header">
        <div class="widget-demo__header__profile">
          <img v-if="avatarHeader" class="avatar" :src="avatarHeader" />
          <span :style="`color:${colorHeader}`">{{ titleHeader }}</span>
        </div>
        <div class="widget-demo__header__mark">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.438 11.11H3.563a.188.188 0 00-.188.187v1.406c0 .103.084.188.188.188h16.875a.188.188 0 00.187-.188v-1.406a.188.188 0 00-.188-.188z" fill="#fff"/></svg>
        </div>
      </div>
      <div class="widget-demo__workspace">
        <div class="widget-demo__workspace__chat scrollbar_basic">
          <template>
            <div v-for="(chat, index) in chats" :key="index">
              <bubble-webchat
                :typeMessage="chat.typeMessage"
                :method="chat.method"
                :text="chat.text"
                :time="chat.time"
                :load="chat.load"
              />
            </div>
          </template>
        </div>
        <div class="widget-demo__workspace__footer">
          <div class="footer__input">
            <div
              class="footer__input__chat"
              :class="{ pushDown: !showWaterMark }"
            >
              <div class="footer__input__chat-content">
                <a-icon
                  type="paper-clip"
                  style="
                    font-size: 20px;
                    color: #8c8c8c;
                    transform: rotate(180deg);
                  "
                  class="attach"
                />
                <div class="emulate_input">
                  <p>{{ placeholderInput }}</p>
                </div>
                <div class="emulate_button">
                  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M1.958 2.815a.75.75 0 01.847-.167l15 6.667a.75.75 0 010 1.37l-15 6.667a.75.75 0 01-.976-1.02L4.995 10 1.829 3.669a.75.75 0 01.129-.854zm4.339 7.935l-2.191 4.382L15.653 10 4.106 4.868l2.19 4.382H10a.75.75 0 010 1.5H6.297z"/></svg>
                </div>
              </div>
            </div>
            <div v-show="showWaterMark" class="footer__input__mark">
              <span>{{ makdown }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BubbleWebchat from './bubble/BubbleWebchat.vue'

export default {
  name: 'WidgetDemo',
  components: {
    BubbleWebchat,
  },
  props: {
    avatarHeader: {
      type: String,
      default: null,
      required: false,
    },
    titleHeader: { type: String, required: true },
    bgColorHeader: { type: String, default: '#262A31' },
    colorHeader: { type: String, default: '#FFFFFF' },
    placeholderInput: { type: String, default: 'Escribe aquí...' },
    showWaterMark: { type: Boolean, default: true },
    messageToFinish: { type: String, default: 'No hemos recibido respuesta' },
    welcomeMessage: {
      type: String,
      default:
        'Hola buenos días, un gusto saludarte, cuentanos en qué podemos ayudarte?',
    },
  },
  data: () => ({
    makdown: 'Powered by Securitec',
    chatsDefault: [
      {
        typeMessage: 'text',
        method: 'received',
        text: 'Hola buenos días, un gusto saludarte, cuentanos en qué podemos ayudarte?',
        time: '08:30AM',
        load: false,
      },
      {
        typeMessage: 'text',
        method: 'sent',
        text: 'Hola que tal? cuanto es el costo del plan inicial el 1 creo?',
        time: '08:31AM',
        load: false,
      },
      {
        typeMessage: 'file',
        method: 'received',
        text: 'ARCHIVO.PDF',
        time: '08:32AM',
        load: false,
      },
      {
        typeMessage: 'text',
        method: 'received',
        text: '¡Gracias por charlar con nosotros hoy! Hasta la próxima 🖐',
        time: '08:34AM',
        load: false,
      },
    ],
  }),
  computed: {
    chats() {
      const chats = this.chatsDefault
      chats[0].text = this.welcomeMessage
      !this.welcomeMessage.length
        ? (chats[0].load = true)
        : (chats[0].load = false)
      chats[3].text = this.messageToFinish
      !this.messageToFinish.length
        ? (chats[3].load = true)
        : (chats[3].load = false)
      return this.chatsDefault
    },
  },
}
</script>

<style lang="sass" scoped>
.widget-demo
  width: 100%
  max-width: 100%
  &-content
    color: white
    height: 446px
    width: 100%
    max-width: 100%
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
    border-radius: 10px
    overflow: hidden
  &__header
    padding: 11px 20px
    display: flex
    justify-content: space-between
    align-items: center
    &__profile
      font-weight: 600
      font-size: 16px
      .avatar
        width: 28px
        height: 28px
        border-radius: 100%
        margin: 0 8px 0 0
    &__mark
      color: $blue-2
      font-size: 12px
      font-weight: normal
  &__workspace
    background-color: $gray_1
    height: -webkit-fill-available
    color: #262626
    border-radius: 10px
    overflow: hidden
    &__chat
      padding: 16px 20px 0 20px
      height: 305px
      overflow-y: scroll
    &__footer
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05)
      .footer__input
        &__chat
          margin: 16px 12px 0 12px
          padding: 12px 0 4px 0
          &.pushDown
            margin: 32px 12px 0 12px
          &-content
            display: flex
            justify-content: space-between
            align-items: center
            .emulate_input
              border: 1px solid $gray_5
              padding: 5px 12px
              width: 90%
              text-align: left
              color: $gray_6
              font-size: 14px
              border-radius: 2px
              margin: 0 8px
              p
                margin: 0
            .emulate_button
              display: flex
              justify-content: space-between
              align-items: center
              svg
                width: 24px
                height: 24px
                fill: $gray_7
        &__mark
          color: $gray_5
          font-size: 12px
</style>
