<template>
  <div class="form-continue-message">
    <custom-section-scroll :master-styles="{ height: heightContainer }">
      <a-row
        class="form-content flex-no-wrap"
        :class="{
          'form-content--empty':
            !assigned_templates || !assigned_templates.length,
        }"
        type="flex"
      >
        <template v-if="assigned_templates && assigned_templates.length">
          <div>
            <p class="body-2 mrg-bottom-8">Completa todos los campos</p>
            <a-alert
              v-if="error.visible"
              :message="error.message"
              type="error"
              show-icon
              class="mrg-bottom-8"
            />
            <a-form layout="vertical" :form="form" class="form__container">
              <a-form-item label="Selecciona una plantilla">
                <a-select
                  v-decorator="['template']"
                  :size="inputs_size"
                  @change="handleTemplate"
                  placeholder="Escoge una plantilla"
                >
                  <a-select-option
                    v-for="assigned_template in assigned_templates"
                    :key="assigned_template._id"
                  >
                    {{ assigned_template.friendlyName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <h6
                v-if="templateSelected"
                class="heading-h7"
                style="margin-bottom: 4px"
              >
                Parámetros
              </h6>
              <custom-section-scroll
                v-if="templateSelected"
                :master-styles="{ maxHeight: heightBodyForm }"
              >
                <a-form-item
                  v-for="param in templateSelected.parameters"
                  :key="`param${param.idParameter}`"
                  :label="param.title"
                >
                  <a-input
                    v-model="param.value"
                    :size="inputs_size"
                    @input="replaceParamsBody"
                    placeholder="Escribe aquí..."
                  />
                </a-form-item>
              </custom-section-scroll>
              <a-form-item
                v-if="templateSelected.type === 'MEDIA'"
                label="Archivo adjunto"
              >
                <a-upload-dragger
                  v-decorator="[
                    'fileMessage',
                    {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor sube el archivo',
                        },
                      ],
                    },
                  ]"
                  name="file"
                  :multiple="false"
                  :file-list="fileList"
                  :customRequest="uploadFile"
                  :before-upload="beforeUpload"
                  :remove="handleRemoveFile"
                  :accept="templateSelected.formats.accept"
                >
                  <p>
                    <a-icon type="inbox" class="form__icon-inbox" />
                  </p>
                  <p class="ant-upload-text">Sube aquí tu archivo</p>
                  <p class="ant-upload-hint">
                    <span>
                      Solo se acepta
                      {{ templateSelected.formats.titles.join(' ,') }}.
                    </span>
                    <br />
                    <span>
                      {{ templateSelected.max_size.title }} tamaño máximo.
                    </span>
                    <template
                      v-if="
                        templateSelected &&
                        templateSelected.typeAttachment &&
                        templateSelected.typeAttachment === 'IMAGE'
                      "
                    >
                      <br />
                      <span>Las dimensiones ideales son 500 x 500</span>
                    </template>
                  </p>
                </a-upload-dragger>
              </a-form-item>
            </a-form>
          </div>
          <mobile-wsp
            v-if="!active_breakpoint.is_mobile"
            :message="message"
            :media-type="mediaTypeForPreview"
            :media-url="mediaUrl"
            :file-name="fileName"
            type="small"
            class="form__mobile"
          />
          <preview-message
            v-else
            :text="message"
            :media-type="mediaTypeForPreview"
            :media-url="mediaUrl"
            :file-name="fileName"
          />
        </template>
        <template v-else>
          <p class="body-2 text--center full-width">
            No tienes plantillas asignadas.
          </p>
        </template>
      </a-row>
    </custom-section-scroll>
    <div class="footer">
      <a-button class="mrg-right-8" :size="buttons_size" @click="handleCancel"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        :size="buttons_size"
        @click="prepareTemplate"
        :loading="loadBtnSend"
        :disabled="disabledBtnSend || disabledForAttachment"
        >Enviar</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import MobileWsp from '@/app/strategies/components/MobileWsp'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import PreviewMessage from '@/app/tickets/components/molecules/PreviewMessage'
import { TYPES_TEMPLATES } from '@/app/channels/utils/componentsDataTemplates'

export default {
  name: 'FormContinueMessage',
  components: {
    MobileWsp,
    CustomSectionScroll,
    PreviewMessage,
  },
  data: () => ({
    templateSelected: {
      _id: null,
      attachment: {
        type: 'TEXT', // 'TEXT', 'VIDEO', 'IMAGE', 'DOCUMENT'
        url: null,
        urlFileTemp: null,
      },
      type: 'TEXT', // 'TEXT', 'MEDIA'
    }, // Plantilla seleccionada
    message: null, // Mensaje preparado, con los parametros completos
    loadBtnSend: false, // loading de boton de enviar plantilla
    disabledBtnSend: false,
    error: { message: '', visible: false },
    fileList: [],
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  mounted() {
    this.$nextTick(() => {
      if (this.channelCompanyId)
        this.getAssignedTemplates(this.channelCompanyId)
    })
  },
  mixins: [formatMixin],
  watch: {
    message: {
      handler() {
        this.disabledBtnSend = this.evaluateDisabledBtnSend()
      },
      deep: true,
    },
    channelCompanyId(val) {
      if (val) this.getAssignedTemplates(this.channelCompanyId)
    },
  },
  computed: {
    ...mapGetters([
      'assigned_templates',
      'ticketSelected',
      'active_breakpoint',
      'buttons_size',
      'inputs_size',
    ]),

    /**
     * Id del canal del ticket seleccionado
     * @return {String}
     */
    channelCompanyId() {
      return this.ticketSelected && this.ticketSelected.channelCompanyId
    },
    /**
     * @return {String} altura del cuerpo
     */
    heightBodyForm() {
      return this.active_breakpoint.is_mobile ? 'auto' : '22.4em'
    },
    /**
     * @return {String} altura del cuerpo
     */
    heightContainer() {
      const breakpoint = this.active_breakpoint.breakpoint
      switch (breakpoint) {
        case 'xs':
          return '331px'
        case 'sm':
          return '434px'
        case 'md':
          return '426px'
        default:
          return 'auto'
      }
    },
    /**
     * @return {String} - text, video, document, image
     */
    mediaTypeForPreview() {
      if (this.templateSelected.type === 'TEXT') return 'text'
      return (
        this.templateSelected.typeAttachment &&
        this.templateSelected.typeAttachment.toLowerCase()
      )
    },
    /**
     * @return {String} url del media cargado
     */
    mediaUrl() {
      return this.templateSelected.attachment.url || ''
    },
    /**
     * @return {String} nombre del archivo adjunto
     */
    fileName() {
      return this.templateSelected.type === 'MEDIA' &&
        this.templateSelected.attachment?.caption
        ? this.templateSelected.attachment.caption
        : ''
    },
    /**
     * @return {Boolean} deshabilita el botón si tiene un archivo adjunto
     */
    disabledForAttachment() {
      if (!this.templateSelected._id) return true
      return this.templateSelected.type !== 'MEDIA'
        ? false
        : !(this.fileList.length > 0)
    },
  },
  methods: {
    ...mapActions([
      'sendTemplate',
      'getAssignedTemplates',
      'uploadFileMessage',
    ]),

    /**
     * @return {Boolean}
     */
    evaluateDisabledBtnSend() {
      if (
        this.templateSelected._id &&
        this.templateSelected.parameters.every((param) => param.value)
      )
        return false
      return true
    },
    /**
     * Selecciona una plantilla y se preparan los parametros a completar
     * @param {String} value Valor del id de la plantilla escogida
     */
    handleTemplate(value) {
      this.templateSelected = this.assigned_templates.find(
        (template) => template._id === value
      )
      this.message = this.templateSelected.body

      // Limpiar los parametros de todas las plantillas
      this.assigned_templates.map((template) => {
        template.parameters.map((param) => (param.value = ''))
      })
      // Setea datos de la plantilla selecionada
      this.templateSelected.attachment = {
        url: null,
        type: 'TEXT',
        urlFileTemp: null,
        caption: null,
      }

      const TYPE_TEMPLATE = `TEMPLATE_${this.templateSelected.typeAttachment}`

      this.templateSelected = {
        ...this.templateSelected,
        ...TYPES_TEMPLATES[TYPE_TEMPLATE], // la info pasada aqui, solo servirá para hacer validaciones
      }
      this.handleRemoveFile()
    },
    /** Reemplaza el texto de la plantilla por el mismo mensaje pero con los parametros llenos */
    replaceParamsBody() {
      this.message = this.templateSelected.body
      this.templateSelected.parameters.forEach((param, index) => {
        if (param.value) {
          this.message = this.message.replace(`{{${index + 1}}}`, param.value)
        }
      })
      this.message = this.validCompareTexts(
        this.templateSelected.body,
        this.message
      )
    },
    /**Prepara la plantilla para enviarla */
    async prepareTemplate() {
      this.loadBtnSend = true
      const parameters = this.templateSelected.parameters.map((param) => {
        var rObj = {}
        rObj['default'] = param.value
        return rObj
      })
      const paramsMessage = {
        phoneTo: this.ticketSelected.channel.phoneFrom,
        message: { text: this.message },
        channel: this.ticketSelected.channel.type,
        channelCompanyId: this.ticketSelected.channelCompanyId,
        lineId: this.ticketSelected.company.lineId,
        template: {
          name: this.templateSelected.name,
          parameters: parameters,
          attachment: this.templateSelected.attachment,
          type: this.templateSelected.type,
          _id: this.templateSelected._id,
        },
        newMessage: false, // si es falso quiere decir que es una plantilla para continuar
        ticketId: this.ticketSelected._id,
      }
      const response = await this.sendTemplate(paramsMessage)
      this.loadBtnSend = false

      if (response.success) {
        this.$message.success('Se envio correctamente el mensaje')
        this.emitCloseModal()
        this.resetFields()
      } else {
        this.$message.error('Ocurrió un error')
        this.error.visible = true
        switch (response.result) {
          case 'already-assigned':
            {
              this.error.message = response.ticketNumber
                ? `El número ingresado tiene el ticket #${response.ticketNumber} activo`
                : 'El número ingresado tiene un ticket activo'
            }
            break
          case 'denied':
            this.error.message =
              'La cola seleccionada no tiene permisos para enviar la plantilla'
            break
          default:
            this.error.message =
              'Se produjo un error al tratar de enviar el mensaje'
        }
      }
    },

    //emite una funcion para cerrar el modal del padre
    emitCloseModal() {
      this.$emit('closeModal')
    },
    resetFields() {
      if (this.templateSelected._id) {
        // limpiar parametros
        this.templateSelected.parameters.map((param) => (param.value = ''))
      }
      // limpiar el nombre
      this.form.resetFields()
      this.templateSelected = {
        _id: null,
        attachment: {
          type: 'TEXT', // 'TEXT', 'VIDEO', 'IMAGE', 'DOCUMENT'
          url: null,
          urlFileTemp: null,
        },
        type: 'TEXT', // 'TEXT', 'MEDIA'
      }
      this.message = null
      this.error = { message: '', visible: false }
    },
    // cancelar el modal
    handleCancel() {
      this.emitCloseModal()
      this.resetFields()
    },
    /**
     * Evento de la subida del archivo
     * @param {Object} e - evento
     */
    normFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    /**
     * Subida local
     * @param {Object}   args
     * @param {String}   args.action
     * @param {File}     args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    async uploadFile({ file, onSuccess, onError }) {
      await this.uploadFileMessage({
        file,
        caption: file.name || new Date().getTime(),
      })
        .then((res) => {
          if (!res.success) {
            this.setAlertError(
              res.details ||
                'Ocurrió un error mientras se subía el archivo 😥.',
              true
            )
            this.handleRemoveFile()
            return
          }

          this.templateSelected.attachment = {
            url: res.urlFile.gcp,
            caption: file.name,
            type: this.templateSelected.typeAttachment,
            urlFileTemp: res.urlFile.temp,
          }
          onSuccess(res.urlFile)
          this.fileList = [file]
        })
        .catch((err) => {
          console.error('[uploadFile]', err)
          this.setAlertError(
            'Ocurrió un error mientras se subía el archivo 😥.',
            true
          )
          onError(err)
        })
    },
    /**
     * Verifica si el archivos cumple con las reglas antes de subirlo
     * @param {Object} file
     */
    beforeUpload(file) {
      this.handleRemoveFile()
      this.setAlertError('', false)
      let isAllowed = true

      const extension = file.name.split('.').pop()
      const formats = this.templateSelected.formats.values.map((format) =>
        format.toLowerCase()
      )
      const isValidFormat = formats.includes(extension)
      if (!isValidFormat) {
        this.setAlertError(
          `Solo se pueden subir archivos ${this.templateSelected.formats.titles.join(
            ' ,'
          )}`,
          true
        )
        isAllowed = false
      }
      const isValidSize =
        file.size / 1024 / 1024 <= this.templateSelected.max_size.size
      if (!isValidSize) {
        this.setAlertError(
          `El tamaño máximo permitido es de ${this.templateSelected.max_size.title}`,
          true
        )
        isAllowed = false
      }
      if (!isAllowed)
        setTimeout(() => {
          this.handleRemoveFile()
        }, 1500)
      return isAllowed
    },
    /**
     * Setea datos a a la alerta de error
     */
    setAlertError(message, visible) {
      this.error.message = message
      this.error.visible = visible
    },
    /**
     * Remueve el archivo subido
     */
    handleRemoveFile() {
      this.fileList = []
      this.form.resetFields(['fileMessage'])
      this.templateSelected.attachment.url = null
    },
  },
}
</script>

<style lang="sass" scoped>
.form-continue-message
  height: 100%
  position: relative
  .form-content
    padding: 24px 32px
    min-height: 400px
    @include xs
      flex-direction: column
    @include sm
      flex-direction: column
    @include md
      flex-direction: column
    &--empty
      min-width: 30em
  .footer
    display: flex
    justify-content: flex-end
    flex-direction: row
    padding: 12px
    border-top: 1px solid #E8E8E8
.form__mobile
  margin-left: 50px
.form__container
  width: 26em
  @include xs
    width: 100%
  @include sm
    width: 100%
  @include md
    width: 100%
</style>
