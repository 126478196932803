<template>
  <div class="contact-template">
    <div class="contact-template-content">
      <!-- Cabecera y filtros -->
      <a-row>
        <div class="head">
          <a-row>
            <header-module
              :title="title"
              tipography="heading-h5"
              class="template__header"
              :link="`${vari.WEB_DOC}caracteristicas/contactos/`"
              text-before-link="Administra todos tus clientes o si eres agente, solo los que has gestionado."
              text-after-link="si deseas conocer más."
              textLink="Click aquí"
              positionTooltip="bottom"
            />
          </a-row>
          <a-row>
            <FilterContactModule
              ref="filterContact"
              @onChangeRange="(range) => (drawerDetails.dateRange = range)"
              @onLoading="handleLoadtable"
            />
          </a-row>
          <!-- Opciones masivas -->
          <a-row
            type="flex"
            justify="space-between"
            align="middle"
            class="mrg-top-12"
          >
            <!--Si hay seleccionado contactos-->
            <span v-if="showMassiveOptions">
              <span class="heading-h6">{{ contactsToDelete.length }}</span
              >{{ ' ' }}
              {{
                contactsToDelete.length > 1
                  ? 'contactos seleccionados'
                  : 'contacto seleccionado'
              }}
            </span>
            <!--Si no hay seleccionado contactos-->
            <p v-else class="body-2 mrg-bottom-0">
              Selecciona uno o más contactos para realizar acciones masivas
            </p>
            <!--Eliminar contactos-->
            <a-button
              v-if="showMassiveOptions"
              icon="delete"
              type="danger"
              @click="handleShowModalDelete"
              ghost
              >Eliminar contactos</a-button
            >
          </a-row>
        </div>
      </a-row>
      <!-- Cuerpo y tabla -->
      <a-row>
        <div class="body">
          <TableContactModule
            @handleAction="handleAction"
            @onShowProducts="onShowAssignedProducts"
            @onSelectedRows="handleSetSelectedRows"
            :loading="loadingTable"
          />
        </div>
      </a-row>
    </div>
    <!-- Modales -->
    <DrawerDetailsContact
      v-if="client"
      :visible="drawerDetails.visible"
      :data-source-client="client"
      @hide="closeDetailsModal"
      @update="onUpdateClient"
      :date-range="drawerDetails.dateRange"
    />
    <!--Drawer para productos asignados-->
    <assigned-products-drawer
      :visible="drawerProducts.visible"
      :data-source="drawerProducts.data"
      @onClose="handleCloseProducts"
      :show-tickets="true"
    />
    <!--Modal para eliminar contactos -->
    <modal-type
      v-bind="modalDeleteContacts"
      :title="modalDeleteTitle"
      @onClose="handleCloseModalDelete"
      :onOk="handleDeleteContacts"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vari from '@/app/shared/utils/variables'
import FilterContactModule from '@/app/contacts/components/organisms/FilterContactModule.vue'
import TableContactModule from '@/app/contacts/components/organisms/TableContactModule.vue'
import DrawerDetailsContact from '@/app/contacts/components/organisms/modals/DrawerDetailsContact.vue'
import AssignedProductsDrawer from '@/app/shared/components/drawers/AssignedProductsDrawer'
import ModalType from '@/app/shared/components/modals/ModalType'
import transformMixin from '@/app/shared/mixins/transform'
import HeaderModule from '@/app/shared/components/headers/HeaderModule.vue'

export default {
  name: 'ContactTemplate',
  components: {
    FilterContactModule,
    TableContactModule,
    DrawerDetailsContact,
    AssignedProductsDrawer,
    ModalType,
    HeaderModule,
  },
  data: () => ({
    vari,
    title: 'CONTACTOS',
    visibleModalInfo: false,
    client: null,
    drawerDetails: {
      visible: false,
      dateRange: null,
    },
    loadingTable: false,
    drawerProducts: {
      visible: false,
      load: false,
      data: [],
    },
    contactsToDelete: [],
    modalDeleteContacts: {
      visible: false,
      type: 'delete',
      showCancel: true,
      subTitle: 'Recuerda que si se elimina un contacto pasará lo siguiente:',
      list: [
        'Los tickets activos asociados al contacto se finalizarán.',
        'Después de eliminado el contacto, no podrán ser editados en la sección del perfil dentro del ticket finalizado.',
        'Los datos del contacto solo quedarán como histórico en sus tickets asociados.',
        'Si tu contacto volviese a escribir, este ingresará como nuevo contacto.',
        'Tu contacto ya no se listará en listado de contactos',
      ],
      showValidationWord: true,
      strongWord: 'Eliminar contactos',
    },
  }),
  mixins: [transformMixin],
  computed: {
    /**
     * Muestra las opciones masivas
     * @return {Boolean}
     */
    showMassiveOptions() {
      return this.contactsToDelete && this.contactsToDelete.length > 0
    },
    /**
     * @return {String} titulo del modal de eliminar
     */
    modalDeleteTitle() {
      const lengthContacts = this.contactsToDelete.length
      const gray_10 = '#262626'
      const gray_8 = '#595959'
      return `<span style="color: ${gray_8}">¿Estás ${this.wordSure} de eliminar los <span style="color: ${gray_10}">${lengthContacts} contactos</span> seleccionados?</span>`
    },
  },
  methods: {
    ...mapActions(['getAssignedProductsClient', 'deleteMassiveContacts']),

    /**
     * Click en algún botón de acciones
     * @param {String} typeAction [details]
     * @param {Client} client
     */
    handleAction(typeAction, client) {
      this.client = client
      if (typeAction === 'details') {
        this.openDetailsModal()
      }
    },
    // Abre el drawer de detalles del cliente
    openDetailsModal() {
      this.drawerDetails.visible = true
    },
    onUpdateClient(client) {
      this.client = { ...client }
      this.$refs.filterContact.filter()
    },
    // Cierra el drawer de detalles del cliente
    closeDetailsModal() {
      this.drawerDetails.visible = false
      this.client = null
    },
    /**
     * Mostrar el drawer de productos asignados
     * @param {String} clientId - id del cliente
     */
    async onShowAssignedProducts(clientId) {
      this.drawerProducts.visible = true
      this.drawerProducts.load = true
      const response = await this.getAssignedProductsClient(clientId)
      if (response.success) this.drawerProducts.data = response.result
      else
        this.$message.error(
          response.details ? response.details : 'Ocurrió un error 😥'
        )
      this.drawerProducts.load = false
    },
    /**
     * Cargar la tabla de productos
     * @param {Boolean} loading
     */
    handleLoadtable(loading) {
      this.loadingTable = loading
    },
    // Cierra el drawer de productos
    handleCloseProducts() {
      this.drawerProducts.visible = false
      this.drawerProducts.data = []
    },
    /**
     * Setea los contactos selecccionados
     * @param {Object[]} selectedRows
     * @param {String} selectedRows[]._id
     * @param {String} selectedRows[].avatar
     * @param {String} selectedRows[].email
     * @param {String[]} selectedRows[].channels_companies
     * @param {Object} selectedRows[].phones
     */
    handleSetSelectedRows(selectedRows) {
      this.contactsToDelete = selectedRows
    },
    /**
     * Muestra el modal de eliminar contactos     */
    handleShowModalDelete() {
      if (this.contactsToDelete.length > 0) {
        this.modalDeleteContacts.visible = true
      }
    },
    /**
     * Cierra el modal de eliminar contactos
     */
    handleCloseModalDelete() {
      this.modalDeleteContacts.visible = false
    },
    /**
     * Elimina contactos
     */
    async handleDeleteContacts() {
      const clientsIds = this.contactsToDelete.map((contact) => contact._id)
      this.handleLoadtable(true)
      const response = await this.deleteMassiveContacts(clientsIds)
      this.handleLoadtable(false)
      return response
    },
  },
}
</script>

<style lang="sass" scoped>
$margin: 12px

.contact-template
  &-content
    .head
      text-align: left
      margin: $margin
    .body
      margin: 0 $margin
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
