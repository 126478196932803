import router from '@/router'

const actions = {
  /** Escuchar Menú del Sidebar seleccionado por el agente */
  async listenSetMenuSelected(context) {
    this._vm.$socket.on(
      'client:update:menu:selected',
      ({ userId, menuSelected, startedInMenu_at }) => {
        startedInMenu_at = new Date(startedInMenu_at)
        context.commit('SET_MENU_AGENT_SUPERVISION', {
          userId,
          menuSelected,
          startedInMenu_at,
        })
        context.commit('SET_TICKET_REMOTE_SELECTED', null) // Id del ticket remoto seleccionado del visor
        context.commit('SET_EVENT_REMOTE', {
          userId,
          event: null,
          client: {},
          startedInEvent_at: null,
        }) // Quita el ticket remoto seleccionado de supervision
      }
    )
  },
  /** Escuchar Estado seleccionado por el agente */
  async listenSetStatusAgent(context) {
    this._vm.$socket.on('client:update:user:status', (iodata) => {
      iodata.startedInMenu_at = new Date(iodata.startedInMenu_at)
      context.commit('SET_STATUS_AGENT_SUPERVISION', {
        ...iodata,
        userId: iodata.idUser,
      })
    })
  },
  /**
   * Escuchar evento para actualizar ticket seleccionado
   * @param context
   * @returns {Promise<void>}
   */
  async listenTicketRemoteSelected(context) {
    this._vm.$socket.on('client:update:event', (iodata) => {
      context.commit('SET_TICKETS_PENDINGS', iodata) // Cantidad de tickets no leídos
      context.commit('SET_EVENT_REMOTE', iodata)
      /** Solo se ejecuta cuando está en el visor */
      if (router.currentRoute.meta.is_monitor) {
        if (context.getters.profileRemote?.userId === iodata.userId) {
          context.commit('SET_TICKET_REMOTE_SELECTED', iodata.ticketId) // Id del ticket remoto seleccionado
          context.dispatch('clearPendings', {
            ticketId: iodata.ticketId,
            pendings: true,
          })
        }
      }
    })
  },
  /**
   * Escuchar evento para actualizar ticekts no leidos en lista de supervision
   * @param context
   * @returns {Promise<void>}
   */
  async listenTicketUnread(context) {
    this._vm.$socket.on('client:area:gross:mode:agent', async (iodata) => {
      // Escribiendo para el visor o la tabla
      if (iodata.istyping !== null && iodata.istyping !== undefined) {
        context.dispatch('setTypingRemote', {
          userId: iodata.userId,
          istyping: iodata.istyping,
        })
      }
      // Supervisa si pertenece a la cola
      if (router.history.current.meta.is_monitor) {
        if (
          !context.getters.profileRemote.company.linesId.some(
            (lineId) => lineId === iodata.idArea
          )
        ) {
          return
        }
      }

      // Controla el buzón en el visor
      if (
        router.history.current.meta.is_monitor &&
        iodata.idTicket &&
        iodata.userId === context.getters.profileRemote?.userId
      ) {
        // console.log('[SupervisionThread][listenTicketUnread] iodata',iodata)
        const idTicket = iodata.idTicket
        const mailboxId = iodata.mailboxId
        context.dispatch('addTicketUnread', { idTicket, mailboxId })
      }
      // Controla las cantidades en la tabla de supervision

      const hasPropertyPendings = Object.prototype.hasOwnProperty.call(
        iodata,
        'ticketsPending'
      )
      if (hasPropertyPendings) {
        const userId = iodata.userId
        const ticketsPending = iodata.ticketsPending
        context.commit('SET_TICKETS_PENDINGS', { userId, ticketsPending })
      }
    })
  },
  /**
   * Escuchar evento para setear tickets activos en supervision
   * @param context
   * @returns {Promise<void>}
   */
  async listenSupervisionTicketsActive(context) {
    this._vm.$socket.on(
      'client:supervision:ticketsAssigned:agent',
      ({ ticketsAssigned, userId }) => {
        context.commit('SET_SUPERVISION_ACTIVE_TICKETS', {
          ticketsAssigned,
          userId,
        })
      }
    )
  },
  /**
   * Escuchar evento para setear etiqueta en visor de supervision
   * @param context
   * @returns {Promise<void>}
   */
  async listenSettingsTicket(context) {
    this._vm.$socket.on('client:ticket:settings', (iodata) => {
      // console.log('listenSettingsTicket',iodata)

      const idTicket = iodata.ticketId
      if (iodata.settings.tags !== undefined) {
        const tags = iodata.settings.tags
        context.commit('SET_TAG_TICKETS', { idTicket, tags })
      }
      if (iodata.settings.starred !== undefined) {
        const starred = iodata.settings.starred
        context.commit('SET_STARRED_IN_TICKET', { idTicket, starred })
      }
    })
  },
  /** Escucha el buzón remoto seleccionado por el agente */
  async listenMailboxSelectedThread(context) {
    this._vm.$socket.on('client:mailbox:selected', (iodata) => {
      context.commit('SET_MAILBOX_REMOTE_SELECTED', iodata.mailboxId)
    })
  },
  async listenMailboxPendingsThread(context) {
    this._vm.$socket.on('client:chat:mailbox:pendings', (iodata) => {
      context.commit('SUM_NOTIFICATIONS_MAILBOX', {
        idMailbox: iodata.mailboxId,
        quantity: -1,
      })
    })
  },
}

export default {
  actions,
}
