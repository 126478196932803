<template>
  <div>
    <a-col
      style="width: 100%; height: 100%"
      :xs="24"
      :sm="24"
      :md="12"
      :lg="10"
      :xl="10"
    >
      <div class="card-channel" style="height: 100%">
        <!-- Título -->
        <a-row
          type="flex"
          justify="space-between"
          class="section-icon-channels"
          style="margin-bottom: 8px"
        >
          <div class="section-title">
            <SocialIcon :type="channel.channel" />
            <h5
              class="heading-h5 gray-dark-900"
              style="margin-bottom: 0px; margin-left: 8px"
            >
              {{ channel.name }}
            </h5>
          </div>
          <a-row>
            <a-tag
              v-for="channel_status in channel_statuses"
              :key="channel_status.name"
              :color="channel_status.color"
              style="margin-right: 0px"
            >
              {{ channel_status.name }}
            </a-tag>
          </a-row>
        </a-row>
        <!-- Descripción -->
        <a-row>
          <h6 class="heading-h7 gray-dark-900" style="font-weight: 600">
            {{ channel.subtitle }}
          </h6>
          <p class="body-1 margin-text" style="margin: 0px; height: 40px">
            {{ channel.description }}
          </p>
        </a-row>
        <!-- Botones bajos -->
        <a-row
          type="flex"
          :justify="usedChannels > 0 ? 'space-between' : 'end'"
        >
          <!-- Ejecuta método cuando NO está conectado -->
          <template v-if="!channel.down">
            <span v-if="usedChannels > 0" class="regular-12 gray_7">
              {{ usedChannels }}
              {{ usedChannels > 1 ? 'canales conectados' : 'canal conectado' }}
            </span>
            <!--Si el canal es WhatsApp-->
            <a-col v-if="channel.channel === 'whatsapp' && channel.active">
              <a-row type="flex" justify="end" align="bottom">
                <!--Modal de sandbox-->
                <ModalSandbox
                  v-show="hasSandboxWsp"
                  nameButton="Conectar con la sandbox"
                  :isLink="true"
                  step="one"
                />
                <!--Link de conectar-->
                <p class="body-3 link-text-channels">
                  <!--Cantidad de canales conectados-->
                  <a-tooltip placement="top">
                    <template slot="title" v-if="!isAllowConnectChannel">
                      Ya tienes
                      {{ limitChannel }}
                      {{
                        limitChannel > 1 ? 'canales activos' : 'canal activo'
                      }}
                    </template>
                    <a
                      @click="
                        isAllowConnectChannel &&
                          handleSetDataModalIntegrate({
                            visible: true,
                            channel: 'whatsapp',
                          })
                      "
                      :class="{ 'link-desactive': !isAllowConnectChannel }"
                    >
                      <u>Conectar un número de WhatsApp</u>
                    </a>
                  </a-tooltip>
                </p>
                <!--Modal para conectar whatsapp-->
                <modal-integrate-channel
                  v-if="modal_integrate_channel.channel === 'whatsapp'"
                  :visible="modal_integrate_channel.visible"
                  :channel="modal_integrate_channel.channel"
                  @onClose="
                    handleSetDataModalIntegrate({
                      visible: false,
                      channel: 'whatsapp',
                    })
                  "
                />
              </a-row>
            </a-col>
            <!--Si el canal es SMS-->
            <a-col v-else-if="channel.channel === 'sms' && channel.active">
              <a-row type="flex" justify="end" align="bottom">
                <!--Link de conectar-->
                <p class="body-3 link-text-channels">
                  <!--Cantidad de canales conectados-->
                  <a-tooltip placement="top">
                    <template slot="title" v-if="!isAllowConnectChannel">
                      Ya tienes
                      {{ limitChannel }}
                      {{
                        limitChannel > 1 ? 'canales activos' : 'canal activo'
                      }}
                    </template>
                    <a
                      @click="
                        isAllowConnectChannel &&
                          handleSetDataModalIntegrate({
                            visible: true,
                            channel: 'sms',
                          })
                      "
                      :class="{ 'link-desactive': !isAllowConnectChannel }"
                    >
                      <u>Conectar con SMS</u>
                    </a>
                  </a-tooltip>
                </p>
                <!--Modal para conectar SMS-->
                <modal-integrate-channel
                  v-if="modal_integrate_channel.channel === 'sms'"
                  :visible="modal_integrate_channel.visible"
                  :channel="modal_integrate_channel.channel"
                  @onClose="
                    handleSetDataModalIntegrate({
                      visible: false,
                      channel: 'sms',
                    })
                  "
                />
              </a-row>
            </a-col>
            <!--Si el canal es otro-->
            <div v-else>
              <p
                v-if="channel.channel === 'messenger' && channel.active"
                class="body-3 link-text-channels"
              >
                <a-tooltip placement="top">
                  <template slot="title" v-if="!isAllowConnectChannel">
                    Ya tienes
                    {{ limitChannel }}
                    {{ limitChannel > 1 ? 'canales activos' : 'canal activo' }}
                  </template>
                  <a
                    @click="isAllowConnectChannel ? onGuideFacebookLogin() : ''"
                    :class="{ 'link-desactive': !isAllowConnectChannel }"
                    ><u>Conectar con Messenger</u></a
                  >
                </a-tooltip>
              </p>
              <p
                v-if="channel.channel === 'instagram' && channel.active"
                class="body-3 link-text-channels"
              >
                <a-tooltip placement="top">
                  <template slot="title" v-if="!isAllowConnectChannel">
                    Ya tienes
                    {{ limitChannel }}
                    {{ limitChannel > 1 ? 'canales activos' : 'canal activo' }}
                  </template>
                  <a
                    @click="isAllowConnectChannel && onGuideInstagramLogin()"
                    :class="{ 'link-desactive': !isAllowConnectChannel }"
                    ><u>Conectar con Instagram</u></a
                  >
                </a-tooltip>
              </p>
              <p
                v-if="channel.channel === 'webchat' && channel.active"
                class="body-3 link-text-channels"
              >
                <a-tooltip placement="top">
                  <template slot="title" v-if="!isAllowConnectChannel">
                    Ya tienes
                    {{ limitChannel }}
                    {{ limitChannel > 1 ? 'canales activos' : 'canal activo' }}
                  </template>
                  <router-link
                    :to="
                      isAllowConnectChannel
                        ? {
                            name: 'channel',
                            params: { channel: 'webchat' },
                            query: { create: true },
                          }
                        : {}
                    "
                    :class="{
                      'link-desactive': !isAllowConnectChannel,
                    }"
                    ><u>Conectar un WebChat</u></router-link
                  >
                </a-tooltip>
              </p>
            </div>
          </template>
        </a-row>
      </div>
    </a-col>
    <!--Modal informativo para los canales de Facebook-->
    <ModalInfoList
      v-if="hasModalInfo && !!Object.keys(dataModalInfoList).length"
      :data="dataModalInfoList"
      :enumerated="enumetaredModalInfoList"
      @handleClose="closeModalInfo"
      @handleOk="okModalInfo"
    />
    <!--Modal de selección de página para los canales de Facebook-->
    <ModalSelectPage
      v-if="listPages.length > 1"
      :visible="modalSelectPage.visible"
      :list-pages="listPages"
      :login-type="loginType"
      @onCancel="handleCancelSelected"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import transformWordGender from '@/app/shared/mixins/transform'
import ModalSandbox from '@/app/channels/components/whatsapp/modals/ModalSandbox'
import ModalInfoList from '@/app/channels/components/organisms/modals/ModalInfoList'
import ModalSelectPage from '@/app/channels/components/messenger/ModalSelectPage'
import ModalIntegrateChannel from '@/app/channels/components/organisms/modals/ModalIntegrateChannel.vue'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'CardChannel',
  props: {
    channel: {
      // este canal viene de canales
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    SocialIcon,
    ModalSandbox,
    ModalInfoList,
    ModalSelectPage,
    ModalIntegrateChannel,
  },
  data: () => ({
    visiblePage: false,
    visibleRequest: false,
    modalSelectPage: { visible: false },
    listPages: [], // lista de paginas vinculadas
    responseError: [], // errores de login
    tempResponse: null,
    disabledBtnConect: false,
    loginType: '',
    channelToView: '',
    dataModalInfoList: {}, // Modales informativos para mostrar
    otherFbIntegration: false, // Existe otra conexión de integración con otra aplicación de Facebook?
    stepModal: 1, // Numero del paso de los modales a mostrar
    sandboxNumber: '',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'requestForm' })
  },
  async created() {
    // SE HABILITARÁ PROXIMAMENTE, ES NECESARIO OPTIMIZAR
    // this.intervalAttempt(async () => {
    //   if (!this.channel) throw 'No channel'
    //   if (this.channel.channel === 'whatsapp')
    //     this.sandboxNumber = await getSandboxIdentifier('whatsapp')
    // })
  },
  mixins: [attemptMixin, transformWordGender],
  watch: {
    listPages() {
      // sale el modal de seleccion de páginas si es mayor a una
      if (this.listPages.length > 1) this.modalSelectPage.visible = true
      else {
        // si la página es solo una, entonces la suscripción se hace defrente
        this.subscribePages(this.listPages[0])
      }
      // TODO: Comentado mientras solo sale WhatsApp
      // Suscribe a todas las páginas seleccionadas
      // for (const page of this.listPages) {
      //   this.subscribePages(page)
      // }
    },
  },
  computed: {
    ...mapGetters([
      'profile',
      'modal_integrate_channel',
      'simple_company_channels',
      'spent_channels',
    ]),
    /**
     * @returns {Boolean}
     */
    hasSandboxWsp() {
      return !!this.sandboxNumber
    },
    /**
     * @return {Object} {status_es, fontColor, bgColor, borColor}
     */
    channel_statuses() {
      let statuses = []
      if (this.channel && this.channel.down) {
        // Servicio caído
        statuses.push({
          status_es: `Error de canal`, // Texto en español
          nameColor: 'red', // Nombre del color
        })
      } else statuses = this.channel.tags

      return statuses
    },
    /**
     * @return {Boolean}
     */
    hasModalInfo() {
      return ['instagram', 'messenger'].includes(this.channel.channel)
    },
    /**
     * Lista del modal enumerado? Lo enumera si la lista tiene más de un elemento
     * @return {Boolean}
     */
    enumetaredModalInfoList() {
      return (
        this.dataModalInfoList.list && this.dataModalInfoList.list.length > 1
      )
    },
    /**
     * Permitir conectar canales
     * @return {Boolean}
     */
    isAllowConnectChannel() {
      if (!this.spent_channels) return false
      const spentChannel = this.spent_channels[this.channel.channel]
      return spentChannel ? !spentChannel.isFull : false
    },
    /**
     * @return {Number} - limite del canal
     */
    limitChannel() {
      if (!this.spent_channels) return 0
      const spentChannel = this.spent_channels[this.channel.channel]
      return spentChannel ? spentChannel.limit : 0
    },
    /**
     * @return {Number} - canales usados
     */
    usedChannels() {
      if (!this.spent_channels) return 0
      const spentChannel = this.spent_channels[this.channel.channel]
      return spentChannel ? spentChannel.used : 0
    },
  },
  methods: {
    ...mapActions([
      'msgrMetaLogin',
      'igMetaLogin',
      'fbLongUserAccessToken',
      'fbAccounts',
      'fbSubscribeApps',
      'saveFacebookPage',
      'getInstagramBusiness',
      'setAnalytics',
      'getSimpleCompanyChannels',
    ]),
    ...mapMutations(['SET_MODAL_INTEGRATION_CHANNELS']),

    /**
     * Abre los modales de Facebook para la vinculación
     * @param {Number} [step=1] El número del paso en el que se encuentra de la llamada de modales
     * @return {Boolean} último modal?
     */
    onGuideFacebookLogin(step = 1) {
      this.stepModal = step
      // TODO: Esto se solucionará para cuando los canales de Facebook no dependan entre si
      // TODO: Se mostrará el modal de información cuando exista un canal de instagram conectado
      const isInstagramConnected = false
      // this.simple_company_channels &&
      // this.simple_company_channels.some(
      //   (company_channel) => company_channel.channel === 'instagram'
      // )

      // si la app ya es dependiente este valor se falsea
      const isDependent = true

      this.otherFbIntegration = isInstagramConnected && isDependent
      if (this.otherFbIntegration) {
        switch (this.stepModal) {
          case 1: {
            this.dataModalInfoList = {
              key: 'considerations',
              channel: 'messenger',
              width: '600px',
              title: 'Facebook Messenger',
              okButtonName: 'Conectar mi canal de Facebook Messenger',
              okButtonType: 'primary',
              subtitle:
                '¡Hey! Te dejamos unos consejos para conectar con éxito tu canal de Facebook Messenger',
              list: [
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Messenger es la misma que tienes conectada en el canal de Instagram',
                  description:
                    'Solo necesitaras darle en continuar  a todo el proceso de vinculación',
                },
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Messenger es diferente a la que tienes conectada en el canal de Instagram',
                  description:
                    'No quites el check de la página de Facebook que seleccionaste para conectar tu canal de Instagram, sino removerás esta página de ese canal.',
                },
                {
                  title:
                    'Si tu página de Facebook tiene otra cuenta administradora de Facebook',
                  description:
                    'Para el proceso de vinculación solo selecciona “Iniciar sesión en otra cuenta”, ingresa tus credenciales y selecciona la página que deseas vincular.',
                },
              ],
            }
            break
          }
          default: {
            return true
          }
        }
        // return false
      } else {
        this.onFbLogin()
        return false
      }
    },
    /**
     * Logueo de Facebook, se levanta un modal propio de facebook
     */
    async onFbLogin() {
      this.disabledBtnConect = true
      this.loginType = 'messenger'

      await this.msgrMetaLogin()
        .then(async (response) => {
          await this.fbLongUserAccessToken({
            accessToken: response.authResponse.accessToken,
            channel: this.loginType,
          })
            .then(async (responseToken) => {
              await this.fbAccounts({
                userId: response.authResponse.userID,
                userAccessToken: responseToken.access_token,
              })
                .then(async (responseAccounts) => {
                  if (responseAccounts.data.length) {
                    this.listPages = responseAccounts.data
                  } else {
                    this.listPages = []
                    this.$message.warning(
                      'No existen los permisos suficientes para conectar tu página'
                    )
                  }
                })
                .catch((error) => {
                  this.responseError.push(error)
                  this.$message.warning(
                    'No existen los permisos suficientes para conectar tu página'
                  )
                })
            })
            .catch((error) => {
              console.error('errror', error)
              this.$message.warning(
                'No existen los permisos suficientes para conectar tu página'
              )
            })
        })
        .catch(() => {
          this.$message.error('No logueado')
        })
      this.disabledBtnConect = false
    },
    /**
     * Abre los modales de Instagram
     * @param {Number} [step=1] El número del paso en el que se encuentra de la llamada de modales
     * @return {Boolean} último mnodal?
     */
    onGuideInstagramLogin(step = 1) {
      this.stepModal = step

      const isMessengerConnected =
        this.simple_company_channels &&
        this.simple_company_channels.some(
          (company_channel) => company_channel.channel === 'messenger'
        )

      // si la app ya es dependiente este valor se falsea
      const isDependent = true

      this.otherFbIntegration = isMessengerConnected && isDependent
      if (this.otherFbIntegration) {
        switch (this.stepModal) {
          // Modal de confirmar los requisitos de Instagram
          case 1: {
            this.dataModalInfoList = {
              key: 'considerations',
              width: '650px',
              channel: 'instagram',
              title: 'Instagram para empresas',
              okButtonName: 'Conectar mi canal de Instagram',
              okButtonType: 'primary',
              subtitle:
                'Necesitamos validar la siguiente información para conectar con éxito tu canal de Instagram Business',
              list: [
                {
                  title: '¿Tienes una cuenta comercial de Instagram?',
                  description:
                    'Necesitas una cuenta comercial de Instagram para continuar.',
                  checkbox: 'Tengo una cuenta comercial de Instagram.',
                  info: '¿No es una cuenta comercial? Sigue <a href="https://help.instagram.com/502981923235522?fbclid=IwAR1uXVY0-thX1fFZmEZxebofptZWbsEfswxfBtqn8pUmROQC7VDSiN_30AI" target="_blank" rel="noopener noreferrer" >estos pasos</a> para actualizar tu cuenta. Necesitarás una página de Facebook y acceso de administrador.',
                },
                {
                  title:
                    '¿Está tu cuenta comercial de Instagram vinculada con una página de Facebook?',
                  description:
                    'Para continuar, debes tener acceso de administrador y una página de Facebook vinculada a tu cuenta de Instagram. Esto solo se puede realizar desde la App móvil de Instagram.',
                  checkbox:
                    'Tengo mi cuenta  de Facebook vinculada a mi cuenta comercial de Instagram.',
                  info: '¿No tienes acceso? Ponte en contacto con tu admin de Facebook para proceder. ¿No estás seguro/a de cómo vincular una página de Facebook? <a href="https://help.instagram.com/399237934150902/?helpref=related" target="_blank" rel="noopener noreferrer">Descubre cómo, aquí.</a>',
                },
                {
                  title:
                    'Habilitar "Permitir acceso a los mensajes" en Instagram',
                  description:
                    'Asegúrate de tener activado "Permitir acceso a los mensajes" en tu configuración de Instagram. Solo puede hacerse desde la App móvil de Instagram.',
                  checkbox:
                    'Tengo habilitado "Permitir acceso a los mensajes".',
                  info: `<p>¿Cómo permitir el acceso a los mensajes en Instagram?</p>
              <p style="margin: 4px 0;">✅ Abre la App móvil de Instagram y accede a "Configuración".</p>
              <p style="margin: 4px 0;">✅ Accede a "Privacidad" y selecciona "Mensajes".</p>
              <p>✅ Activa "Permitir acceso a los mensajes" en "Herramientas conectadas".</p>
              <p style="margin: 4px 0;"><i>Configuración>Privacidad>Mensajes</i></p>
              <img style="max-width: 280px;" src="/img/items/switch-access-messges-instagram-min.png"/>
              `,
                },
              ],
            }
            break
          }
          // Modal de consejos de vinculación en la plataforma cuando hay messenger vinculado
          /*case 2: {
            this.dataModalInfoList = {
              key: 'doble_connection',
              channel: 'instagram',
              title: 'Instagram para empresas',
              okButtonName: '¡Conectar Instagram!',
              okButtonType: 'primary',
              subtitle:
                '¡Hey! Te dejamos unos consejos para conectar con éxito tu canal de Instagram',
              list: [
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Instagram es la misma que tienes conectada en el canal de Messenger',
                  description:
                    'Solo necesitas darle continuar a todo el proceso de vinculación, ya que tu página de Facebook estará seleccionada porque conectaste la misma en canal de Messenger anteriormente.',
                },
                {
                  title:
                    'Si tu página de Facebook que quieres conectar para Instagram es diferente a la que tienes conectada en el canal de Messenger',
                  description:
                    'No quites el check de la página de Facebook que seleccionaste para conectar tu canal de Messenger, sino removerás esta página de ese canal.',
                },
                {
                  title:
                    'Si tu página de Instagram tiene otra cuenta administradora de Facebook',
                  description:
                    'Para el proceso de vinculación solo haz click en “Iniciar sesión en otra cuenta”, ingresa tus credenciales y selecciona la página que deseas vincular.',
                },
              ],
            }
            break
          }*/
          default: {
            return true
          }
        }
        return false
      } else {
        this.dataModalInfoList = {
          key: 'considerations',
          width: '650px',
          channel: 'instagram',
          title: 'Instagram para empresas',
          okButtonName: 'Conectar mi canal de Instagram',
          okButtonType: 'primary',
          subtitle:
            'Necesitamos validar la siguiente información para conectar con éxito tu canal de Instagram Business',
          list: [
            {
              title: '¿Tienes una cuenta comercial de Instagram?',
              description:
                'Necesitas una cuenta comercial de Instagram para continuar.',
              checkbox: 'Tengo una cuenta comercial de Instagram.',
              info: '¿No es una cuenta comercial? Sigue <a href="https://help.instagram.com/502981923235522?fbclid=IwAR1uXVY0-thX1fFZmEZxebofptZWbsEfswxfBtqn8pUmROQC7VDSiN_30AI" target="_blank" rel="noopener noreferrer">estos pasos</a> para actualizar tu cuenta. Necesitarás una página de Facebook y acceso de administrador.',
            },
            {
              title:
                '¿Está tu cuenta comercial de Instagram vinculada con una página de Facebook?',
              description:
                'Para continuar, debes tener acceso de administrador y una página de Facebook vinculada a tu cuenta de Instagram. Esto solo se puede realizar desde la App móvil de Instagram.',
              checkbox:
                'Tengo mi cuenta  de Facebook vinculada a mi cuenta comercial de Instagram.',
              info: '¿No tienes acceso? Ponte en contacto con tu admin de Facebook para proceder. ¿No estás seguro/a de cómo vincular una página de Facebook? <a href="https://help.instagram.com/399237934150902/?helpref=related" target="_blank" rel="noopener noreferrer">Descubre cómo, aquí.</a>',
            },
            {
              title: 'Habilitar "Permitir acceso a los mensajes" en Instagram',
              description:
                'Asegúrate de tener activado "Permitir acceso a los mensajes" en tu configuración de Instagram. Solo puede hacerse desde la App móvil de Instagram.',
              checkbox: 'Tengo habilitado "Permitir acceso a los mensajes".',
              info: `<p>¿Cómo permitir el acceso a los mensajes en Instagram?</p>
              <p style="margin: 4px 0;">✅ Abre la App móvil de Instagram y accede a "Configuración".</p>
              <p style="margin: 4px 0;">✅ Accede a "Privacidad" y selecciona "Mensajes".</p>
              <p>✅ Activa "Permitir acceso a los mensajes" en "Herramientas conectadas".</p>
              <p style="margin: 4px 0;"><i>Configuración>Privacidad>Mensajes</i></p>
              <img style="max-width: 280px;" src="/img/items/switch-access-messges-instagram-min.png"/>
              `,
            },
          ],
        }
        return true
      }
    },
    closeModalInfo() {
      this.dataModalInfoList = {}
    },
    /**
     * Evento ok del ModalInfo
     * @param {String} channel
     * @param {String} key
     */
    okModalInfo(channel, key) {
      this.dataModalInfoList = {}
      switch (channel) {
        case 'messenger':
          {
            if (key === 'considerations_unlink') {
              this.unlinkFbPage()
            } else this.onFbLogin()
          }
          break
        case 'instagram':
          {
            if (key === 'considerations_unlink') {
              this.unlinkFbPage()
            } else {
              if (!this.otherFbIntegration) {
                this.onConfirmInstagramLogin()
              } else {
                const lastModal = this.onGuideInstagramLogin(this.stepModal + 1)
                if (lastModal) this.onConfirmInstagramLogin()
              }
            }
          }
          break
      }
    },
    /**
     * Confirma el logeo a instagram
     */
    async onConfirmInstagramLogin() {
      this.disabledBtnConect = true
      this.loginType = 'instagram'
      try {
        const responseLogin = await this.igMetaLogin()
        const responseToken = await this.fbLongUserAccessToken({
          accessToken: responseLogin.authResponse.accessToken,
          channel: this.loginType,
        })
        const responseAccounts = await this.fbAccounts({
          userId: responseLogin.authResponse.userID,
          userAccessToken: responseToken.access_token,
        })
        if (responseAccounts.data.length) {
          this.listPages = responseAccounts.data
        } else {
          this.listPages = []
          this.$message.warning(
            'No existen los permisos suficientes para conectar tu página'
          )
        }
      } catch (error) {
        console.error(error)
        this.$message.warning(
          'No existen los permisos suficientes para conectar tu página'
        )
      }

      this.disabledBtnConect = false
    },
    /**
     * Suscribe la página a Facebook
     * @param {Object} page
     */
    async subscribePages(page) {
      await this.fbSubscribeApps({
        pageId: page.id,
        pageAccessToken: page.access_token,
      })
        .then(async () => {
          await this.savePage(page)
          await this.getSimpleCompanyChannels()
          this.$router.push({ name: 'my-channels' }).catch(() => {})
        })
        .catch((error) => {
          console.error(error)
          this.$message.warning(
            'No existen los permisos suficientes para conectar tu página'
          )
        })
    },
    /**Guarda la página seleccionado en base
     * @param {Object} page
     * @param {String} page.channel
     * @param {String} page.access_token
     * @param {String} page.name
     * @param {Object} page.picture
     * @param {Object} page.picture.data
     * @param {String} page.picture.data.url
     */
    async savePage(page) {
      page.channel = this.loginType
      let pageId = page.id
      let igId = null
      if (page.channel == 'instagram') {
        const pageBusinessResponse = await this.getInstagramBusiness({
          pageId: page.id,
          pageAccessToken: page.access_token,
        })
        if (!pageBusinessResponse.instagram_business_account?.id) {
          this.$confirm({
            title: `No se pudo vincular tu página "${page.name}"`,
            icon: () => (
              <a-icon type="close-circle" style={{ color: '#F5222D' }} />
            ),
            content: `Asegúrate que tu página esté correctamente asociada a tu cuenta de Instagram Business seleccionada.`,
            okText: 'Aceptar',
          })
          return
        }
        igId = pageBusinessResponse.instagram_business_account.id
      }
      const response = await this.saveFacebookPage({
        channel: page.channel,
        idFacebookPage: pageId,
        idBusinessIg: igId,
        pageAccessToken: page.access_token,
        name: page.name,
        picture: page.picture.data.url,
      })

      if (response.data?.result === 'credentials-saved') {
        this.$message.success(`Página "${page.name}" vinculada correctamente`)
      } else {
        this.$message.error(
          response.details ||
            `La pagina "${page.name}" ya se encuentra conectada.`
        )
      }
    },
    // cancela la pagina de seleccionar paginas
    handleCancelSelected() {
      this.modalSelectPage.visible = false
    },
    /**
     * Setea como visible el modal de integracion de canal
     * @param {Boolean} visible
     * @param {String} channel
     */
    handleSetDataModalIntegrate({ visible, channel }) {
      this.SET_MODAL_INTEGRATION_CHANNELS({
        visible,
        channel: !visible ? '' : channel,
      })
      // solo si es whatsapp enviará un evento a analytics
      if (!visible && channel === 'whatsapp') {
        this.setAnalytics({ eventName: 'modal_wab_request_close' })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.gutter-row
  width: 50%

.gray_7
  color: $gray_7
.card-channel
  background-color: $white_000
  padding: 20px 20px 10px
  border-radius: 8px
  height: auto
  text-align: left
  border: 1px solid $gray_5
  &:hover
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
    border-radius: 8px
    border-color: $gray_4
  .section-icon-channels
    .section-title
      display: flex
      flex-direction: row
  .gray-color-700
    color: $gray_dark_700
  .gray-color-300
    color: $gray_dark_300
  .gray-dark-900
    color: $gray_dark_900
  .link-desactive
    color: $gray_dark_300
    cursor: no-drop
  .button-cards-channels
    text-align: right
  .link-text-channels
    text-align: right
    font-size: 12px
    line-height: 20px
    margin: 0px
    position: relative
    margin-left: 8px
.connected-page
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  border: 1px solid $gray_dark_200
  border-radius: 8px
  width: 256px
  padding: 8px 12px
  img
    margin-right: 12px
  &-name
    margin-bottom: 0
    color: $gray_dark_900
  &-status
    display: flex
    flex-direction: row
    align-items: center
    p
      margin-bottom: 0
      margin-right: 4px
      color: $gray_dark_800
</style>

<style lang="sass" scoped></style>
