<template>
  <div class="section-time-answer">
    <div class="header">
      <p class="title heading-h6">Tiempo promedio de respuesta de un ticket</p>
      <div>
        <a-tooltip placement="bottomRight" :arrowPointAtCenter="true">
          <template slot="title">
            Visualiza el tiempo promedio de respuesta a un ticket por cola. Se
            mostrarán según el rango de fecha y hora que decidas en el filtro.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!-- pop confirm con icon-->
        <a-popconfirm
          v-if="visibleConfirm"
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="confirm"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template v-if="!isLoading" slot="title">
              Descargar en excel
            </template>
            <a-icon
              type="download"
              class="section__icon--download"
              :class="{
                'btn-dowload': !isLoading,
                'icon--disabled': isLoading,
              }"
            />
          </a-tooltip>
        </a-popconfirm>
        <!--popover con icon-->
        <a-popover
          v-if="visiblePopOver"
          placement="bottomRight"
          :arrowPointAtCenter="true"
          :visible="visiblePopOver"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <template slot="content">
            <p style="text-align: center" class="gray-dark-900">
              {{ textProgress }}
            </p>
            <a-progress :percent="percent" :status="status" />
            <div v-if="this.status === 'exception'" class="contain-btns">
              <a-button type="link" size="small" @click="handleCancel"
                >Cancelar</a-button
              >
              <a-button type="link" size="small" @click="handleOk"
                >Volver a intentar</a-button
              >
            </div>
          </template>
          <a-icon
            type="download"
            class="btn-dowload section__icon--download"
            :class="{
              'btn-focus': visiblePopOver && !isLoading,
              'icon--disabled': isLoading,
            }"
          />
        </a-popover>
      </div>
    </div>
    <div
      v-if="timeAnswer.lines.length >= 1"
      class="body"
      :class="{ 'flex-center': timeAnswer.lines.length === 1 }"
    >
      <BubbleChart
        v-if="timeAnswer && timeAnswer.lines.length > 1"
        :styles="myStyles"
        :chartdata="chartdata"
      />
      <CardTimeAnswer
        v-else
        :nameline="timeAnswer.lines[0].name"
        :time="timeAnswer.lines[0].timeFormatted"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BubbleChart from '@/app/dashboard/components/organisms/charts/BubbleChart.vue'
import CardTimeAnswer from '@/app/dashboard/components/organisms/cards/CardTimeAnswer.vue'
import moment from 'moment'

export default {
  name: 'SectionTimeAnswer',
  components: {
    BubbleChart,
    CardTimeAnswer,
  },
  props: {
    date: { type: Array, required: true },
    lineId: { type: String, default: 'allLines' },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '302px',
      margin: '50px 12px 20px 12px ',
      position: 'relative',
    },
    visibleConfirm: true,
    visiblePopOver: false,
    percent: 70, // porcentaje del progress
    status: 'active', // status del progress
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
  }),
  computed: {
    ...mapGetters(['timeAnswer', 'token']),
    /**
     * @return {Object}
     */
    chartdata() {
      const data = {
        labels: this.timeAnswer.lines.map((item) => item.name),
        datasets: [
          {
            label: 'Tiempo promedio',
            labelText: 'Tiempo promedio',
            borderWidth: 2,
            backgroundColor: 'transparent',
            borderColor: '#F5D312',
            pointBackgroundColor: '#F5D312',
            pointHoverRadius: 8,
            pointRadius: 6,
            showLine: false, // no line shown
            data: this.timeAnswer.lines.map((item) => item.time),
          },
        ],
      }
      return data
    },
    /**
     * @return {String}
     */
    textProgress() {
      switch (this.status) {
        case 'exception':
          return 'Lo sentimos, ocurrió un error'
        case 'active':
          return 'Descargando datos del dashboard...'
        case 'success':
          return 'Datos descargados'
        default:
          return null
      }
    },
  },
  methods: {
    ...mapActions(['exportAverageAnswer', 'setAnalytics']),
    // confirmar pop confirm
    confirm() {
      this.visibleConfirm = false
      this.visiblePopOver = true
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'time_answer_tickets' },
      })
    },
    // cancela la descarga
    handleCancel() {
      this.visiblePopOver = false
      this.visibleConfirm = true
    },
    // vuelve a descargar
    handleOk() {
      this.visiblePopOver = true
      this.exportDashboard()
    },
    exportDashboard() {
      this.percent = 70
      this.status = 'active'
      const date = `${moment(this.date[0]).toISOString()}/${moment(
        this.date[1]
      ).toISOString()}`
      const lineId = this.lineId
      this.exportAverageAnswer({ date, lineId })
      setTimeout(() => {
        this.percent = 100
        this.status = 'success'
      }, 2000)
      setTimeout(() => {
        this.visiblePopOver = false
        this.visibleConfirm = true
      }, 3000)
    },
  },
}
</script>

<style lang="sass" scoped>
.flex-center
  display: flex
  justify-content: center
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-time-answer
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    // margin-bottom: 12px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
  .body
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.section__icon--download
  font-size: 20px
  color: #595959
  margin-right: 8px
</style>
