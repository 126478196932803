<template>
  <div class="button-whatsapp regular-14">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonWhatsApp',
}
</script>

<style lang="sass" scoped>
.button-whatsapp
  background-color: $gray_1
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))
  color: $gray_6
  border: 1px solid $gray_5
  border-radius: 6px
  padding: 1px 12px
  text-align: center
</style>
