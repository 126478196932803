var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"text--left",attrs:{"form":_vm.form,"layout":"vertical"}},[_c('router-link',{attrs:{"to":{ name: 'campaigns' }}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Regresar a campañas ")],1),_c('h4',{staticClass:"semibold-20 mrg-bottom-8 mrg-top-12"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"regular-14",class:{
      form__description: !_vm.error.visible,
      'mrg-bottom-0': _vm.error.visible,
    }},[_vm._v(" Completa todos los campos e importa tu campaña. Recuerda enviar plantillas a contactos que han "),_c('strong',[_vm._v(" aceptado recibir información ")]),_vm._v(" de tu negocio. Si tienes dudas de como hacerlo "),_c('anchor',{attrs:{"href":`${_vm.vari.WEB_DOC}campanas/campana-masiva-whatsapp/`}},[_vm._v(" revisa nuestros docs")]),_vm._v(". ")],1),(_vm.error.visible)?_c('a-alert',{staticClass:"form__alert",attrs:{"type":"error","message":_vm.error.message,"show-icon":""}}):_vm._e(),(_vm.currentStep === 0)?[_vm._v(" "+_vm._s(_vm.validateNewValuesInDom())+" "),_c('a-row',[_c('a-form-item',{attrs:{"label":"Nombre de campaña"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Escribe aquí..."}})],1)],1),_c('a-row',[_c('a-form-item',{attrs:{"label":"Número"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'channelCompanyId',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'channelCompanyId',\n            {\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona un número"},on:{"change":_vm.handleChannelCompany}},_vm._l((_vm.filterSimpleChannelWhatsAppByType({
              type: 'gupshup',
            })),function(channel){return _c('a-select-option',{key:channel._id},[_vm._v(" "+_vm._s(channel.title)+" ( "+_vm._s(channel.alias ? channel.alias : 'Sin alias')+" ) ")])}),1)],1)],1),_c('a-row',[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Cola de destino  "),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_vm._v(" Los tickets respondidos serán asignados a esta cola. ")]),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'lineId',
            {
              initialValue: !_vm.linesFilteredByChannel.length
                ? undefined
                : _vm.linesFilteredByChannel[0]._id,
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'lineId',\n            {\n              initialValue: !linesFilteredByChannel.length\n                ? undefined\n                : linesFilteredByChannel[0]._id,\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona una cola"}},_vm._l((_vm.linesFilteredByChannel),function(line){return _c('a-select-option',{key:line._id},[_vm._v(" "+_vm._s(line.name)+" ")])}),1)],1)],1),_c('a-row',[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Selecciona el tiempo máximo de respuesta  "),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.app)+" usará este tiempo para verificar y notificar que las respuestas a los envíos son de esta campaña o no. ")]),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'hoursOfLife',
            {
              initialValue: _vm.timeOptions[0],
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]),expression:"[\n            'hoursOfLife',\n            {\n              initialValue: timeOptions[0],\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona una cola"}},_vm._l((_vm.timeOptions),function(time){return _c('a-select-option',{key:time},[_vm._v(" "+_vm._s(time)+" horas ")])}),1)],1)],1)]:(_vm.currentStep === 1)?[_vm._v(" "+_vm._s(_vm.validateNewValuesInDom())+" "),_c('a-row',[_c('a-form-item',{attrs:{"label":"Selecciona el tipo de plantilla"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mediaType',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
              ],
            },
          ]),expression:"[\n            'mediaType',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor completa este campo',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona aquí"},on:{"change":_vm.handleChangeTypeTemplate}},_vm._l((_vm.TYPE_TEMPLATES_FOR_LIST),function(typeTemplate){return _c('a-select-option',{key:typeTemplate.value},[_vm._v(" "+_vm._s(typeTemplate.title)+" ")])}),1)],1)],1),_c('a-row',[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Selecciona una plantilla  "),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_vm._v(" Se listarán únicamente las plantillas de tipo \"Texto\". ")]),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'templateId',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'templateId',\n            {\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona una plantilla"},on:{"change":_vm.handleSelectTemplate}},_vm._l((_vm.templatesFilteredByType),function(template){return _c('a-select-option',{key:template._id},[_vm._v(" "+_vm._s(template.friendlyName)+" ")])}),1)],1)],1),_c('a-row',[(_vm.typeTemplateSelected.type === 'MEDIA')?_c('a-form-item',{attrs:{"label":"Archivo adjunto"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fileMedia',
            {
              valuePropName: 'fileMediaList',
              getValueFromEvent: _vm.normFileMedia,
              rules: [
                {
                  required: true,
                  message: 'Por favor sube el archivo',
                },
              ],
            },
          ]),expression:"[\n            'fileMedia',\n            {\n              valuePropName: 'fileMediaList',\n              getValueFromEvent: normFileMedia,\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor sube el archivo',\n                },\n              ],\n            },\n          ]"}],attrs:{"name":"fileMedia","multiple":false,"file-list":_vm.fileMediaList,"customRequest":_vm.uploadFileMedia,"before-upload":_vm.beforeUploadMedia,"remove":_vm.handleRemoveFileMedia,"accept":_vm.typeTemplateSelected.formats.accept}},[_c('p',[_c('a-icon',{staticClass:"form__icon-inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_c('span',[_vm._v(" Solo se acepta "+_vm._s(_vm.typeTemplateSelected.formats.titles.join(' ,'))+". ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.typeTemplateSelected.max_size.title)+" tamaño máximo. ")])])])],1):_vm._e()],1)]:(_vm.currentStep === 2)?[_vm._v(" "+_vm._s(_vm.validateNewValuesInDom())+" "),_c('a-row',{staticClass:"mrg-bottom-8",attrs:{"type":"flex","align":"middle"}},[_c('p',{staticClass:"semibold-14 mrg-bottom-0 mrg-right-8 form__label--black"},[_vm._v(" Formato para campaña ")]),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_c('anchor',{attrs:{"href":`${_vm.vari.WEB_DOC}campanas/plantilla-predefinida-whatsapp/`}},[_vm._v(" Click aquí")]),_vm._v(". si deseas más info de cómo completar la plantilla predefinida. ")],1),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"form__container-download"},[_c('a',{attrs:{"disabled":_vm.hasNewValues({
            valuesForm: _vm.form.getFieldsValue(),
            except: 'fileMessage',
          })},on:{"click":function($event){return _vm.handleGenerateAndDownloadFileCsv(false)}}},[_vm._v("Click aquí para descargar archivo .CSV")])]),_c('p',{staticClass:"semibold-14 mrg-bottom-8 form__label--black"},[_vm._v(" Sube tu formato con los datos completos ")]),_c('p',{staticClass:"mrg-bottom-8"},[_vm._v(" Tienes un envío "),_c('strong',[_vm._v(" máximo a "+_vm._s(_vm.tier.tier)+" contactos en tu "),_c('anchor',{attrs:{"href":"https://developers.facebook.com/docs/whatsapp/messaging-limits#quality"}},[_vm._v(_vm._s(_vm.tier.type))])],1),_vm._v(" y recuerda que el tiempo de envío es de 5 segundos por contacto. ")]),_c('a-tooltip',[(
          _vm.hasNewValues({
            valuesForm: _vm.form.getFieldsValue(),
            except: 'fileMessage',
          })
        )?_c('template',{attrs:{"placement":"right"},slot:"title"},[_vm._v(" Completa todos los campos para poder subir tu archivo.")]):_vm._e(),_c('a-form-item',[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fileMessage',
            {
              valuePropName: 'fileList',
              getValueFromEvent: _vm.normFile,
              rules: [
                { required: true, message: 'Por favor sube el archivo' },
              ],
            },
          ]),expression:"[\n            'fileMessage',\n            {\n              valuePropName: 'fileList',\n              getValueFromEvent: normFile,\n              rules: [\n                { required: true, message: 'Por favor sube el archivo' },\n              ],\n            },\n          ]"}],attrs:{"name":"file","multiple":false,"customRequest":_vm.uploadFile,"file-list":_vm.fileList,"before-upload":_vm.beforeUpload}},[_c('p',[_c('a-icon',{staticClass:"form__icon-inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v("Solo se aceptan archivos .csv")])])],1)],2),_c('div',[_c('p',{staticClass:"semibold-14 mrg-bottom-8"},[_vm._v("Recuerda")]),_c('p',{staticClass:"info_i2"},[_vm._v(" Esta campaña solo podrá ser lanzada durante los próximos 7 días desde la fecha de su creación. Luego se detendrá forzosamente. ")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }