import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'

const actions = {
  /** Lista Los Supervisores de la Empresa */
  async listSupervisors(context) {
    try {
      if (
        !(await compare.isAllowedFor(context, [
          'admin',
          'supervisor',
          'securitec',
          'master_agent',
        ]))
      )
        return

      const result = await axios.get(`${vari.UHR}/supervisor/list/all`)
      const supervisors = result.data
      context.commit('SET_SUPERVISORS', supervisors)
    } catch (error) {
      console.error('[adminModule][listSupervisors] error -', error)
    }
  },
  /**Lista todos los administradores de la Empresa
   * @param {vuex} context
   */
  async listAdmins(context) {
    try {
      if (
        !(await compare.isAllowedFor(context, [
          'admin',
          'supervisor',
          'securitec',
          'admin_agent',
          'master_agent',
        ]))
      )
        return
      const url = `${vari.UHR}/admin/company/user/admins`
      const response = await axios.get(url)

      context.commit('SET_ADMINS', response.data.result)
    } catch (error) {
      console.log(error)
    }
  },
}

export default actions
