var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-state-tickets"},[_c('div',{staticClass:"header"},[_c('p',{staticClass:"title heading-h6"},[_vm._v("Resumen de estados de tickets")]),_c('div',[_c('a-tooltip',{attrs:{"placement":"bottomRight","arrowPointAtCenter":true,"overlayClassName":"tooltip-dash2"}},[_c('template',{slot:"title"},[_vm._v(" Visualiza la cantidad de tickets según sus estados en el sistema."),_c('br'),_c('strong',[_vm._v("Cola de espera: ")]),_vm._v("Tickets que aun no son transferidos a un agente y están a la espera."),_c('br'),_c('strong',[_vm._v("Entregados: ")]),_vm._v("Tickets que han sido entregados a los agentes."),_c('br'),_c('strong',[_vm._v("Respondidos: ")]),_vm._v("Tickets que han sido respondidos por un agente por primera vez."),_c('br'),_c('strong',[_vm._v("Finalizados: ")]),_vm._v("Tickets que han sido resueltos por los agentes. "),_c('br'),_vm._v(" Se mostrarán según el rango de fecha y hora que decidas en el filtro. ")]),_c('a-icon',{staticStyle:{"font-size":"20px","color":"#595959","margin-right":"8px"},attrs:{"type":"info-circle"}})],2),(_vm.visibleConfirm)?_c('a-popconfirm',{attrs:{"title":"¿Estás segur@ de descargar los datos de este dashboard?","ok-text":"Descargar","cancel-text":"Cancelar","placement":"bottomRight","arrowPointAtCenter":true,"overlayStyle":_vm.popStyles,"disabled":_vm.isLoading},on:{"confirm":_vm.confirm}},[_c('a-tooltip',{attrs:{"placement":"bottomRight","arrowPointAtCenter":true,"overlayClassName":"tooltip-dash1","trigger":"hover"}},[(!_vm.isLoading)?_c('template',{slot:"title"},[_vm._v(" Al descargar este dashboard en un excel solo se respetarán los filtros superiores. ")]):_vm._e(),_c('a-icon',{staticClass:"section__icon--download",class:{
              'btn-dowload': !_vm.isLoading,
              'icon--disabled': _vm.isLoading,
            },attrs:{"type":"download"}})],2)],1):_vm._e(),(_vm.visiblePopOver)?_c('a-popover',{attrs:{"placement":"bottomRight","arrowPointAtCenter":true,"visible":_vm.visiblePopOver,"overlayStyle":_vm.popStyles,"disabled":_vm.isLoading}},[_c('template',{slot:"content"},[_c('p',{staticClass:"gray-dark-900",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.textProgress)+" ")]),_c('a-progress',{attrs:{"percent":_vm.percent,"status":_vm.status}}),(_vm.status === 'exception')?_c('div',{staticClass:"contain-btns"},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":_vm.handleCancel}},[_vm._v("Cancelar")]),_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":_vm.handleOk}},[_vm._v("Volver a intentar")])],1):_vm._e()],1),_c('a-icon',{staticClass:"btn-dowload section__icon--download",class:{
            'btn-focus': _vm.visiblePopOver && !_vm.isLoading,
            'icon--disabled': _vm.isLoading,
          },attrs:{"type":"download"}})],2):_vm._e()],1)]),_c('div',{staticClass:"body"},[_c('p',{staticClass:"text--left body__text"},[_vm._v(" Recueda que puedes seleccionar los estados que deseas visualizar. ")]),_c('LineChart',{attrs:{"styles":_vm.myStyles,"chartdata":_vm.chartdata}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }