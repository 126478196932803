<template>
  <a-row class="notifications" type="flex" justify="center">
    <a-col :span="16" class="col">
      <NotificationsTemplate />
    </a-col>
  </a-row>
</template>

<script>
import NotificationsTemplate from '@/app/notifications/components/templates/NotificationsTemplate.vue'
export default {
  name: 'Notifications',
  components: {
    NotificationsTemplate,
  },
}
</script>

<style lang="sass" scoped>
.notifications
  height: 100vh
  .col
    height: max-content
</style>
