import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const payments_statuses = {
  // TODO: Tratar de reutilizar https://api.codestream.com/c/X9_flMi1mlDiMUvE/i3IHVCp5TC2oz9s7hiNYBA
  approved: {
    key: 'approved',
    value: 'Aprobado',
  },
  rejected: {
    key: 'rejected',
    value: 'Rechazado',
  },
  // Estado igual que el aprobado, pero como reposición por el lado de Ticker, es decir, ni pasa por el market, sino se aplica de manera interna
  readjustment: {
    key: 'readjustment',
    value: 'Reposición',
  },
  pending: {
    key: 'pending',
    value: 'Pendiente',
  },
  annulled: {
    key: 'annulled',
    value: 'Anulado',
  },
}

/**
 * Obtener los gastos de wsp
 * @param {Number}  page
 * @param {Object}  settings
 * @param {Date}    settings.startDate
 * @param {Date}    settings.endDate
 * @param {String}  settings.subType
 * @param {String}  settings.channelsCompaniesIds
 * @param {String} settings.sortByCreatedAt
 */
async function getWhatsappSpendings(page, settings) {
  try {
    const response = await axios.get(
      `${vari.UHR}/admin/spendings/whatsapp?date=${new Date()}&start=${
        settings.startDate
      }&end=${settings.endDate}&subType=${
        settings.subType
      }&page=${page}&channelsCompaniesIds=${
        settings.channelsCompaniesIds
      }&sortByCreatedAt=-1`
    )

    let data = response.data.data.filter((item) => !item.isTotal)
    const totalSpendings = response.data.data.find((item) => item.isTotal)

    data = data.map((spending) => {
      switch (settings.subType) {
        case 'all':
          break
        case 'session':
          spending.totalItems = spending.totalSessions
          spending.totalPrice = spending.totalSessionsPrice

          break
        case 'template':
          spending.totalItems = spending.totalTemplates
          spending.totalPrice = spending.totalTemplatesPrice
          break
        case 'conversation':
          spending.totalItems = spending.totalConversations
          spending.totalPrice = spending.totalConversationsPrice
          break
      }
      spending.totalPrice = '$' + spending.totalPrice.toFixed(3)
      return spending
    })
    if (totalSpendings)
      totalSpendings.totalAllPrice =
        '$' + totalSpendings.totalAllPrice.toFixed(3)

    return { ...response.data, data, totalSpendings: totalSpendings || {} }
  } catch (error) {
    console.error('[getWhatsappSpendings]', error)
    return { success: false }
  }
}
/**
 * Obtener las recargas de wsp
 */
async function getRecharges(type, page, settings) {
  try {
    const response = await axios.get(
      `${vari.UHR}/license/billing/list/${page}?date=${new Date()}&startDate=${
        settings.startDate
      }&endDate=${settings.endDate}`,
      {
        params: {
          search: settings.search,
          status: settings.status,
          type: type,
        },
      }
    )
    response.data.data = response.data.data.map((spending) => {
      spending.total =
        '$' +
        spending.products
          .filter((product) => product.permission == type)
          .reduce((acc, product) => {
            return acc + product.total
          }, 0)
          .toFixed(2)
      spending.method =
        spending.cardToUse.type + '*' + spending.cardToUse.lastDigits.slice(3)
      spending.state = payments_statuses[spending.status].value
      spending.amount = spending.products
        .filter((product) => product.permission == type)
        .reduce((acc, product) => {
          return acc + product.amount
        }, 0)
      return spending
    })
    return response.data
  } catch (error) {
    return { success: false }
  }
}

export default {
  getWhatsappSpendings,
  getRecharges,
}
