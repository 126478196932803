import socketModule from './socketModule'
import companyThread from './threads/companyThread'
import userThread from './threads/userThread'
import ticketThread from './threads/ticketThread'
import scheduleThread from './threads/scheduleThread'
import lineThread from './threads/lineThread'
import SupervisionThread from './threads/SupervisionThread'
import authThread from './threads/authThread'
import notificationsThread from './threads/notificationsThread'
import routesThread from './threads/routesThread'
import strategiesThread from './threads/strategiesThread'

const modules = {
  socketModule,
  companyThread,
  userThread,
  ticketThread,
  lineThread,
  scheduleThread,
  SupervisionThread,
  authThread,
  notificationsThread,
  routesThread,
  strategiesThread,
}

export default {
  modules,
}
