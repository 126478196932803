<template>
  <div class="drawer-settings">
    <div class="drawer-settings__header">
      <h6 class="heading-h7 title">
        {{ title }}
        <a-tag
          v-if="hasDeletedVar"
          color="gold"
          class="drawer-settings__header__tag"
        >
          <a-icon type="warning" />
          Variable eliminada</a-tag
        >
      </h6>
      <a-icon type="close" style="margin-right: 8px" @click="handleClose" />
    </div>
    <custom-section-scroll
      :master-styles="{
        height: 'calc( 100% - 90px)',
        padding: '12px 0px 12px 12px',
      }"
      :content-styles="{ paddingRight: '12px', position: 'relative' }"
    >
      <a-row
        class="mrg-bottom-8"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <p class="body-2 mrg-0" style="text-align: left">
          {{ description }}
        </p>
        <a-button
          v-if="showExample"
          type="link"
          @click="handleShowModalPreviewNode"
          >Visualiza el ejemplo</a-button
        >
      </a-row>
      <component
        :is="componentForm"
        :ref="refForm"
        :chatbotId="chatbotId"
        :node-id="settings._id"
        :data-source-line="chatbotLines"
        :has-child="hasChild"
        :parent-node-id="settings.parentNodeId"
        :id-option="settings.idOption"
        :id-button="settings.idButton"
        :child-id="childId"
        :subchild-id="subchildId"
        :data-source-lines="chatbotLines"
        :only-read="settings.onlyRead"
      />
    </custom-section-scroll>
    <div class="drawer-settings-footer">
      <a-button
        style="width: 50%"
        :disabled="isSavedNode || onlyRead"
        @click="handleCancel"
        >Cancelar</a-button
      >
      <a-button
        type="primary"
        style="margin-left: 12px; width: 50%"
        html-type="submit"
        :disabled="isSavedNode || onlyRead"
        :loading="loading"
        @click="handleSubmit"
        >Guardar</a-button
      >
    </div>
    <modal-preview-node
      :visible="modalPreviewNode.visible"
      :type-node="modalPreviewNode.typeNode"
      @onClose="handleCloseModalPreviewNode"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import transformMixin from '@/app/shared/mixins/transform'
import OptionsForm from '@/app/chatbots/components/organisms/forms/OptionsForm'
import OptionForm from '@/app/chatbots/components/organisms/forms/OptionForm'
import MessageForm from '@/app/chatbots/components/organisms/forms/MessageForm'
import CatcherForm from '@/app/chatbots/components/organisms/forms/CatcherForm'
import ButtonsForm from '@/app/chatbots/components/organisms/forms/ButtonsForm'
import ButtonForm from '@/app/chatbots/components/organisms/forms/ButtonForm'
import CardsForm from '@/app/chatbots/components/organisms/forms/CardsForm'
import CardForm from '@/app/chatbots/components/organisms/forms/CardForm'
import ListForm from '@/app/chatbots/components/organisms/forms/ListForm'
import ListSectionForm from '@/app/chatbots/components/organisms/forms/ListSectionForm'
import ListItemForm from '@/app/chatbots/components/organisms/forms/ListItemForm'
import ModalPreviewNode from '@/app/chatbots/components/organisms/modals/ModalPreviewNode'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  name: 'DrawerSettings',
  mixins: [transformMixin],
  components: {
    CustomSectionScroll,
    OptionsForm,
    OptionForm,
    MessageForm,
    CatcherForm,
    ButtonsForm,
    ButtonForm,
    CardsForm,
    CardForm,
    ListForm,
    ListSectionForm,
    ListItemForm,
    ModalPreviewNode,
  },
  props: {
    settings: {
      type: Object,
      required: true,
      default: () => ({ type: 'options' }),
    },
    chatbotId: {
      type: String,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    NODES,
    loading: false, // loading de boton de guardar
    modalPreviewNode: {
      visible: false,
      typeNode: '',
    },
    testList: {
      enabled: false,
      type: 'list_item',
    },
  }),
  created() {
    this.setValues()
  },
  beforeDestroy() {
    this.handleClose()
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        // espera a que se termine de renderizar el componente
        this.$nextTick(() => {
          // setea los nuevos valores
          this.setValues()
        })
      },
    },
  },
  computed: {
    ...mapGetters(['companyLines', 'isSavedNode', 'nodes']),
    /**
     * @returns {String}
     */
    title() {
      switch (this.type) {
        case 'options':
          return 'Configurar opciones múltiples'
        case 'option':
          return 'Configurar opción'
        case 'message':
          return 'Configurar mensaje'
        case 'catcher':
          return 'Configurar capturar variable'
        case 'buttons':
          return 'Configurar botones'
        case 'button':
          return 'Configurar botón'
        case 'cards':
          return 'Configurar tarjetas con botones'
        case 'card':
          return 'Configurar tarjeta'
        case 'card_button':
          return 'Configurar botón'
        case 'lists':
          return 'Configurar listas'
        case 'list_section':
          return 'Configurar sección'
        case 'list_item':
          return 'Configurar item'
        default:
          return null
      }
    },
    /**
     * Retorna el tipo de nodo
     * @returns {String}
     */
    type() {
      // TODO: Solo para pruebas, luego sacar
      if (this.testList.enabled) return this.testList.type
      if (!this.settings) return ''
      else {
        if (!this.settings.drawer) {
          const nodeParent = this.nodes.find(
            (node) => node._id === this.settings.parentNodeId
          )
          const structureParent = NODES[nodeParent.drawer.question.type]
          if (this.settings.is_subchild) return structureParent.child.child.type
          else return structureParent.child.type
        } else return this.settings.drawer.question.type
      }
    },
    /**
     * @returns {Component}
     */
    componentForm() {
      switch (this.type) {
        case 'options':
          return OptionsForm
        case 'option':
          return OptionForm
        case 'message':
          return MessageForm
        case 'catcher':
          return CatcherForm
        case 'buttons':
          return ButtonsForm
        case 'button':
          return ButtonForm
        case 'cards':
          return CardsForm
        case 'card':
          return CardForm
        case 'card_button':
          return ButtonForm
        case 'lists':
          return ListForm
        case 'list_section':
          return ListSectionForm
        case 'list_item':
          return ListItemForm
        default:
          return null
      }
    },
    /**
     * @return {String} - ref de formulario
     */
    refForm() {
      return `${this.type}_form`
    },
    /**
     * @returns {Boolean} - si el nodo tiene hijos
     */
    hasChild() {
      switch (this.type) {
        case 'option':
          return this.settings.action.type === 'continue'
        default:
          return !!this.settings.child
      }
    },
    /**
     * FIltra las colas disponibles para mostrar según su canal asignado
     * @returns {Object[]}
     */
    chatbotLines() {
      const linesFilter = []
      this.companyLines.forEach((companyLine) => {
        let isHere = false
        isHere = this.channels.every((channel) => {
          return companyLine.channels_companies?.some(
            (channel_company) => channel_company._id === channel._id
          )
        })
        if (isHere || companyLine.default) {
          linesFilter.push({ key: companyLine._id, title: companyLine.name })
        }
      })
      return linesFilter
    },
    /**
     * @returns {Boolean} - verifica si el nodo tiene una variables eliminada
     */
    hasDeletedVar() {
      if (!this.settings) return
      let hasDeleted = false
      switch (this.type) {
        case 'options':
          {
            hasDeleted =
              (this.settings.drawer.question.varsInContent &&
                this.settings.drawer.question.varsInContent.some(
                  (item) => item.deleted
                )) ||
              (this.settings.drawer.question.varsInErrorMessage &&
                this.settings.drawer.question.varsInErrorMessage.some(
                  (item) => item.deleted
                ))
          }
          break
        case 'message':
          {
            hasDeleted =
              this.settings.drawer.question.varsInContent &&
              this.settings.drawer.question.varsInContent.some(
                (item) => item.deleted
              )
          }
          break
        case 'catcher':
          {
            hasDeleted =
              (this.settings.drawer.question.varToSave &&
                this.settings.drawer.question.varToSave.deleted) ||
              (this.settings.drawer.question.varsInContent &&
                this.settings.drawer.question.varsInContent.some(
                  (item) => item.deleted
                ))
          }
          break
        case 'option': {
          hasDeleted =
            this.settings.varsInMessage &&
            this.settings.varsInMessage.some((item) => item.deleted)
        }
      }
      return hasDeleted
    },
    /**
     * @return {Boolean} - si el nodo se puede editar o no
     */
    onlyRead() {
      return this.settings.onlyRead || false
    },
    /**
     * @return {String} - descripcion del formulario
     */
    description() {
      switch (this.type) {
        case 'message':
          return 'Completa los campos. Debes contar con al menos una archivo o mensaje para esta acción.'
        default:
          return 'Completa todos los campos'
      }
    },
    /**
     * @return {Boolean} - mostrar ejemplo de nodo
     */
    showExample() {
      const enabled = ['card_button', 'lists']
      return enabled.includes(this.type)
    },
    /**
     * @return {String} id del nodo hijo
     */
    childId() {
      switch (this.type) {
        case 'card':
          return this.settings.idCard
        case 'list_section':
          return this.settings.idItem
        case 'list_item':
          return this.settings.idOption
        default:
          return ''
      }
    },
    /**
     * @return {String} id del nodo subhijo
     */
    subchildId() {
      switch (this.type) {
        case 'list_item':
          return this.settings.idOption
        default:
          return ''
      }
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),

    // setea los valores al formulario
    setValues() {
      setTimeout(() => {
        switch (this.type) {
          case 'options':
            this.$refs[this.refForm]?.setValues({
              content: this.settings.drawer.question.content,
              errorMessage: this.settings.drawer.question.errorMessage,
            })
            break
          case 'option':
            {
              this.$refs[this.refForm]?.setValues({
                name: this.settings.text,
                action: this.settings.action,
                order: this.settings.order,
              })
            }
            break
          case 'message':
            {
              this.$refs[this.refForm]?.setValues({
                text: this.settings.drawer.question.content,
                action: this.settings.drawer.action,
                media: this.settings.drawer.question.media,
              })
            }
            break
          case 'catcher':
            {
              this.$refs[this.refForm]?.setValues({
                content: this.settings.drawer.question.content,
                errorMessage: this.settings.drawer.question.errorMessage,
                action: this.settings.drawer.action,
                varToSave: this.settings.drawer.question.varToSave,
              })
            }
            break
          case 'buttons':
            this.$refs[this.refForm]?.setValues({
              content: this.settings.drawer.question.content,
              errorMessage: this.settings.drawer.question.errorMessage,
            })
            break
          case 'button':
          case 'card_button':
            {
              this.$refs[this.refForm]?.setValues({
                text: this.settings.text,
                action: this.settings.action,
                order: this.settings.order,
                isSubchild: this.settings.is_subchild,
                simulateParentNodeId: this.settings.simulateParentNodeId,
              })
            }
            break
          case 'cards':
            this.$refs[this.refForm]?.setValues({
              errorMessage: this.settings.drawer.question.errorMessage,
            })
            break
          case 'card':
            this.$refs[this.refForm]?.setValues({
              title: this.settings.title,
              subtitle: this.settings.subtitle,
              order: this.settings.order,
              media: this.settings.media,
            })
            break
          case 'lists':
            this.$refs[this.refForm]?.setValues({
              title: this.settings.drawer.question.title,
              body: this.settings.drawer.question.body,
              globalOptionTitle:
                this.settings.drawer.question.globalOptionTitle,
              errorMessage: this.settings.drawer.question.errorMessage,
            })
            break
          case 'list_section':
            this.$refs[this.refForm]?.setValues({
              title: this.settings.title,
              order: this.settings.order,
            })
            break
          case 'list_item':
            this.$refs[this.refForm]?.setValues({
              title: this.settings.title,
              description: this.settings.description,
              action: this.settings.action,
              order: this.settings.order,
              simulateParentNodeId: this.settings.simulateParentNodeId,
            })
            break
        }
      }, 100)
    },
    // submit de formulario
    handleSubmit() {
      this.$refs[this.refForm].handleSubmit()
      this.$emit('update')
    },
    /**
     * Cancela todos los cambios realizados
     */
    handleCancel() {
      this.setValues()
      this.setIsSavedItem(true)
    },
    /**
     * Setea un valor a isSavedNode
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.SET_IS_SAVED_NODE(isSavedNode)
    },
    /**
     * Cierra el drawer de edicion del nodo
     */
    handleClose() {
      this.handleCancel()
      this.$emit('handleCloseDrawer')
    },
    /**
     * Muestra el modal de previsualización de nodo
     */
    handleShowModalPreviewNode() {
      this.modalPreviewNode.visible = true
      this.modalPreviewNode.typeNode = this.type
    },
    /**
     * Cierra el modal de previsualizacion de nodo
     */
    handleCloseModalPreviewNode() {
      this.modalPreviewNode = {
        visible: false,
        typeNode: '',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.text-align-left
  text-align: left
.drawer-settings
  height: 100%
  background-color: $white_000
  border: 1px solid $gray_dark_100
  border-radius: 8px
  position: relative
  &__header
    padding: 12px
    border-bottom: 1px solid $gray_dark_100
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    .title
      color: $gray_dark_900
      text-align: left
      margin-bottom: 0px
  &-body
    height: calc( 100% - 44px)
    .help
      color: $gray_dark_700
      margin-top: -8px
    .switch-form
      position: absolute
      right: 12px
      transform: translateY(3px)
      z-index: 1
  &-footer
    padding: 12px
    border-top: 1px solid $gray_dark_100
    width: 100%
    display: flex
    flex-direction: row
    justify-content: flex-end
    position: absolute
    bottom: 0
.section-form-item--message
  margin-bottom: 0px
</style>
