<template>
  <div class="section-channel">
    <div class="section-channel-content">
      <router-link
        :to="{ name: 'my-channels' }"
        class="align-text-left"
        style="display: inherit; margin-bottom: 20px; width: max-content"
        ><a-icon type="arrow-left" /> Regresar a mis canales</router-link
      >
      <div
        style="
          width: 100%;
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h6 class="heading-h6 align-text-left title">
          {{ transformApiChannel(dataChannel.channel) }}
        </h6>
        <!--Se comenta hasta que se vuelve a activar sandbox-->
        <!-- <ModalSandbox nameButton="Conectar con la sandbox" step="two" /> -->
      </div>
      <BannerProvider
        @onOpenDetails="handleShowModalDetails"
        @onOpenUnsubscribe="handleOpenUnsubscribe"
        :channel="dataChannel"
      />
      <modal-details-wba
        :visible="modalDetailsWba.visible"
        :numberWba="numberWhatApp"
        :broadcast="broadcast"
        :integration="integration"
        :facebook="facebook"
        :request="dataChannel.request || false"
        :supplier="dataChannel.supplier || null"
        :channelCompanyId="dataChannel._id"
        @onCancel="modalDetailsWba.visible = false"
      />
      <ModalUnsubscribe
        :visible="modalUnsubscribe.visible"
        @onClose="modalUnsubscribe.visible = false"
        :channelCompanyId="dataChannel._id"
      />
      <modal-confirmation
        :visible="modalConfirmation.visible"
        :colorIcon="red_6"
        typeIcon="delete"
        :title="`¿Estás segura de eliminar WhatsApp con el  número  ${numberWhatApp}?`"
        :isList="true"
        :descriptions="modalConfirmation.descriptions"
        subTitle="Recuerda que se tomarán las siguientes acciones:"
        strongWord="Eliminar WhatsApp"
        nameButton="Eliminar"
        typeButton="danger"
        :loadingButton="modalConfirmation.loading"
        @onChangeVisible="handleChangeVisibleConfirmation"
        @actionExecuted="handleExecutedConfirmation"
      />
      <a-divider />
      <SectionTemplates
        :data-templates="dataTemplates"
        :data-channel="dataChannel"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionTemplates from '@/app/channels/components/whatsapp/SectionTemplates.vue'
import transformApiChannel from '@/app/shared/mixins/transform'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'
import BannerProvider from '@/app/channels/components/whatsapp/BannerProvider'
import ModalUnsubscribe from '@/app/channels/components/whatsapp/modals/ModalUnsubscribe'
import ModalConfirmation from '@/app/accounts/components/modals/ModalConfirmation'
import ModalDetailsWba from '@/app/channels/components/whatsapp/modals/ModalDetailsWba'

moment.locale('es')

export default {
  name: 'SectionWhatsapp',
  mixins: [transformApiChannel, attemptMixin],
  props: {
    dataChannel: {
      type: Object,
      required: true,
    },
  },
  components: {
    SectionTemplates,
    BannerProvider,
    ModalUnsubscribe,
    ModalDetailsWba,
    ModalConfirmation,
  },
  data: () => ({
    moment: moment,
    drawerDetails: {
      visible: false,
    },
    modalDetailsWba: {
      visible: false,
    },
    modalUnsubscribe: {
      visible: false,
      loading: false,
    },
    red_6: '#f5222d',
    modalConfirmation: {
      visible: false,
      descriptions: [
        'Todos tus tickets activos de WhatsApp se finalizarán.',
        'WhatsApp se desasignará de los chatbots activos e inactivos.',
        'Se inactivarán los chatbots que solo tengan asociado a WhatsApp.',
        'WhatsApp se desasignará de las colas que esten asociadas.',
      ],
    },
  }),
  computed: {
    ...mapGetters(['allTemplates', 'company']),

    /**
     * @return {Object} Data para las plantillas
     */
    dataTemplates() {
      let n = 1
      const data = this.allTemplates.map((template) => {
        template.order = n++
        return template
      })
      return { data: data }
    },
    /**
     * Number whatsapp
     * @returns {String}
     */
    numberWhatApp() {
      return this.dataChannel.credentials?.wspbusiness
    },
    broadcast() {
      return this.dataChannel.broadcast || {}
    },
    integration() {
      return {
        form: this.dataChannel.form || null,
        webhooks: this.dataChannel.webhooks || null,
      }
    },
    facebook() {
      return this.dataChannel.form && this.dataChannel.form.facebook
        ? this.dataChannel.form.facebook
        : {}
    },
  },
  methods: {
    ...mapActions([
      'getAssignedLines',
      'listAccounts',
      'getAccountsUsers',
      'unsuscribeChannel',
    ]),
    /**
     * Retorna el icono de  cada proveedor
     * @param {String} supplier nombre de proveedor
     * @param {Boolean} request si es proveedor
     * @return {Object} {graphIcon, icon}
     */
    iconSupplier(supplier, request) {
      // si es default
      if (request)
        return { graphIcon: 'route', icon: 'securitec-logo-24x24.png' }
      else {
        switch (supplier) {
          case 'Twilio':
            return { graphIcon: 'route', icon: 'twilio-logo-24x24.png' }
          case 'Gupshup':
            return { graphIcon: 'route', icon: 'gupshup-logo-24x24.png' }
          default:
            return { graphIcon: 'route', icon: 'intermax-logo-24x24.png' }
        }
      }
    },
    /**
     * Cambia el visible de la confirmacion
     * @param {Boolean} visible
     */
    handleChangeVisibleConfirmation(visible) {
      this.modalConfirmation.visible = visible
    },
    /**
     * Accion ejecutada del modal de confirmacion
     */
    async handleExecutedConfirmation() {
      this.modalUnsubscribe.loading = true
      const response = await this.unsuscribeChannel({
        channel: 'whatsapp',
        channelCompanyId: this.dataChannel._id,
      })
      this.modalUnsubscribe.loading = false
      if (response.success) {
        this.modalConfirmation.visible = false
        this.$message.success('Se eliminó tu canal exitosamente')
        this.$router.push({ name: 'channels' })
      } else this.$message.error(`${response.result}`)
    },
    /**
     * Accion ejecutada para abrir el modal
     */
    handleOpenUnsubscribe() {
      const provider = this.dataChannel.api_version
      if (provider !== 'twilio_v3') {
        this.modalUnsubscribe.visible = true
      } else this.modalConfirmation.visible = true
    },
    handleShowModalDetails() {
      this.modalDetailsWba.visible = true
    },
  },
}
</script>

<style lang="sass" scoped>
.align-text-left
  text-align: left

.section-channel
  height: 100%
  padding: 12px
  &-content
    background-color: $white_000
    height: 100%
    border-radius: 8px
    padding: 20px
    >.title
      text-transform: capitalize
</style>
