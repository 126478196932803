import instanceJson from '@/app/shared/utils/instance'

const initialState = () => ({
  crm_max_months_to_get: instanceJson.reports.crm_max_months_to_get, // maximo de meses para obtener data
  dashboard_max_months_to_get: instanceJson.reports.dashboard_max_months_to_get, // maximo de meses para obtener data
  drawer_filters: {
    // para los drawers de filtros
    title: 'Aplicar filtros',
    width: '28vw',
  },
  max_size_to_upload: 5,
  max_size_to_upload_avatar_company: 3,
  modules_pagination: instanceJson.modules_pagination,
  submenu_chat_atc: instanceJson.submenu_chat_atc,
  enable_twilio_provider: instanceJson.enable_twilio_provider, // proveedor twilio habilitado o no
  enable_register_facebook: instanceJson.enable_register_facebook,
  enable_sms: instanceJson.enable_sms,
  enable_subspaces: instanceJson.enable_subspaces,
  show_documentation: instanceJson.show_documentation,
  enabled_resolve_queued_tickets: instanceJson.enabled_resolve_queued_tickets,
  status_to_prevent_resolve_tickets: [], // estados de tickets para prevenir resolverlos
  enable_spendings_tables: instanceJson.enable_spendings_tables,
})

const state = initialState

const getters = {
  crm_max_months_to_get: (state) => state.crm_max_months_to_get,
  dashboard_max_months_to_get: (state) => state.dashboard_max_months_to_get,
  max_size_to_upload: (state) => state.max_size_to_upload,
  max_size_to_upload_avatar_company: (state) =>
    state.max_size_to_upload_avatar_company,
  drawer_filters: (state) => state.drawer_filters,
  modules_pagination: (state) => state.modules_pagination,
  submenu_chat_atc: (state) => state.submenu_chat_atc,
  enable_twilio_provider: (state) => state.enable_twilio_provider,
  enable_register_facebook: (state) => state.enable_register_facebook,
  enable_sms: (state) => state.enable_sms,
  enable_subspaces: (state) => state.enable_subspaces,
  show_documentation: (state) => state.show_documentation,
  enabled_resolve_queued_tickets: (state) =>
    state.enabled_resolve_queued_tickets,
  status_to_prevent_resolve_tickets: (state) =>
    state.enabled_resolve_queued_tickets ? ['ended'] : ['ended', 'queued'],
  enable_spendings_tables: (state) => state.enable_spendings_tables,
}

export default {
  state,
  getters,
}
