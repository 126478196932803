var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-form"},[_c('div',{staticClass:"content"},[_c('h5',{staticClass:"heading-h5 gray-dark-900"},[_vm._v(" ¡Bienvenid@! Ingresa a tu cuenta. ")]),(_vm.showAlert)?_c('a-alert',{staticClass:"form-model-alert",attrs:{"message":_vm.messageAlert,"type":_vm.typeAlert,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Correo electrónico"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa tu correo electrónico.',
                },
                {
                  type: 'email',
                  message: 'Ingresa un correo válido.',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios en blanco.',
                },
                {
                  min: 6,
                  message: '6 caracteres como mínimo.',
                },
                {
                  max: 50,
                  message: '50 caracteres como máximo.',
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor ingresa tu correo electrónico.',\n                },\n                {\n                  type: 'email',\n                  message: 'Ingresa un correo válido.',\n                },\n                {\n                  whitespace: true,\n                  message: 'No se aceptan espacios en blanco.',\n                },\n                {\n                  min: 6,\n                  message: '6 caracteres como mínimo.',\n                },\n                {\n                  max: 50,\n                  message: '50 caracteres como máximo.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Escriba aquí...","maxLength":50,"size":_vm.inputs_size}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"Contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor ingresa tu contraseña',
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor ingresa tu contraseña',\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"password","placeholder":"Escriba aquí...","size":_vm.inputs_size}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),(!_vm.active_breakpoint.is_mobile)?_c('p',{staticClass:"body-2"},[_vm._v(" ¿Olvidaste tu contraseña? Haz "),_c('router-link',{attrs:{"to":{ name: 'restore-password' }}},[_vm._v("click aquí")])],1):_c('p',{staticClass:"mrg-bottom-16"},[_c('router-link',{staticClass:"regular-14",attrs:{"to":{ name: 'restore-password' }}},[_vm._v(" Olvidaste tu contraseña")])],1),_c('a-button',{staticClass:"form-button form-button-submit",attrs:{"type":"primary","html-type":"submit","disabled":_vm.disabled,"loading":_vm.loadingLogin,"size":_vm.buttons_size}},[_vm._v(" Iniciar sesión ")]),_c('a-divider',{staticClass:"form-divider"},[_c('span',{staticClass:"body-2 mrg-bottom-0"},[_vm._v("o")])]),_c('a-button',{staticClass:"form-button",attrs:{"size":_vm.buttons_size},on:{"click":_vm.facebookSignIn}},[_c('span',{staticStyle:{"margin-right":"8px"}},[_c('img',{attrs:{"src":"img/icon_login/facebook.png"}})]),_vm._v(" Inicia sesión con Facebook")])],1),_c('ModalLogin',{ref:"modalLogin",attrs:{"visible":_vm.visibleModal,"typeModal":_vm.typeModal},on:{"handleNoVisible":_vm.handleNoVisible}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }