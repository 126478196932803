var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":`Sección ${_vm.order}`}},[_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthTitleItem,"text":_vm.form.getFieldValue('title') ? _vm.form.getFieldValue('title') : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'title',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribir aquí","maxLength":_vm.validations.maxLengthTitleItem,"disabled":_vm.onlyRead},on:{"input":_vm.onInput}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }