<template>
  <div class="list">
    <a-row type="flex" justify="space-between" class="list__description">
      <slot
        name="description"
        :checked="checkboxChecked"
        :total="dataSource.length"
      >
        <p>Columnas seleccionadas:</p>
        <span>{{ checkboxChecked }}/{{ dataSource.length }} </span>
      </slot>
    </a-row>

    <custom-section-scroll
      :master-styles="{ maxHeight: '200px', marginRight: '-12px' }"
      :container-styles="{ maxHeight: '200px' }"
      :content-styles="{ paddingRight: '12px' }"
    >
      <p v-for="item in dataSource" :key="item.key">
        <a-checkbox
          :checked="item.checked"
          :disabled="item.disabled"
          @change="handleChange($event, item.key)"
        >
          <slot name="title" :data="item">{{ item.title }} </slot>
        </a-checkbox>
      </p>
    </custom-section-scroll>
  </div>
</template>

<script>
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'ListCheckboxes',
  components: {
    CustomSectionScroll,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    dataSource: {
      type: Array,
      default: () => [
        {
          title: 'Column',
          checked: true,
          disabled: false,
          key: 'first',
        },
        { title: 'Column', checked: false, disabled: true, key: 'second' },
      ],
    },
  },
  computed: {
    /**
     * @returns {Number} - número de columnas que estan marcadas
     */
    checkboxChecked() {
      const checked = this.dataSource.filter((item) => item.checked)
      return checked.length
    },
  },
  methods: {
    /**
     * Escucha el cambio del checkbox
     * @param {Object} e - evento del checkbox
     * @param {String} key - key de la columna
     */
    handleChange(e, key) {
      const checked = e.target.checked
      this.$emit('onChange', checked, key)
    },
  },
}
</script>

<style lang="sass" scoped>
.list__description
  color: $gray_7
  margin-bottom: 8px
</style>
