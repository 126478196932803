<template>
  <div class="section-total-tickets">
    <div class="header">
      <p class="title heading-h6">Total de etiquetas en colas</p>
      <div>
        <a-tooltip placement="bottomRight" :arrowPointAtCenter="true">
          <template slot="title">
            Visualiza la cantidad de etiquetas asignadas a los tickets por los
            agentes en sus respectivas colas. Se mostrarán según el rango de
            fecha y hora que decidas en el filtro.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!--POPOVER DE CONFIRMACION-->
        <a-popconfirm
          v-if="visibleConfirm"
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="confirm"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template v-if="!isLoading" slot="title">
              Al descargar este dashboard en un excel solo se respetarán los
              filtros superiores.
            </template>
            <a-icon
              type="download"
              class="section__icon--download"
              :class="{
                'btn-dowload': !isLoading,
                'icon--disabled': isLoading,
              }"
            />
          </a-tooltip>
        </a-popconfirm>
        <!--POPOVER DE PROGRESO DE DESCARGA-->
        <a-popover
          v-if="visiblePopOver"
          placement="bottomRight"
          :arrowPointAtCenter="true"
          :visible="visiblePopOver"
          :overlayStyle="popStyles"
          :disabled="isLoading"
        >
          <template slot="content">
            <p style="text-align: center" class="gray-dark-900">
              {{ textProgress }}
            </p>
            <a-progress :percent="percent" :status="status" />
            <div v-if="this.status === 'exception'" class="contain-btns">
              <a-button type="link" size="small" @click="handleCancel"
                >Cancelar</a-button
              >
              <a-button type="link" size="small" @click="handleOk"
                >Volver a intentar</a-button
              >
            </div>
          </template>
          <a-icon
            type="download"
            class="btn-dowload section__icon--download"
            :class="{
              'btn-focus': visiblePopOver && !isLoading,
              'icon--disabled': isLoading,
            }"
          />
        </a-popover>
      </div>
    </div>
    <div class="body">
      <p v-if="!totalTags" class="mrg-top-12">No se encontraron etiquetas</p>
      <template v-else>
        <p class="text--left body__text">
          Este gráfico mostrará las etiquetas si es que fueron asignadas en el
          rango de fecha establecido.
        </p>
        <BarChart :styles="myStyles" :chartdata="chartdata" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BarChart from '@/app/dashboard/components/organisms/charts/BarChart.vue'
import moment from 'moment'

export default {
  name: 'SectiontotalTags',
  components: {
    BarChart,
  },
  props: {
    date: { type: Array, required: true },
    lineId: { type: String, default: 'allLines' },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '310px',
      margin: '12px 30px',
      position: 'relative',
    },
    visibleConfirm: true,
    visiblePopOver: false,
    percent: 0, // porcentaje del progress
    status: null, // status del progress
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
  }),
  computed: {
    ...mapGetters(['totalTags']),
    arrTotalTags() {
      const arr = this.totalTags.map((tag) => {
        tag.lines.sort((a, b) => a.nameLine.localeCompare(b.nameLine))
        return tag
      })
      return arr
    },
    dataSets() {
      const arr = []
      this.arrTotalTags.forEach((item) => {
        const obj = {}
        obj['label'] = item.name
        obj['backgroundColor'] = item.color
        obj['data'] = item.lines.map((line) => line.quantity)
        arr.push(obj)
      })
      return arr
    },
    chartdata() {
      if (!this.arrTotalTags[0]) return {}
      const data = {
        labels: this.arrTotalTags[0].lines.map((item) => item.nameLine),
        datasets: this.dataSets,
      }
      return data
    },
    textProgress() {
      switch (this.status) {
        case 'exception':
          return 'Lo sentimos, ocurrió un error'
        case 'active':
          return 'Descargando datos del dashboard...'
        case 'success':
          return 'Datos descargados'
        default:
          return null
      }
    },
  },
  methods: {
    ...mapActions(['exportTotalTags', 'setAnalytics']),
    // confirmar pop confirm
    confirm() {
      this.visibleConfirm = false
      this.visiblePopOver = true
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'tags_tickets' },
      })
    },
    // cancela la descarga
    handleCancel() {
      this.visiblePopOver = false
      this.visibleConfirm = true
    },
    // vuelve a descargar
    handleOk() {
      this.visiblePopOver = true
      this.exportDashboard()
    },
    exportDashboard() {
      this.percent = 70
      this.status = 'active'
      const date = `${moment(this.date[0]).toISOString()}/${moment(
        this.date[1]
      ).toISOString()}`
      const lineId = this.lineId
      this.exportTotalTags({ date, lineId })
      setTimeout(() => {
        this.percent = 100
        this.status = 'success'
      }, 2000)
      setTimeout(() => {
        this.visiblePopOver = false
        this.visibleConfirm = true
      }, 3000)
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-total-tickets
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
  .body
    position: relative
    span
      position: absolute
      left: calc(50% - 80px)
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.body__text
  margin: 12px 30px
  color: $gray_8
.section__icon--download
  font-size: 20px
  color: #595959
  margin-right: 8px
</style>
