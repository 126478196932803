<template>
  <div class="section-menu-billing">
    <a-row>
      <header class="template__header">
        <h5 class="heading-h5 mrg-bottom-0">{{ title }}</h5>
        <a-tooltip placement="bottom">
          <template slot="title">
            ❗️Importante: Nuestros procesos de cobros en {{ app }} están bajo
            horario internacional UTC, por lo que los consumos que se realicen
            aparecerán en este apartado según este formato.
          </template>
          <a-icon type="info-circle" class="template__icon" />
        </a-tooltip>
      </header>
    </a-row>
    <a-row v-if="companyPricing">
      <div class="details">
        <div class="cards">
          <CurrentPackDetail
            v-for="item in companyPricing.packs"
            :key="item.description"
            :pack="item"
            :withAction="true"
            :showCountries="item.image === 'SMS'"
            class="current-card"
          />
        </div>
      </div>
    </a-row>
    <a-tabs
      :default-active-key="defaultActiveKey"
      :tabBarStyle="{
        margin: '16px auto 20px auto',
        display: 'flex',
        justifyContent: 'flex-start',
      }"
    >
      <a-tab-pane v-if="hasRequestedWsp" key="1" tab="WhatsApp">
        <CustomSectionScroll
          id-master="section-all-products"
          :masterStyles="{
            height: 'calc(100vh - 280px)',
            width: '100%',
            marginTop: '-12px',
          }"
        >
          <!--Tabla de gastos de WhatsApp-->
          <TableMenuSpendings
            v-if="enable_spendings_tables.whatsapp.spendings"
            title="Mis gastos de saldo"
            type="spending"
            :columns="spendingColumns"
            :fetch="getWspSpendings"
            type-pagination="scroll"
            tableKey="table_1"
            @onCollapse="handleCollapseTable"
            :isSelected="true"
            :show-info-title="true"
            :footer="wspSpendings.footerColumns"
            :data-source-footer="wspSpendings.dataSourceFooter"
          />
          <!--Tabla de recargas de WhatsApp-->
          <TableMenuSpendings
            v-if="enable_spendings_tables.whatsapp.recharges"
            title="Mis Recargas"
            type="recharge"
            :columns="rechargeColumns"
            :fetch="getWspRecharges"
            tableKey="table_2"
            @onCollapse="handleCollapseTable"
            :isSelected="true"
          />
        </CustomSectionScroll>
      </a-tab-pane>
      <a-tab-pane key="2" tab="SMS">
        <!--Tabla de gastos de SMS-->
        <TableMenuSpendings
          v-if="enable_spendings_tables.sms.spendings"
          title="Mis gastos de saldo"
          type="slot"
        >
          <p>
            Los gastos de los SMS se pueden ver a detalle desde el dashboard
            <router-link to="/dashboards#dashboard-sms">
              Resumen de envíos SMS.
            </router-link>
          </p>
        </TableMenuSpendings>
        <!--Tabla de recargas de SMS-->
        <TableMenuSpendings
          v-if="enable_spendings_tables.sms.recharges"
          title="Mis Recargas"
          type="recharge"
          :columns="rechargeSmsColumns"
          :fetch="getSmsRecharges"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableMenuSpendings from '@/app/shop/components/spendings/TableMenuSpendings.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import CurrentPackDetail from '@/app/shop/components/market/CurrentPackDetail.vue'
import SpendingServices from '@/app/shop/services/spendings_services.js'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll.vue'
import {
  SPENDINGS_COLUMNS,
  RECHARGE_COLUMNS,
  RECHARGE_SMS_COLUMNS,
  FOOTER_SPENDINGS,
  // MONTH_MODIFY,
} from '@/app/shop/utils/spendingsUtil'
// import Range from '@/app/shared/utils/ranges'

import moment from 'moment'
moment.locale('es')

export default {
  name: 'SectionMenuSpendings',
  mixins: [attemptMixin],
  components: {
    TableMenuSpendings,
    CurrentPackDetail,
    CustomSectionScroll,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    FOOTER_SPENDINGS,
    moment,
    title: 'Recargas y gastos',
    secondaryDescription: {
      text: '',
      url: '',
    },
    pricePlanDetails: null,
    tableKeyCollapsed: 'table_1',
    filterSpendingsColumns: [],
    dataSourceFooterWspSpendings: {},
    wspSpendings: {
      dataSourceFooter: {},
      footerColumns: [],
    },
  }),
  async created() {
    this.intervalAttempt(async () => {
      if (!this.companyPricing) throw 'companyPricing'
      this.getCompanyPricing()
      const result = await this.pricePerUser({
        pricingId: this.companyPricing?.plan._id,
      })
      this.pricePlanDetails = result
    })
  },
  computed: {
    ...mapGetters(['companyPricing', 'enable_spendings_tables']),
    /**
     * @return {String} id del tab por defecto
     */
    defaultActiveKey() {
      return this.hasRequestedWsp ? '1' : '2'
    },
    /**
     * @return {Boolean} si tiene whatsapp en los paquetes de la empresa
     */
    hasRequestedWsp() {
      if (!this.companyPricing) return false
      return this.companyPricing.packs.some((pack) => pack.image === 'whatsapp')
    },
    /**
     * Columnas para la tabla de whatsapp
     * @return {Object[]} spendingsColumns
     * @return {String} spendingsColumns[].title
     * @return {String} spendingsColumns[].key
     * @return {String} spendingsColumns[].dataIndex
     * @return {Number} spendingsColumns[].width
     */
    spendingColumns() {
      return this.alignNumberColumns(this.filterSpendingsColumns)
    },
    /**
     * Columnas para la tabla de recargas de whatsapp
     * @return {Object[]} spendingsColumns
     * @return {String} spendingsColumns[].title
     * @return {String} spendingsColumns[].key
     * @return {String} spendingsColumns[].dataIndex
     * @return {Number} spendingsColumns[].width
     */
    rechargeColumns() {
      return this.alignNumberColumns(RECHARGE_COLUMNS)
    },
    /**
     * Columnas para la tabla de recargas de sms
     * @return {Object[]} spendingsColumns
     * @return {String} spendingsColumns[].title
     * @return {String} spendingsColumns[].key
     * @return {String} spendingsColumns[].dataIndex
     * @return {Number} spendingsColumns[].width
     */
    rechargeSmsColumns() {
      return this.alignNumberColumns(RECHARGE_SMS_COLUMNS)
    },
  },
  methods: {
    ...mapActions(['pricePerUser', 'getCompanyPricing']),
    /**
     * Obtiene los gastos de whatsapp
     * @param {Number} page
     * @param {Object} settings
     * @param {String} settings.subType
     * @param {Date} settings.startDate
     * @param {Date} settings.endDate
     * @param {String} settings.status
     * @param {String} settings.channelCompanyId
     */
    async getWspSpendings(page, settings) {
      // crear fecha del primero de junio del 2023
      const date = moment.utc().set({
        year: 2023,
        month: 5,
        date: 1,
        hour: '00',
        minute: '00',
        second: '00',
      })

      const copyStartDate = moment.utc(settings.startDate)
      const startDate = copyStartDate.set({ hour: 1 })

      // si la fecha está entre el inicio o fin
      const isBetween = date.isBetween(settings.startDate, settings.endDate)
      const isAfter = startDate.isAfter(date)
      const isBefore = startDate.isBefore(date)
      if (isBetween) this.filterColumns({ all: true })
      else if (isBefore) this.filterColumns({ all: false, onlyOld: true })
      else if (isAfter) this.filterColumns({ all: true })

      const result = await SpendingServices.getWhatsappSpendings(page, {
        ...settings,
        startDate: settings.startDate.toISOString(),
        endDate: settings.endDate.toISOString(),
      })

      // para que solo cargue la primera vez
      if (
        !this.wspSpendings.dataSourceFooter ||
        !this.wspSpendings.dataSourceFooter.isTotal
      )
        this.wspSpendings.dataSourceFooter = result.totalSpendings

      return { ...result, push: true }
    },
    async getWspRecharges(page, settings) {
      const result = await SpendingServices.getRecharges(
        'wallet',
        page,
        settings
      )
      return result
    },
    async getSmsRecharges(page, settings) {
      const result = await SpendingServices.getRecharges(
        'outbound_sms_channel',
        page,
        settings
      )
      return result
    },
    alignNumberColumns(array) {
      return array.map((column) => {
        if (column.type == 'number') {
          // column.customRender = (value) => <div class="number-cell">{value}</div>
        }
        return column
      })
    },
    handleCollapseTable(tableKey, hide) {
      if (!hide) this.tableKeyCollapsed = tableKey
      else {
        const key = tableKey === 'table_1' ? 'table_2' : 'table_1'
        this.tableKeyCollapsed = key
      }
    },
    /**
     * Filtra columnas de acuerdo a las parametros pasados
     * @param {}
     */
    filterColumns({ onlyNew = false, onlyOld = false, all = false }) {
      if (all) {
        this.filterSpendingsColumns = SPENDINGS_COLUMNS
        this.wspSpendings.footerColumns = FOOTER_SPENDINGS
      } else if (onlyNew) {
        this.filterSpendingsColumns = SPENDINGS_COLUMNS.filter(
          (column) => !column.isOld
        )
        this.wspSpendings.footerColumns = FOOTER_SPENDINGS.filter(
          (column) => !column.isOld
        )
      } else if (onlyOld) {
        {
          this.filterSpendingsColumns = SPENDINGS_COLUMNS.filter(
            (column) => !column.isNew
          )
          this.wspSpendings.footerColumns = FOOTER_SPENDINGS.filter(
            (column) => !column.isNew
          )
        }
      } else {
        this.filterSpendingsColumns = SPENDINGS_COLUMNS
        this.wspSpendings.footerColumns = FOOTER_SPENDINGS
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$padding: 32px
$space-cards: 20px

.section-menu-billing
  padding: $padding
  .heading-h5
    text-align: left
    margin-bottom: 16px
  .details
    display: flex
    justify-content: space-between
    align-items: flex-end
    .cards
      display: flex
      .card-billing
        margin: 0 $space-cards 0 0
      .current-card
        margin-right: 12px
  .table-menu-billing
    margin-top: 12px
  .number-cell
    text-align: right
</style>
