<template>
  <div class="content text--center">
    <logo :app="app" type="dark" size="medium" class="content__logo" />
    <h1 class="heading-h1 content__title">
      {{ titleModal }}
    </h1>

    <p v-for="newItem in news" :key="newItem._id" class="body-2">
      <span v-html="newItem.description"></span>
    </p>

    <p class="body-2 mrg-bottom-0">
      <strong>{{ textFooterContentFormatted }}</strong>
    </p>
    <canvas v-if="visible" id="confetti" class="content__canvas"></canvas>

    <template v-for="button in buttons">
      <button-call-to-action
        v-if="button.type === 'call-to-action'"
        :key="button._id"
        :title="button.title"
        :description="button.description"
        :link="button.link"
        :imageSrc="button.imageSrc"
        class="content__button-call"
      />
    </template>
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js'
import Logo from '@/app/shared/components/logos/Logo.vue'
import ButtonCallToAction from '@/app/commons/components/molecules/ButtonCallToAction'
import replaceAllUtils from '@/app/shared/utils/replaceAll'

export default {
  name: 'ContentWelcome',
  props: {
    visible: { type: Boolean, default: false },
    names: { type: String },
    textFooterContent: { type: String },
    title: { type: String },
    news: {
      type: Array,
      default: () => [],
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Logo,
    ButtonCallToAction,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    showCanvas: false,
  }),
  mounted() {
    setTimeout(() => {
      this.handleStartConfetti()
    }, 2000)
  },
  computed: {
    /**
     * Devuelve un objeto donde las keys son el string que hay que reemplazar por el valor de estas , por ejemplo
     * { __APP__ : "Ticker" } , si un string contiene __APP__ , este será reemplazado por "Ticker"
     * @return {Object}
     */
    variablesValues() {
      return {
        __NAMES__: this.names,
        __APP__: this.app,
      }
    },

    /**
     * Devuelve el titulo del modal pero en lugar de un string con variables, ya devuelve sus valores
     * @return {String}
     */
    titleModal() {
      return replaceAllUtils.replaceAllStr(this.title, this.variablesValues)
    },

    /**
     * Devuelve el texto del footer del contenido del modal pero en lugar de un string con variables, ya devuelve sus valores
     * @return {String}
     */
    textFooterContentFormatted() {
      return replaceAllUtils.replaceAllStr(
        this.textFooterContent,
        this.variablesValues
      )
    },
  },
  methods: {
    handleStartConfetti() {
      var confettiSettings = {
        target: 'confetti',
        width: '900',
        height: '60',
      }
      var confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 80px 160px 0px 160px
  width: 900px
  position: relative
  color: $gray_dark_900
  &__title
    text-align: center
  &__logo
    margin-bottom: 20px
  &__canvas
    position: absolute
    top: 0px
  &__picture
    position: absolute
    right: 20px
    bottom: 0px
  &__button-call
    margin-top: 40px
</style>
