<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :bodyStyle="bodyStyle"
    width="32em"
    @cancel="handleCancel"
    centered
  >
    <div class="modal">
      <!-- El icono puede ser: socialicon, anticon y every -->
      <social-icon
        v-if="icon.type == 'socialicon'"
        :type="icon.path_or_name"
        size="52"
        class="modal__picture"
      />

      <a-icon v-if="icon.type == 'anticon'" :type="icon.path_or_name" />

      <img
        v-if="icon.type == 'evericon'"
        :src="require(`@/app/commons/assets/${icon.path_or_name}`)"
        alt=""
      />

      <h5 class="heading-h5 title">{{ title }}</h5>
      <h6 class="heading-h6 subtitle">{{ subtitle }}</h6>
      <p class="body-2 description">{{ description }}</p>
      <template v-if="showButtons">
        <a-button type="primary" class="modal__button" @click="handleOk">{{
          nameButton
        }}</a-button>
        <a class="link-cancel" @click="handleCancel">Quizás más tarde</a>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import compareMixin from '@/app/shared/mixins/compare'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'BasicModal',
  props: {
    title: {
      // titulo, podria ser el nombre del plan
      type: String,
      require: false,
      default: 'Title Ticker',
    },
    subtitle: {
      type: String,
      require: false,
      default: 'Here is the subtitle',
    },
    icon: {
      type: Object,
      default: () => {
        return { type: 'socialicon', path_or_name: 'sms' }
      },
    },
    routeNameButtonOk: {
      type: String,
      require: false,
      default: 'shop',
    },
    id: {
      type: String,
      require: false,
      default: null,
    },
    description: {
      type: String,
      require: false,
      default:
        'Ad deserunt tempor reprehenderit fugiat nisi veniam occaecat. Magna magna consequat qui nisi qui dolore. Minim mollit exercitation incididunt exercitation aliqua exercitation elit laborum fugiat adipisicing ad ea ex.',
    },
    visible: {
      // hacer visible o no el modal
      type: Boolean,
      require: false,
      default: false,
    },
    nameButton: {
      type: String,
      require: false,
      default: 'ok',
    },
  },
  components: {
    SocialIcon,
  },
  data: () => ({
    bodyStyle: {
      padding: '40px 36px',
    },
    vari: vari,
  }),
  mixins: [compareMixin],
  computed: {
    ...mapGetters(['profile', 'active_breakpoint']),

    /**
     * @return {Boolean} - solo se muestran los botones para los administradores
     */
    showButtons() {
      // si el usuario es solo agente no debería ver el boton de subir de plan
      return (
        this.profile &&
        this.isAllowedFor(this.profile.type, ['admin', 'master']) &&
        !this.active_breakpoint.is_mobile
      )
    },
  },
  methods: {
    handleCancel() {
      this.$emit('onClose', this.id)
    },
    handleOk() {
      this.$emit('onOk', this.routeNameButtonOk, this.id)
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  .title
    margin-top: 20px
    margin-bottom: 4px
    color: $gray_dark_900
  .subtitle
    margin-top: 8px
    margin-bottom: 12px
    color: $gray_dark_800
  .link
    margin-bottom: 24px
  .link-cancel
    margin-top: 12px
    color: $gray_dark_800
.modal__picture
  cursor: auto
.modal__button
  width: 100%
</style>
