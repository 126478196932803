<template>
  <div class="no-connection" :style="`background-color: ${bgColor}`">
    <svg
      v-if="icon === 'internet'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.2559 1.07742L1.07739 2.25593L4.40316 5.5817C3.44323 6.1043 2.54119 6.77166 1.72908 7.58376C1.70817 7.60467 1.68736 7.62564 1.66665 7.64666L2.8452 8.82521C2.86588 8.80416 2.88668 8.78318 2.90759 8.76228C3.72697 7.9429 4.65316 7.29544 5.64135 6.81989L6.87114 8.04968C5.81034 8.45542 4.81578 9.08579 3.96078 9.94079L5.13929 11.1193C6.01603 10.2426 7.07621 9.66107 8.19628 9.37482L9.65857 10.8371C8.44463 10.8841 7.24457 11.371 6.3178 12.2978L7.49631 13.4763C8.79806 12.1746 10.9086 12.1746 12.2104 13.4763L12.2541 13.4326L17.7441 18.9226L18.9226 17.7441L2.2559 1.07742ZM11.106 7.59414L14.5992 11.0874L15.7459 9.94079C14.4382 8.63315 12.8042 7.85094 11.106 7.59414ZM7.86961 4.3578L9.36395 5.85214C12.0966 5.68487 14.8848 6.63412 16.9868 8.69989L18.1653 7.52134C15.3536 4.75108 11.4855 3.69657 7.86961 4.3578ZM11.0318 14.6548C10.381 14.004 9.3257 14.004 8.67482 14.6548L9.85333 15.8333L11.0318 14.6548Z"
        fill="white"
      />
    </svg>
    <svg
      v-else-if="icon === 'api'"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0172 9.81328C17.9438 6.98203 15.2086 4.96875 12.0047 4.96875C8.80078 4.96875 6.06563 6.97969 4.99219 9.81094C2.98359 10.3383 1.5 12.1664 1.5 14.3438C1.5 16.9336 3.59766 19.0312 6.18516 19.0312H17.8148C20.4023 19.0312 22.5 16.9336 22.5 14.3438C22.5 12.1711 21.0211 10.343 19.0172 9.81328ZM19.868 16.3992C19.599 16.6698 19.2791 16.8842 18.9266 17.0303C18.5742 17.1763 18.1963 17.251 17.8148 17.25H6.18516C5.40937 17.25 4.68047 16.9477 4.13203 16.3992C3.86146 16.1298 3.64696 15.8094 3.50093 15.4566C3.3549 15.1038 3.28024 14.7256 3.28125 14.3438C3.28125 13.6875 3.49453 13.0711 3.89531 12.5555C4.28779 12.056 4.83121 11.6967 5.44453 11.5312L6.33281 11.2992L6.65859 10.4414C6.86016 9.90703 7.14141 9.40781 7.49531 8.95547C7.8447 8.50713 8.25857 8.11301 8.72344 7.78594C9.68672 7.10859 10.8211 6.75 12.0047 6.75C13.1883 6.75 14.3227 7.10859 15.2859 7.78594C15.7523 8.11406 16.1648 8.50781 16.5141 8.95547C16.868 9.40781 17.1492 9.90938 17.3508 10.4414L17.6742 11.2969L18.5602 11.5312C19.8328 11.8711 20.7188 13.0266 20.7188 14.3438C20.7188 15.1195 20.4164 15.8508 19.868 16.3992Z"
        fill="white"
      />
      <rect
        x="18.9705"
        y="4"
        width="2"
        height="24"
        rx="0.3"
        transform="rotate(45 18.9705 4)"
        fill="white"
      />
    </svg>
    <a
      v-if="text"
      :href="url"
      style="color: #fff"
      :style="!url ? 'cursor: default' : ''"
      target="_blank"
      rel="noopener noreferrer"
    >
      <h6 class="heading-h7">{{ text }}</h6>
    </a>
    <h6 v-else-if="textHTML" class="heading-h7 textHTML" v-html="textHTML"></h6>
    <a-icon
      type="close"
      style="font-size: 14px; position: absolute; right: 8px"
      :style="`color: ${color}`"
      @click="handleClose"
    />
  </div>
</template>
<script>
export default {
  name: 'NoConnection',
  props: {
    icon: { type: String },
    text: { type: String },
    textHTML: { type: String },
    bgColor: { type: String, default: '#FF6A72' },
    color: { type: String, default: '#FFFFFF' },
    url: { type: String },
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
  },
}
</script>
<style lang="sass" scoped>
.no-connection
  background-color: #FF6A72
  position: fixed
  z-index: 1054
  color: $white_000
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  padding-top: 8px
  width: 100%
  padding-bottom: 8px
  h6
    color: inherit
    margin-bottom: 0
    margin-left: 8px
  .textHTML
    display: flex
</style>
