import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Obtiene todos los canales
   * @param {*} context
   */
  async getChannels(context) {
    try {
      const response = await axios.get(`${vari.UHR}/channels`)
      if (response.data.success)
        context.commit('SET_CHANNELS', response.data.data)
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Finaliza los tickets por canal
   * @param {String} channel
   */
  async finishTicketsInChannel(context, channel) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/ticket/ended/massive`,
        { channel }
      )
      return response.data
    } catch (error) {
      console.error('finishTicketsInChannel', console.error())
      return error
    }
  },
  /**
   * Obtiene todas las plantillas de la empresa de acuerdo a los parametros
   * @param {*} context
   * @param {String} state
   * @param {Boolean} requestState
   * @param {String} name
   * @param {Number} skip
   * @param {String} category
   * @param {String} lang
   */
  async getAllTemplates(
    context,
    { channelId, state, requestState, name, skip, category, lang }
  ) {
    try {
      const response = await axios.get(
        `${
          vari.UHR
        }/admin/templates/${channelId}?state=${state}&requestState=${requestState}&name=${name}&skip=${skip}&category=${category}&lang=${lang}&date=${new Date()}`
      )
      context.commit('SET_ALL_TEMPLATES', response.data)
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Obtiene todas las plantillas siguientes
   * @param {*} context
   */
  async getAllNextTemplates(
    context,
    { channelId, state, requestState, name, skip, category, lang }
  ) {
    try {
      const response = await axios.get(
        `${
          vari.UHR
        }/admin/templates/${channelId}?state=${state}&requestState=${requestState}&name=${name}&skip=${skip}&category=${category}&lang=${lang}&date=${new Date()}`
      )
      context.commit('SET_ALL_NEXT_TEMPLATES', response.data)
    } catch (error) {
      console.error(error)
    }
  },
  async changeTempateState(context, { templateId }) {
    try {
      await axios.patch(`${vari.UHR}/admin/templates/${templateId}`)
      return { success: true }
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Crea o registra una plantilla del canal de whatsapp
   * @param {*} context
   * @param {Object} args
   * @param {String} args.name
   * @param {String} args.friendlyName
   * @param {String} args.body
   * @param {String} args.category
   * @param {String} args.lang
   * @param {String} args.channelId
   * @param {String} args.type
   * @param {String} args.typeAttachment
   */
  async createTemplate(
    context,
    {
      name,
      friendlyName,
      body,
      parameters,
      category,
      lang,
      channelId,
      type,
      typeAttachment,
    }
  ) {
    try {
      const response = await axios.post(`${vari.UHR}/admin/templates`, {
        name,
        friendlyName,
        body,
        parameters,
        category,
        lang,
        channelId,
        type,
        typeAttachment,
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Actualiza una plantilla
   * @param {} context
   * @param {String} templateId id de la plantilla
   * @param {Object} template objeto con las propiedades a actualizar
   */
  async updateTemplate(context, { templateId, template }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/templates/${templateId}`,
        {
          ...template,
        }
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Valida si la plantilla ya fue aprobada por su proveedor
   * @param {*} context
   * @param {Object} template
   * @param {String} channel
   */
  async validateTemplate(context, { template, companyChannelId }) {
    try {
      const response = await axios.post(
        `${vari.UHR}/admin/test/template/${companyChannelId}`,
        template
      )
      return response.data
    } catch (error) {
      console.error('error de validar plantilla', error)
    }
  },

  /**
   * Listar los canales de la sandbox disponibles para que lo use la empresa
   * @param {*} context
   */
  async listSandboxChannels(context) {
    try {
      const response = await axios.get(`${vari.UHR}/sandbox/channels`)
      const sandboxChannels = response.data
      context.commit('SET_SANDBOX_CHANNELS', sandboxChannels)
    } catch (error) {
      console.error('Error al cargar los canales de la sandbox', error)
      return error.response?.data
    }
  },
  /**
   * Lista los canales conectados de una empresa con paginación
   * @param {*} context
   */
  async getCompanyChannels(
    context,
    { page = 1, limit = 12, status = 'all', channel = 'all' }
  ) {
    const params = {
      page,
      limit,
      status,
      channel,
    }
    try {
      const response = await axios.get(`${vari.UHR}/my-channels/paginate`, {
        params,
      })
      if (response.data.success) {
        const myChannels = response.data.data.data
        context.commit('SET_PAGINATE_COMPANY_CHANNELS', myChannels)
        delete response.data.data.data
        context.commit('SET_PAGINATION_COMPANY_CHANNELS', response.data.data)
      }
      return response.data
    } catch (error) {
      console.error('getCompanyChannels', error)
      return error.response?.data
    }
  },

  /**
   * Lista los canales conectados de una empresa con datos básicos y sin paginación
   * @param {*} context
   */
  async getSimpleCompanyChannels(context) {
    try {
      const response = await axios.get(`${vari.UHR}/my-channels/simple`)
      if (response.data.success) {
        const simpleCompanyChannels = response.data.data
        // Setea para los canale simples y los canales que se pueden filtrar
        context.commit('SET_SIMPLE_COMPANY_CHANNELS', simpleCompanyChannels)
        // Setea canales a company
        context.commit('SET_GENERAL_COMPANY_CHANNELS', simpleCompanyChannels)
      }
      return response.data
    } catch (error) {
      console.error('[getSimpleCompanyChannels]', error)
      return error.response?.data
    }
  },
  /**
   * Lista los canales conectados de una empresa con datos básicos y sin paginación
   * @param {*} context
   */
  async getCompanyChannel(context, companyChannelId) {
    try {
      const response = await axios.get(
        `${vari.UHR}/my-channels/${companyChannelId}/get`
      )
      if (response.data && response.data.success)
        context.commit('SET_CURRENT_CHANNEL', response.data.data)
      return response.data
    } catch (error) {
      console.error('[getCompanyChannel]', error)
      return error.response?.data
    }
  },
}

export default actions
