<template>
  <div class="section-mailbox">
    <div class="section-mailbox-card">
      <h6 class="heading-h6 title">
        <a-icon type="inbox" class="mrg-right-8" />Buzones
      </h6>
      <p class="body-2 description" style="margin-bottom: 20px">
        Crear buzones permitirá que los agentes puedan agrupar. Solo podrás
        agregar 5 como máximo.
      </p>
      <!-- NUEVO BUZÓN -->
      <a-row>
        <!--template con submit-->
        <template v-if="rows.length < 5">
          <a-form
            layout="vertical"
            :form="form"
            @submit="handleSubmit"
            class="modal-form"
          >
            <a-form-item
              label="Nuevo nombre de buzón"
              class="section-form-item"
              :validate-status="mailBoxNameError() ? 'error' : ''"
              :help="mailBoxNameError() || ''"
            >
              <div class="inline">
                <a-input
                  :maxLength="50"
                  v-decorator="[
                    'mailboxName',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor rellena el campo',
                        },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  placeholder="Ingrese nombre"
                  size="default"
                  :disabled="rows.length >= 5"
                />
                <a-button
                  type="primary"
                  html-type="submit"
                  :disabled="
                    rows.length >= 5 || hasErrors(form.getFieldsError())
                  "
                >
                  Agregar buzón
                </a-button>
              </div>
            </a-form-item>
          </a-form>
        </template>
        <!--template sin submit-->
        <template v-if="rows.length >= 5">
          <a-form layout="vertical" :form="form" class="modal-form">
            <a-form-item
              label="Nuevo nombre de buzón"
              class="section-form-item"
              :validate-status="mailBoxNameError() ? 'error' : ''"
              :help="mailBoxNameError() || ''"
            >
              <div class="inline">
                <a-input
                  :maxLength="50"
                  v-decorator="[
                    'mailboxName',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor rellena el campo',
                        },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  placeholder="Ingrese nombre"
                  size="default"
                  :disabled="rows.length >= 5"
                />
                <a-button
                  type="primary"
                  :disabled="
                    rows.length >= 5 || hasErrors(form.getFieldsError())
                  "
                >
                  Agregar buzón
                </a-button>
              </div>
            </a-form-item>
          </a-form>
        </template>
      </a-row>
      <!-- TABLA DE BUZONES -->
      <a-row>
        <a-table
          class="scrollbar_basic table-middle"
          :columns="columns"
          :data-source="rows"
          :pagination="false"
          :scroll="{ y: 'calc(90.2vh - 19em - 250px)' }"
          size="small"
        >
          <template
            v-for="col in ['order', 'mailboxName']"
            :slot="col"
            slot-scope="attr, record"
          >
            <div :key="col">
              <a-input
                v-if="record.editable && col !== 'order'"
                style="margin: -5px 0"
                :defaultValue="attr.value"
                @change="(e) => handleChange(e.target.value, record.key, col)"
                :maxLength="50"
              />
              <template v-else>
                {{ attr.value }}
              </template>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => handleSave(record)">Guardar</a>
                <a-divider type="vertical" />
                <a @click="handleCancel(record)">Cancelar</a>
              </span>
              <span v-else>
                <a
                  :disabled="!record.editableMailbox.value || editingKey !== ''"
                  @click="handleEdit(record)"
                  >Editar</a
                >
                <a-divider type="vertical" />
                <a
                  :disabled="!record.editableMailbox.value || editingKey !== ''"
                  @click="handleDelete(record)"
                  >Eliminar</a
                >
              </span>
            </div>
          </template>
        </a-table>
      </a-row>
    </div>
    <!-- MODAL DE ELIMINAR BUZÓN -->
    <a-modal
      v-if="mailboxSelected"
      title="Eliminar buzón"
      :visible="visibleMoDelete"
      :closable="false"
      :bodyStyle="bodyStyle"
      centered
    >
      <p class="body-2" style="margin-bottom: 20px">
        Estás por eliminar el buzón "{{ mailboxSelected.title }}", recuerda que
        los tickets pendientes, podrás repartirlos a otro buzón o marcarlos como
        finalizados
      </p>
      <div class="options">
        <p class="heading-h7" style="margin-bottom: 8px">Opciones</p>
        <div>
          <a-checkbox v-model="chkMove" @change="(e) => onChangeChk(e, 'move')">
            Enviar tickets a un buzón
          </a-checkbox>
          <div class="mailboxes">
            <a-select
              style="width: calc(100% - 27px); margin: 8px 0 12px 27px"
              placeholder="Seleccionar buzón"
              @change="handleChangeMailbox"
              :disabled="!chkMove"
            >
              <a-select-option
                v-for="(availableMailbox, index) in availableMailboxes"
                :key="index"
                :value="availableMailbox.idMailbox"
              >
                {{ availableMailbox.title }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div>
          <a-checkbox
            v-model="chkEnd"
            @change="(e) => onChangeChk(e, 'finish')"
          >
            Marcar como finalizados
          </a-checkbox>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" style="width: 85px" @click="handleMoCancel">
          Cancelar
        </a-button>
        <a-button
          key="submit"
          style="min-width: 85px"
          type="primary"
          :loading="loadingMo"
          @click="handleMoOk"
          :disabled="disabledToMove"
        >
          Enviar
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'SectionMailbox',
  data() {
    return {
      rows: [],
      editingKey: '',
      columns: [
        {
          title: 'Nº',
          key: 'order',
          dataIndex: 'order',
          width: '10%',
          scopedSlots: { customRender: 'order' },
        },
        {
          title: 'Nombre de buzón',
          key: 'mailboxName',
          dataIndex: 'mailboxName',
          scopedSlots: { customRender: 'mailboxName' },
        },
        {
          title: 'Acción',
          key: 'action',
          dataIndex: 'action',
          width: '30%',
          scopedSlots: { customRender: 'action' },
        },
      ],
      mailboxSelected: null, // Buzón seleccionado para eliminar
      visibleMoDelete: false, // Visualizar Modal de opciones de Acciones de Buzones antes de eliminar
      mailboxToMove: null, // Buzón a transferir
      chkMove: true, // Checkbox de mover a otro buzón
      chkEnd: false, // Checkbox de finalizar
      loadingMo: false, // Carga del botón del modal de eliminación
      submit: false, // acompañan a handleSubmit para evitar mas de 1 enter . se obliga a entrar en el primer if en el primer de handlesubmit
      bodyStyle: {
        padding: '12px 16px',
        color: '#262A31',
      },
    }
  },
  props: {
    mailboxes: {
      type: Array,
      required: true,
    },
  },
  watch: {
    mailboxes() {
      this.load()
    },
  },
  computed: {
    disabledToMove() {
      // Boton de Enviar desabilitado
      if (this.chkMove && this.mailboxToMove !== null) return false
      else if (this.chkEnd) return false
      else return true
    },
    availableMailboxes() {
      // Buzones disponibles para mover los tickets del buzon que se eliminará
      if (!this.mailboxSelected) return []
      return this.mailboxes
        .filter((box) => !box.end)
        .filter((box) => box.idMailbox !== this.mailboxSelected.idMailbox)
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  mounted() {
    this.load(),
      this.$nextTick(() => {
        // Inhabilita el boton al inicio
        this.form.validateFields()
      })
  },
  methods: {
    ...mapActions([
      'addNewMailbox',
      'setMailbox',
      'deleteMailbox',
      'moveToMailbox',
    ]),
    /** Carga las filas de la tabla */
    load() {
      this.rows = []
      let list = [...this.mailboxes]
      let n = 0
      list = list
        .sort((a, b) => (b.created_at ? -1 : 0))
        .sort((a, b) => (b.editable ? -1 : 0)) // Reordena y coloca los "No editables" al principio
      list = list.map((box) => {
        n++
        let row = {
          key: `${box.idMailbox}`,
          idMailbox: { value: `${box.idMailbox}` },
          order: { value: `${n}` },
          mailboxName: { value: `${box.title}` },
          editableMailbox: { value: box.editable },
        }
        return row
      })
      this.rows = list
    },
    /** */
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field])
    },
    // solo muestra error despues de que el input es tocado
    mailBoxNameError() {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('mailboxName') && getFieldError('mailboxName')
    },
    /** Crea un nuevo buzón */
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.submit) {
            this.submit = true
            let mailbox = { title: values.mailboxName }
            console.log('create mailbox', mailbox) // CREAR EL BUZON AQUÍ
            let response = await this.addNewMailbox(mailbox)
            if (!response.success) return
            console.log(response)
            this.clearFields()
            this.$message.success({
              content: `El buzón se creó correctamente`,
              key: 'updatable',
              duration: 2,
            })
            return (this.submit = false)
          } else {
            // si llega aqui es porque se dio 2 o mas veces  enter
            console.log('ya se hizo submit')
          }
        }
      })
    },
    /** Guarda los cambios al escribir en un inout editable de una de las filas */
    handleChange(value, key, column) {
      console.log(column)
      const newData = [...this.rows]
      const target = newData.find((item) => key === item.key)
      if (target) {
        target[column].value = value
        this.rows = newData
      }
    },
    /** Guarda los cambios de una fila */
    async handleSave(record) {
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)
      if (target) {
        delete target.editable
        this.rows = newData
        let mailbox = this.mailboxes.find(
          (box) => box.idMailbox === record.idMailbox.value
        )
        Object.assign(mailbox, {
          title: record.mailboxName.value,
        })
        await console.log('save mailbox', mailbox) // GUARDAR EL BUZON AQUÍ
        await this.setMailbox(mailbox)
        this.$message.success({
          content: `El buzón se guardó correctamente`,
          key: 'updatable',
          duration: 2,
        })
      }
      this.editingKey = ''
    },
    /** Habilita una fila a un input editable */
    handleEdit(record) {
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)
      this.editingKey = record.key
      if (target) {
        target.editable = true
        this.rows = newData
      }
    },
    /** Devuelve el input editable a una fila no editable */
    handleCancel(record) {
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)
      this.editingKey = ''
      if (target) {
        delete target.editable
        this.rows = newData
        /** Devuelve los valores anteriores */
        let mailbox = this.mailboxes.find(
          (box) => box.idMailbox === record.idMailbox.value
        )
        let rows = this.rows.find((d) => d.key === record.idMailbox.value)
        Object.assign(rows, { mailboxName: { value: mailbox.title } })
      }
    },
    /** QUita a una fila de la tabla de buzones */
    async handleDelete(record) {
      this.form.validateFields()
      let mailbox = this.mailboxes.find(
        (box) => box.idMailbox === record.idMailbox.value
      )
      this.mailboxSelected = mailbox
      const response = await this.deleteMailbox(mailbox)
      if (response.success) {
        this.$message.success({
          content: `El buzón se eliminó correctamente`,
          key: 'updatable',
          duration: 2,
        })
      } else {
        this.handleMoShow()
      }
    },
    /** Limpia los campos del formulario de crear nuevo buzón */
    clearFields() {
      this.form.setFieldsValue({
        mailboxName: '',
      })
    },
    // MODAL
    handleMoShow() {
      this.visibleMoDelete = true
    },
    handleMoCancel() {
      this.visibleMoDelete = false
    },
    /**
     * Cuando hay un cambio en el estado del checkbox
     * @param {Event} e
     * @param {String} action Nombre de la acción que realiza el checkbox
     */
    onChangeChk(e, action) {
      switch (action) {
        case 'move': {
          this.chkEnd = false
          break
        }
        case 'finish': {
          this.chkMove = false
          break
        }
      }
    },
    /** Se ejecuta cuando hay un cambio en el select para cambiar de buzon */
    handleChangeMailbox(value) {
      console.log(`selected ${value}`)
      this.mailboxToMove = this.mailboxes.find((box) => box.idMailbox === value)
    },
    async handleMoOk() {
      this.loadingMo = true
      let args = {
        mailbox_edited: this.mailboxSelected,
        action:
          this.chkMove === true
            ? 'move'
            : this.chkEnd === true
            ? 'finish'
            : null,
        mailbox_merge: this.mailboxToMove,
      }
      const response = await this.moveToMailbox(args)
      this.handleMoCancel()
      this.loadingMo = false
      if (response.success) {
        await this.deleteMailbox(this.mailboxSelected)
        this.$message.success({
          content: `El buzón se eliminó correctamente`,
          key: 'updatable',
          duration: 2,
        })
      } else
        this.$message.error({
          content: `Hubo un error al eliminar el buzón`,
          key: 'updatable',
          duration: 2,
        })
    },
  },
}
</script>

<style lang="sass" scoped>
// $max-table: 442px

.section-mailbox
  padding: 12px 20px
  border: 1px solid #D9D9D9
  border-radius: 8px
  &-card
    .title
      text-align: left
      color: $gray_dark_800
    .description
      text-align: left
      color: $gray_light_900
  .ant-row
    // max-width: $max-table
    // margin: 0 auto
    .inline
      display: flex
      &>*
        &:last-child
          margin: 0 0 0 12px
      input[type="text"]
        max-width: 512px
</style>

<style lang="sass">
.section-mailbox
  .ant-row
    table
      tbody
        td
          padding: 8px !important
    .scrollbar_basic
      .ant-table-body
        &::-webkit-scrollbar
          width: 10px
        &::-webkit-scrollbar-track
          background: transparent
          border-left: solid 5px transparent
        &::-webkit-scrollbar-thumb
          border-radius: 8px
          box-shadow: inset 0 0 10px 10px $gray_dark_100
          border: solid 3px transparent
        &::-webkit-scrollbar-thumb:hover
          box-shadow: inset 0 0 10px 10px $gray_dark_100
.section-form-item
  .ant-form-item-control-wrapper
    .has-error
      .ant-form-explain
        text-align: left
    .ant-form-item-control
      .ant-form-explain, .show-help-leave, .show-help-active
        text-align: left!important
</style>
