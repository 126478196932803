<template>
  <div class="tree">
    <!--NODO BASICO-->
    <basic-node
      v-if="structureNode.layout === 'basic-node'"
      class="tree__width"
      :class="{ tree__connection: dataSource.parentNodeId }"
      :title="structureNode.title"
      :icon-type="structureNode.icon"
      :description="dataSource.drawer.question.content"
      :action="
        formattedAction(
          dataSource.drawer.action.type,
          dataSource.drawer.action.nodeType,
          dataSource.drawer.action.wait
        )
      "
      :selected="selectedNodeId === dataSource._id ? true : false"
      :tooltip-action="
        dataSource.drawer.action.type === 'none'
          ? structureNode.tooltip_text
          : null
      "
      :disabled="disabledActions"
      :only-read="dataSource.onlyRead"
      :attachment="
        dataSource.drawer.question.media
          ? dataSource.drawer.question.media.type
          : null
      "
      @onPreviewAttachment="
        handlePreviewAttachment(dataSource.drawer.question.media)
      "
      @onDelete="
        handleShowConfirmToDelete({
          type: dataSource.drawer.question.type,
          nodeId: dataSource._id,
          parentNodeId: dataSource.parentNodeId,
          parentNodeType: dataSource.parentNodeType,
        })
      "
      @onSelect="handleOpenDrawer(dataSource)"
    />
    <!--NODO PADRE-->
    <parent-node
      v-else-if="structureNode.layout === 'parent-node'"
      class="tree__width"
      :class="{
        tree__connection__card: structureNode.type === 'cards',
        tree__connection:
          structureNode.type !== 'cards' && dataSource.parentNodeId,
      }"
      :title="structureNode.title"
      :icon-type="structureNode.icon"
      :description="
        dataSource.drawer.question[structureNode.description_property]
      "
      :selected="selectedNodeId === dataSource._id ? true : false"
      :disabled="disabledActions || disabledParentActionsLocal"
      :disabled-add="disabledParentNodes"
      :addText="structureNode.add_text"
      :only-read="dataSource.onlyRead"
      :node-type="structureNode.type"
      @onAdd="handleAddChild(dataSource._id, dataSource.drawer.question.type)"
      @onDelete="
        handleShowConfirmToDelete({
          type: dataSource.drawer.question.type,
          nodeId: dataSource._id,
          parentNodeId: dataSource.parentNodeId,
          parentNodeType: dataSource.parentNodeType || null,
        })
      "
      @onSelect="handleOpenDrawer(dataSource)"
    />
    <!--NODE HIJO-->
    <template v-if="structureNode.child">
      <div class="tree__options">
        <div
          v-for="(child, index) in children"
          :key="index"
          :class="{
            'tree__option__connection-brothers': children[index + 1],
            tree__option__first: index === 0 ? true : false,
          }"
        >
          <!--Si el hijo NO es de tipo padre-->
          <template v-if="!structureNode.child.simulate_parent">
            <child-node
              :title="structureNode.child.title"
              :description="child.text"
              :order="child.order"
              :quantity-orders="children.length"
              :data-source="child"
              class="tree__width tree__option"
              :class="{ tree__option__connection: child.parentNodeId }"
              :selected="
                selectedNodeId === child[structureNode.child.id_property]
                  ? true
                  : false
              "
              :show-delete="child.order === 1 ? false : true"
              :action="
                formattedAction(
                  child.action.type,
                  child.action.nodeType,
                  child.action.wait
                )
              "
              :isAllowedChange="isSavedNode"
              :disabled="disabledActions"
              :node-type-parent="structureNode.type"
              @onDelete="
                handleShowConfirmToDelete({
                  type: structureNode.child.type,
                  parentNodeId: child.parentNodeId,
                  childId: child[structureNode.child.id_property],
                  parentNodeType: structureNode.type,
                })
              "
              @onSelect="
                handleOpenDrawer(
                  { ...child, onlyRead: dataSource.onlyRead },
                  structureNode.child.id_property,
                  dataSource._id
                )
              "
              @onChangeOrder="handleChangeOrderChild"
            />
            <div class="tree__child" v-if="child.child">
              <tree
                :data-source="{
                  ...child.child,
                  parentNodeType: structureNode.child.type,
                }"
                @onOpenDrawer="handleOpenDrawer"
                :disabled-actions="disabledActions"
                @onPreviewAttachment="handlePreviewAttachment"
              />
            </div>
          </template>
          <template v-else>
            <!--Si el hijo es de tipo padre-->
            <child-node
              :title="structureNode.child.title"
              :description="child.title"
              :order="child.order"
              :quantity-orders="children.length"
              :data-source="child"
              class="tree__width tree__option"
              :class="{ tree__option__connection: child.parentNodeId }"
              :selected="
                selectedNodeId === child[structureNode.child.id_property]
              "
              :simulate-parent="true"
              :show-delete="child.order === 1 ? false : true"
              :isAllowedChange="isSavedNode"
              :disabled="disabledActions || disabledChildActionsLocal"
              :node-type-parent="structureNode.type"
              :add-text="structureNode.child.add_text"
              attachment="image"
              @onDelete="
                handleShowConfirmToDelete({
                  type: structureNode.child.type,
                  childId: child[structureNode.child.id_property],
                  parentNodeId: child.parentNodeId,
                  parentNodeType: structureNode.type,
                })
              "
              @onSelect="
                handleOpenDrawer(
                  child,
                  structureNode.child.id_property,
                  dataSource._id
                )
              "
              @onChangeOrder="changeOrderSimulateParent"
              @onAdd="
                handleAddSubchild(
                  child.parentNodeId,
                  structureNode.type,
                  child[structureNode.child.id_property]
                )
              "
              @onPreviewAttachment="
                handlePreviewAttachment(
                  child.media ? { type: 'image', ...child.media } : null
                )
              "
            />
            <div class="tree__options tree__subchildren">
              <div
                v-for="(subchild, indexButton) in child[
                  structureNode.child.child.group
                ]"
                :key="indexButton"
                :class="{
                  'tree__option__connection-brothers':
                    child[structureNode.child.child.group][indexButton + 1],
                  tree__option__first: indexButton === 0 ? true : false,
                }"
              >
                <child-node
                  :title="structureNode.child.child.title"
                  :description="
                    subchild[structureNode.child.child.title_property]
                  "
                  :order="subchild.order"
                  :quantity-orders="
                    child[structureNode.child.child.group].length
                  "
                  :data-source="subchild"
                  class="tree__width tree__option"
                  :class="{ tree__option__connection: subchild.parentNodeId }"
                  :selected="
                    selectedNodeId ===
                    subchild[structureNode.child.child.id_property]
                  "
                  :action="
                    formattedAction(
                      subchild.action.type,
                      subchild.action.nodeType,
                      false
                    )
                  "
                  :show-delete="subchild.order === 1 ? false : true"
                  :isAllowedChange="isSavedNode"
                  :disabled="disabledActions"
                  :node-type-parent="structureNode.type"
                  @onDelete="
                    handleExecuteDeleteSubchild({
                      type: structureNode.child.child.type,
                      childId: child[structureNode.child.id_property],
                      subchildId:
                        subchild[structureNode.child.child.id_property],
                      parentNodeId: child.parentNodeId,
                      parentNodeType: structureNode.type,
                    })
                  "
                  @onSelect="
                    handleOpenDrawer(
                      subchild,
                      structureNode.child.child.id_property,
                      dataSource._id,
                      true,
                      child[structureNode.child.id_property]
                    )
                  "
                  @onChangeOrder="changeOrderSubchild"
                />
                <div class="tree__child" v-if="subchild.child">
                  <tree
                    :data-source="{
                      ...subchild.child,
                      parentNodeId:
                        subchild[structureNode.child.child.id_property],
                      parentNodeType: structureNode.child.child.type,
                    }"
                    @onOpenDrawer="handleOpenDrawer"
                    :disabled-actions="disabledActions"
                    @onPreviewAttachment="handlePreviewAttachment"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <!--SI EL NODO ACTUAL TIENE HIJOS-->
    <div class="tree__child" v-if="dataSource.child">
      <tree
        :data-source="dataSource.child"
        @onOpenDrawer="handleOpenDrawer"
        @onPreviewAttachment="handlePreviewAttachment"
        :disabled-actions="disabledActions"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ParentNode from './ParentNode'
import BasicNode from './BasicNode'
import ChildNode from './ChildNode'
import { NODES } from '@/app/chatbots/utils/componentsDataNodes'
import addDefaultChild from '@/app/chatbots/mixins/actionsToNodes'
import deleteNodes from '@/app/chatbots/mixins/deleteNodes'

export default {
  name: 'Tree',
  components: {
    ParentNode,
    BasicNode,
    ChildNode,
  },
  mixins: [addDefaultChild, deleteNodes],
  props: {
    dataSource: {
      type: Object,
      default: () => ({
        drawer: {
          options: [],
        },
      }),
      required: true,
    },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    NODES,
    disabledOptionsActions: false,
    disabledParentActionsLocal: false,
    disabledChildActionsLocal: false,
  }),
  computed: {
    ...mapGetters([
      'nodes',
      'selectedNodeId',
      'isSavedNode',
      'chatbot',
      'validations_chatbot',
    ]),

    children() {
      return (
        this.dataSource.drawer.options ||
        this.dataSource.drawer.buttons ||
        this.dataSource.drawer.cards ||
        this.dataSource.drawer.lists
      )
    },
    /**
     * @return {String} id del chatbot
     */
    chatbotId() {
      return this.$route.params.id
    },
    /**
     * @return {Object} structure
     * @return {Object} structure.child
     */
    structureNode() {
      return NODES[this.dataSource.drawer?.question.type]
    },
    /**
     * Deshabilita las acciones del nodo multiple
     * @return {Boolean}
     */
    disabledOptions() {
      return this.disabledActions || this.disabledOptionsActions
    },
    /**
     * Deshabilita las acciones de los nodos padre
     * @return {Boolean}
     */
    disabledParentNodes() {
      const nodeType = this.structureNode.type
      return (
        this.children.length === this.validations_chatbot[nodeType]?.maxChildren
      )
    },
  },
  methods: {
    ...mapMutations(['SET_SELECTED_NODE_ID', 'SET_NODE_SELECTED']),

    /**
     * Agrega un hijo
     * @param {String} nodeId - id del nodo al que se le va agregar un hijo
     * @param {String} nodeType - tipo del nodo al que se le va agregar un hijo
     */
    async handleAddChild(nodeId, nodeType) {
      const node = NODES[nodeType]
      if (
        this.children.length === this.validations_chatbot[nodeType].maxChildren
      )
        return this.$message.error(`Se alcanzó el máximo de ${node.title} 😥`)

      this.disabledParentActionsLocal = true
      await this.addDefaultChild(nodeId, nodeType)
      this.disabledParentActionsLocal = false
    },

    /**
     * Emite la funcion de abrir el drawer de configuracion
     * @param {Object} node - puede ser cualquier nodo
     * @param {String} node._id - solo si el nodo es padre
     * @param {String} node.idOption - si el nodo es opcion
     * @param {String} node.idButton - si el nodo es boton
     * @param {String} node.idCard - si el nodo es card
     * @param {String} idProperty - el nombre de la propiedad
     * @param {String} parentNodeId - id del nodo padre que lo contiene
     * @param {Boolean} isSubchild -si es subchild
     * @param {String} childId - solo si proviene de un hijo
     */
    handleOpenDrawer(node, idProperty, parentNodeId, isSubchild, childId) {
      if (this.isSavedNode) {
        this.$emit('onOpenDrawer', node)
        this.SET_SELECTED_NODE_ID(node._id || node[idProperty])

        if (parentNodeId) node.parentNodeId = parentNodeId
        if (isSubchild) node.is_subchild = isSubchild
        if (childId) node.simulateParentNodeId = childId

        this.SET_NODE_SELECTED(node)
      } else this.$message.warning('Es necesario guardar los cambios')
    },
    /**
     * Cambia el orden de las opciones
     * @param {Object} option - la opcion a cambiar de posicion
     * @param {Number} newOrder - el orden al cual se quiere cambiar
     */
    async handleChangeOrderChild(child, newOrder, nodeType) {
      if (!this.isSavedNode)
        this.$message.warning('Es necesario guardar los cambios')
      this.changeOrderChild(child, newOrder, nodeType)
    },
    /**
     * Formatea una accion
     * @param {String} action - none, continue, transfer, rollback
     * @param {String} nodeType - message, options
     * @param {Boolean} wait - mensaje con espera o  sin espera
     * @param {String} lineId
     */
    formattedAction(action, nodeType, wait, lineId) {
      if (wait && action !== 'none') return 'Esperar una respuesta'
      switch (action) {
        case 'continue': {
          if (nodeType === 'message') return 'Continuar al siguiente mensaje'
          else if (nodeType === 'catcher')
            return 'Continuar a capturar variable'
          else return 'Continuar a las opciones'
        }
        case 'none':
          return 'Finalizar chatbot'
        case 'transfer': {
          if (!lineId) return `Transferir a cola`
          const lineFound = this.lines.find((line) => line._id === lineId)
          if (!lineFound) return 'Transferir a cola'
          const nameLine = lineFound.name
          return `Transferir a cola "${nameLine}"`
        }
        case 'rollback':
          return 'Regresar al menú anterior'
        default:
          return 'Siguiente'
      }
    },
    /**
     * Emite la previsualizacion del archivo
     * @param {Object} attachment
     * @param {String} attachment.orginalName
     * @param {String} attachment.urlStorage
     */
    handlePreviewAttachment(attachment) {
      this.$emit('onPreviewAttachment', attachment)
    },
    /**
     * Agregar un subhijo al hijo
     * @param {String} nodeId - id del nodo padre
     * @param {String} nodeType - tipo del nodo padre
     * @param {String} childId - id del nodo hijo
     */
    async handleAddSubchild(nodeId, nodeType, childId) {
      this.disabledChildActionsLocal = true
      await this.addDefaultSubchild(nodeId, nodeType, childId)
      this.disabledChildActionsLocal = false
    },
    async handleExecuteDeleteSubchild(data) {
      this.disabledChildActionsLocal = true
      await this.handleShowConfirmToDelete(data)
      this.disabledChildActionsLocal = false
    },
  },
}
</script>
<style lang="sass" scoped>
$border-width: 1px

.tree
  &__options
    margin-left: 20px
  &__child
    margin-left: 20px
  &__width
    width: 468px
  &__connection
    position: relative
    &::before
      content: ""
      display: block
      position: absolute
      width: 8px
      height: calc( 100% - 26px )
      border-bottom-left-radius: 6px
      border-left: $border-width solid $blue_3
      border-bottom: $border-width solid $blue_3
      margin-left: calc( -20px - #{$border-width})
      margin-top: -8px
      bottom: 50%
      pointer-events: none
      transform: translateZ(0)
      background: transparent
    &__card
      position: relative
      &::before
        content: ""
        display: block
        position: absolute
        width: 8px
        height: calc( 100% - 14px )
        border-bottom-left-radius: 6px
        border-left: $border-width solid $blue_3
        border-bottom: $border-width solid $blue_3
        margin-left: calc( -20px - #{$border-width})
        margin-top: -8px
        bottom: 50%
        pointer-events: none
        transform: translateZ(0)
        background: transparent
  &__option
    &__first
      >.tree__option__connection
        &::before
          height: 62%
    &__connection
      position: relative
      &::before
        content: ""
        display: block
        position: absolute
        width: 8px
        height: calc(100% + 12px)
        border-bottom-left-radius: 6px
        border-left: $border-width solid $blue_3
        border-bottom: $border-width solid $blue_3
        margin-left: calc( -20px - #{$border-width})
        margin-top: -8px
        bottom: 50%
        pointer-events: none
        transform: translateZ(0)
        background: transparent
      &-brothers
        >.tree__child
          position: relative
          &::before
            content: ""
            display: block
            position: absolute
            width: 8px
            height: calc( 100% + 50px)
            border-left: $border-width solid $blue_3
            margin-left: calc( -27px - #{$border-width})
            margin-top: -50px
            pointer-events: none
            transform: translateZ(0)
            background: transparent
        >.tree__subchildren
          position: relative
          &::before
            content: ""
            display: block
            position: absolute
            width: 8px
            height: calc( 100% + 50px)
            border-left: $border-width solid $blue_3
            margin-left: calc( -27px - #{$border-width})
            margin-top: -50px
            pointer-events: none
            transform: translateZ(0)
            background: transparent
</style>
