<template>
  <a-form :form="form" layout="vertical" @submit.prevent="handleSubmit">
    <a-form-item label="Mensaje de botones" class="section-form-item">
      <p class="help text--left mrg-bottom-8">
        Recuerda que sera un mensaje de introducción para tus usuarios.
      </p>
      <counter-text
        :maxLength="validations.maxLengthMessage"
        :text="
          form.getFieldValue('content') ? form.getFieldValue('content') : ''
        "
      >
        <a-mentions
          v-decorator="[
            'content',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          ref="content"
          rows="3"
          placeholder="Escribe aquí..."
          @change="setIsSavedItem(false)"
          :maxLength="validations.maxLengthMessage"
          class="text--left"
          :prefix="PREFIX_VARS"
          :notFoundContent="notFoundVars"
          placement="bottom"
          :filterOption="filterOption"
          :disabled="onlyRead"
        >
          <a-mentions-option
            v-for="simpleVar in simpleVars"
            :key="simpleVar._id"
            :value="simpleVar.name"
          >
            {{ simpleVar.name }}</a-mentions-option
          >
        </a-mentions>
      </counter-text>
      <menu-options-content
        @onChangeEmoji="(emoji) => handleSetEmoji(emoji, 'content')"
        @onOpenVars="handleOpenVars('content')"
        :show-wait="false"
        :disabled-emoji="onlyRead"
      />
    </a-form-item>
    <a-form-item
      label="Mensaje por respuesta incorrecta"
      class="section-form-item"
    >
      <p class="help" style="margin-bottom: 8px; text-align: left">
        Recuerda este mensaje se mostrará al cliente cuando mande un nuevo
        mensaje y no seleccione ninguno de los botones mostrados.
      </p>
      <counter-text
        :maxLength="validations.maxLengthErrorMessage"
        :text="
          form.getFieldValue('errorMessage')
            ? form.getFieldValue('errorMessage')
            : ''
        "
      >
        <a-mentions
          v-decorator="[
            'errorMessage',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          ref="errorMessage"
          rows="3"
          placeholder="Escribe aquí..."
          @change="setIsSavedItem(false)"
          :maxLength="validations.maxLengthErrorMessage"
          class="text--left"
          :prefix="PREFIX_VARS"
          :notFoundContent="notFoundVars"
          placement="bottom"
          :filterOption="filterOption"
          :disabled="onlyRead"
        >
          <a-mentions-option
            v-for="simpleVar in simpleVars"
            :key="simpleVar._id"
            :value="simpleVar.name"
            @onOpenVars="handleOpenVars('errorMessage')"
          >
            {{ simpleVar.name }}</a-mentions-option
          >
        </a-mentions>
      </counter-text>
      <menu-options-content
        @onChangeEmoji="(emoji) => handleSetEmoji(emoji, 'errorMessage')"
        @onOpenVars="handleOpenVars"
        :show-wait="false"
        :disabled-emoji="onlyRead"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import CounterText from '@/app/shared/components/molecules/CounterText'
import actionsToNodeSelected from '@/app/chatbots/mixins/actionsToNodes'
import verifyAddDefaultNode from '@/app/chatbots/mixins/actionsToNodes'
import MenuOptionsContent from '@/app/chatbots/components/molecules/MenuOptionsContent'
import { PREFIX_VARS } from '@/app/chatbots/utils/componentsDataNodes'

export default {
  name: 'OptionsForm',
  components: {
    CounterText,
    MenuOptionsContent,
  },
  mixins: [actionsToNodeSelected, verifyAddDefaultNode],
  props: {
    chatbotId: {
      type: String,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    PREFIX_VARS,
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'options-form-chatbot' })
  },
  computed: {
    ...mapGetters([
      'nodes',
      'simple_chatbots_vars',
      'selectedNodeId',
      'validations_chatbot',
    ]),

    /**
     * @returns {Boolean} - verifica si existe un nodo de tipo catcher
     */
    existCatcher() {
      return this.verifyExistCatcher({ nodeId: this.nodeId })
    },
    /**
     * @returns {Object[]} simpleVars - variables sin paginacion
     * @returns {String} simpleVars.name
     * @returns {String} simpleVars._id
     */
    simpleVars() {
      return !this.existCatcher
        ? []
        : this.filterSimpleVarsBySave({ nodeId: this.nodeId })
    },
    /**
     * @returns {String} - mensaje que aparecerá cuando no haya datos
     */
    notFoundVars() {
      return !this.existCatcher
        ? 'Aún no haz agregado "Capturar variable"'
        : 'No se han encontrado variables'
    },
    /**
     * @return {Object} - validations
     * @return {Number} - validations.maxLengthMessage
     * @return {String} - validations.maxLengthErrorMessage
     */
    validations() {
      const options = 'buttons'
      return this.validations_chatbot[options]
    },
  },
  methods: {
    ...mapActions(['updateNodeButtons']),
    ...mapMutations(['SET_IS_SAVED_NODE']),

    /**
     * Setea valores al formulario
     * @param {Object} args
     * @param {String} args.content
     * @param {String} args.errorMessage
     */
    setValues({ content, errorMessage }) {
      this.form.setFieldsValue({
        content,
        errorMessage,
      })
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        await this.handleUpdateOptions(values)
        this.setIsSavedItem(true)
      })
    },
    /**
     * Subir cambios al nodo de opciones
     * @param {Object} values - valores del formulario
     * @param {String} values.content - el texto de opciones
     * @param {String} values.errorMessage - el mensaje de error
     */
    async handleUpdateOptions(values) {
      const nodeButtonsEdited = {
        message: values.content,
        errorMessage: values.errorMessage,
      }
      const response = await this.updateNodeButtons({
        chatbotId: this.chatbotId,
        nodeId: this.nodeId,
        nodeButtonsEdited,
      })
      if (response.success) this.$message.success('Se actualizó correctamente')
      else this.$message.error(response.details || 'Ocurrió un error 😥')
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     * @param {String} field - nombre de campo en el formulario
     */
    handleSetEmoji(emoji, field) {
      let text = this.form.getFieldValue(field)
      text = text + emoji
      this.form.setFieldsValue({
        [field]: text,
      })
      this.setIsSavedItem(false)
    },
    /**
     * Muestra el listado de variables
     * @param {String} field - nombre de campo en el formulario
     */
    handleOpenVars(field) {
      let text = this.form.getFieldValue(field)
      const textArray = text.split('')
      // si el ultimo valor no es el prefijo de variables, seteará el prefijo al texto
      if (textArray.pop() !== this.PREFIX_VARS) {
        text = text + this.PREFIX_VARS
        this.form.setFieldsValue({
          [field]: text,
        })
      }

      this.$refs[field].focus()
      const element = document.getElementById(`options-form-chatbot_${field}`)
      // Importante no borrar, porque si no, no renderizara el listado de variables
      setTimeout(() => {
        this.simulateKey(element, this.PREFIX_VARS, 'up')
      }, 0)
    },
    /**
     * Simula el #
     * @param {Number} keyCode The keyCode of the key to simulate
     * @param {String} type (optional) The type of event : down, up or press. The default is down
     * @param {Object} modifiers (optional) An object which contains modifiers keys { ctrlKey: true, altKey: false, ...}
     */
    simulateKey(element, keyCode, type, modifiers) {
      var evtName = typeof type === 'string' ? 'key' + type : 'keydown'
      var modifier = typeof modifiers === 'object' ? modifier : {}
      var event = document.createEvent('HTMLEvents')
      event.initEvent(evtName, true, false)
      event.key = keyCode
      for (var i in modifiers) {
        event[i] = modifiers[i]
      }
      element.dispatchEvent(event)
    },
    /**
     * Filtra el valor de una opcion
     * @param {String} input
     * @param {Object} option
     */
    filterOption(input, option) {
      const optionFound = option.children[0].text.toLowerCase()
      const optionTrim = optionFound.trim()

      if (input.toLowerCase().includes(optionTrim)) {
        return optionFound
      } else {
        return optionFound.indexOf(input.toLowerCase()) >= 0
          ? optionFound
          : null
      }
    },
  },
}
</script>

<style scoped></style>
