<template>
  <div class="section-list-supervision">
    <a-table
      class="scrollbar_basic table-supervision"
      :data-source="newData"
      :pagination="false"
      :loading="sectionSupervision.list.loading.table"
      :locale="localeTable"
      :scroll="{ y: 'calc(100vh - 168px)' }"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
      size="middle"
    >
      <a-table-column key="number" title="N°" data-index="number" width="5%">
        <template slot-scope="number">
          <span>{{ number }}</span>
        </template>
      </a-table-column>
      <a-table-column key="agent" title="Agente" data-index="agent">
        <template slot-scope="agent">
          <div style="display: flex">
            <StatusAvatar
              :size="40"
              :status="agent.status"
              :component="'List'"
              :info="agent"
            />
            <div>
              <a
                @click="handleOpenDrawer(agent)"
                class="ellipsis mrg-bottom-0 table__names"
                :disabled="agent.subType && agent.subType === 'chatbot'"
                >{{ agent.names }}</a
              >
              <div>
                <span style="color: #686a6f" class="body-1"
                  >En
                  {{
                    agent.status === 'connected'
                      ? agent.menu
                      : transformStatus(agent.status)
                  }}</span
                >
                <a-divider
                  v-if="
                    agent.status !== 'disconnected' &&
                    agent.subType !== 'chatbot'
                  "
                  type="vertical"
                />
                <span
                  v-if="
                    agent.status !== 'disconnected' &&
                    agent.subType !== 'chatbot'
                  "
                >
                  <WaitPointsAnimation v-if="agent.isTyping">
                    <template
                      ><span style="color: #686a6f" class="body-1"
                        >Escribiendo</span
                      ></template
                    >
                  </WaitPointsAnimation>
                  <template v-else
                    ><span style="color: #686a6f" class="body-1">{{
                      agent.time
                    }}</span></template
                  >
                </span>
              </div>
            </div>
          </div>
        </template>
      </a-table-column>
      <a-table-column
        key="pendings"
        title="Tickets sin leer"
        data-index="pendings"
        width="16%"
      >
        <template slot-scope="pendings">
          <span class="gray_dark_900">{{ pendings }}</span>
        </template>
      </a-table-column>
      <a-table-column
        key="ticketsAssigned"
        title="Tickets activos"
        data-index="ticketsAssigned"
        width="16%"
      >
        <template slot-scope="ticketsAssigned">
          <span class="gray_dark_900">{{ ticketsAssigned }}</span>
        </template>
      </a-table-column>
      <a-table-column key="event" title="Evento" data-index="event">
        <template slot-scope="event">
          <div v-if="event.value" class="display-flex align--center">
            <social-icon :type="event.value" />
            <div class="mrg-left-8">
              <p class="body-2 ellipsis mrg-bottom-0 gray_dark_900">
                {{ event.client ? event.client.names : event.client.phone }}
              </p>
              <span class="gray_dark_900">{{ event.time }}</span>
            </div>
          </div>
        </template>
      </a-table-column>
      <!-- columna de acciones -->
      <a-table-column key="action" title="Acciones" :width="200">
        <template slot-scope="text, record">
          <span>
            <!-- Visualizar -->
            <a @click="handleView(record)">Visualizar</a>

            <!-- Cerrar sesion -->
            <template v-if="record.agent.subType !== 'chatbot'">
              <a-divider type="vertical" />
              <a
                v-if="
                  (record.agent.status === 'disconnected' &&
                    record.agent.session === false) ||
                  isAllowedFor(record.agent.type, ['admin'])
                "
                :disabled="
                  (record.agent.status === 'disconnected' &&
                    record.agent.session === false) ||
                  isAllowedFor(record.agent.type, ['admin'])
                "
                :aria-disabled="true"
                >Cerrar sesión</a
              >
              <a v-else @click="handleCloseSesion(record)">Cerrar sesión</a>
            </template>
          </span>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import StatusAvatar from '@/app/supervision/components/organisms/avatars/StatusAvatar.vue'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import WaitPointsAnimation from '@/app/shared/components/animations/WaitPointsAnimation.vue'
import transformWordGender from '@/app/shared/mixins/transform'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'SectionListSupervision',
  props: {
    typeState: {
      type: String,
      required: true,
    },
    sectionSupervision: {
      type: Object,
    },
  },
  components: {
    StatusAvatar,
    WaitPointsAnimation,
    SocialIcon,
  },
  data: () => ({
    moment: moment,
    test: {
      data: [],
    },
    timer: null, // Intervalo para el timer
    localeTable: {
      emptyText: 'No se han encontrado agentes activos',
    },
  }),
  mixins: [attemptMixin, transformWordGender, compareMixin],
  watch: {
    reloadTableSupervision() {
      this.load()
    },
  },
  computed: {
    ...mapGetters(['getListSupervision', 'profile', 'reloadTableSupervision']),
    dataFiltered() {
      switch (this.typeState) {
        case 'general': {
          return this.getListSupervision.filter(
            (obj) => obj.user.status !== 'disconnected'
          )
        }
        default: {
          return this.getListSupervision.filter(
            (obj) => obj.user.status === this.typeState
          )
        }
      }
    },
    newData() {
      const arrData = []
      for (let i = 0; i < this.dataFiltered.length; i++) {
        if (this.dataFiltered[i].userId !== this.profile.userId) {
          arrData.push({
            key: i + 1,
            number: i + 1,
            _id: this.dataFiltered[i]._id,
            agent: {
              userId: this.dataFiltered[i].userId,
              type: this.dataFiltered[i].profile.type,
              names: `${this.dataFiltered[i].profile.names} ${this.dataFiltered[i].profile.surnames}`,
              time: `${this.dataFiltered[i].startTimeMenu} hrs`,
              status: this.dataFiltered[i].user.status,
              menu: this.dataFiltered[i].menu,
              session: this.dataFiltered[i].user.session,
              isTyping: this.dataFiltered[i].isTyping,
              subType: this.dataFiltered[i].profile.subType,
              avatar: this.dataFiltered[i].profile.avatar
                ? this.dataFiltered[i].profile.avatar
                : null,
            },
            pendings: `${this.dataFiltered[i].ticketsPending} sin leer`,
            ticketsAssigned: `${this.dataFiltered[i].ticketsAssigned} tickets`,
            event: {
              value: this.dataFiltered[i].event,
              client: this.dataFiltered[i].client,
              time: `${this.dataFiltered[i].startTimeEvent} hrs`,
            },
          })
        }
      }
      return arrData
    },
  },
  created() {
    this.intervalAttempt(() => {
      this.load()
    })
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions(['listSupervision', 'remoteLogout', 'listTicketsAgent']),
    ...mapMutations(['SET_TIME_SUPERVISION']),
    load() {
      this.listSupervision({ self: this })
      this.countTimeModuleAgents()
    },
    async handleView(record) {
      let userId = record.agent.userId
      const response = await this.listTicketsAgent({
        status: 'active',
        agentUserId: userId,
      })
      if (response.result != 'overflow')
        window.open(
          `${window.location.origin}/workspacemonitor/${userId}`,
          '_blank',
          'noopener'
        )
    },
    handleOpenDrawer(agent) {
      if (agent.subType === 'chatbot') return
      this.$emit('handleOpenDrawer', agent.userId)
    },
    handleCloseSesion(record) {
      const wordSure = this.transformWordGender('seguro', this.profile.gender)
      const self = this
      this.$confirm({
        title: `¿Estás ${wordSure} de cerrar la sesión  de ${record.agent.names}?`,
        content:
          'Recuerda que se cerrará la sesión del agente y la plataforma regresará de nuevo al inicio de sesión',
        okText: 'Cerrar sesión',
        cancelText: 'Cancelar',
        centered: true,
        class: 'logout-modal',
        width: '430px',
        onOk() {
          self.remoteLogout(record.agent.userId)
        },
      })
    },
    transformStatus(state) {
      switch (state) {
        case 'connected':
          return 'conectado'
        case 'disconnected':
          return 'sesión cerrada'
        case 'bussy':
          return 'ocupado'
        case 'services':
          return 'servicios'
        case 'training':
          return 'capacitación'
        case 'paused':
          return 'No disponible'
        case 'snacking':
          return 'refrigerio'
        default:
          return 'conectado'
      }
    },
    secondsToTime(seconds) {
      let sec_num = parseInt(seconds, 10),
        hh = Math.floor(sec_num / 3600),
        mm = Math.floor((sec_num - hh * 3600) / 60),
        ss = sec_num - hh * 3600 - mm * 60
      if (hh < 10) hh = '0' + hh
      if (mm < 10) mm = '0' + mm
      if (ss < 10) ss = '0' + ss
      return `${hh}:${mm}:${ss}`
    },
    /**
     * Interval para las fechas de supervisión
     * @param {Date} datetime
     */
    getIntervalTimeAt(datetime) {
      const dateNow = moment()
      const dateStart = moment(datetime)

      const duration = moment.duration(dateNow.diff(dateStart))
      const totalSeconds = duration.asSeconds()

      return this.secondsToTime(totalSeconds)
    },
    countTimeModuleAgents() {
      this.timer = setInterval(() => {
        try {
          this.dataFiltered.forEach((sup) => {
            let timeupMenu = this.getIntervalTimeAt(sup.startedInMenu_at)
            const timeupEvent = this.getIntervalTimeAt(sup.startedInEvent_at)
            this.SET_TIME_SUPERVISION({
              idSupervision: sup._id,
              startTimeMenu: timeupMenu,
              startTimeEvent: timeupEvent,
            })
          })
        } catch (error) {
          console.error('[countTimeModuleAgents', error)
        }
      }, 1000)
    },
  },
}
</script>

<style lang="sass" scoped>
.gray_dark_900
  color: $gray_dark_900
.table__names[disabled]
  color: $gray_dark_900
</style>

<style lang="sass">
.table-supervision
  .ant-table-body
    &::-webkit-scrollbar
      width: 10px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
.logout-modal
  .ant-modal-content
    .ant-modal-body
      .ant-modal-confirm-body-wrapper
        .ant-modal-confirm-btns
          .ant-btn
            min-width: 113px
</style>
