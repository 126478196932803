export default {
  connected: {
    allow_navigated: true,
    state: 'connected',
    title_for_footer_menu: 'Conectado',
    title_for_selects: 'Conectado',
    color: '#52c41a',
    color_for_dashboard: 'rgba(47, 194, 91, 0.5)',
    _id: '0',
    show_when: { paused: true },
    action_when_select: ['save', 'show-notification-on-save'],
    notification_description:
      'Volverás a recibir tickets y continuar con tus gestiones como siempre 😎.',
    notification_title: 'Tu estado ahora es conectado',
  },
  bussy: {
    allow_navigated: false,
    state: 'bussy',
    title_for_footer_menu: 'Ocupado',
    title_for_selects: 'Ocupado',
    color: '#F5222D',
    color_for_dashboard: 'rgba(245, 34, 45, 0.5)',
    _id: '1',
    action_when_select: ['save', 'show-modal', 'start-timer'],
  },
  services: {
    allow_navigated: false,
    state: 'services',
    title_for_footer_menu: 'En Servicios',
    title_for_selects: 'Servicios',
    color: '#1890FF',
    color_for_dashboard: 'rgba(24, 144, 255, 0.5)',
    _id: '2',
    action_when_select: ['save', 'show-modal', 'start-timer'],
  },
  training: {
    allow_navigated: false,
    state: 'training',
    title_for_footer_menu: 'En Capacitación',
    title_for_selects: 'Capacitación',
    color: '#FA8C16',
    color_for_dashboard: 'rgba(250, 140, 22, 0.5)',
    _id: '3',
    action_when_select: ['save', 'show-modal', 'start-timer'],
  },
  snacking: {
    allow_navigated: false,
    state: 'snacking',
    title_for_footer_menu: 'En Refrigerio',
    title_for_selects: 'Refrigerio',
    color: '#FAAD14',
    color_for_dashboard: 'rgba(250, 173, 20, 0.5)',
    _id: '4',
    action_when_select: ['save', 'show-modal', 'start-timer'],
  },
  paused: {
    allow_navigated: true,
    state: 'paused',
    title_for_footer_menu: 'No disponible',
    title_for_selects: 'No disponible',
    color: '#13c2c2',
    color_for_dashboard: 'rgba(54, 207, 201, 0.5)',
    _id: '5',
    show_when: { connected: true },
    action_when_select: [
      'save',
      'show-notification-on-save',
      'show-status-in-avatar',
      'start-timer',
    ],
    notification_description:
      'En este estado solo podrás gestionar tus tickets asignados pero no podrás recibir tickets.',
    notification_title: 'Tu estado ahora es No disponible',
  },
  disconnected: {
    allow_navigated: false,
    hide: true,
    state: 'disconnected',
    title_for_footer_menu: 'Desconectado',
    title_for_selects: 'Desconectado',
    color: '#AFB1B7',
    _id: '6',
    action_when_select: [],
  },
}
