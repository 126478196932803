<template>
  <div class="section-tag">
    <div class="section-tag-card">
      <h6 class="heading-h6 title">
        <a-icon type="tag" class="mrg-right-8" />
        Etiquetas
      </h6>
      <p class="body-2 description" style="margin-bottom: 20px">
        Crea etiquetas, podrán ser asignadas a las conversaciones y así poder
        realizar reportes.
      </p>
      <!-- NUEVA ETIQUETA -->
      <a-row>
        <a-form
          layout="vertical"
          :form="form"
          @submit="handleSubmit"
          class="modal-form"
        >
          <a-form-item
            label="Nuevo nombre de etiqueta"
            class="section-form-item"
            :validate-status="tagsNameError() ? 'error' : ''"
            :help="tagsNameError() || ''"
          >
            <div class="inline">
              <a-input
                :maxLength="50"
                v-decorator="[
                  'tagName',
                  {
                    rules: [
                      { required: true, message: 'Por favor rellena el campo' },
                    ],
                  },
                ]"
                placeholder="Ingrese nombre"
                size="default"
              />
              <a-select
                v-decorator="[
                  'tagColor',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor seleccione un color',
                      },
                    ],
                  },
                ]"
                placeholder="Color"
              >
                <a-select-option v-for="color in colors" :key="`${color}`">
                  <ColorPick :color="color" size="bold" />
                </a-select-option>
              </a-select>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="hasErrors(form.getFieldsError())"
              >
                Agregar etiqueta
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-row>
      <!-- TABLA DE ETIQUETAS -->
      <a-row>
        <div>
          <a-table
            class="scrollbar_basic table-tags"
            :columns="columns"
            :data-source="rows"
            :pagination="false"
            :scroll="{ y: 'calc(90.2vh - 19em - 250px)' }"
            size="small"
          >
            <template
              v-for="col in ['order', 'tagName', 'color']"
              :slot="col"
              slot-scope="attr, record"
              :class="{ editable: record.editable && attr.type === 'picker' }"
            >
              <div :key="col">
                <!-- En editar -->
                <a-input
                  v-if="record.editable && attr.type === 'input'"
                  style="margin: -5px 0"
                  :defaultValue="attr.value"
                  @change="(e) => handleChange(e.target.value, record.key, col)"
                  :maxLength="50"
                />
                <a-form
                  v-else-if="record.editable && attr.type === 'picker'"
                  :form="formEdit"
                  @submit="(e) => e.preventDefault()"
                >
                  <a-form-item style="margin-bottom: 0">
                    <a-select
                      style="width: 74%"
                      v-decorator="[
                        'tagColorEdited',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Por favor seleccione un color',
                            },
                          ],
                        },
                      ]"
                      placeholder="Color"
                      @change="(e) => handleColorTag(e, record.key, col)"
                    >
                      <a-select-option
                        v-for="color in colors"
                        :key="`${color}`"
                      >
                        <ColorPick :color="color" />
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-form>
                <!-- Sin editar -->
                <template v-else>
                  <span v-if="attr.type === 'picker'">
                    <ColorPick :color="attr.value" />
                  </span>
                  <span v-else>
                    {{ attr.value }}
                  </span>
                </template>
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <div class="editable-row-operations">
                <span v-if="record.editable">
                  <a @click="() => handleSave(record)">Guardar</a>
                  <a-divider type="vertical" />
                  <a @click="handleCancel(record)">Cancelar</a>
                </span>
                <span v-else>
                  <a
                    :disabled="
                      record.default ||
                      record.editableByBack.value === false ||
                      editingKey !== ''
                    "
                    @click="handleEdit(record)"
                    >Editar</a
                  >
                  <a-divider type="vertical" />
                  <a
                    :disabled="
                      record.default ||
                      record.editableByBack.value === false ||
                      editingKey !== ''
                    "
                    @click="handleDelete(record)"
                    >Eliminar</a
                  >
                </span>
              </div>
            </template>
          </a-table>
        </div>
      </a-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ColorPick from '@/app/shared/components/pickers/ColorPick.vue'
import transformWordGender from '@/app/shared/mixins/transform'

export default {
  name: 'SectionTag',
  components: {
    ColorPick,
  },
  data: () => ({
    rows: [],
    colors: [
      '#AFB1B7',
      '#C13131',
      '#5F233F',
      '#C17529',
      '#FFDB01',
      '#FDBD42',
      '#32C38C',
      '#1ABEC7',
      '#73B9D7',
      '#FB3611',
      '#5F4C8C',
      '#001A3D',
      '#96B2A6',
    ], // Lista de colores predeterminados
    editingKey: '',
    columns: [
      {
        title: 'Nº',
        key: 'order',
        dataIndex: 'order',
        width: '7%',
        scopedSlots: { customRender: 'order' },
      },
      {
        title: 'Nombre de etiqueta',
        key: 'tagName',
        dataIndex: 'tagName',
        width: '46%',
        scopedSlots: { customRender: 'tagName' },
      },
      {
        title: 'Color',
        key: 'color',
        dataIndex: 'color',
        scopedSlots: { customRender: 'color' },
      },
      {
        title: 'Acción',
        key: 'action',
        dataIndex: 'action',
        width: '28%',
        scopedSlots: { customRender: 'action' },
      },
    ],
    submit: false, // acompañan a handleSubmit para evitar mas de 1 enter . se obliga a entrar en el primer if en el primer de handlesubmit
  }),
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  watch: {
    tags() {
      this.load()
    },
  },
  mixins: [transformWordGender],
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
    this.formEdit = this.$form.createForm(this, { name: 'normal' })
  },
  mounted() {
    this.load()
    this.setDefaultColor()
    this.$nextTick(() => {
      // Inhabilita el boton al inicio
      this.form.validateFields()
    })
  },
  computed: {
    ...mapGetters(['profile']),
  },
  methods: {
    ...mapActions(['addNewTag', 'updateTag', 'deleteTag']),
    /** Carga las filas para la tabla */
    load() {
      this.rows = []
      let list = [...this.tags]
      let n = 0
      list = list.map((tag) => {
        n++
        let row = {
          key: `${tag.idTag}`,
          idTag: { value: `${tag.idTag}` },
          order: { value: `${n}` },
          tagName: { value: `${tag.title}`, type: 'input' },
          color: { value: `${tag.color}`, type: 'picker' },
          editableByBack: { value: tag.editable !== false },
          default: tag.default || false,
        }
        return row
      })
      this.rows = list
    },
    /** */
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field])
    },
    // solo muestra error despues de que el input es tocado
    tagsNameError() {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('tagName') && getFieldError('tagName')
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.submit) {
            this.submit = true
            let tag = { title: values.tagName, color: values.tagColor }
            const response = await this.addNewTag(tag)
            this.clearFields()
            this.setDefaultColor()
            if (response.success === true) {
              this.$message.success({
                content: `La etiqueta se creó correctamente`,
                key: 'updatable',
                duration: 2,
              })
            }
            return (this.submit = false)
          } else {
            // si llega aqui es porque se dio 2 o mas veces  enter
            console.log('ya se hizo submit')
          }
        }
      })
    },
    /** Guarda los cambios al escribir en un inout editable de una de las filas */
    handleChange(value, key, column) {
      const newData = [...this.rows]
      const target = newData.find((item) => key === item.key)
      if (target) {
        target[column].value = value
        this.rows = newData
      }
    },
    /** Obtiene el valor de cada color selecionado y guarda el valor nuevo en la fila*/
    handleColorTag(value, key, column) {
      const newData = [...this.rows]
      const target = newData.find((item) => key === item.key)
      if (target) {
        target[column].value = value
        this.rows = newData
      }
    },
    /** Guarda los cambios de una fila */
    async handleSave(record) {
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)

      if (target) {
        delete target.editable
        this.rows = newData
        let tag = this.tags.find((box) => box.idTag === record.idTag.value)
        Object.assign(tag, {
          title: record.tagName.value,
          color: record.color.value,
        })
        await this.updateTag(tag)
        this.$message.success({
          content: `La etiqueta se guardó correctamente`,
          key: 'updatable',
          duration: 2,
        })
      }
      this.editingKey = ''
    },
    /** Habilita una fila a un input editable
     * @param {Object} record
     * @param {Boolean} record.default
     * @param {String} record.key
     * @param {Object} record.color
     * @param {String} record.color.value
     */
    handleEdit(record) {
      if (record.default) return
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)

      this.editingKey = record.key
      if (target) {
        target.editable = true
        this.rows = newData
        setTimeout(() => {
          this.formEdit.setFieldsValue({
            tagColorEdited: record.color.value,
          })
        }, 100)
      }
    },
    /** Devuelve el input editable a una fila no editable
     * @param {Object} record
     * @param {String} record.key
     * @param {Object} record.idTag
     * @param {String} record.idTag.value
     */
    handleCancel(record) {
      const newData = [...this.rows]
      const target = newData.find((item) => record.key === item.key)
      if (target) {
        delete target.editable
        this.rows = newData
        /** Devuelve los valores anteriores */
        let tag = this.tags.find((box) => box.idTag === record.idTag.value)
        let rows = this.rows.find((d) => d.key === record.idTag.value)
        Object.assign(rows, {
          tagName: { type: 'input', value: tag.title },
          color: { type: 'picker', value: tag.color },
        })
      }
      this.editingKey = ''
    },
    /** Quita a una fila de la tabla
     * @param {Object} record
     * @param {Boolean} record.default
     * @param {Object} record.idTag
     * @param {String} record.idTag.value
     */
    async handleDelete(record) {
      if (record.default) return
      const self = this
      let tag = this.tags.find((box) => box.idTag === record.idTag.value)
      const wordSure = this.transformWordGender('seguro', this.profile.gender)
      // modal de confirmación
      this.$confirm({
        title: `¿Estás ${wordSure} de eliminar la etiqueta "${tag.title}”?`,
        content:
          'Recuerda que la etiqueta se mantendrá en los tickets que lo tienen asignado',
        onOk() {
          self.deleteTag(tag).then(() => {
            self.$message.success({
              content: `La etiqueta se eliminó correctamente`,
              key: 'updatable',
              duration: 2,
            })
          })
        },
        onCancel() {},
        okText: 'Eliminar',
        cancelText: 'Cancelar',
        class: 'test',
        centered: true,
      })
    },
    clearFields() {
      this.form.setFieldsValue({
        tagName: '',
        tagColor: '',
      })
    },
    setDefaultColor() {
      this.form.setFieldsValue({
        tagColor: '#AFB1B7',
      })
    },
  },
}
</script>

<style lang="sass" scoped>
// $max-table: 537px

.section-tag
  padding: 12px 20px
  border: 1px solid #D9D9D9
  border-radius: 8px
  &-card
    .title
      text-align: left
      color: $gray_dark_800
    .description
      text-align: left
      color: $gray_light_900
  .ant-row
    // max-width: $max-table
    // margin: 0 auto
    .inline
      display: flex
      .ant-select
        margin: 0 8px
        min-width: 84px
        width: min-content
      input[type="text"]
        // max-width: 390px
</style>

<style lang="sass">
.section-tag
  .ant-row
    .ant-select
      .ant-select-selection__rendered
        height: min-content
        display: flex
        align-items: center
    .scrollbar_basic
      .ant-table-body
        &::-webkit-scrollbar
          width: 10px
        &::-webkit-scrollbar-track
          background: transparent
          border-left: solid 5px transparent
        &::-webkit-scrollbar-thumb
          border-radius: 8px
          box-shadow: inset 0 0 10px 10px $gray_dark_100
          border: solid 3px transparent
        &::-webkit-scrollbar-thumb:hover
          box-shadow: inset 0 0 10px 10px $gray_dark_100
    table
      tbody
        td
          padding: 8px !important
          &:nth-child(3n) // En este caso, en la tercera columna se encuentra en select de colores
            // padding: 0px !important
          // input
          //   width: 95%
.table-tags
  .ant-spin-nested-loading
    .ant-spin-container
      .ant-table
        .ant-table-content
          .ant-table-scroll
            .ant-table-header
              overflow-y: auto !important
            .ant-table-body
              overflow-y: auto !important
</style>
