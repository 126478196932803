<template>
  <a-form :form="form" layout="vertical">
    <!--Titulo de la tarjeta-->
    <a-form-item :label="`Sección ${order}`" class="section-form-item">
      <counter-text
        :maxLength="validations.maxLengthTitleItem"
        :text="form.getFieldValue('title') ? form.getFieldValue('title') : ''"
      >
        <a-input
          v-decorator="[
            'title',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          @input="onInput"
          placeholder="Escribir aquí"
          :maxLength="validations.maxLengthTitleItem"
          :disabled="onlyRead"
        />
      </counter-text>
    </a-form-item>
  </a-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import transformWordGender from '@/app/shared/mixins/transform'
import CounterText from '@/app/shared/components/molecules/CounterText'

export default {
  name: 'ListSectionForm',
  components: {
    CounterText,
  },
  mixins: [transformWordGender],
  props: {
    dataSourceLines: {
      type: Array,
      default: () => [],
    },
    hasChild: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentNodeId: {
      type: String,
      required: true,
    },
    childId: {
      type: String,
      required: true,
    },
    chatbotId: {
      type: String,
      required: true,
    },
    onlyRead: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'list-section-form-chatbot',
    })
  },
  computed: {
    ...mapGetters(['nodes', 'selectedNodeId', 'validations_chatbot']),

    /**
     * Validaciones del nodo de acuerdo al soporte
     * @return {Object} validations
     * @return {Number} validations.maxLengthTitleCard
     * @return {Number} validations.maxLengthSubtitleCard
     */
    validations() {
      const node = 'lists'
      return this.validations_chatbot[node]
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SAVED_NODE']),
    ...mapActions(['updateChild']),

    /**
     * Setea valores al formulario
     * @param {Object} args
     * @param {String} args.title
     * @param {String} args.subtitle
     * @param {Object} args.media
     * @param {Number} args.order
     */
    setValues({ title, order }) {
      this.order = order

      this.$nextTick(() => {
        this.form.setFieldsValue({
          title,
        })
      })
    },
    /**
     * Escucha el evento de input
     */
    onInput() {
      this.setIsSavedItem(false)
    },
    /**
     * Setea un valor de guardo o  no
     * @param {Boolean} isSavedNode
     */
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Ejecuta el submit del formulario
     */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        this.handleUpdateCard(values)
        this.setIsSavedItem(true)
      })
    },
    /**
     * Subir cambios al nodo de mensaje
     * @param {Object} values - valores del formulario
     * @param {String} values.text - texto del mensaje
     * @param {Boolean} values.wait - si el mensaje esperará una respuesta o no
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     * @param {String} values.action - transfer, continue, none
     */
    async handleUpdateCard(values) {
      const settings = {
        order: this.order,
        parentNodeId: this.parentNodeId,
        childId: this.childId,
      }
      const newChild = {
        order: settings.order,
        title: values.title,
      }

      const response = await this.updateChild({
        chatbotId: this.chatbotId,
        nodeId: settings.parentNodeId,
        nodeType: 'lists',
        childId: settings.childId,
        newChild,
        childIdProperty: 'idItem',
      })
      if (response.success) {
        this.$emit('onChangeSettings', response)
        this.$message.success('Se actualizó correctamente')
      } else {
        this.$message.error(response.details || 'No se pudo ejecutar la acción')
      }
    },
    /**
     * Escucha el cambio de texto y verifica si el archivo es requerido
     */
    handleChangeText() {
      this.setIsSavedItem(false)
    },
  },
}
</script>

<style lang="sass" scoped>
.radio-message
  display: flex
  flex-direction: row
  align-items: center
  &__icon
    font-size: 14px
.form__selector__option
  width: 100%
.selector__option-container
  width: calc(100% + 12px)
  margin-left: -12px
  padding-left: 12px
.form__icon--inbox
  font-size: 24px
</style>
