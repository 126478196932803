<template>
  <a-form
    :form="form"
    layout="vertical"
    @submit.prevent="handleSubmit"
    class="text--left"
  >
    <slot name="title">
      <h4 class="semibold-20">Preferencias</h4>
    </slot>
    <p class="regular-14">
      Configura tu webchat, como su tiempo activo de conversación, mensaje de
      bienvenida, mensaje de despedida y más.
    </p>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">Tiempo de reinicio</h6>
      <p class="regular-14 mrg-bottom-12 text--left">
        Tiempo de persistencia de una conversación. Este tiempo se toma desde el
        último mensaje enviado por el cliente. Si se excede, se cerrará la
        conversación y se iniciará una nueva.
      </p>
      <a-select
        v-decorator="[
          'timeOfLifeAfterLastMessage',
          {
            initialValue: 10,
            rules: [
              {
                required: true,
                message: 'Por favor rellena este campo',
              },
            ],
          },
        ]"
        show-search
        placeholder="30 minutos"
        option-filter-prop="children"
        class="form__select"
        :filter-option="filterOption"
        @change="handleChangeTime"
      >
        <a-select-option v-for="option in optionsTime" :key="option">
          {{ option }} minutos
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">
        Registro de cliente
        <a-tooltip
          title="Si tienes un chatbot activo para este canal, que ya pide datos de tus clientes, esta opción podría ser redundante si está activada."
          placement="rightBottom"
        >
          <a-icon type="info-circle-o" />
        </a-tooltip>
      </h6>
      <div class="display-flex align--center">
        <p class="regular-14 mrg-bottom-0 text--left mrg-right-8">
          Formulario que iniciará una conversación, los datos que se pedirán
          son: Nombres, documento de identidad, email y número de celular.
        </p>
        <a-switch
          v-decorator="[
            'isRegistrationRequired',
            { valuePropName: 'checked', initialValue: false },
          ]"
          @change="(value) => hasNewValue({ isRegistrationRequired: value })"
        />
      </div>
    </a-form-item>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">Marca de agua</h6>
      <div class="display-flex align--center">
        <p class="regular-14 mrg-bottom-0 text--left mrg-right-8">
          Desactiva esta opción si deseas que no se muestre la marca de
          Securitec.
        </p>
        <a-switch
          v-decorator="[
            'isFreeVersion',
            { valuePropName: 'checked', initialValue: true },
          ]"
          @change="handleChangeWaterMark"
        />
      </div>
    </a-form-item>
    <a-form-item
      :class="{
        'mrg-bottom-0': hasMessage,
        'mrg-bottom-24': !hasMessage,
      }"
    >
      <h6 class="semibold-14 text--left mrg-bottom-4">
        Mensaje inicial
        <a-tooltip
          title="Si tienes un chatbot activo para este canal, este mensaje inicial podría ser redundante con el saludo del chatbot."
          placement="rightBottom"
        >
          <a-icon type="info-circle-o" />
        </a-tooltip>
      </h6>
      <div class="display-flex align--center">
        <p class="regular-14 mrg-bottom-0 text--left mrg-right-8">
          Mensaje de bienvenida a los usuarios cuando inician una conversación.
        </p>
        <a-switch
          v-decorator="[
            'isWelcomeMessageVisible',
            { valuePropName: 'checked', initialValue: false },
          ]"
          @change="handleChangeHasMessage"
        />
      </div>
    </a-form-item>
    <a-form-item v-if="hasMessage">
      <counter-text :text="msgWelcome" :maxLength="250">
        <a-textarea
          v-decorator="[
            'welcomeMessage',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          class="position-relative"
          placeholder="Escribe aquí..."
          :auto-size="{ minRows: 3, maxRows: 4 }"
          :maxLength="250"
          @input="handleInputMsgWelcome($event.target.value)"
        />
      </counter-text>
    </a-form-item>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">Mensaje de despedida</h6>
      <p class="regular-14 mrg-bottom-12 text--left">
        Mensaje que se muestra al usuario cuando un agente finaliza un ticket de
        WebChat o expira el tiempo de reinicio.
      </p>
      <counter-text :text="msgFinish" :maxLength="250">
        <a-textarea
          v-decorator="[
            'messageToFinish',
            { initialValue: webchat_demo.messageToFinish },
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor completa este campo',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios vacios',
                },
              ],
            },
          ]"
          class="position-relative"
          placeholder="Escribe aquí..."
          :auto-size="{ minRows: 3, maxRows: 4 }"
          :maxLength="250"
          @input="handleInputMsgFinish($event.target.value)"
        />
      </counter-text>
    </a-form-item>
  </a-form>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import CounterText from '@/app/shared/components/molecules/CounterText'
import { isEqual } from '@/app/channels/utils/compare'

export default {
  name: 'WebchatPreferences',
  components: {
    CounterText,
  },
  data: () => ({
    optionsTime: [10, 20, 30, 40, 50, 60],
    hasMessage: false,
    originalValues: null,
    msgFinish: '',
    msgWelcome: '',
    formValues: null, // usado solo para validar si hay algun cambio
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  computed: {
    ...mapGetters([
      'company',
      'isPlanFree',
      'companyPricing',
      'webchat_demo',
      'spent_channels',
    ]),

    /**
     * @returns {Boolean} si company tiene el canal de webchat
     */
    hasWebchat() {
      if (!this.company) return false
      return this.spent_channels.webchat && !this.spent_channels.webchat.isFull
    },
    /**
     * @returns {Boolean} Si company tiene el plan advanced
     */
    isPlanAdvanced() {
      if (!this.companyPricing) return false
      return this.companyPricing.plan.key === 'advanced'
    },
  },
  methods: {
    ...mapActions(['updatedWebchat']),
    ...mapMutations(['SET_WEBCHAT', 'SET_WEBCHAT_DEMO', 'SET_MODAL_UPGRADE']),

    /**Submit de preferencias */
    handleSubmit() {
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!values.hasMessage) values.hasMessage = false
        if (!err) {
          this.$emit('onLoading', true)
          const create = this.$route.query.create

          const response = await this.updatedWebchat({
            newValues: values,
            action: create ? 'activate' : null,
          })
          if (response.success) {
            this.$emit('onSuccessSubmit', response.result)
            this.SET_WEBCHAT(response.result)
            // para la demo de webchat
            this.SET_WEBCHAT_DEMO(response.result)
          } else this.$message.error(response.details)
          this.$emit('onLoading', false)
        }
      })
    },
    /**
     * Filtrado de opciones para las busquedas
     * @param {String} input
     * @param {Object} option
     */
    filterOption(input, option) {
      const optionFound = option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase())
      if (optionFound >= 0)
        return option.componentOptions.children[0].text.toLowerCase()
    },
    /**
     * Cambiar tiempo de expiracion
     * @param {String} value
     */
    handleChangeTime(value) {
      this.hasNewValue({ timeOfLifeAfterLastMessage: `${value}` })
    },
    /**Cambio de tener o no mensaje
     * @param {String} value
     */
    handleChangeHasMessage(value) {
      this.hasMessage = value
      this.hasNewValue({ isWelcomeMessageVisible: value })
    },

    /**
     * Setea valores al formulario
     * @param {Object} newVals
     * @param {String} newVals.title
     * @param {String} newVals.color
     * @param {String} newVals.avatar
     */
    handleSetValues(newVals) {
      if (!newVals) return
      this.originalValues = newVals
      // es necesario que esta linea esta antes, para que pueda renderizar el mensaje de bienvenida
      this.hasMessage = newVals.isWelcomeMessageVisible ? true : false
      if (!newVals.isRegistrationRequired)
        newVals.isRegistrationRequired = false
      if (!newVals.isWelcomeMessageVisible)
        newVals.isWelcomeMessageVisible = false
      setTimeout(() => this.form.setFieldsValue(newVals), 100)
    },
    /** Resetear valores */
    handleResetValues() {
      this.hasMessage = false
    },
    /**
     * Checka si el valor de un campo ha cambiado con referencia al valor original
     * {title: 'this is my title'}
     * @param {Object} field
     * @param {String} field[fielName] - el nombre del campo
     * @param {String} field.fielName - el nuevo valor del campo
     */
    hasNewValue(field) {
      if (!this.originalValues) return
      if (!this.formValues) this.formValues = this.form.getFieldsValue()
      this.formValues = { ...this.formValues, ...field }
      // si el mensaje de bienvenida se ha marcado como no visible, no deberia validarse
      if (!this.formValues.isWelcomeMessageVisible)
        delete this.formValues.welcomeMessage
      const hasNew = !isEqual(
        { ...this.formValues },
        { ...this.originalValues }
      )
      this.$emit('onChangeValue', hasNew)
      return hasNew
    },
    handleChangeWaterMark(isFreeVersion) {
      if (this.isPlanFree || this.isPlanAdvanced) {
        // resetea el valor cambiado del switch
        setTimeout(
          () => this.form.setFieldsValue({ isFreeVersion: true }),
          1000
        )
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
      }
      this.hasNewValue({ isFreeVersion })
      this.SET_WEBCHAT_DEMO({ isFreeVersion })
    },
    /**
     * Evento de input del mensaje de finalizacion
     * @param {String} msg
     */
    handleInputMsgFinish(msg) {
      this.msgFinish = msg
      this.SET_WEBCHAT_DEMO({ messageToFinish: msg })
      this.hasNewValue({ messageToFinish: msg })
    },
    /**
     * Evento de input del mensaje de bienvenida
     * @param {String} msg
     */
    handleInputMsgWelcome(msg) {
      this.msgWelcome = msg
      this.SET_WEBCHAT_DEMO({ welcomeMessage: msg })
      this.hasNewValue({ welcomeMessage: msg })
    },
  },
}
</script>

<style lang="sass" scoped>
.form__select
  width: 100%
.mrg-bottom-24
  margin-bottom: 24px
</style>
