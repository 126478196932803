<template>
  <div class="table-menu-billing-container">
    <div class="table-menu-billing-header">
      <h6 class="table-menu-billing-title heading-h6">
        {{ title }}
        <a-tooltip v-if="showInfoTitle">
          <span slot="title">
            Recuerda que todos los mensajes que sean de inicio de conversación
            es decir FTC, FEP, UIC y BIC tienen una comisión de 0, 003. Más
            detalles
            <anchor href="https://securitec.pe/whatsapp-business-api/"
              >aquí.</anchor
            >
          </span>
          <a-icon type="info-circle" />
        </a-tooltip>
      </h6>
      <a-icon
        :type="collapse ? 'down' : 'up'"
        @click="handleChangeHide(!collapse)"
      />
    </div>
    <a-divider style="margin-top: 4px; margin-bottom: 12px; color: #c4c4c4" />
    <div v-if="!collapse" class="table-menu-billing-content">
      <div v-if="type === 'slot'">
        <slot></slot>
      </div>
      <div v-else>
        <FilterMenuSpendings
          :type="type"
          @search="search"
          :loading="loading"
          :show-download="showDownload"
        />
        <a-table
          :id="`table_menu_${type}`"
          class="table-menu__table"
          :class="{
            'scrollbar--invisible': showFooter,
            scrollbar_basic: !showFooter,
          }"
          rowKey="_id"
          size="middle"
          :columns="columns"
          :data-source="data"
          :pagination="typePagination === 'default' ? pagination : false"
          @change="typePagination === 'default' && handlePagination"
          :locale="{ emptyText: 'No hay datos' }"
          :scroll="{ x: '60vw', y: 'calc(100vh - 45em)' }"
          :loading="loading"
        >
          {{ addScrollBody() }}
          <!--Titulo personalizado de las tablas-->
          <header-info-table
            slot="sessionsTitle"
            :title="TITLES_SPENDINGS_WHATSAPP.sessionsTitle.title"
            :tooltip="TITLES_SPENDINGS_WHATSAPP.sessionsTitle.tooltip"
          />
          <header-info-table
            slot="FEPTitle"
            :title="TITLES_SPENDINGS_WHATSAPP.FEPTitle.title"
            :tooltip="TITLES_SPENDINGS_WHATSAPP.FEPTitle.tooltip"
          />
          <header-info-table
            slot="FTCTitle"
            :title="TITLES_SPENDINGS_WHATSAPP.FTCTitle.title"
            :tooltip="TITLES_SPENDINGS_WHATSAPP.FTCTitle.tooltip"
          />
          <header-info-table
            slot="BICTitle"
            :title="TITLES_SPENDINGS_WHATSAPP.BICTitle.title"
            :tooltip="TITLES_SPENDINGS_WHATSAPP.BICTitle.tooltip"
          />
          <header-info-table
            slot="UICTitle"
            :title="TITLES_SPENDINGS_WHATSAPP.UICTitle.title"
            :tooltip="TITLES_SPENDINGS_WHATSAPP.UICTitle.tooltip"
          />
          <!-- Tag del estado de pago -->
          <span slot="state" slot-scope="state, record" class="state">
            <a-tag
              :color="
                ['Aprobado', 'Reposición'].includes(state) ? 'green' : 'red'
              "
            >
              {{ state }}
            </a-tag>
            <a-popover
              v-if="record.rejectionDetail"
              title="Detalle de pago rechazado"
              :overlayStyle="popStyles"
            >
              <template slot="content">
                <p class="mrg-bottom-0">
                  Este pago no se efectúo porque
                  {{ record.rejectionDetail.toLowerCase() }}. Si tienes dudas
                  <router-link :to="{ name: 'support' }" target="_blank">
                    comunícate con nosotros </router-link
                  >.
                </p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </span>
          <span slot="apiBusinessId" slot-scope="apiBusinessId, record">
            {{ apiBusinessId }}
            {{
              `${
                channelCompanyAlias(record.channelCompanyId)
                  ? '(' + channelCompanyAlias(record.channelCompanyId) + ')'
                  : ''
              }`
            }}
          </span>
          <!-- Boton descarga Invoice -->
          <span slot="action" slot-scope="record">
            <a
              @click="handleAction('download', record)"
              :disabled="record.status === 'rejected'"
            >
              Descargar
            </a>
          </span>
          <!--Footer de tabla-->
          <a-row
            v-if="showFooter"
            slot="footer"
            type="flex"
            class="table__footer scrollbar_basic"
            id="table__footer"
          >
            {{ addScrollFooter() }}
            <a-col
              v-for="item in footer"
              :key="item.key"
              :style="`width: ${item.width}`"
              class="table__footer__cell text--left"
            >
              <span v-if="item.title" class="semibold-14">{{
                item.title
              }}</span>
              <template v-else>{{
                dataSourceFooter[item.dataIndex] ||
                dataSourceFooter[item.dataIndex] == 0
                  ? dataSourceFooter[item.dataIndex]
                  : ''
              }}</template>
            </a-col>
          </a-row>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import FilterMenuSpendings from '@/app/shop/components/spendings/FilterMenuSpendings.vue'
import HeaderInfoTable from './HeaderInfoTable.vue'
import { TITLES_SPENDINGS_WHATSAPP } from '@/app/shop/utils/spendingsUtil'
import Anchor from '@/app/shared/components/atoms/Anchor'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'

import moment from 'moment'
moment.locale('es')

export default {
  name: 'TableMenuSpendings',
  components: { FilterMenuSpendings, HeaderInfoTable, Anchor },
  props: {
    fetch: {
      type: Function,
      required: false,
    },
    columns: {
      type: Array,
      default: () => [],
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String,
      default: '',
      required: true,
      enum: ['spending', 'recharge', 'slot'],
    },
    tableKey: {
      type: String,
      required: false,
    },
    isSelected: {
      type: Boolean,
      default: true,
      required: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    showInfoTitle: {
      type: Boolean,
      default: false,
    },
    typePagination: {
      type: String,
      required: false,
      enum: ['default', 'scroll'],
      default: 'default',
    },
    footer: {
      type: Array,
      default: () => [], // los elementos deben contener la misma cantidad de columnas
    },
    dataSourceFooter: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    TITLES_SPENDINGS_WHATSAPP,
    // Columnas de la tabla
    collapse: false,
    loading: false,
    transacctions: {},
    page: 1,
    settings: {},
    localColumns: [
      {
        title: 'Fecha',
        key: 'date',
        dataIndex: 'date',
      },
      {
        title: 'Nro de pago',
        dataIndex: 'paycode',
        key: 'paycode',
      },
      {
        title: 'Método',
        dataIndex: 'method',
        key: 'method',
      },
      {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Estado',
        key: 'state',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    popStyles: {
      width: '16.5em',
    },
    lastChild: null,
    morePaginate: true,
  }),
  mixins: [supportMixin, attemptMixin],
  watch: {
    isSelected(val) {
      this.collapse = !val
    },
  },
  mounted() {
    this.collapse = !this.isSelected
    if (this.typePagination === 'scroll') this.setLastChild()
  },
  destroyed() {
    // remueve los listeners, cuando el componente se destruya
    document.removeEventListener('scroll', this.setScrollXBody)
    document.removeEventListener('scroll', this.setScrollXFooter)
  },
  computed: {
    /**
     * @returns {Object}
     */
    pagination() {
      return {
        collapseOnSinglePage: true,
        defaultPageSize: 7,
        size: 'default',
        total: this.transacctions.total || 0,
        current: this.page,
      }
    },
    /**
     * @returns {Object[]}
     */
    data() {
      if (!this.transacctions.data) return []
      return this.transacctions.data
    },
    showFooter() {
      return this.footer && !!this.footer.length
    },
  },
  methods: {
    ...mapMutations(['SET_PAYMENTS_PAGINATION']),
    ...mapActions(['exportPayment']),

    /**
     * Escucha el cambio de paginacion
     */
    handlePagination(pagination) {
      this.page = pagination.current
      this.nextPage()
    },
    /**
     * Busca de acuerda a la configuracion
     * @param {*} settings
     */
    async search(settings) {
      this.loading = true
      this.settings = settings
      this.page = 1
      this.transacctions = await this.fetch(this.page, settings)
      this.loading = false
      if (!this.transacctions.data.length) this.morePaginate = false
      else this.morePaginate = true
    },
    /**
     * Busca en la siguiente página
     */
    async nextPage() {
      try {
        this.loading = true
        const response = await this.fetch(this.page, this.settings)
        if (response.push)
          this.transacctions.data = [
            ...this.transacctions.data,
            ...response.data,
          ]
        else this.transacctions = response
        this.loading = false

        if (!response.data.length) this.morePaginate = false
        if (this.typePagination === 'scroll') this.setLastChild()
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Click en algún botón de acciones
     * @param {String} type [details]
     * @param {Object} record
     */
    async handleAction(type, record) {
      const statusNotAllowed = ['rejected', 'annulled']
      if (statusNotAllowed.includes(record.status)) return
      if (type === 'download') {
        await this.exportPayment(record._id)
      }
    },
    /**
     * Cambia el valor del colapsado
     */
    handleChangeHide(collapse) {
      this.$emit('onCollapse', this.tableKey, collapse)
      this.collapse = collapse
    },
    /**
     * Sete el ultimo hijo de todos las filas de la tabla
     */
    setLastChild() {
      this.intervalAttempt(() => {
        const parent = document.getElementById(`table_menu_${this.type}`)
        const childs = parent.getElementsByClassName('ant-table-row')
        if (childs.length === 0) throw 'no childs'
        this.lastChild = childs[childs.length - 1]
        this.observer = new IntersectionObserver(this.handleBottomReached, {
          threshold: 0.5, // 0.5 para secciones pequeñas y 1.0 para secciones grandes
        })
        this.observer.observe(this.lastChild)
      })
    },
    /**
     * Evento ejecutado cuando se detecta que está en la última fila
     * @param {*} entries
     */
    handleBottomReached(entries) {
      const entry = entries[0]
      if (entry.isIntersecting && this.morePaginate && !this.isLoading) {
        const pagination = { current: this.pagination.current + 1 }
        this.handlePagination(pagination)
      }
    },
    /**
     * Agrega el evento de scroll al footer
     */
    addScrollFooter() {
      if (!this.showFooter) return
      this.$nextTick(() => {
        const footer = document.getElementById('table__footer')
        footer.addEventListener('scroll', this.setScrollXBody)
      })
    },
    /**
     * Agrega el evento de scroll al body
     */
    addScrollBody() {
      if (this.typePagination !== 'scroll') return
      this.$nextTick(() => {
        const body = document.querySelector(
          '#table_menu_spending .ant-table-body'
        )
        if (!body) return
        body.addEventListener('scroll', (e) => {
          if (this.showFooter) this.setScrollXFooter(e)
        })
      })
    },
    /**
     * Cuando hay scroll horizontal en el footer, scrollea también el body
     * @param {Event} event
     */
    setScrollXBody(event) {
      const scrollLeft = event.target.scrollLeft
      const body = document.querySelector(
        '#table_menu_spending .ant-table-body'
      )
      if (body) body.scrollLeft = scrollLeft
    },
    /**
     * Cuando hay scroll horizontal en el body, scrollea también el footer
     * @param {Event} event
     */
    setScrollXFooter(event) {
      const scrollLeft = event.target.scrollLeft
      const footer = document.getElementById('table__footer')
      if (footer) footer.scrollLeft = scrollLeft
    },
  },
}
</script>

<style lang="sass" scoped>
.table-menu-billing-container
  // width: 80%
.table-menu-billing-header
  display: flex
  justify-content: space-between
  align-items: center
.table-menu-billing-title
  margin-bottom: 0px
.table-menu-billing
  &-container
    &:last-child
      margin-top: 20px
  &-content
.table-menu
  &__table
    margin-top: 12px
.table__footer
  flex-wrap: nowrap
  overflow-x: scroll
  border-top: 1px solid $gray_4
  &__cell
    flex-shrink: 0
    padding: 12px 8px
</style>
<style lang="sass">
.table-menu__table
  .ant-table-body, .ant-table-body-inner
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
#table_menu_spending
  .ant-table-footer
    padding: 0px
  .ant-table-body
    overflow-x: hidden
</style>
