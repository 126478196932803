<template>
  <box-template>
    <div class="template__container-steps">
      <a-steps
        v-if="steps"
        :current="currentStep"
        size="small"
        direction="horizontal"
        class="template__steps"
      >
        <a-step
          v-for="item in steps"
          :key="item.step_title"
          :title="item.step_title"
          class="template__step template-add-strategy__step"
        />
      </a-steps>
    </div>
    <custom-section-scroll
      :master-styles="{
        height: `calc(100vh - (${heightFooter} + ${margin} + ${heightSteps}))`,
        width: '100%',
        padding: '8px 0px',
      }"
      :content-styles="{
        margin: '2.4em auto 0px',
        maxWidth: '1000px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }"
    >
      <a-row :gutter="[100]" type="flex" justify="center">
        <a-col :xs="10" :sm="8" :md="12" :lg="11" :xl="12" :xxl="12">
          <component
            :is="addStrategyForm"
            ref="add_strategy_form"
            :current-step="currentStep"
            :title="currentTitle"
            @onChange="handleChangeForm"
            @onLoading="handleLoadingForm"
            @onHasNewValues="(hasNew) => (isDisabledOk = hasNew)"
            @onResetMedia="handleResetMedia"
          />
        </a-col>
        <a-col :xs="4" :sm="6" :md="11" :lg="11" :xl="10" :xxl="10">
          <a-row
            type="flex"
            justify="center"
            align="middle"
            class="full-height"
          >
            <mobile-wsp
              :channel="channelPreview.type"
              :default-message="channelPreview.default_message"
              :message="mobilePreview.message"
              :media-type="mobilePreview.mediaType"
              :media-url="mobilePreview.mediaUrl"
              :file-name="mobilePreview.fileName"
            />
          </a-row>
        </a-col>
      </a-row>
    </custom-section-scroll>
    <basic-footer
      :okText="currentOkText"
      :cancelText="currentCancelText"
      :okLoading="isLoadingOk"
      :okDisabled="isDisabledOk"
      :cancelDisabled="isDisabledCancel"
      @onOk="handleSubmit"
      @onCancel="handleCancel"
    >
      <p slot="extra" class="mrg-bottom-0">
        <a-icon type="info-circle" /> Las respuestas a estos envíos serán
        transferidas como tickets directamente a tus agentes, sin pasar por un
        chatbot.
      </p>
    </basic-footer>
  </box-template>
</template>

<script>
import BoxTemplate from '@/app/shared/components/templates/BoxTemplate'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import FormWhatsappStrategy from '@/app/strategies/components/forms/FormWhatsappStrategy'
import FormSmsStrategy from '@/app/strategies/components/forms/FormSmsStrategy'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import MobileWsp from '@/app/strategies/components/MobileWsp'
import { mapGetters } from 'vuex'
import { STEPS_BY_CHANNEL } from '@/app/strategies/utils/utilStrategies'

export default {
  name: 'AddStrategyTemplate',
  components: {
    BoxTemplate,
    BasicFooter,
    FormWhatsappStrategy,
    CustomSectionScroll,
    MobileWsp,
    FormSmsStrategy,
  },
  data: () => ({
    heightFooter: '53px',
    heightSteps: '76px',
    margin: '24px',
    isLoadingOk: false,
    isDisabledOk: false,
    isDisabledCancel: false,
    mobilePreview: {
      message: null, // el cuerpo de la plantilla
      mediaType: null, // TEXT, DOCUMENT, VIDEO, IMAGE
      fileName: null, // nombre del archivo adjuntado
      mediaUrl: null, // la url del archivo adjuntado
    },
    currentStep: 0,
    newStrategy: {},
  }),
  computed: {
    ...mapGetters(['simple_templates']),

    steps() {
      const channel = this.$route.query.channel
      return channel ? STEPS_BY_CHANNEL[channel].steps : []
    },
    currentTitle() {
      return this.steps[this.currentStep].title
    },
    currentOkText() {
      return this.steps[this.currentStep].ok_text
    },
    currentCancelText() {
      return this.steps[this.currentStep].cancel_text
    },
    addStrategyForm() {
      const channel = this.$route.query.channel

      switch (channel) {
        case 'whatsapp':
          return FormWhatsappStrategy
        case 'sms':
          return FormSmsStrategy
        default:
          return null
      }
    },
    channelPreview() {
      const channel = this.$route.query.channel

      return STEPS_BY_CHANNEL[channel].preview_mobile
    },
  },
  methods: {
    /**
     * Escucha el evento de submit de la creacion
     */
    async handleSubmit() {
      const isLast = this.currentStep === this.steps.length - 1
      // si no es el ultimo paso
      if (!isLast) {
        this.$refs.add_strategy_form.handleSubmit()
        this.handleNext(this.$refs.add_strategy_form.newStrategy)
      } else await this.$refs.add_strategy_form.handleSubmit()
    },
    /**
     * Cancela la creación o retrocede al modulo de campañas
     */
    handleCancel() {
      const isFirst = this.currentStep === 0
      if (!isFirst) this.handleBack()
      else this.$router.push({ name: 'campaigns' })
    },
    /**
     * Escucha los cambios en el formulario
     * @param {*} key - el nombre de la propiedad
     * @param {*} value - el valor de la propiedad
     */
    handleChangeForm(key, value) {
      if (key === 'mediaType') {
        this.mobilePreview.mediaType = value
      }
      if (key === 'templateId')
        this.mobilePreview.message = this.simple_templates.find(
          (template) => template._id === value
        ).body
      if (key === 'fileName') {
        this.mobilePreview.fileName = value
      }
      if (key === 'mediaUrl') {
        this.mobilePreview.mediaUrl = value
      }
      if (key === 'messageSms') {
        this.mobilePreview.message = value
      }
    },
    handleLoadingForm(loading) {
      this.isLoadingOk = loading
      this.isDisabledOk = loading
      this.isDisabledCancel = loading
    },
    /**
     * Avanza al siguiente paso
     * @param {Object} values
     * @param {Boolean} values.success
     */
    handleNext(values) {
      this.newStrategy = Object.assign(this.newStrategy, values)
      this.currentStep = this.currentStep + 1 // avanza a la siguiente pagina
      this.$nextTick(() => {
        this.$refs.add_strategy_form.handleSetValues(this.newStrategy)
      })
    },
    /**
     * Regresar al paso anterior
     */
    handleBack() {
      this.$refs.add_strategy_form.handleBack()
      this.currentStep = this.currentStep - 1
      this.$nextTick(() => {
        this.$refs.add_strategy_form.handleSetValues(this.newStrategy)
      })
    },
    /**
     * Escucha el reseteo de los medias
     */
    handleResetMedia() {
      this.mobilePreview = {
        message: null,
        mediaType: null,
        fileName: null,
        mediaUrl: null,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form__icon
  font-size: 32px

.template
  &__container-steps
    padding: 32px 20% 20px
    width: 100%
</style>
<style lang="sass">
.template-add-strategy__step
  .ant-steps-item-container
    display: flex
</style>
