const CATEGORIES_TEMPLATES = [
  {
    value: 'UTILITY',
    title: 'Utility',
    description:
      'Esta categoría sirve para enviar actualizaciones de las cuentas, pedidos, alertas y mucho más. Para compartir información importante.',
  },
  {
    value: 'MARKETING',
    title: 'Marketing',
    description:
      'Esta categoría sirve para enviar ofertas promocionales, anuncios de productos, etc. Esto, con el fin de aumentar el reconocimiento y la interacción con los clientes.',
  },
  {
    value: 'AUTHENTICATION',
    title: 'Authentication',
    description:
      'Esta categoría sirve para enviar código a tus clientes que les permitan acceder de forma segura a sus cuentas.',
  },
]

export { CATEGORIES_TEMPLATES }
