import moment from 'moment'
moment.locale('es')

import linkifyHtml from 'linkify-html'

export default {
  data: () => ({
    localePicker: {
      // Traducción de botones del datepicker
      lang: {
        placeholder: 'Seleccione Fecha y Hora',
        rangePlaceholder: ['Fecha inicial', 'Fecha final'],
        today: 'Hoy',
        now: 'Ahora',
        backToToday: 'Volver a hoy',
        ok: 'Agregar',
        clear: 'Limpiar',
        month: 'Mes',
        year: 'Año',
        timeSelect: 'Seleccione hora',
        dateSelect: 'Seleccione día',
        monthSelect: 'Elija un mes',
        yearSelect: 'Elija un año',
        decadeSelect: 'Elija una década',
        yearFormat: 'YYYY',
        dateFormat: 'D/M/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'D/M/YYYY HH:mm',
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: 'Mes anterios (PageUp)',
        nextMonth: 'Siguienmte mes (PageDown)',
        previousYear: 'Año anterior (Control + left)',
        nextYear: 'Siguiente año (Control + right)',
        previousDecade: 'Década anterior',
        nextDecade: 'Siguiente Década',
        previousCentury: 'Siglo anterior',
        nextCentury: 'Siglo siguiente',
      },
      // timePickerLocale: {
      //   placeholder: "Select time"
      // },
      // dateFormat: "YYYY-MM-DD",
      // dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
      // weekFormat: "YYYY-wo",
      // monthFormat: "YYYY-MM"
    },
    textFormats: [
      {
        format: 'italic',
        // eslint-disable-next-line no-useless-escape
        regex_to_find_format: /(_[^\n^_]+_)/gm,
        offset: 1,
        replace_tag: '<em>{{}}</em>',
      },
      {
        format: 'bold',
        // eslint-disable-next-line no-useless-escape
        regex_to_find_format: /(\*[^\n^\*]+\*)/gm,
        offset: 1,
        replace_tag: '<strong>{{}}</strong>',
      },
      {
        format: 'overline',
        // eslint-disable-next-line no-useless-escape
        regex_to_find_format: /(\~[^\n^\~]+\~)/gm,
        offset: 1,
        replace_tag: '<del>{{}}</del>',
      },
      {
        format: 'monospace',
        regex_to_find_format: /(`{3})[^\n]\w.*?(`{3})/gm,
        offset: 3,
        replace_tag: '<code>{{}}</code>',
      },
    ],
  }),
  computed: {},
  methods: {
    /**
     * Formatea el número para anteponerle ceros
     * @param {Number} ticket El número de ticket para ser formateado
     * @return {String} Número de ticket formateado
     */
    ticketNumberFormatted(ticket) {
      const max_digits = 8 // Máxima cantidad de digitos para el texto formateado
      const length_digits = ticket.toString().length
      let stringSplitted = ticket.toString().split('').reverse()
      for (let i = length_digits; i < max_digits; i++) {
        stringSplitted.push('0')
      }
      const ticketString = stringSplitted.reverse().join('')
      return `#${ticketString}`
    },
    /**
     * Formatea el número para anteponerle un cero, solo para pares de números
     * @param {Number} number El número para ser formateado
     * @return {String} Número con 0 a la izquierda si es de un dígito
     */
    fillPairCeroSpace(number) {
      if (number.toString().length === 2) {
        return `${number}`
      } else if (number.toString().length === 1) {
        return `0${number}`
      } else {
        return `${number}`
      }
    },
    /**
     * Formatea una cantidad de tiempo en horas, min, seg
     * @param {Number} time La cantidad de tiempo
     * @param {String} inUnity La unidad en la que se encuentra esa cantidad de tiempo de entrada: [miliseconds,seconds,minutes,hours]
     * @param {String} outUnity La unidad en la que se quiere que se formatee: [minutes,hours]
     * @return {String} La hora formateada con los ":"
     */
    timeFormatted(time, inUnity, outUnity) {
      let toSeconds = 0
      if (inUnity === 'miliseconds') toSeconds = time * 1000
      else if (inUnity === 'seconds') toSeconds = time
      else if (inUnity === 'minutes') toSeconds = time / 30
      else if (inUnity === 'hours') toSeconds = time / 3600

      switch (outUnity) {
        case 'toMinutes': {
          let res = toSeconds % 60
          return `${this.fillPairCeroSpace(
            Math.floor(toSeconds / 60)
          )}:${this.fillPairCeroSpace(res)}`
        }
        case 'toHours': {
          let res_h = Math.floor(toSeconds / 3600)
          let res_m = Math.floor((toSeconds % 3600) / 60)
          let res_s = (toSeconds % 3600) % 60
          return `${this.fillPairCeroSpace(res_h)}:${this.fillPairCeroSpace(
            res_m
          )}:${this.fillPairCeroSpace(res_s)}`
        }
        default:
          return time
      }
    },
    /**
     * Devuelve al texto prioritario, a menos que este sea nulo, en ese caso devuelve al otro
     * @param {String} text_a Texto A, es de baja prioridad pero es una opción si no hay qué mas devolver - Obligatorio
     * @param {String} text_b Texto B, es de alta prioridad - opcional
     * @return {String} Texto disponible según su prioridad y existencia
     */
    validCompareTexts(text_a, text_b) {
      const text = !text_b ? text_a : text_b
      return text
    },
    /**
     * Formatea la fecha, si es posible a texto, pero la primera letra con Mayuscula
     * @param {Date} date Fecha
     * @returns {String} fecha formateada
     */
    firstMayDateText(date) {
      let text = moment(date).calendar()
      let mayus = text.toUpperCase().charAt(0)
      let minus = text.substring(1, text.length)
      return `${mayus}${minus}`
    },
    /**
     * Transforma una unidad de peso a otra
     * @param {Numer} size Peso
     * @param {String} unitySizeIn Unidad de peso entrante [B,KB, MK, GB]
     * @param {String} unitySizeOut Unidad de peso saliente [toB,toKB, toMK, toGB]
     * @param {String} formatOut indica qué tipo de valor devolverá [Number, String]
     * @return {String} devuelve lo transformado concatenado con su unidad de medida, cuando formatOut es 'String'
     * @return {Number} devuelve el peso transformado, cuando formatOut es 'Number'
     */
    toOtherUnitySize(size, unitySizeIn, unitySizeOut, formatOut = 'String') {
      let toBytes = 0
      if (unitySizeIn === 'B') toBytes = size
      else if (unitySizeIn === 'KB') toBytes = size * 1024
      else if (unitySizeIn === 'MB') toBytes = size * (1024 * 2)
      else if (unitySizeIn === 'GB') toBytes = size * (1024 * 3)

      switch (unitySizeOut) {
        case 'toB': {
          return formatOut === 'Number' ? toBytes : `${toBytes}B`
        }
        case 'toKB': {
          let toKBytes = toBytes / 1024
          return formatOut === 'Number' ? toKBytes : `${toKBytes}KB`
        }
        case 'toMB': {
          let toMBytes = toBytes / Math.pow(1024, 2)
          return formatOut === 'Number' ? toMBytes : `${toMBytes}MB`
        }
        case 'toGB': {
          let toGBytes = toBytes / Math.pow(1024, 3)
          return formatOut === 'Number' ? toGBytes : `${toGBytes}GB`
        }
        default:
          return formatOut === 'Number' ? size : `${size}${unitySizeIn}`
      }
    },
    /**
     * Reemplaza los parametros {{}} en el formato por el string
     * @param {String} string
     * @param {Object} format
     * @param {Number} format.offset
     * @param {String} format.replace_tag
     * @returns {String}
     */
    replaceParamsInFormat(string, format = null) {
      let value = string
      value = value.slice(format.offset)
      value = value.slice(0, -format.offset)
      return format.replace_tag.replace('{{}}', value)
    },
    /**
     * Reemplaza en el string por valor de replace
     * @param {String} string
     * @param {String} search
     * @param {String} replace
     * @returns {String}
     */
    replaceString(string, search, replace) {
      let newText = string
      return newText.replaceAll(search, replace)
    },
    /**
     * Formatea el texto de acuerdo a los patrones de formatos
     * @param {String} text
     * @param {String[]} support - ['all'], ['formats', 'link'], ['formats'], ['link']
     * @returns {String} - texto formateado
     */
    textFormatter(text, formats, support = ['all']) {
      if (!text) return
      // formatea los signos de etiqueta html: < >
      let preFormatted = this.replaceString(text.slice(), '<', '&lt;')
      preFormatted = this.replaceString(preFormatted.slice(), '>', '&gt;')
      let newText = preFormatted.slice()

      // si en support incluye all o links
      if (support.includes('link') || support.includes('all')) {
        newText = linkifyHtml(preFormatted.slice(), {
          target: '_blank',
          attributes: { rel: 'noopener noreferrer' },
        })
      }
      // si el support solo es para links
      if (support.length === 1 && support.includes('link')) return newText
      const formatEnabled = this.textFormats.filter((textFormat) =>
        formats.includes(textFormat.format)
      )
      formatEnabled.forEach((format) => {
        if (format.regex_to_find_format.test(text)) {
          newText = newText.replaceAll(format.regex_to_find_format, (match) =>
            this.replaceParamsInFormat(match, format)
          )
        }
      })
      return newText
    },
  },
}
