<template>
  <div class="section-accounts" v-if="profile">
    <header-module
      title="USUARIOS"
      tipography="heading-h5"
      class="template__header"
      :link="`${vari.WEB_DOC}primeros-pasos/gestion-usuarios/`"
      text-before-link="Crea y gestiona tus usuarios por perfiles."
      text-after-link="si deseas conocer más."
      textLink="Click aquí"
      positionTooltip="right"
    />
    <div class="first-section">
      <!-- Filtro -->
      <a-input-search
        placeholder="Buscar nombre"
        style="width: 312px; margin-right: 12px"
        @input="onInput"
        @search="onSearch"
      />
      <a-select
        v-if="permissionsModule && permissionsModule.filter"
        default-value="allUsers"
        @change="handleChangeTypeAccount"
        style="width: 204px; margin-right: 12px"
      >
        <a-select-option value="allUsers"> Todos los usuarios </a-select-option>

        <a-select-option
          v-for="typeUser in typeUsersPerPermissions"
          :key="typeUser.value"
        >
          {{ typeUser.title }}
        </a-select-option>
      </a-select>
      <a-select
        default-value="all"
        style="width: 204px; margin-right: 12px"
        @change="handleChangeStatusAccount"
      >
        <a-select-option v-for="status in STATUS_ACCOUNT" :key="status.value">
          {{ status.title }}
        </a-select-option>
      </a-select>

      <a-select
        default-value="all"
        style="width: 204px"
        @change="handleChangeStatusPayment"
      >
        <a-select-option v-for="status in STATUS_PAYMENT" :key="status.value">
          {{ status.title }}
        </a-select-option>
      </a-select>
      <ModalConfirmation
        v-if="modalConfirmation.visible"
        :visible="modalConfirmation.visible"
        :title="modalConfirmation.title"
        :descriptions="modalConfirmation.descriptions"
        :new-price="modalConfirmation.priceTo"
        :regular-price="modalConfirmation.priceFrom"
        :strong-word="modalConfirmation.strongWord"
        :not-confirm="modalConfirmation.notConfirm"
        @actionExecuted="handleModalActionExecuted"
        @onChangeVisible="handleChangeVisibleConfirmation"
        :name-button="modalConfirmation.nameButton"
        :type-button="modalConfirmation.typeButton"
      >
        <template v-if="modalConfirmation.icon" v-slot:icon>
          <a-icon
            :type="modalConfirmation.icon.type"
            :style="modalConfirmation.icon.styles"
          />
        </template>
      </ModalConfirmation>
      <ModalCreateAccount
        @onSubmit="handleSubmit"
        :loadingForm="loadingModalAccount"
        class="btn"
        :success="successCreate"
        :visible="modalCreateAccountVisible"
        @open="modalCreateAccountVisible = true"
        @cancel="modalCreateAccountVisible = false"
      />
      <ModalUpdateAccount
        :visible="visibleModalUpdate"
        @onSubmit="handleSubmit"
        :account="valuesFormUpdate"
        @onClose="handleCloseModalUpdate"
        :assignedLines="newFormattedAssignedLines"
        @onChangeActive="handleChangeActive"
        @onDelete="handleOpenDelete"
        :loadingForm="loadingModalAccount"
      />
    </div>
    <AccountList
      :sectionAccounts="sectionAccounts"
      :data="arrNewData"
      :totalData="totalAccounts"
      :columns="columns.nameColumns"
      @onOpenModal="handleOpenModalUpdate"
      @onOpenBuyAccount="handleOpenModalBuyAccount"
      @onActiveAccount="handleChangeActive"
    />
    <ModalPurchaseAccount
      :account="selectedAccount"
      :enable="openModalBuyAccount"
      @done="handlePurchaseDone"
      @updateList="onSearch('')"
      @cancel="handlePurchaseCancel"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import vari from '@/app/shared/utils/variables'
import AccountList from '@/app/accounts/components/AccountList.vue'
import transformMixin from '@/app/shared/mixins/transform'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import accountsMixin from '@/app/accounts/mixins/accountsMixin'
import moment from 'moment'
moment.locale('es')
import ModalCreateAccount from '@/app/accounts/components/modals/ModalCreateAccount.vue'
import ModalUpdateAccount from '@/app/accounts/components/modals/ModalUpdateAccount.vue'
import ModalPurchaseAccount from '@/app/accounts/components/modals/ModalPurchaseAccount.vue'
import ModalConfirmation from '@/app/accounts/components/modals/ModalConfirmation.vue'
import UserMarketServices from '../services/user_market_services'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import {
  STATUS_ACCOUNT,
  STATUS_PAYMENT,
  USERS,
} from '@/app/accounts/utils/accountsUtil'

export default {
  name: 'SectionAccounts',
  components: {
    AccountList,
    ModalCreateAccount,
    ModalUpdateAccount,
    ModalPurchaseAccount,
    ModalConfirmation,
    HeaderModule,
  },
  data: () => ({
    STATUS_ACCOUNT,
    STATUS_PAYMENT,
    vari,
    modalCreateAccountVisible: false,
    moment: moment,
    columns: {
      nameColumns: [
        {
          title: 'N°',
          dataIndex: 'number',
          key: 'number',
          scopedSlots: { customRender: 'number' },
          width: 60,
          fixed: 'left',
        },
        {
          title: 'Tipo',
          dataIndex: 'type',
          key: 'type',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
          fixed: 'left',
        },
        {
          title: 'Nombres',
          dataIndex: 'names',
          key: 'names',
          ellipsis: true,
        },
        {
          title: 'Apellidos',
          dataIndex: 'surnames',
          key: 'surnames',
          ellipsis: true,
        },
        {
          title: 'Correo',
          dataIndex: 'email',
          key: 'email',
          with: 220,
          ellipsis: true,
        },
        {
          title: 'Teléfono',
          dataIndex: 'phone',
          key: 'phone',
          // width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'phone' },
        },
        {
          title: 'Colas',
          dataIndex: 'quantity_queues',
          key: 'quantity_queues',
          width: 60,
        },
        {
          title: 'Estado del usuario',
          dataIndex: 'active',
          key: 'active',
          scopedSlots: { customRender: 'active' },
          width: 150,
        },
        {
          title: 'Estado de pago',
          dataIndex: 'status_payment',
          key: 'status_payment',
          scopedSlots: { customRender: 'status_payment' },
          width: 120,
        },
        {
          title: 'Fecha de creación',
          dataIndex: 'created_at',
          key: 'created_at',
          ellipsis: true,
          // width: 140,
        },
        {
          title: 'Acciones',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ],
    },
    _idAccountSelected: null, // Id de la cuenta seleccionada
    accountStatusSelected: 'all', // Filtro de activo o inactivo selecionado
    userTypeSelected: null, // Filtro segun tipo de usuario
    search: '', // Busqueda personalizada por nombres
    sectionAccounts: {
      modal: {
        loading: {
          button: false,
        },
      },
      createoredit: {
        names: null,
        surnames: null,
        username: null,
        password: null,
        gender: null,
        phone: null,
        email: null,
        type: null,
        active: true,
        ticketsAsigned: {},
        company: {},
        externals: [],
      },
      list: {
        account_status: 'all',
        type_user: null,
        pagination: 1,
        search: null,
        account_payment_status: 'all',
        data: [],
        loading: {
          table: false,
        },
      },
    },
    activeAccountValue: null,
    masterAgentMode: null,
    /** Formato de Codigos de paises para el combo */
    countrySelected: null,
    type_role: null, // Rol o tipo de usuario seleccionado
    owner: false, // si tiene el permiso de owner
    successCreate: false,
    visibleModalUpdate: false,
    valuesFormUpdate: {}, // valores del formulario de editar
    openModalBuyAccount: false,
    modalConfirmation: {
      visible: false,
      nameButton: 'Inactivar',
    },
    selectedAccount: null,
    loadingModalAccount: false,
    newFormattedAssignedLines: [],
    gold_6: '#FAAD14',
    red_6: '#f5222d',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  mixins: [transformMixin, attemptMixin, compareMixin, accountsMixin],
  computed: {
    ...mapGetters([
      'lines',
      'accounts',
      'totalAccounts',
      'profile',
      'company',
      'countries',
      'permissions',
    ]),
    // crea un nuevo array a partir de la data
    arrNewData() {
      const arrData = []
      // for(let i = 0; i < this.accounts.length; i++){
      for (let i = 0; i < this.sectionAccounts.list.data.length; i++) {
        arrData.push({
          key: this.accounts[i]._id,
          _id: this.accounts[i]._id,
          userId: this.accounts[i].userId,
          number: i + 1,
          type: {
            value: this.accounts[i].type,
            option: this.transformTypeUser(
              this.accounts[i].type,
              this.accounts[i].subType || null
            ),
          },
          names: this.accounts[i].names,
          surnames: this.accounts[i].surnames,
          username: this.accounts[i].username,
          email: this.accounts[i].email,
          gender: this.accounts[i].gender,
          // this.accounts[i].gender === 'female' ? 'Femenino' : 'Masculino',
          code: this.accounts[i].code,
          phone: this.accounts[i].phone,
          created_at: moment(this.accounts[i].created_at).format('LL'),
          quantity_queues: this.accounts[i].company.linesId
            ? this.accounts[i].company.linesId.length
            : 0,
          linesId: this.accounts[i].company.linesId
            ? this.accounts[i].company.linesId
            : [],
          active: this.accounts[i].active,
          externals: this.accounts[i].externals,
          socialLogin: this.accounts[i].socialLogin,

          owner: this.accounts[i].owner,
          status_payment: this.accounts[i].payment
            ? this.accounts[i].payment.status
            : '',
          agentMode: this.accounts[i].agentMode,
          subType: this.accounts[i].subType || null,
        })
      }
      return arrData // retorna un array de objetos
    },
  },
  methods: {
    ...mapActions([
      'createAccount',
      'updatedAccount',
      'listAccounts',
      'getLine',
      'getAssignedLines',
      'getAccounts',
      'inactiveUser',
      'activeUser',
      'deleteAccount',
    ]),
    ...mapMutations(['SET_INTRODUCTORY_MODAL']),

    /** Cuando termina de escriir en el buscador */
    onInput(e) {
      let value = e.target.value
      this.endWaitTyping(() => {
        this.onSearch(value)
      }, 500)
    },
    /** Cuando se presiona enter en el buscador */
    onSearch(value) {
      this.sectionAccounts.list.pagination = 1
      this.sectionAccounts.list.search = value
      this.getAccounts({ self: this })
    },

    // submit de form
    async handleSubmit(values, typeAction) {
      this.loadingModalAccount = true
      /* si el id en el objeto de user en lista existe dentro de la toda la data entonces se edita*/
      const type_role = USERS[values.typeUser].for_endpoint
      this.sectionAccounts.createoredit.names = values.names
      this.sectionAccounts.createoredit.surnames = values.surnames
      this.sectionAccounts.createoredit.username = values.username
      this.sectionAccounts.createoredit.email = values.email
      this.sectionAccounts.createoredit.password = values.password
      this.sectionAccounts.createoredit.gender = values.gender
      this.sectionAccounts.createoredit.code = values.code
      this.sectionAccounts.createoredit.phone = values.phone
      this.sectionAccounts.createoredit.agentMode = values.agentMode
      this.sectionAccounts.createoredit.active = true
      this.sectionAccounts.createoredit.externals = []
      // TODO: mejorar Integración con CRM externo para multi-integraciones
      if (values.id_user_external_crm) {
        const external = {
          companyIntegrationId:
            values.externalCRMIntegration.companyIntegrationId,
          integrationId: values.externalCRMIntegration.integrationId,
          platform: values.externalCRMIntegration.platform,
          field: {
            name: values.externalCRMIntegration.field.name,
            key: values.externalCRMIntegration.field.key,
          },
        }
        external[values.externalCRMIntegration.field.key] =
          values.id_user_external_crm
        this.sectionAccounts.createoredit.externals.push(external)
      }
      this.sectionAccounts.createoredit.company = {
        linesId: values.lines,
      }
      if (typeAction === 'create') {
        Object.assign(this.sectionAccounts.createoredit, {
          type: !this.isAllowedFor(this.profile.type, ['supervisor'])
            ? values.typeUser
            : 'agent',
          company: {
            linesId: values.lines,
          },
        })
        const response = await this.createAccount({ self: this, type_role })
        this.loadingModalAccount = false
        if (response?.result != 'overflow') return
        // Que pasa cuando no se puede crear pq falta pagar
        this.openModalBuyAccount = true
        this.selectedAccount = {
          new: true,
          ...this.sectionAccounts.createoredit,
        }
      } else if (typeAction === 'update') {
        let current_account = this.accounts.find(
          (v) => v._id === this._idAccountSelected
        )
        Object.assign(this.sectionAccounts.createoredit, {
          // gender: values.gender,
          type: current_account.type,
          userId: current_account.userId,
          _id: this._idAccountSelected,
          active: values.active === 'active' ? true : false,
        })
        await this.updatedAccount({
          self: this,
          companyIntegrationId:
            values.externalCRMIntegration?.companyIntegrationId,
        })
      }
      this.loadingModalAccount = false
    },
    // evento que edita
    async handleOpenModalUpdate(record) {
      this._idAccountSelected = record._id
      this.valuesFormUpdate = {
        names: record.names,
        surnames: record.surnames,
        username: record.username,
        gender: record.gender,
        typeUser: record.type.value,
        email: record.email,
        active: record.active ? 'active' : 'inactive',
        phone: record.phone,
        code: record.code,
        assignedLines: record.linesId,
        userId: record.userId,
        account: record.account,
        owner: record.owner,
        id_user_external_crm: record.externals.length
          ? record.externals[0][record.externals[0].field?.key]
          : undefined,
        socialLogin: record.socialLogin,
        agentMode: record.agentMode,
        subType: record.subType || null,
      }
      this.masterAgentMode = record.agentMode
      this.activeAccountValue = record.active
      // Valida si el usuario está habilitado para tener asignadas colas
      const enabledAssignedLines =
        USERS[this.valuesFormUpdate.typeUser].assign_lines
      this.formattedAssignedLines(
        record.linesId,
        this.lines,
        enabledAssignedLines
      )
      this.visibleModalUpdate = true
    },
    /** Se ejecuta al cambio de una de las opciones del select del filtro por 'activo' o 'inactivo' */
    handleChangeStatusAccount(value) {
      this.sectionAccounts.list.account_status = value
      //
      this.getAccounts({ self: this })
    },
    handleChangeStatusPayment(value) {
      this.sectionAccounts.list.account_payment_status = value
      //
      this.getAccounts({ self: this })
    },
    /** Se ejecuta al cambio del select del filtro por tipo de usuario */
    handleChangeTypeAccount(value) {
      value = value === 'allUsers' ? null : value
      this.sectionAccounts.list.type_user = value
      //
      this.getAccounts({ self: this })
    },
    handleConfirm(gender, name, mensaje) {
      let self = this
      const textSure = this.transformWordGender('seguro', gender)
      this.$confirm({
        title: `¿Estás ${textSure} de inactivar a ${name}?`,
        content: <p>{`${mensaje}`}</p>,
        okText: 'Inactivar',
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          await self.updatedAccount({ self: self })
        },
        class: 'test',
      })
    },
    /**
     * Selecciona el código de país
     * @param {String} key Nombre del atributo a buscar
     * @param {String} value Valor del código seleccionado
     */
    handleCountry(key, value) {
      this.countrySelected = this.countries.find(
        (country) => country[key] === value
      )
    },
    handleCloseModalUpdate() {
      this.visibleModalUpdate = false
      this.valuesFormUpdate = {}
    },
    /**
     * Formateo de colas asignadas
     * @param {Array} idAssignedLines - id de colas seleccionadas
     * @param {Array} lines - todas las colas
     * @param {Boolean} enabledAssign - habilitado o no para tener colas asignadas
     */
    formattedAssignedLines(idAssignedLines, lines, enabledAssign) {
      this.newFormattedAssignedLines = []
      // verifica si los administradores no tienen modo agente
      if (!enabledAssign) return []
      // si es que tienen el modo agente activo
      idAssignedLines.map(async (idAssignedLine) => {
        const newObj = {}
        let lineFound = lines.find((line) => line._id === idAssignedLine)
        if (!lineFound) {
          lineFound = await this.getLine(idAssignedLine)
          newObj.disabled = true
        } else {
          newObj.disabled = false
        }

        newObj.key = lineFound._id
        newObj.title = lineFound.name
        this.newFormattedAssignedLines.push(newObj)
      })
    },
    handleOpenModalBuyAccount(account) {
      this.openModalBuyAccount = true
      const selectedAccount = this.accounts.find((v) => v._id === account._id)
      this.selectedAccount = selectedAccount
    },
    handlePurchaseDone() {
      this.openModalBuyAccount = false
      this.selectedAccount = null
      this.handleCloseModalUpdate()
      this.getAccounts({ self: this })
      this.modalCreateAccountVisible = false
    },
    handlePurchaseCancel() {
      this.openModalBuyAccount = false
      this.selectedAccount = null
      this.enable = false
    },
    async handleChangeActive(active, fullname, accountId) {
      const textSure = this.transformWordGender('seguro', this.profile.gender)
      if (accountId) {
        this._idAccountSelected = accountId
      }
      this.modalConfirmation.active = active
      this.modalConfirmation.typeButton = 'primary'
      this.modalConfirmation.typeButton = 'primary'
      this.modalConfirmation.icon = null
      if (active === true) {
        const totalNewPrice = await UserMarketServices.getNewPlanPrice()
        this.modalConfirmation.visible = true
        this.modalConfirmation.title = `¿Estás ${textSure} de inactivar a  “${fullname}”?`
        this.modalConfirmation.descriptions = [
          'Recuerda que se cerrará la sesión del usuario y no tendrá acceso al sistema. Sus tickets activos serán reasignados a sus colas respectivas.',
        ]
        if (totalNewPrice.result != 0) {
          this.modalConfirmation.descriptions.push(
            'Recuerda que el nuevo precio de tu plan bajará:'
          )
        }
        this.modalConfirmation.strongWord = 'Inactivar'
        this.modalConfirmation.priceFrom = `$${this.company.settings.subscription?.total}`
        this.modalConfirmation.priceTo = `$${totalNewPrice.result}`
        this.modalConfirmation.nameButton = 'Inactivar usuario'
        this.modalConfirmation.notConfirm = false
      }
      if (active === false) {
        const selectedAccount = this.accounts.find(
          (v) => v._id === this._idAccountSelected
        )
        const isPayed = await UserMarketServices.userIsPayed(
          selectedAccount.userId
        )
        const totalNewPrice = await UserMarketServices.getNewPlanPrice(1)
        this.modalConfirmation.visible = true
        this.modalConfirmation.title = `¿Estás ${textSure} de activar a  “${fullname}”?`
        this.modalConfirmation.descriptions = []
        if (totalNewPrice.result != 0) {
          if (isPayed.result)
            this.modalConfirmation.descriptions = [
              'Recuerda que este usuario ya ha sido pagado en tu actual ciclo de facturación por lo que no te volveremos a cobrar.',
            ]
          else
            this.modalConfirmation.descriptions = [
              'Recuerda que se agregará este usuario a tu plan y  el nuevo precio pasará ',
            ]
        }
        this.modalConfirmation.notConfirm = true
        this.modalConfirmation.strongWord = 'Activar'
        this.modalConfirmation.priceFrom = `$${this.company.settings.subscription?.total}`
        this.modalConfirmation.priceTo = `$${totalNewPrice.result}`
        this.modalConfirmation.nameButton = 'Activar usuario'
      }
    },
    handleChangeVisibleConfirmation(visible) {
      this.modalConfirmation.visible = visible
      delete this.modalConfirmation.active
      delete this.modalConfirmation.title
      delete this.modalConfirmation.descriptions
      delete this.modalConfirmation.strongWord
      delete this.modalConfirmation.nameButton
      delete this.modalConfirmation.typeButton
      delete this.modalConfirmation.icon
    },
    async handleModalActionExecuted() {
      this.modalConfirmation.visible = false
      if (
        Object.prototype.hasOwnProperty.call(this.modalConfirmation, 'active')
      ) {
        this.handleExecutedActivate()
      } else {
        this.handleDeleteAccount()
      }
    },
    async handleOpenDelete(typeUser, userId, fullname, active) {
      this.modalConfirmation.priceFrom = null
      this.modalConfirmation.priceTo = null
      const totalNewPrice = await UserMarketServices.getNewPlanPrice()
      const textSure = this.transformWordGender('seguro', this.profile.gender)
      this.modalConfirmation.visible = true
      this.modalConfirmation.title = `¿Estás ${textSure} de eliminar  a  “${fullname}”?`
      this.modalConfirmation.descriptions = [
        'Recuerda que se cerrará la sesión del usuario y no tendrá acceso al sistema. Sus tickets activos serán reasignados a sus colas respectivas.',
      ]
      if (totalNewPrice.result != 0 && active == 'active') {
        this.modalConfirmation.descriptions.push(
          'Recuerda que el nuevo precio de tu plan bajará:'
        )
      }
      this.modalConfirmation.notConfirm = false
      this.modalConfirmation.strongWord = 'Eliminar'
      this.modalConfirmation.nameButton = 'Eliminar cuenta'
      this.modalConfirmation.typeButton = 'danger'
      this.modalConfirmation.icon = {
        type: 'delete',
        styles: { color: this.red_6, fontSize: '64px' },
      }
      this.modalConfirmation.typeUser = typeUser
      this.modalConfirmation.userId = userId
      if (active == 'active') {
        this.modalConfirmation.priceFrom = `$${this.company.settings.subscription.total}`
        this.modalConfirmation.priceTo = `$${totalNewPrice.result}`
      }
      delete this.modalConfirmation.active
    },
    async handleExecutedActivate() {
      this.modalConfirmation.visible = false
      const selectedAccount = this.accounts.find(
        (v) => v._id === this._idAccountSelected
      )
      selectedAccount.new = false
      const key = 'inactive-user'
      const text = !this.modalConfirmation.active
        ? 'Activando usuario'
        : 'Inactivando usuario'
      this.$message.loading({ key, content: text })
      if (!this.modalConfirmation.active) {
        // activar usuario
        const totalNewPrice = await UserMarketServices.getNewPlanPrice(1)

        const response = await UserMarketServices.userIsPayed(
          selectedAccount.userId
        )
        if (response.result || totalNewPrice.result == 0) {
          this.$message.loading({ key, content: text })
          const result = await this.activeUser(selectedAccount.userId)
          if (result.success) {
            this.$message.success({ key, content: 'Usuario activado' })
            this.openModalUpdate = false
            this.getAccounts({ self: this })
          } else
            this.$message.warning({
              key,
              content: this.modalConfirmation.active
                ? 'No puedes inactivar este usuario'
                : 'No puedes activar este usuario',
            })
        } else {
          this.openModalBuyAccount = true
          this.selectedAccount = selectedAccount
        }
        this.handleCloseModalUpdate()

        return
      }
      this.$message.loading({ key, content: text })

      const result = await this.inactiveUser(selectedAccount.userId)
      if (result.success) {
        this.$message.success({ key, content: 'Usuario inactivado' })
        this.openModalUpdate = false
        delete this.modalConfirmation.active
        this.getAccounts({ self: this })
        this.handleCloseModalUpdate()

        return
      }
      this.$message.error({
        key,
        content: 'No pudimos inactivar este usuario',
      })

      // inactivar usuario
    },
    // Elimina la cuenta de un usuario
    async handleDeleteAccount() {
      const keyMessage = 'delete-user'
      this.$message.loading({ key: keyMessage, content: 'Eliminando usuario' })
      this.loadingModalAccount = true
      const response = await this.deleteAccount({
        typeUser: USERS[this.valuesFormUpdate.typeUser].for_endpoint_delete,
        userId: this.valuesFormUpdate.userId,
      })
      this.loadingModalAccount = false
      if (response.success) {
        this.visibleModalUpdate = false
        this.$message.success({
          key: keyMessage,
          content: 'Se elimino el usuario correctamente',
        })
        this.getAccounts({ self: this })
      } else {
        this.$message.error({
          key: keyMessage,
          content: response.details || 'No se pudo eliminar 😥',
        })
      }
    },
    handleOpenIntroducction() {
      const modal = {
        visible: true,
        description:
          'In voluptate elit excepteur sint adipisicing non aliqua nostrud.',
      }
      this.SET_INTRODUCTORY_MODAL(modal)
    },
  },
}
</script>

<style lang="sass" scoped>
.section-accounts
  padding-top: 8px
  h5
    text-align: left
    color: $gray_dark_900
  .first-section
    margin-bottom: 12px
    display: flex
    flex-direction: row
    width: 100%
    .btn
      position: absolute
      right: 12px
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>

<style lang="sass">
.ant-alert-close-text
  font-size: 14px
  color: #1890FF!important
.ant-form-explain
  margin-top: 2px!important
  margin-bottom: -5px!important
  color: #F5222D!important
.ant-modal-footer
  text-align: center!important
.ant-modal-body
  &::-webkit-scrollbar
    width: 10px
  &::-webkit-scrollbar-track
    background: transparent
    border-left: solid 5px transparent
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    box-shadow: inset 0 0 10px 10px $gray_dark_100
    border: solid 3px transparent
  &::-webkit-scrollbar-thumb:hover
    box-shadow: inset 0 0 10px 10px $gray_dark_100
.ant-table-pagination.ant-pagination
  margin-bottom: 0px!important
.input-number-phone
  .ant-input:not(:first-child)
    padding-left: 46px!important
</style>
